import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import { dataLabels } from "../../configs/TechnicalData";
import { sentenceCase } from "../../utils/namesManipulation";
import { SecondaryNavLink } from "../SecondaryNavLink";

const NavMenuItem = ({ isActive, isDisabled, label, onClick }) => {
  return (
    <li className="mt-2 px-2" onClick={onClick}>
      <SecondaryNavLink className="h-10 w-full" isActive={isActive} isDisabled={isDisabled}>
        {label}
      </SecondaryNavLink>
    </li>
  );
};

const NavMenuItemGroup = ({ activeTab, childrenLabels, enabledTabs, groupLabel, setActiveTab }) => {
  const { t } = useTranslation();
  return (
    <li>
      <h3 className="t-heading-l0 border-b-gray-100 px-4 py-2  ">{groupLabel}</h3>
      <ul className="mb-4">
        {childrenLabels.map((label, index) => {
          return (
            <NavMenuItem
              isActive={label === activeTab}
              isDisabled={!enabledTabs?.includes(label)}
              key={index}
              label={t(`TechnicalData.nav.${label}`)}
              onClick={() => {
                if (enabledTabs?.includes(label)) setActiveTab(label);
              }}
            />
          );
        })}
      </ul>
    </li>
  );
};
export const NavMenu = ({ activeTab, className, enabledTabs, setActiveTab }) => {
  const { t } = useTranslation();
  return (
    <Paper className={className}>
      <ul className="mb-4">
        {Object.keys(dataLabels)?.map((groupLabel, index) => {
          return (
            <NavMenuItemGroup
              activeTab={activeTab}
              childrenLabels={dataLabels[groupLabel]}
              enabledTabs={enabledTabs}
              groupLabel={sentenceCase(t(`TechnicalData.nav.${groupLabel}`))}
              key={index}
              setActiveTab={setActiveTab}
            />
          );
        })}
      </ul>
    </Paper>
  );
};
