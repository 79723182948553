import React, { ReactNode } from "react";
import styled from "styled-components";

type ChipProps = {
  children: ReactNode;
  className?: string;
  isOn?: boolean;
  onClick?: () => void;
  width?: number | string;
};

const Chip = ({ children, className, isOn, onClick, width }: ChipProps) => {
  return (
    <Wrapper className={`t-body-l ${className}`} isOn={isOn} onClick={onClick} tabIndex={0} width={width}>
      {children}
    </Wrapper>
  );
};

export default Chip;
const Wrapper = styled("div")(({ isOn, width }: { isOn?: boolean; width?: number | string }) => ({
  ":active": {
    background: `${isOn ? "var(--clr-secondary-blue-100)" : "var(--clr-secondary-blue-50)"}`,
    border: `1px solid ${isOn ? "var(--clr-secondary-blue-700)" : "var(--clr-secondary-blue-300)"}`,
    color: `${isOn ? "var(--clr-secondary-blue-700)" : "var(--clr-secondary-blue-500)"}`,
  },
  alignItems: "center",
  background: `${isOn ? "var(--clr-secondary-blue-50)" : ""}`,
  border: `1px solid ${isOn ? "var(--clr-secondary-blue-500)" : "var(--clr-gray-700)"}`,
  borderRadius: "4px",
  color: `${isOn ? "var(--clr-secondary-blue-500)" : "var(--clr-gray-900)"}`,
  cursor: "pointer",
  // transition: "all 0.003s ease-out",
  display: "flex",
  height: "3.2rem",
  justifyContent: "center",
  padding: "0.65rem 1.2rem",
  width: width,
}));
