export default {
  browse: "durchsuchen",
  cancel: "abbrechen",
  downloadFileProblem: "Verzeihung! Beim Herunterladen der Dateien ist ein Problem aufgetreten.",
  dragToUpload: "Ziehen Sie Ihr Bild per Drag & Drop oder durchsuchen Sie es, um es hochzuladen.",
  dragToUploadFile: "Ziehen Sie Ihre Datei per Drag-and-Drop oder durchsuchen Sie sie, um sie hochzuladen.", // TODO: make completely sure
  getDataProblem: "Beim Abrufen der Daten ist ein Problem aufgetreten!",
  noRecords: "Es sind keine Datensätze vorhanden!",

  OK: "Okay",
  sorry: "sorry",
  successfulFileUpload: "Ihre Datei wurde erfolgreich hochgeladen!", // TODO: make completely sure

  successfulImgaeUpload: "Ihr Bild wurde erfolgreich hochgeladen!",
  upload: "hochladen", // TODO: make completely sure
  uploadFiles: "Dateien hochladen",
  uploadImage: "Bild hochladen",
  uploadingFile: "Datei wird hochgeladen",
};
