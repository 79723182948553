import assets from "./assets";
import buildingImage from "./buildingImage";
import buildingUsage from "./buildingUsage";
import certificates from "./certificates";
import dynamicDashboard from "./dynamicDashboard";
import energyMix from "./energyMix";
import energyPerformance from "./energyPerformance";
import energyPerformanceNew from "./energyPerformanceNew";
import files from "./files";
import kpis from "./kpis";
import layerFiles from "./layerFiles";
import modernization from "./modernization";
import performanceIndicator from "./performanceIndicator";
import technicalData from "./technicalData";
import threeDViewer from "./threeDViewer";
import translation from "./translation";
import user from "./user";
import userFiles from "./userFiles";

export default {
  assets,
  buildingImage,
  buildingUsage,
  certificates,
  dynamicDashboard,
  energyMix,
  energyPerformance,
  energyPerformanceNew,
  files,
  kpis,
  layerFiles,
  modernization,
  performanceIndicator,
  technicalData,
  threeDViewer,
  translation,
  user,
  userFiles,
};
