import { Stack } from "@mui/material";

import { Button } from "./ButtonNew";
import { Icon } from "./Icon";

export const ComponentsCommonHeader = ({ action, actionIsDisabled, actionText, className, hasActionAccess, title }) => {
  return (
    <Stack className={`justify-between py-3 ${className}`}>
      <span className="t-heading-l  ">{title}</span>
      {hasActionAccess && (
        <Button disabled={actionIsDisabled} onClick={action} size="sm" variant="tertiary">
          <Stack>
            <Icon color="var(--clr-secondary-blue-500)" disabled={actionIsDisabled} iconName="Download" />
            <p className="t-body-m ml-1.5">{actionText}</p>
          </Stack>
        </Button>
      )}
    </Stack>
  );
};
