import { createSlice } from "@reduxjs/toolkit";

const defaultUnitsState = { consumption: "kWh", emission: "KgCO₂", price: "€" };

const initialState = {
  chartData: [],
  config: {
    allUnits: defaultUnitsState,
    bobResourcesOptions: ["heating", "cooling"],
    dates: [new Date(null), new Date(null)],
    hourlyDates: [new Date(null), new Date(null)],
    measureType: ["simulated"],
    quantity: "consumption",
    resourcesOptions: ["electricity", "hot_water", "heating", "cooling"],
    selectedResourcesOptions: ["electricity", "hot_water", "heating", "cooling"],
    springhofResourcesOptions: ["heating"],
    timeframe: "monthly",
  },
};

const energyPerformanceSlice = createSlice({
  initialState,
  name: "energyPerformance",
  reducers: {
    setChartData: (state, { payload }) => {
      state.chartData = payload;
    },
    setConfig: (state, { payload }) => {
      state.config[payload.key] = payload.value;
    },
  },
});

export const { setChartData, setConfig } = energyPerformanceSlice.actions;
export default energyPerformanceSlice.reducer;
