import React from "react";
import styled from "styled-components";
export const Checkbox = ({ checked, children, className, onChange }) => {
  return (
    <Wrapper className={className}>
      <label className="container flex items-center">
        {children}
        <input checked={checked} onChange={onChange} type="checkbox" />
        <span className="checkmark" />
      </label>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  display: block;
  position: relative;
  padding-left: 1.5rem;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    left: 0;
    height: 1.6rem;
    width: 1.6rem;
    border: 1px solid var(--clr-gray-700);
    background-color: var(--clr-white);
    border-radius: 2px;
    cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  label:hover input ~ .checkmark {
    border: 1px solid var(--clr-secondary-blue-300);
    box-shadow: 0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059),
      0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11);
  }
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    border: 1px solid var(--clr-secondary-blue-500);
  }

  label:hover input:checked ~ .checkmark {
    border: 1px solid var(--clr-secondary-blue-300);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 4px;
    top: 0px;
    width: 4.5px;
    height: 9px;
    border: 1px solid var(--clr-secondary-blue-500);
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
