import { Stack } from "@mui/material";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Dropdown, SearchInput } from "..";
import { setFilters, setSearchTerm } from "../../features/allBuildings/allBuildingsSlice";
import { eventNames, trackGAEvent, withGAEventTracking } from "../../functions/googleAnalytics";
import { theme } from "../../styles/theme";

export const FilterContainer = ({ certificatesOptions, locationsOptions, noOfCards }) => {
  const { t } = useTranslation();
  const { filters, searchTerm } = useSelector((store) => store.allBuildings);
  const dispatch = useDispatch();

  const [searchTermRef, setSearchTermRef] = useState("");

  const [certificateValues, setCertificateValues] = useState(filters.certificate.values);
  const [locationValues, setLocationValues] = useState(filters.location.values);
  const [typeValues, setTypeValues] = useState(filters.type.values);
  const [yearValues, setYearValues] = useState(filters.year.values);
  const [energyLabelValues, setEnergyLabelValues] = useState(filters.energyLabel.values);

  const allFiltersValues = {
    certificate: certificateValues,
    energyLabel: energyLabelValues,
    location: locationValues,
    type: typeValues,
    year: yearValues,
  };

  const waitTime = 5000;
  const allFiltersSetValues = {
    certificate: setCertificateValues,
    energyLabel: setEnergyLabelValues,
    location: setLocationValues,
    type: setTypeValues,
    year: setYearValues,
  };

  const onFilterChange = useMemo(() => debounce(() => trackGAEvent(eventNames.AO_FILTERS_CHANGE), waitTime), []);
  useEffect(() => {
    dispatch(setFilters(allFiltersValues));
    dispatch(setSearchTerm(searchTermRef));
  }, [allFiltersValues, searchTermRef]);

  const clearFilters = () => {
    setEnergyLabelValues([]);
    setLocationValues([]);
    setTypeValues([]);
    setCertificateValues([]);
    setYearValues([]);
    setSearchTermRef("");
  };
  return (
    <section>
      <div className="mt-4 flex flex-wrap justify-between">
        {Object.keys(allFiltersValues)?.map((filterName, index) => {
          return (
            <Dropdown
              displayOptions={filters[filterName].displayOptions}
              key={index}
              label={t(`AssetOverview.filters.${filters[filterName].label}`)}
              options={
                filterName === "location"
                  ? locationsOptions
                  : filterName === "certificate"
                  ? certificatesOptions
                  : filters[filterName].options
              }
              setValues={(newValue) => {
                allFiltersSetValues[filterName](newValue);
                onFilterChange();
              }}
              values={allFiltersValues[filterName]}
              width="15%"
             />
          );
        })}
        <SearchInput
          height="4rem"
          label={t(`AssetOverview.filters.search`)}
          onChange={(e) => setSearchTermRef(e.target.value)}
          type="search"
          value={searchTermRef}
          width="20%"
        />
      </div>
      <Stack className="mt-5" spacing={theme.spacing(7)}>
        <span className="t-heading-m  ">
          {noOfCards} {t("AssetOverview.filters.buildings")}
        </span>
        <span
          className="t-heading-s cursor-pointer text-gray-500"
          onClick={() => withGAEventTracking(eventNames.AO_FILTERS_RESET, {}, clearFilters)}
        >
          {t("AssetOverview.filters.resetFilters")}
        </span>
      </Stack>
    </section>
  );
};
