import { FilterTypes } from "../enums/components";
import { dateToUCTDatetime } from "../utils/date";
import { DateRangePickerNew } from "./DateRangePickerNew";
import { Dropdown } from "./Dropdown";
import { DropdownSingleSelect } from "./DropdownSingleSelect";
import { Input } from "./Input";
import { NumericRangePicker } from "./NumericRangePicker";

class DynamicFilterBase {
  constructor(args) {
    this.args = args;
    if (!this.args.setNewValue) this.args.setNewValue = (newValue) => args.setConfig(this.args.filterName, newValue);
  }
  createComponent() {}
  filterData(data) {
    return data;
  }
  getComponentProps() {
    const { props } = this.args;
    return props;
  }
  getValue() {
    return this.args.config[this.args.filterName];
  }
}

class DynamicFilterColorInput extends DynamicFilterBase {
  constructor(args) {
    super(args);
  }

  createComponent() {
    return (
      <input
        className="block w-3/4 "
        onChange={(e) => {
          this.args.setNewValue(e.target.value);
        }}
        type="color"
        value={this.getValue()}
      />
    );
  }
  filterData(data) {
    return data;
  }
}

class DynamicFilterDateRangePicker extends DynamicFilterBase {
  constructor(args) {
    super(args);
  }
  createComponent() {
    return (
      <DateRangePickerNew onChange={this.args.setNewValue} value={this.getValue()} {...this.getComponentProps()} />
    );
  }
  filterData(data, dataKey) {
    const dates = this.getValue();
    if (!dates) return data;

    const dataDateIsString = data?.some((row) => typeof row[dataKey] === "string");
    const dataDateIsStringWithoutTime = dataDateIsString && data?.some((row) => row[dataKey]?.length === 10);

    const minDate = dateToUCTDatetime(dates[0]);
    let maxDate = dates[1];
    maxDate.setDate(maxDate.getDate() + 1);
    const dayAfterMaxDate = dateToUCTDatetime(maxDate);
    return data?.filter((row) => {
      const datetimeString = row[dataKey] + (dataDateIsStringWithoutTime ? "T00:00:00" : "");
      const datetime = dataDateIsString ? new Date(datetimeString) : row[dataKey];

      const isUpperThanMin = !dates[0] || datetime >= minDate;
      const isLowerThanMax = !dates[1] || datetime < dayAfterMaxDate;
      return isUpperThanMin && isLowerThanMax;
    });
  }
  getValue() {
    const selectedDates = super.getValue();
    const startDate = selectedDates?.[0] || this.args.props.minDate;
    const endDate = selectedDates?.[1] || this.args.props.maxDate;
    return [new Date(startDate), new Date(endDate)];
  }
}

class DynamicFilterDropdownMulti extends DynamicFilterBase {
  constructor(args) {
    super(args);
  }
  createComponent() {
    return <Dropdown setValues={this.args.setNewValue} values={this.getValue() ?? []} {...this.getComponentProps()} />;
  }
  filterData(data, dataKey) {
    const selectedValues = this.getValue();
    return data?.filter((row) => !selectedValues?.length || selectedValues.includes(row[dataKey]));
  }
}

class DynamicFilterDropdownSingle extends DynamicFilterBase {
  constructor(args) {
    super(args);
  }

  createComponent() {
    return (
      <DropdownSingleSelect
        selectedValue={this.getValue()}
        setSelectedValue={this.args.setNewValue}
        {...this.getComponentProps()}
      />
    );
  }
  filterData(data, dataKey) {
    return data?.filter((row) => row[dataKey] === this.getValue() || this.getValue() === undefined);
  }
}

class DynamicFilterInput extends DynamicFilterBase {
  constructor(args) {
    super(args);
  }

  createComponent() {
    return (
      <Input
        onChange={(e) => this.args.setNewValue(e.target.value)}
        value={this.getValue()}
        {...this.getComponentProps()}
      />
    );
  }
  filterData(data) {
    return data;
  }
}

class DynamicFilterNumericRangePicker extends DynamicFilterBase {
  constructor(args) {
    super(args);
  }
  createComponent() {
    return (
      <NumericRangePicker setValue={this.args.setNewValue} value={this.getValue()} {...this.getComponentProps()} />
    );
  }

  filterData(data, dataKey) {
    const value = this.getValue();
    if (value?.[0] == undefined || value?.[1] == undefined) return data;
    return data?.filter((row) => {
      return value[0] <= row[dataKey] && row[dataKey] <= value[1];
    });
  }
}

export class DynamicFilterFactory {
  constructor(filterType, args) {
    this.filterType = filterType;
    this.args = args;
  }

  createFilterClassInstance() {
    if (this.filterType === FilterTypes.DROPDOWN_SINGLE) return new DynamicFilterDropdownSingle(this.args);
    if (this.filterType === FilterTypes.DROPDOWN_MULTI) return new DynamicFilterDropdownMulti(this.args);
    if (this.filterType === FilterTypes.DATERANGE_PICKER) return new DynamicFilterDateRangePicker(this.args);
    if (this.filterType === FilterTypes.INPUT) return new DynamicFilterInput(this.args);
    if (this.filterType === FilterTypes.COLOR_INPUT) return new DynamicFilterColorInput(this.args);
    if (this.filterType === FilterTypes.NUMERIC_RANGE_PICKER) return new DynamicFilterNumericRangePicker(this.args);
  }
}
