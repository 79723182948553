import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { FilterTypes } from "../enums/components";
import { cn } from "../utils/styling";
import { Button } from "./ButtonNew";
import { DynamicFilterFactory } from "./DynamicFormInput";

export type DynamicFormProps = {
  fields: FormField[];
  isSubmitting?: boolean;
  onSubmit: () => void;
  title?: string;
};
export type FormField = {
  config: Record<string, unknown>;
  filterLabel?: string;
  filterLabelClassName?: string;
  filterName: string;
  filterType: (typeof FilterTypes)[keyof typeof FilterTypes];
  props?: {
    [key: string]: any;
    displayOptions?: string[];
    label?: string;
    options?: string[];
    optionsGroups?: {
      group: string;
      options: string[];
    }[];
    placeholder?: string;
    type?: "number" | "string";
    width?: string;
  };
  required?: boolean;
  setConfig: (filterName: string, newValue: any) => void;
  visible?: () => boolean;
};

const DynamicForm: React.FC<DynamicFormProps> = ({ fields, isSubmitting, onSubmit, title }) => {
  const { t } = useTranslation();
  return (
    <Stack className="h-full" direction="column">
      {title && <h2 className="t-heading-l mb-4">{title}</h2>}
      <Stack className="min-h-0 w-full  flex-1 justify-between" direction="column">
        <Stack className="w-full flex-1 gap-2 overflow-y-auto p-2 text-left" direction="column">
          {fields?.map((field, index) => {
            if (!field.visible?.()) return;
            const { filterType, ...args } = field;
            const filterFactory = new DynamicFilterFactory(filterType, args);
            const filterInstance = filterFactory.createFilterClassInstance();
            if (filterInstance) {
              const FilterComponent = filterInstance.createComponent();
              return (
                <Stack className="w-full" justifyContent="space-between" key={index}>
                  <span className={cn("t-heading-m block w-1/3", field.filterLabelClassName)}>
                    <span className="text-red-500">{`${field.required ? "*" : ""}`}</span>
                    {field.filterLabel ?? field.filterName} :
                  </span>
                  {FilterComponent}
                </Stack>
              );
            }
          })}
        </Stack>
        <div className="mt-4">
          <Button isLoading={isSubmitting} onClick={onSubmit} size="md">
            {t("general.submit")}
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};

export default DynamicForm;
