const url = process.env.REACT_APP_API_URL + "/api/projects";
export default {
  certificationStatus: {
    list: ({ projectId }) => `${url}/${projectId}/get_certification_status_by_floor/`,
  },
  energyRatingStatus: {
    list: ({ projectId }) => `${url}/${projectId}/get_project_energy_rating_by_floor/`,
  },
  list: (projectId) => `${url}/${projectId}/get_project_certificate_list/`,
};
