import { CircularProgress, Stack } from "@mui/material";
import React from "react";
export const Loading = ({ label, style }) => {
  return (
    <div
      style={{
        left: "50%",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: "99",
        ...style,
      }}
    >
      <Stack className="h-10 gap-2" flexDirection="column">
        <CircularProgress />
        <span className="t-body-l">{label}</span>
      </Stack>
    </div>
  );
};
