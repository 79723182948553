import i18next from "i18next";

import apiUrls from "../api/urls";
import { ButtonNew, Icon } from "../components";
import { eventNames, trackGAEvent, withGAEventTracking } from "../functions/googleAnalytics";
import { customApiCall } from "../utils/axios";
import { addCommaToNumber } from "../utils/namesManipulation";

const downloadLayerFiles = ({ activeTab, layerId, projectId }) => {
  const url = apiUrls.layerFiles.techData[activeTab].downloadLayer(projectId, layerId);
  const fileName = `${activeTab} layer files ${layerId}.zip`;
  const errorMessage = i18next.t("general.downloadFileProblem");
  customApiCall.download({ errorMessage, fileName, url });
};

export const getTechTableConfig = ({ activeTab, projectId }) => {
  const wallAndRooftopColumns = [
    {
      cellType: null,
      dataKey: "description",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Description"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => i18next.t(row["description"]),
    },
    {
      cellType: null,
      dataKey: "area",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Area") + " (m²)",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => addCommaToNumber(row["area"]),
    },
    {
      cellType: "",
      dataKey: "last_checked",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.lastChecked"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["last_checked"],
    },

    {
      cellType: null,
      dataKey: "color",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Colour"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => i18next.t(row["color"]),
    },
    {
      cellType: "",
      dataKey: "modernization",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Modernization"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["modernization"],
    },
    {
      cellType: null,
      dataKey: "id",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Id"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["id"],
    },
    {
      cellType: null,
      dataKey: "installed",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Installed"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["installed"],
    },
    {
      cellType: null,
      dataKey: "u_value",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.uValue"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["u_value"],
    },
    {
      cellType: null,
      dataKey: "thickness",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Thickness") + " (m)",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["thickness"],
    },
    {
      cellType: "downloadZipFile",
      dataKey: "files",
      headerClassName: "sentence-case",
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.files"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => (
        <ButtonNew
          disabled={!row?.hasFiles}
          onClick={(row) => {
            withGAEventTracking(eventNames.TD_DOWNLOAD_PARENT, { tabName: activeTab }, () =>
              downloadLayerFiles({ activeTab, layerId: row.id, projectId })
            );
          }}
          size="sm"
          variant="tertiary"
        >
          <Icon color="var(--clr-secondary-blue-500)" disabled={!row?.hasFiles} iconName="Download" size="md" />
        </ButtonNew>
      ),
    },
  ];
  const windowsColumns = [
    {
      cellType: null,
      dataKey: "window_type",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.windowType"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => i18next.t(row["window_type"]),
    },
    {
      cellType: null,
      dataKey: "number_of_windows",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.numberOfWindows"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => addCommaToNumber(row["number_of_windows"]),
    },
    {
      cellType: null,
      dataKey: "area",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Area") + " (m²)",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => addCommaToNumber(row["area"]),
    },

    {
      cellType: "",
      dataKey: "u_value",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.uValue") + " (W/m²K)",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["u_value"],
    },
    {
      cellType: "",
      dataKey: "g_value",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.gValue"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["g_value"],
    },

    {
      cellType: "",
      dataKey: "last_checked",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.lastChecked"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["last_checked"],
    },
    {
      cellType: "",
      dataKey: "id",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Id"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["id"],
    },
  ];
  const childColumns = [
    {
      cellType: null,
      dataKey: "name",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Layer"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => i18next.t(row["name"]),
    },
    {
      cellType: null,
      dataKey: "thickness",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Thickness") + " (m)",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["thickness"],
    },
    {
      cellType: null,
      dataKey: "density",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Density") + " (kg/m³)",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["density"],
    },
    {
      cellType: "",
      dataKey: "thermal_conductivity",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Thermal conductivity") + " (W/(m*K))",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["thermal_conductivity"],
    },
    {
      cellType: "",
      dataKey: "heat_capacity",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Heat capacity") + " (J/kg*K)",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["heat_capacity"],
    },

    {
      cellType: "",
      dataKey: "_created_at",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Created at"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["_created_at"],
    },
    {
      cellType: "",
      dataKey: "_updated_at",
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
      label: i18next.t("TechnicalData.table.columns.Updated at"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["_updated_at"],
    },
    {
      cellType: "downloadFile",
      dataKey: "",
      downloadZipFile: (row) => null,
      href: (row) => row.file_url,
      isInDefault: true,
      label: i18next.t("TechnicalData.table.columns.Files"),
      onCellClick: () => trackGAEvent(eventNames.TD_DOWNLOAD_CHILD),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => (
        <ButtonNew disabled={!row.file_url} size="sm" variant="tertiary">
          <Icon
            color={!row.file_url ? "var(--clr-gray-500)" : "var(--clr-secondary-blue-500)"}
            iconName="Download"
            size="md"
          />
        </ButtonNew>
      ),
    },
  ];
  const energyMixColumns = [
    {
      cellType: null,
      dataKey: "utility_mix",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: i18next.t("EnergyPerformance.table.columns.Utility mix"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["utility_mix"],
    },
    {
      cellType: null,
      dataKey: "cost_per_kwh",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: i18next.t("EnergyPerformance.table.columns.costPerkWh"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["cost_per_kwh"],
    },
    {
      cellType: null,
      dataKey: "cost_increase_per_year",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: i18next.t("EnergyPerformance.table.columns.costPerYear"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["cost_increase_per_year"],
    },
    {
      cellType: null,
      dataKey: "co2_emissions_per_kwh",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: i18next.t("EnergyPerformance.table.columns.co2Emissions"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["co2_emissions_per_kwh"],
    },
  ];

  return {
    children: {
      columns: childColumns,
    },
    energyMix: {
      columns: energyMixColumns,
      dataKey: "energyMix",
    },
    rooftop: {
      columns: wallAndRooftopColumns,
      dataKey: "rooftop_features",
    },
    wall: {
      columns: wallAndRooftopColumns,
      dataKey: "facade_features",
    },
    windows: {
      columns: windowsColumns,
      dataKey: "windows_features",
    },
  };
};

export const tabNames = [
  "windows",
  "wall",
  "rooftop",
  "shading system",
  "energyMix",
  "heating system",
  "hot water",
  "cooling system",
  "PV system",
  "building automation",
];

export const dataLabels = {
  "building envelope": ["windows", "wall", "rooftop", "shading system"],
  "energy supply system": ["energyMix", "heating system", "hot water", "cooling system", "PV system"],
  other: ["building automation"],
};
