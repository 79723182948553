import React from "react";

const Bullet = ({
  bottom,
  color,
  handleHeight = "14px",
  height,
  label,
  left,
  reverse,
  style,
  textColor,
  top,
  transform,
  type,
  width,
}) => {
  return (
    <div
      style={{
        alignItems: "center",
        bottom: reverse ? null : bottom,
        display: "flex",
        flexDirection: reverse ? "column-reverse" : "column",
        left: left,
        position: "absolute",
        textTransform: "capitalize",
        top: reverse ? top : null,
        transform: transform,
        width: "max-content",
        zIndex: 99,
        ...style,
      }}
    >
      <div
        className="t-numbers-xxs"
        style={{
          border: `2px solid ${color || "var(--clr-gray-700)"}`,
          borderColor: color,
          borderRadius: "7px",
          boxShadow: "var(--box-shadow-elevation-200)",
          color: "black",
          maxWidth: "19rem",
          padding: "4px 8px 4px 8px",
        }}
      >
        {label}
      </div>
      <div
        style={{
          backgroundColor: "var(--clr-secondary-blue-100)",
          height: handleHeight,
          width: "1px",
        }}
       />
      {type && (
        <div
          style={{
            backgroundColor: color,
            border: "2px solid var(--clr-secondary-blue-100)",
            borderRadius: type === "circle" ? "50%" : "0",
            height: height,
            width: width,
          }}
         />
      )}
    </div>
  );
};

export default Bullet;
