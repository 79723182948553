const url = process.env.REACT_APP_API_URL + "/api";
export default {
  selectedColumns: {
    filter: (projectId, userId) => `${url}/user_technical_features/?project_id=${projectId}&user_id=${userId}`,
    post: `${url}/user_technical_features/`,
  },
  windows: {
    layers: {
      list: (projectId) => `${url}/layers/get_window_layer_list_of_window/?project_id=${projectId}`,
    },
    list: (projectId) => `${url}/layers/get_windows_list_of_project/?project_id=${projectId}`,
  },
};
