import ReactGA from "react-ga4";

import { IsAllowed } from "../acl";
import { resources } from "../acl/resources";
import { getUserFromLocalStorage } from "../utils/localStorage";

export const eventNames = {
  AO_FILTERS_CHANGE: "AO_FILTERS_CHANGE",

  AO_FILTERS_RESET: "AO_FILTERS_RESET",
  AO_VIEW: "AO_VIEW",
  BP_DOWNLOAD_3DVIEWER: "BP_DOWNLOAD_3DVIEWER",
  BP_DOWNLOAD_ALL: "BP_DOWNLOAD_ALL",
  BP_DOWNLOAD_ALL_FLOOR_PLANS: "BP_DOWNLOAD_ALL_FLOOR_PLANS",

  BP_DOWNLOAD_CERTIFICATE: "BP_DOWNLOAD_CERTIFICATE",
  BP_DOWNLOAD_SINGLE_FLOOR_PLANS: "BP_DOWNLOAD_SINGLE_FLOOR_PLANS",
  BP_VIEW: "BP_VIEW",

  CERT_VIEW: "CERT_VIEW",
  CRREM_QUANTITY_CHANGE: "CRREM_QUANTITY_CHANGE",
  CRREM_SCENARIO_CHANGE: "CRREM_SCENARIO_CHANGE",
  DYNAMIC_DASHBOARD_RESET: "DYNAMIC_DASHBOARD_RESET",
  DYNAMIC_DASHBOARD_SAVE: "DYNAMIC_DASHBOARD_SAVE",
  EP_VIEW: "EP_VIEW",

  HEADER_LOGOUT: "HEADER_LOGOUT",

  MODERN_CARD_ADD_RETROFIT: "MODERN_CARD_ADD_RETROFIT",
  MODERN_CARD_DELETE_RETROFIT: "MODERN_CARD_DELETE_RETROFIT",
  MODERN_CARD_UPDATE_RETROFIT: "MODERN_CARD_UPDATE_RETROFIT",
  MODERN_CRREM_SCENARIO_CHANGE: "MODERN_CRREM_SCENARIO_CHANGE",
  MODERN_DASHBOARD_ADD_RETROFIT: "MODERN_DASHBOARD_ADD_RETROFIT",
  MODERN_DASHBOARD_DELETE_RETROFIT_: "MODERN_DASHBOARD_DELETE_RETROFIT_",
  MODERN_RETROFIT_DETAILS: "MODERN_RETROFIT_DETAILS",
  MODERN_RETROFIT_MEASURE_ADD: "MODERN_RETROFIT_MEASURE_ADD",

  MODERN_RETROFIT_MEASURE_DELETE: "MODERN_RETROFIT_MEASURE_DELETE",

  MODERN_RETROFIT_MEASURE_UPDATE: "MODERN_RETROFIT_MEASURE_UPDATE",
  MODERN_VIEW: "MODERN_VIEW",

  NAVBAR_ADMINSTRATION: "NAVBAR_ADMINSTRATION",

  NAVBAR_CONTACT: "NAVBAR_CONTACT",
  NAVBAR_HOME: "NAVBAR_HOME",

  NAVBAR_LANGUAGE_DE: "NAVBAR_LANGUAGE_DE",
  NAVBAR_LANGUAGE_EN: "NAVBAR_LANGUAGE_EN",
  PORTFOLIO_VIEW: "PORTFOLIO_VIEW",
  TD_DOWNLOAD_ALL: "TD_DOWNLOAD_ALL",
  TD_DOWNLOAD_ALL_TAB: "TD_DOWNLOAD_ALL_TAB",
  TD_DOWNLOAD_CHILD: "TD_DOWNLOAD_CHILD",
  TD_DOWNLOAD_PARENT: "TD_DOWNLOAD_PARENT",
  TD_EXPAND_FOLD_ROW: "TD_EXPAND_FOLD_ROW",
  TD_TAB_VIEW: "TD_TAB_VIEW",

  TD_UPLOAD_FILES: "TD_UPLOAD_FILES",
  TD_VIEW: "TD_VIEW",
};

const sendGAEvent = (eventName, eventParams) => {
  if (IsAllowed(resources.GOOGLE_ANALYTICS))
    try {
      ReactGA.event(eventName, eventParams);
    } catch (error) {}
};

export const trackGAEvent = (eventName, eventParams) => {
  const user = getUserFromLocalStorage();
  if (!eventParams) eventParams = {};
  sendGAEvent(eventName, { ...eventParams, myCustomer: `myCustomer_${user.user_id}` });
};

export const withGAEventTracking = (eventName, eventParams, eventHandler) => {
  trackGAEvent(eventName, eventParams);
  eventHandler();
};
