import { createSlice } from "@reduxjs/toolkit";

const initialFiltersState = {
  certificate: {
    label: "certification",
    lookUpField: "certificate",
    nestedLookUpField: "type",
    options: ["DGNB", "Pending"],
    values: [],
  },
  energyLabel: {
    label: "energy class",
    lookUpField: "energy_label",
    options: ["+A", "A", "B", "C", "D", "E", "F", "G", "Pending"],
    values: [],
  },
  location: {
    label: "location",
    lookUpField: "location",
    options: ["Mannheim", "Blomberg", "Minden", "Berlin"],
    values: [],
  },
  type: {
    label: "type",
    lookUpField: "type",

    options: ["Commercial", "Public", "Office", "Pending"],
    values: [],
  },
  year: {
    displayOptions: [
      "< 1951",
      "1951-1960",
      "1961-1970",
      "1971-1980",
      "1981-1990",
      "1991-2000",
      "2001-2010",
      "2011-2020",
      "2021-2030",
      "Pending",
    ],
    label: "construction year",
    lookUpField: "construction_year",
    options: [1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, 2030, null],
    values: [],
  },
};

const initialState = {
  allBuildings: [],
  filters: initialFiltersState,
  isLoading: false,
  searchTerm: "",
};

const allBuildingsSlice = createSlice({
  initialState,
  name: "allBuildings",
  reducers: {
    clearFilters: (state) => {
      state.filters = initialFiltersState;
    },
    setFilters: (state, { payload }) => {
      Object.keys(payload).forEach((filterName) => {
        state.filters[filterName].values = payload[filterName];
      });
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
  },
});

export const { clearFilters, setFilters, setSearchTerm } = allBuildingsSlice.actions;
export default allBuildingsSlice.reducer;
