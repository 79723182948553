import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Grid, Menu, MenuItem, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import { GrPowerReset } from "react-icons/gr";
import { TfiSave } from "react-icons/tfi";
import "rsuite/dist/rsuite-no-reset.min.css";
// import { GeneralErrorBoundary } from "../../ErrorBoundary";
import { toast } from "react-toastify";

import { ButtonNew, Chip, CustomModal } from "../../components";

import "react-resizable/css/styles.css";

import { Icon } from "../../components";
import { DynamicDashboard } from "../../components/chart/DynamicDashboard";
import { Divider } from "../../components/Divider";
import { DynamicFilterFactory } from "../../components/DynamicFormInput";
import { ColorInput } from "../../components/Input";
import { LoadingOrEmptyWrapper } from "../../components/LoadingAndEmptyHandler";
import { ScrollableTabsButtonAuto } from "../../components/Tabs";
import { FilterTypes } from "../../enums/components";
import { chartTypesEnums, colorCategoriesEnums } from "../../enums/dynamicDashboard";
import { eventNames, withGAEventTracking } from "../../functions/googleAnalytics";
import { theme } from "../../styles/theme";
import { deepEqual, distinctFilter } from "../../utils/dataManipulation";
import {
  diagramInitialConfig,
  dynamicDashboardGetRelatedItems,
  dynamicDashboardIsItemHovered,
} from "../../utils/dynamicDashboard";
import { LocalStorageService } from "../../utils/localStorage";

const defaultMinW = 3;
const defaultMinH = 3;

const defaultChart = {
  chartType: null,
  dataPoint: null,
  diagrams: [diagramInitialConfig],
  draggable: true,
  h: defaultMinH + 2,
  // i: "0",
  id: 0,
  legendsPosition: "bottom",
  maxH: 20,
  minH: defaultMinH,
  minW: defaultMinW,
  sortValue: null,
  title: null,
  type: "1",
  w: defaultMinW + 1,
  x: 0,
  xAxis: null,
  y: 0,
  yAxis: null,
};

const DashboardTab = ({ hadDeleteIcon, onDelete, setValue, value }) => {
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const [hovered, setHovered] = useState(false);

  const inputRef = useRef(null);
  const spanHiddenRef = useRef(null);
  const spanVisibleRef = useRef(null);

  useEffect(() => {
    if (spanHiddenRef.current && inputRef.current) {
      inputRef.current.style.width = `${spanHiddenRef.current.offsetWidth + 24}px`;
      if (spanVisibleRef.current) spanVisibleRef.current.style.width = `${spanHiddenRef.current.offsetWidth + 24}px`;
    }
  }, [value, hovered]);

  return (
    <>
      {hovered && (
        <>
          <input
            className="t-subheading-m h-8 min-w-[5rem] bg-transparent p-2 text-center capitalize"
            onBlur={() => {
              setHovered(false);
            }}
            onChange={onChange}
            ref={inputRef}
            style={{
              border: "none",
            }}
            value={value}
          />
          <span
            className="t-subheading-m capitalize"
            ref={spanHiddenRef}
            style={{ height: 0, visibility: "hidden", whiteSpace: "pre" }}
          >
            {value || " "}
          </span>
        </>
      )}
      {!hovered && (
        <span
          // onDoubleClick={() => setHovered(false)}
          className="t-subheading-m h-8 min-w-[5rem] cursor-pointer bg-transparent p-2 text-center capitalize"
          onClick={() => setHovered(true)}
          ref={spanVisibleRef}
        >
          {value}
        </span>
      )}
      {hadDeleteIcon && (
        <Icon className="absolute right-2 top-2 h-[1rem] w-[1rem]" color="white" iconName="Close" onClick={onDelete} />
      )}
    </>
  );
};

const Header = ({
  addChart,
  addTab,
  currentTabIndex,
  dashboardConfigsHasChanged,
  defaultDashboardConfigsHasChanged,
  deleteTab,
  isResetting,
  isSaving,
  onOpenColors,
  onResetDashboardConfigs,
  portfolioConfigs,
  saveDashboardConfigs,
  setCurrentTabIndex,
  tabs,
  updateTabName,
}) => {
  const { t } = useTranslation();

  const tabsLabelsComponents = tabs?.map((tab, index) => {
    return {
      label: (
        <DashboardTab
          hadDeleteIcon={!(tabs?.length === 1)}
          onDelete={() => deleteTab(index)}
          setValue={(newValue) => {
            updateTabName(newValue, index);
          }}
          value={tab}
        />
      ),
    };
  });

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Stack className=" -ml-4 justify-between">
      <ScrollableTabsButtonAuto
        addTab={addTab}
        className=" max-w-[800px] flex-1"
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
        tabs={tabs}
        tabsLabelsComponents={tabsLabelsComponents}
      />
      <Stack className="w-150 items-center justify-end" gap={4}>
        <ButtonNew onClick={onOpenColors} size="md" variant="primary">
          <Stack gap={2}>
            <Icon iconName="ColorPalette" svgClassName="w-4 h-4" />
            <span className="sentence-case">{t("general.colors")}</span>
          </Stack>
        </ButtonNew>
        {defaultDashboardConfigsHasChanged && (
          <>
            <CustomModal height={300} modalOpen={modalOpen} setModalOpen={setModalOpen}>
              <Stack className="-mt-4 h-full justify-between" flexDirection="column">
                <Stack className="t-heading-m" flexDirection="column">
                  <Icon
                    className="mb-4 !h-10 !w-10"
                    color="var(--clr-mystic-red-500)"
                    iconName="Info"
                    svgClassName="!h-10 !w-10"
                  />
                  <h3>{t("Portfolio.resetSettings")}</h3>
                  <p className="t-subheading-m text-red-500">{t("Portfolio.settingsWillBeGone")}</p>
                </Stack>
                <Stack className="gap-8">
                  <ButtonNew className="capitalize" onClick={() => setModalOpen(false)} size="md" variant="secondary">
                    {t("general.cancel")}
                  </ButtonNew>
                  <ButtonNew
                    onClick={() => {
                      onResetDashboardConfigs();
                      setModalOpen(false);
                    }}
                    size="md"
                    variant="primary"
                  >
                    {t("general.resetAnyway")}
                  </ButtonNew>
                </Stack>
              </Stack>
            </CustomModal>
            <ButtonNew isLoading={isResetting} onClick={() => setModalOpen(true)} size="md" variant="primary">
              <Stack gap={2}>
                <GrPowerReset className="h-4 w-4 cursor-pointer" />
                <span className="sentence-case">{t("general.resetToDefault")}</span>
              </Stack>
            </ButtonNew>
          </>
        )}
        {dashboardConfigsHasChanged && (
          <>
            <ButtonNew
              isLoading={isSaving}
              onClick={() => saveDashboardConfigs(portfolioConfigs)}
              size="md"
              variant="primary"
            >
              <Stack className="relative" gap={2}>
                <TfiSave className="h-4 w-4 cursor-pointer" />
                <span className="sentence-case">{t("general.save")}</span>
              </Stack>
            </ButtonNew>
          </>
        )}
        <ButtonNew onClick={addChart} size="md" variant="primary">
          + <span className="sentence-case">{t("general.addNewChart")}</span>
        </ButtonNew>
      </Stack>
    </Stack>
  );
};

const Filters = ({ addFilterName, data, filterCategories, removeFilterName, setConfig, setFilteredData }) => {
  const [showID, setShowID] = useState(undefined);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const filtersDefaultVisibility = LocalStorageService.getItem(LocalStorageService.PORTFOLIO_FILTERS_VISIBILITY);

  const [filtersInfoIsVisible, setFiltersInfoIsVisible] = useState(filtersDefaultVisibility);
  const [activeFilterCategoryName, setActiveFilterCategoryName] = useState(filterCategories?.[0]?.categoryName);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!data?.length) return;

    let partiallyFilteredData = [...data];
    filterCategories.forEach((filterCategory) => {
      filterCategory?.filters
        ?.filter((filter) => filterCategory.selectedFiltersNames?.includes(filter?.filterName))
        ?.forEach((filter) => {
          const { filterType, mainDataKey, ...args } = filter;
          if (!mainDataKey) return;
          const filterFactory = new DynamicFilterFactory(filterType, args);
          const filterInstance = filterFactory.createFilterClassInstance();
          partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey);
        });
    });

    setFilteredData(partiallyFilteredData);
  }, [data, filterCategories]);

  return (
    <>
      <div className="relative pl-4 ">
        {/* Filters Info  */}
        <Stack className={`items-start py-4 ${!filtersInfoIsVisible && "h-5 w-0 overflow-hidden"}`}>
          <ButtonNew className="mr-8" onClick={() => setShowFiltersModal(true)} size="md" variant="secondary">
            <Stack gap={2}>
              <Icon iconName="Filter" size="md" />
              <span>Filters</span>
            </Stack>
          </ButtonNew>
          <Stack className="flex-wrap items-start gap-4">
            {filterCategories?.map((filterCategory, index) => {
              return filterCategory?.selectedFiltersNames?.map((name) => {
                const filter = filterCategory?.filters?.find((filter) => filter?.filterName === name);
                let { filterType, ...args } = filter;
                args = {
                  ...args,
                  props: {
                    ...args.props,
                    onContextMenu: (e) => {
                      e.preventDefault();
                      setActiveFilterCategoryName(filterCategory.categoryName);
                      setShowFiltersModal(true);
                    },
                  },
                };
                const filterFactory = new DynamicFilterFactory(filterType, args);
                const filterInstance = filterFactory.createFilterClassInstance();
                if (filterInstance) {
                  const FilterComponent = filterInstance.createComponent();
                  return (
                    <Stack className="w-80 gap-1">
                      <Icon
                        color="var(--clr-mystic-red-500)"
                        iconName="Remove"
                        onClick={() => {
                          removeFilterName(name);
                        }}
                        size="md"
                      />
                      {FilterComponent}
                    </Stack>
                  );
                }
              });
            })}
          </Stack>
        </Stack>
        {/* Filters fold/expand icon */}
        <Stack
          className={`z-100 t-numbers-m absolute bottom-1 left-0 top-1 -ml-4 min-h-[2.4rem] w-4 
                                  cursor-pointer items-center justify-center bg-blue-50 hover:bg-blue-200 `}
          onClick={() => {
            setFiltersInfoIsVisible((filtersInfoIsVisible) => !filtersInfoIsVisible);
            LocalStorageService.setItem(LocalStorageService.PORTFOLIO_FILTERS_VISIBILITY, !filtersInfoIsVisible);
          }}
        >
          <Icon className={`${filtersInfoIsVisible && "rotate-180"} `} iconName="ChevronRight" size="sm" />
        </Stack>
      </div>
      {/* Filters Modal */}
      <CustomModal
        height="90%"
        left="unset"
        modalOpen={showFiltersModal}
        right={24}
        setModalOpen={setShowFiltersModal}
        style={{ transform: "none" }}
        top={24}
        width="60rem"
      >
        <h3 className="t-heading-l">Filter Catergories</h3>
        <Stack className="w-full flex-col items-center gap-2 py-4">
          <Grid className="w-full" container spacing={4}>
            {filterCategories?.map((filterCategory, index) => {
              const isActive = filterCategory.categoryName == activeFilterCategoryName;
              return (
                <Grid item xs={4}>
                  <Chip isOn={isActive} onClick={() => setActiveFilterCategoryName(filterCategory.categoryName)}>
                    <span>{filterCategory.categoryName}</span>
                  </Chip>
                </Grid>
              );
            })}
          </Grid>
          <Divider className="mt-4" />
          <Stack className="mt-8 w-full flex-col items-center">
            {filterCategories?.map((filterCategory, index) => {
              if (filterCategory.categoryName === activeFilterCategoryName)
                return (
                  <Stack className="w-full gap-2 px-3" flexDirection="column">
                    <ButtonNew
                      aria-controls={showID === index && open ? "basic-menu" + index : undefined}
                      aria-expanded={showID === index && open ? "true" : undefined}
                      aria-haspopup="true"
                      className="mb-4  mt-1"
                      id={"basic-button" + index}
                      onClick={(e) => {
                        setShowID(index);
                        handleClick(e);
                      }}
                      size="sm"
                      variant="secondary"
                    >
                      + Add filter
                    </ButtonNew>
                    <Menu
                      anchorEl={anchorEl}
                      id={"basic-menu" + index}
                      MenuListProps={{
                        "aria-labelledby": "basic-button" + index,
                        disablePadding: true,
                      }}
                      onClose={handleClose}
                      open={showID === index && open}
                      sx={{
                        ".MuiMenu-paper": {
                          padding: "8px 0",
                        },
                      }}
                    >
                      {filterCategory?.filters?.map((f) => {
                        if (filterCategory.selectedFiltersNames?.includes(f?.filterName)) return;
                        return (
                          <MenuItem
                            className=""
                            onClick={() => {
                              handleClose();
                              setShowID(undefined);
                              setConfig(f?.filterName, undefined);
                              addFilterName(f?.filterName);
                            }}
                          >
                            <Stack className="t-body-l w-full justify-between gap-8">
                              <span>{f?.filterDisplayName}</span>
                              <span>+</span>
                            </Stack>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                    {filterCategory?.selectedFiltersNames?.map((name) => {
                      const filter = filterCategory?.filters?.find((filter) => filter?.filterName === name);
                      let { filterType, ...args } = filter;
                      if (filterType === FilterTypes.NUMERIC_RANGE_PICKER)
                        args = { ...args, props: { ...args.props, changeByTyping: true } };
                      const filterFactory = new DynamicFilterFactory(filterType, args);
                      const filterInstance = filterFactory.createFilterClassInstance();
                      if (filterInstance) {
                        const FilterComponent = filterInstance.createComponent();
                        return (
                          <Stack className="w-full gap-1">
                            <Icon
                              color="var(--clr-mystic-red-500)"
                              iconName="Remove"
                              onClick={() => {
                                removeFilterName(name);
                              }}
                              size="md"
                            />
                            {FilterComponent}
                          </Stack>
                        );
                      }
                    })}
                  </Stack>
                );
            })}
          </Stack>
        </Stack>
      </CustomModal>
    </>
  );
};

const adjustConfigs = (configs) => {
  return JSON.stringify(configs);
};

export const DynamicDashboardMultiTab = ({
  allAvailableFilters,
  allFilters,
  allSettings,
  categoricalColumns,
  chartTypes,
  dataTransformator,
  dataTransformatorMultithread,
  defaultColors,
  defaultPortfolioConfigsAndColorsConfigs,
  dynamicChartsRowHeight,
  filteredData,
  filtersCategories,

  generalDashboardData,
  getColorEntity,
  getColumnDisplayName,
  getColumnSortFunction,
  getDataTransformatorMemoDependencyArray,
  getRelatedItems,
  getUnit,
  hoverEffect = true,
  IdColumnName,
  isItemHovered,
  isLoading,
  miniMapObjectsNameKey,
  multithread = false,

  numericalColumns,
  onReset,
  onSave,
  renderChartsObjectClickModal,
  renderMiniMapObjectClickModal,
  savedPortfolioAndColorsConfigs,
  setFilteredData,
  setTriggerFlag,
  useGetSpecificData,
}) => {
  const { t } = useTranslation();
  const [userSavedConfigs, setUserSavedConfigs] = useState();
  useEffect(() => {
    setUserSavedConfigs(savedPortfolioAndColorsConfigs);
  }, [savedPortfolioAndColorsConfigs]);

  useEffect(() => {
    setPortfolioConfigs(savedPortfolioAndColorsConfigs?.tabs);
  }, [savedPortfolioAndColorsConfigs?.tabs]);

  // const userColors = useMemo(() => savedPortfolioAndColorsConfigs?.colors, [savedPortfolioAndColorsConfigs?.colors])

  const getAllDataEntities = ({ categoricalColumns, data, numericalColumns }) => {
    const entities = {};
    entities[colorCategoriesEnums.COLUMNS] = {};
    numericalColumns?.forEach((col) => {
      entities[colorCategoriesEnums.COLUMNS][col] = undefined;
    });

    entities[colorCategoriesEnums.VALUES] = {};
    categoricalColumns?.forEach((col) => {
      const columnUniqueValues = [...new Set(data?.map((row) => row[col]))];
      if (columnUniqueValues?.filter((e) => e !== undefined && e !== null)?.length > 0) {
        entities[colorCategoriesEnums.VALUES][col] = {};
        columnUniqueValues?.forEach((val) => {
          entities[colorCategoriesEnums.VALUES][col][val] = undefined;
        });
      }
    });
    return entities;
  };

  const mergeColorConfigs = (configs) => {
    const mergedConfigs = {};

    mergedConfigs[colorCategoriesEnums.COLUMNS] = configs?.reduce((total, current) => {
      return { ...total, ...current?.[colorCategoriesEnums.COLUMNS] };
    }, {});

    mergedConfigs[colorCategoriesEnums.VALUES] = configs?.reduce((total, current) => {
      const merged = {};
      const totalKeys = Object.keys(total);
      totalKeys?.forEach((key) => {
        merged[key] = { ...total[key], ...(current?.[colorCategoriesEnums.VALUES]?.[key] || {}) };
      });
      return { ...current?.[colorCategoriesEnums.VALUES], ...merged };
    }, {});

    mergedConfigs[colorCategoriesEnums.MIXED] = configs?.reduce((total, current) => {
      const merged = {};
      const totalKeys = Object.keys(total);
      totalKeys?.forEach((key) => {
        merged[key] = { ...total[key], ...(current?.[colorCategoriesEnums.MIXED]?.[key] || {}) };
      });
      return { ...current?.[colorCategoriesEnums.MIXED], ...merged };
    }, {});

    return mergedConfigs;
  };

  const allDataEntities = useMemo(() => {
    return getAllDataEntities({ categoricalColumns, data: generalDashboardData, numericalColumns });
  }, [generalDashboardData]);

  const [colorsConfigs, setColorsConfigs] = useState({});
  const [portfolioConfigs, setPortfolioConfigs] = useState(savedPortfolioAndColorsConfigs?.tabs);

  useEffect(() => {
    const mergedColorConfigs = mergeColorConfigs([
      allDataEntities,
      defaultColors,
      savedPortfolioAndColorsConfigs?.colors,
    ]);
    setColorsConfigs(mergedColorConfigs);
  }, [generalDashboardData, savedPortfolioAndColorsConfigs?.colors]);

  const [isSaving, setIsSaving] = useState(false);

  const saveDashboardConfigs = async () => {
    const data = { colors: colorsConfigs, tabs: portfolioConfigs };
    setIsSaving(true);
    try {
      await onSave?.(data);
      setUserSavedConfigs(data);
      toast.success(t("general.successfulSave"));
    } catch (e) {
      toast.warn("general.failedSave");
    } finally {
      setIsSaving(false);
    }
  };

  const saveDashboardConfigsWithGA = () =>
    withGAEventTracking(eventNames.DYNAMIC_DASHBOARD_SAVE, {}, saveDashboardConfigs);

  const [isResetting, setIsResetting] = useState(false);
  const onResetDashboardConfigs = async () => {
    setIsResetting(true);
    try {
      await onReset?.();
      setUserSavedConfigs({
        colors: defaultPortfolioConfigsAndColorsConfigs?.colors,
        tabs: defaultPortfolioConfigsAndColorsConfigs?.tabs,
      });
      setPortfolioConfigs(defaultPortfolioConfigsAndColorsConfigs?.tabs);
      toast.success(t("general.successfulReset"));
      const mergedDefaultColorConfigs = mergeColorConfigs([
        allDataEntities,
        defaultColors,
        defaultPortfolioConfigsAndColorsConfigs?.colors,
      ]);
      setColorsConfigs(mergedDefaultColorConfigs);
    } catch (e) {
      toast.warn("general.failedReset");
    } finally {
      setIsResetting(false);
    }
  };

  const onResetDashboardConfigsWithGA = () =>
    withGAEventTracking(eventNames.DYNAMIC_DASHBOARD_RESET, {}, onResetDashboardConfigs);

  const mergedColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, userSavedConfigs?.colors]);
  const dashboardConfigsHasChanged = !deepEqual(
    { colors: mergedColorConfigs, tabs: userSavedConfigs?.tabs },
    { colors: colorsConfigs, tabs: portfolioConfigs }
  );

  const mergedDefaultColorConfigs = mergeColorConfigs([
    allDataEntities,
    defaultColors,
    defaultPortfolioConfigsAndColorsConfigs?.colors,
  ]);
  const defaultDashboardConfigsHasChanged = !deepEqual(
    adjustConfigs({ colors: mergedDefaultColorConfigs, tabs: defaultPortfolioConfigsAndColorsConfigs?.tabs }),
    adjustConfigs({ colors: colorsConfigs, tabs: portfolioConfigs })
  );

  const tabs = portfolioConfigs?.map((tabConfigs) => tabConfigs.tab);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabConfigs = portfolioConfigs?.[currentTabIndex];
  useEffect(() => {
    const tabConfigs = portfolioConfigs?.[currentTabIndex];
    if (!tabConfigs && portfolioConfigs?.length) setCurrentTabIndex(0);
  }, [portfolioConfigs]);

  const allChartsConfigs = tabConfigs?.settings;
  const setAllChartsConfigs = (tabAllChartsConfigs) =>
    setPortfolioConfigs((portfoliosConfigs) => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs;
        return { ...tabConfigs, settings: tabAllChartsConfigs };
      });
      return newPortfoliosConfigs;
    });

  const allFiltersConfig = tabConfigs?.filters;
  const setAllFiltersConfig = (tabAllFiltersConfigs) =>
    setPortfolioConfigs((portfoliosConfigs) => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs;
        return { ...tabConfigs, filters: tabAllFiltersConfigs };
      });
      return newPortfoliosConfigs;
    });

  const selectedFiltersNames = useMemo(
    () =>
      filtersCategories.map((category) => {
        return {
          ...category,
          selectedFiltersNames: tabConfigs?.selectedFiltersNames?.filter((name) =>
            category.filtersNames.includes(name)
          ),
        };
      }),
    [portfolioConfigs, currentTabIndex]
  );

  const addFilterName = (name) => {
    setPortfolioConfigs((portfoliosConfigs) => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs;
        const newFilterNames = tabConfigs.selectedFiltersNames;
        newFilterNames.push(name);
        return { ...tabConfigs, selectedFiltersNames: newFilterNames };
      });
      return newPortfoliosConfigs;
    });
  };

  const removeFilterName = (name) => {
    setPortfolioConfigs((portfoliosConfigs) => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs;
        const newFilterNames = tabConfigs.selectedFiltersNames?.filter((filterName) => filterName !== name);
        return { ...tabConfigs, selectedFiltersNames: newFilterNames };
      });
      return newPortfoliosConfigs;
    });
  };

  const updateTabName = (newTabName, index) =>
    setPortfolioConfigs((portfoliosConfigs) => {
      const updatedPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== index) return tabConfigs;
        return { ...tabConfigs, tab: newTabName };
      });
      return updatedPortfoliosConfigs;
    });

  const addTab = () => {
    setCurrentTabIndex((currentTabIndex) => portfolioConfigs?.length);
    setPortfolioConfigs((portfolioConfigs) => {
      return [
        ...portfolioConfigs,
        {
          filters: {},
          selectedFiltersNames: [],
          settings: [],
          tab: `New Tab`,
        },
      ];
    });
  };

  const deleteTab = (index) => {
    setPortfolioConfigs((portfolioConfigs) => {
      return portfolioConfigs?.filter((_tabConfigs, i) => index !== i);
    });
    setCurrentTabIndex((currentTabIndex) => (index === 0 ? 0 : index - 1));
  };
  const scrollRef = useRef();

  const addChart = () => {
    setPortfolioConfigs((portfoliosConfigs) => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings;
      const maxY = allChartsConfigs?.length ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h)) : -1;
      const maxId = allChartsConfigs?.length ? Math.max(...allChartsConfigs?.map((l) => l.id)) : -1;
      const newChart = {
        ...defaultChart,
        id: maxId + 1,
        y: maxY + 1,
      };

      const newallChartsConfigs = [...allChartsConfigs, newChart];
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs;
        return { ...tabConfigs, settings: newallChartsConfigs };
      });
      return newPortfoliosConfigs;
    });

    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const removeChart = (id) => {
    setPortfolioConfigs((portfoliosConfigs) => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings;
      const newallChartsConfigs = allChartsConfigs.filter((l) => l.id !== id);
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs;
        return { ...tabConfigs, settings: newallChartsConfigs };
      });
      return newPortfoliosConfigs;
    });
  };

  const updateChart = (id, key, value) => {
    setPortfolioConfigs((portfoliosConfigs) => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings;
      const newallChartsConfigs = allChartsConfigs.map((l) => {
        if (l.id !== id) return l;
        else return { ...l, [key]: value };
      });
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs;
        return { ...tabConfigs, settings: newallChartsConfigs };
      });
      return newPortfoliosConfigs;
    });
  };

  const [openColorsModal, setOpenColorsModal] = useState(false);
  const [selectedColorEntity, setSelectedColorEntity] = useState();

  const [openColorPicker, setOpenColorPicker] = useState(false);

  const setConfig = (filterName, value) => {
    setAllFiltersConfig({ ...allFiltersConfig, [filterName]: value });
  };
  const allAvailableFiltersMemoized = useMemo(
    () => allAvailableFilters({ allFiltersConfig, setConfig }),
    [generalDashboardData, portfolioConfigs?.[currentTabIndex]]
  );

  const selectedFilters = useMemo(() => {
    return selectedFiltersNames.map((filterCategory) => {
      const filters = filterCategory?.filtersNames?.map((name) =>
        allAvailableFiltersMemoized?.find((filter) => name === filter?.filterName)
      );
      return { ...filterCategory, filters };
    });
  }, [selectedFiltersNames, portfolioConfigs?.[currentTabIndex], generalDashboardData, currentTabIndex]);

  const defaultGetRelatedItems = (props) => dynamicDashboardGetRelatedItems({ ...props, data: filteredData });
  const defaultIsItemHovered = (props) => dynamicDashboardIsItemHovered({ ...props, IdColumnName });

  if (allChartsConfigs)
    return (
      <LoadingOrEmptyWrapper height="400px" showLoading={isLoading}>
        <article className="mt-8 overflow-hidden rounded-xl bg-white px-4 pb-4">
          <Header
            addChart={addChart}
            addTab={addTab}
            currentTabIndex={currentTabIndex}
            dashboardConfigsHasChanged={dashboardConfigsHasChanged}
            defaultDashboardConfigsHasChanged={defaultDashboardConfigsHasChanged}
            deleteTab={deleteTab}
            isResetting={isResetting}
            isSaving={isSaving}
            onOpenColors={() => {
              setSelectedColorEntity(undefined);
              setOpenColorsModal((openColorsModal) => !openColorsModal);
            }}
            onResetDashboardConfigs={onResetDashboardConfigsWithGA}
            portfolioConfigs={portfolioConfigs}
            saveDashboardConfigs={saveDashboardConfigsWithGA}
            setCurrentTabIndex={setCurrentTabIndex}
            tabs={tabs}
            updateChart={updateChart}
            updateTabName={updateTabName}
          />
          {openColorsModal && (
            <Colors
              colorsConfigs={colorsConfigs}
              getColumnDisplayName={getColumnDisplayName}
              modalOpen={openColorsModal}
              selectedColorEntity={selectedColorEntity}
              setColorsConfigs={setColorsConfigs}
              setModalOpen={setOpenColorsModal}
            />
          )}
          {openColorPicker && (
            <ColorPickerModal
              colorsConfigs={colorsConfigs}
              getColumnDisplayName={getColumnDisplayName}
              modalOpen={openColorPicker}
              selectedColorEntity={selectedColorEntity}
              setColorsConfigs={setColorsConfigs}
              setModalOpen={setOpenColorPicker}
            />
          )}
          <DynamicDashboardTab
            addFilterName={addFilterName}
            allChartsConfigs={allChartsConfigs}
            allFilters={allFilters}
            allSettings={allSettings}
            chartTypes={chartTypes}
            colorsConfigs={colorsConfigs}
            dataTransformator={dataTransformator}
            dataTransformatorMultithread={dataTransformatorMultithread}
            dynamicChartsRowHeight={dynamicChartsRowHeight}
            filteredData={filteredData}
            generalDashboardData={generalDashboardData}
            getColorEntity={getColorEntity}
            getColumnDisplayName={getColumnDisplayName}
            getColumnSortFunction={getColumnSortFunction}
            getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
            getRelatedItems={getRelatedItems ?? defaultGetRelatedItems}
            getUnit={getUnit}
            hasFilterSection={allAvailableFiltersMemoized?.length > 0}
            hoverEffect={hoverEffect}
            IdColumnName={IdColumnName}
            isItemHovered={isItemHovered ?? defaultIsItemHovered}
            key={currentTabIndex + portfolioConfigs}
            miniMapObjectsNameKey={miniMapObjectsNameKey}
            multithread={multithread}
            removeChart={removeChart}
            removeFilterName={removeFilterName}
            renderChartsObjectClickModal={renderChartsObjectClickModal}
            renderMiniMapObjectClickModal={renderMiniMapObjectClickModal}
            scrollRef={scrollRef}
            selectedFilters={selectedFilters}
            setAllChartsConfigs={setAllChartsConfigs}
            setConfig={setConfig}
            setFilteredData={setFilteredData}
            setOpenColorPicker={setOpenColorPicker}
            setSelectedColorEntity={setSelectedColorEntity}
            setTriggerFlag={setTriggerFlag}
            updateChart={updateChart}
            useGetSpecificData={useGetSpecificData}
          />
        </article>
      </LoadingOrEmptyWrapper>
    );
};

const getColorEntityOrder = (a, b, aColor, bColor) => {
  if (aColor && !bColor) return -1;
  else if (!aColor && bColor) return 1;
  else if (a < b) return -1;
  else return 1;
};

export const Colors = ({ colorsConfigs, getColumnDisplayName, modalOpen, setColorsConfigs, setModalOpen }) => {
  const [updatedColorsConfigs, setUpdatedColorsConfigs] = useState(colorsConfigs);

  const colorCategories = Object.entries(updatedColorsConfigs)?.map(([categoryName, entitiesColors]) => {
    return { categoryName, entities: Object.keys(entitiesColors) };
  });
  const [activeCategoryName, setActiveCategoryName] = useState(Object.keys(updatedColorsConfigs)?.[0]);

  let activeCategorySubcategoriesNames = Object.keys(updatedColorsConfigs?.[activeCategoryName]);
  activeCategorySubcategoriesNames.sort((a, b) => {
    const aEntities = Object.keys(colorsConfigs?.[activeCategoryName]?.[a] || {});
    const bEntities = Object.keys(colorsConfigs?.[activeCategoryName]?.[b] || {});

    const aColoredEntities = Object.values(colorsConfigs?.[activeCategoryName]?.[a] || {}).filter((e) => e);
    const bColoredEntities = Object.values(colorsConfigs?.[activeCategoryName]?.[b] || {}).filter((e) => e);

    if (aColoredEntities?.length === bColoredEntities?.length) return aEntities?.length - bEntities?.length;
    else return bColoredEntities?.length - aColoredEntities?.length;
  });

  let activeCategoryEntities = colorCategories?.find(
    (category) => category.categoryName === activeCategoryName
  )?.entities;

  activeCategoryEntities.sort((a, b) => {
    const aColor = colorsConfigs?.[activeCategoryName]?.[a];
    const bColor = colorsConfigs?.[activeCategoryName]?.[b];
    return getColorEntityOrder(a, b, aColor, bColor);
  });

  useEffect(() => {
    setUpdatedColorsConfigs(colorsConfigs);
  }, [colorsConfigs]);

  return (
    <CustomModal
      height="90%"
      left="unset"
      modalOpen={modalOpen}
      right={24}
      setModalOpen={setModalOpen}
      style={{ transform: "none" }}
      top={24}
      width="50rem"
    >
      <h3 className="t-heading-l">Color Catergories</h3>
      <Stack className="h-full w-full  flex-col items-center justify-between gap-2 pt-4">
        <Grid className="w-full" container spacing={4}>
          {colorCategories?.map((colorCategory, index) => {
            const isActive = colorCategory.categoryName == activeCategoryName;
            return (
              <Grid item xs={4}>
                <Chip isOn={isActive} onClick={() => setActiveCategoryName(colorCategory.categoryName)}>
                  <span>{colorCategory.categoryName}</span>
                </Chip>
              </Grid>
            );
          })}
        </Grid>
        <Divider className="" />
        <div className="w-full  flex-grow  overflow-y-scroll ">
          <Stack className=" w-full  flex-col items-center gap-2 pr-2">
            {activeCategoryName === colorCategoriesEnums.COLUMNS &&
              activeCategoryEntities?.map((entityName, index) => {
                return (
                  <>
                    <ColorPickerItem
                      color={updatedColorsConfigs?.[activeCategoryName]?.[entityName]}
                      entityName={getColumnDisplayName({ colName: entityName })}
                      setColor={(value) => {
                        setUpdatedColorsConfigs({
                          ...updatedColorsConfigs,
                          [activeCategoryName]: {
                            ...updatedColorsConfigs?.[activeCategoryName],
                            [entityName]: value,
                          },
                        });
                      }}
                    />
                    <Divider />
                  </>
                );
              })}
            {[colorCategoriesEnums.MIXED, colorCategoriesEnums.VALUES].includes(activeCategoryName) &&
              activeCategorySubcategoriesNames?.map((subcategoryName, index) => {
                let entities = Object.keys(updatedColorsConfigs[activeCategoryName][subcategoryName]);
                entities.sort((a, b) => {
                  const aColor = colorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[a];
                  const bColor = colorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[b];
                  return getColorEntityOrder(a, b, aColor, bColor);
                });

                const noOfColoredEntities = entities?.filter(
                  (e) => updatedColorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[e]
                )?.length;
                const noOfDefindedEntities = entities.filter((e) => e)?.length;
                if (noOfDefindedEntities > 0)
                  return (
                    <Accordion
                      className="boder-t-0 w-full"
                      sx={{
                        padding: 0,
                      }}
                    >
                      <AccordionSummary className="t-heading-s" expandIcon={<Icon iconName="ChevronDown" />}>
                        <Stack alignItems="flex-start" flexDirection="column">
                          <h4>{getColumnDisplayName({ colName: subcategoryName })}</h4>
                          <span className="t-numbers-xxs">{`${noOfColoredEntities ?? 0} color${
                            noOfColoredEntities > 1 ? "s" : ""
                          } set`}</span>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails className="">
                        <Stack className="flex-col gap-2 pl-4">
                          {entities?.map((entityName) => {
                            return (
                              <>
                                <ColorPickerItem
                                  color={updatedColorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[entityName]}
                                  entityName={getColumnDisplayName({ colName: entityName })}
                                  setColor={(value) => {
                                    setUpdatedColorsConfigs({
                                      ...updatedColorsConfigs,
                                      [activeCategoryName]: {
                                        ...updatedColorsConfigs?.[activeCategoryName],
                                        [subcategoryName]: {
                                          ...updatedColorsConfigs?.[activeCategoryName][subcategoryName],
                                          [entityName]: value,
                                        },
                                      },
                                    });
                                  }}
                                />
                                <Divider />
                              </>
                            );
                          })}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  );
              })}
          </Stack>
        </div>
        <div className="h-10">
          <ButtonNew
            className="w-20"
            onClick={() => {
              setColorsConfigs(updatedColorsConfigs);
              setModalOpen(false);
            }}
            size="md"
            variant="primary"
          >
            Apply
          </ButtonNew>
        </div>
      </Stack>
    </CustomModal>
  );
};

const ColorPickerItem = ({ className, color, colorInputClassName, entityName, setColor }) => {
  const defaultColor = "#000000";
  const ref = useRef();
  return (
    <Stack className={`w-full justify-between  ${className} relative`}>
      <span className="t-heading-s">{entityName}</span>
      {color ? (
        <ColorInput className={`w-24 rounded border-0 ${colorInputClassName}`} color={color} setColor={setColor} />
      ) : (
        <ButtonNew
          onClick={() => {
            ref.current.click();
            setColor(defaultColor);
          }}
          size="sm"
          variant="primary"
        >
          Choose color
        </ButtonNew>
      )}
      <ColorInput className="absolute right-0 h-0 w-0 opacity-0" color={color} inputRef={ref} setColor={setColor} />
    </Stack>
  );
};

export const ColorPickerModal = ({
  colorsConfigs,
  getColumnDisplayName,
  modalOpen,
  selectedColorEntity,
  setColorsConfigs,
  setModalOpen,
}) => {
  const { t } = useTranslation();

  const categoryName = selectedColorEntity?.categoryName;
  const subcategoryName = selectedColorEntity?.subcategoryName;
  const entityName = selectedColorEntity?.entityName;

  let currentColor;
  if (categoryName === colorCategoriesEnums.COLUMNS) currentColor = colorsConfigs?.[categoryName]?.[entityName];
  else {
    currentColor = colorsConfigs?.[categoryName]?.[subcategoryName]?.[entityName];
  }
  const [newColor, setNewColor] = useState(currentColor);

  useEffect(() => {
    setNewColor(currentColor);
  }, [currentColor]);

  return (
    <CustomModal height="35rem" modalOpen={modalOpen} right="50%" setModalOpen={setModalOpen} top="50%" width="55rem">
      <h3 className="t-heading-l">Pick a color</h3>
      <Stack className="h-full  w-full flex-col items-center justify-between gap-2 py-4">
        <Breadcrumbs aria-label="breadcrumb" separator={<Icon iconName="ChevronRight" size="sm" />}>
          {[
            getColumnDisplayName({ colName: categoryName }),
            getColumnDisplayName({ colName: subcategoryName }),
            getColumnDisplayName({ colName: entityName }),
          ]
            .filter((e) => e)
            .map((e) => (
              <span>{e}</span>
            ))}
        </Breadcrumbs>
        <ColorPickerItem className="!w-auto" color={newColor} colorInputClassName="w-8 h-8 " setColor={setNewColor} />
        <Stack gap={theme.spacing(8)}>
          <ButtonNew className="w-25 capitalize" onClick={() => setModalOpen(false)} size="md" variant="secondary">
            {t("general.cancel")}
          </ButtonNew>
          <ButtonNew
            className="w-25"
            onClick={() => {
              if (categoryName === colorCategoriesEnums.COLUMNS)
                setColorsConfigs({
                  ...colorsConfigs,
                  [categoryName]: {
                    ...colorsConfigs?.[categoryName],
                    [entityName]: newColor,
                  },
                });
              else
                setColorsConfigs({
                  ...colorsConfigs,
                  [categoryName]: {
                    ...colorsConfigs?.[categoryName],
                    [subcategoryName]: {
                      ...colorsConfigs?.[categoryName]?.[subcategoryName],
                      [entityName]: newColor,
                    },
                  },
                });
              setModalOpen(false);
            }}
            size="md"
            variant="primary"
          >
            {t("general.apply")}
          </ButtonNew>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export const DynamicDashboardTab = ({
  addFilterName,
  allChartsConfigs,
  allFilters,
  allSettings,
  chartTypes,
  colorsConfigs,
  dataTransformator,
  dataTransformatorMultithread,
  dynamicChartsRowHeight,
  filteredData,
  generalDashboardData,
  getColorEntity,
  getColumnDisplayName,
  getColumnSortFunction,
  getDataTransformatorMemoDependencyArray,
  getRelatedItems,
  getUnit,

  hasFilterSection,
  hoverEffect = true,
  IdColumnName,
  isItemHovered,
  miniMapObjectsNameKey,
  multithread,
  removeChart,
  removeFilterName,
  renderChartsObjectClickModal,
  renderMiniMapObjectClickModal,
  scrollRef,
  selectedFilters,
  setAllChartsConfigs,
  setConfig,
  setFilteredData,
  setOpenColorPicker,
  setSelectedColorEntity,
  setTriggerFlag,
  updateChart,
  useGetSpecificData,
}) => {
  const [clickedAssets, setClickedAssets] = useState([]);
  const [clickedObject, setClickedObject] = useState();

  const getColor = useCallback(
    ({ chartType, diagrams, quantity }) => {
      const { categoryName, entityName, subcategoryName } = getColorEntity({ chartType, diagrams, quantity });

      if (categoryName === colorCategoriesEnums.COLUMNS) return colorsConfigs?.[categoryName]?.[entityName];
      return colorsConfigs?.[categoryName]?.[subcategoryName]?.[entityName];
    },
    [colorsConfigs]
  );

  const onLegendSelect = useCallback(({ config, params }) => {
    const { chartType, diagrams } = config;
    const { categoryName, entityName, subcategoryName } = getColorEntity({
      chartType,
      diagrams,
      quantity: params?.name,
    });
    setSelectedColorEntity({ categoryName, entityName, subcategoryName });
    setOpenColorPicker(true);
  }, []);

  const [hoveredItem, setHoveredItem] = useState();

  const eventHandlers = useCallback((point, config) => {
    const { chartType, dataPoint, partitions, stacks, xDataKey, yDataKey } = config;

    if (chartType === chartTypesEnums.MINI_MAP) {
      return {
        click: (point, viewportPosition) => {
          setClickedAssets((clickedAssets) => {
            const newClickedAssetId = point?.[IdColumnName];
            // Remove hardcoded value (id)
            if (clickedAssets?.map((asset) => asset?.id)?.includes(newClickedAssetId))
              return clickedAssets.filter((asset) => asset?.id !== newClickedAssetId);
            else return [...clickedAssets, { assetPosition: viewportPosition, id: newClickedAssetId }];
          });
          setTriggerFlag((triggerFlag) => !triggerFlag);
        },
        mouseout: (point) => {
          setHoveredItem(undefined);
        },
        mouseover: (point) => {
          setHoveredItem({ config, point });
        },
      };
    }
  }, []);

  const onHover = useCallback(({ config, params, point }) => {
    if (hoverEffect) setHoveredItem({ config, params, point });
  }, []);

  const onClick = useCallback(({ config, params, point }) => {
    if (renderChartsObjectClickModal) setClickedObject({ config, params, point });
  }, []);

  const onMouseOut = useCallback(() => {
    if (hoverEffect) setHoveredItem();
  }, []);

  const getHoveredItemRelatedItemsMemoized = useMemo(() => {
    return getRelatedItems({ hoveredItem });
  }, [hoveredItem]);

  const isItemHoveredCallback = useCallback(
    ({ chartType, diagrams, row, xAxis, yAxis }) => {
      return isItemHovered({
        chartType,
        diagrams,
        hoveredItem,
        hoveredItemRelatedItems: getHoveredItemRelatedItemsMemoized,
        row,
        xAxis,
        yAxis,
      });
    },
    [hoveredItem]
  );

  return (
    <>
      {hasFilterSection && (
        <Filters
          addFilterName={addFilterName}
          data={generalDashboardData}
          filterCategories={selectedFilters}
          removeFilterName={removeFilterName}
          setConfig={setConfig}
          setFilteredData={setFilteredData}
        />
      )}
      {renderMiniMapObjectClickModal?.({ objects: clickedAssets, setObjects: setClickedAssets })}
      {renderChartsObjectClickModal?.({
        clickedObject,
        relatedObjects: getRelatedItems({ hoveredItem: clickedObject }),
        setClickedObject,
      })}
      <DynamicDashboard
        allChartsConfigs={allChartsConfigs}
        allFilters={allFilters}
        allSettings={allSettings}
        chartTypes={chartTypes}
        dataTransformator={dataTransformator}
        dataTransformatorMultithread={dataTransformatorMultithread}
        dynamicChartsRowHeight={dynamicChartsRowHeight}
        eventHandlers={eventHandlers}
        generalDashboardData={filteredData}
        getColor={getColor}
        getColumnDisplayName={getColumnDisplayName}
        getColumnSortFunction={getColumnSortFunction}
        getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
        getUnit={getUnit}
        hoverEffect={hoverEffect}
        isItemHovered={isItemHoveredCallback}
        miniMapObjectsNameKey={miniMapObjectsNameKey}
        multithread={multithread}
        onClick={onClick}
        onHover={onHover}
        onLegendSelect={onLegendSelect}
        onMouseOut={onMouseOut}
        removeChart={removeChart}
        scrollRef={scrollRef}
        setAllChartsConfigs={setAllChartsConfigs}
        somethingIsHovered={!!hoveredItem}
        specificDataGetter={useGetSpecificData}
        updateChart={updateChart}
      />
    </>
  );
};
