import { Stack } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Chip } from "..";
import { WizardContext } from "../../context/Wizard";

export const SectionChips = () => {
  const { t } = useTranslation();
  const { section, setQuantity, setSection } = useContext(WizardContext);

  return (
    <Stack className="gap-2">
      <Chip isOn={section === "crrem"} onClick={() => setSection("crrem")}>
        {t("Modernization.wizard.crrem")}
      </Chip>
      <Chip
        isOn={section === "energy"}
        onClick={() => {
          setQuantity("consumption");
          setSection("energy");
        }}
      >
        {t("BuildingProfile.kpis.energyEfficiencyClass")}
      </Chip>
    </Stack>
  );
};
