const url = process.env.REACT_APP_API_URL + "/api";
const secondaryUrl = process.env.REACT_APP_ENERGY_DASHBOARD_API_URL + "/api";
export default {
  get: (projectId) => `${url}/projects/${projectId}/get_project_details/`,
  getEPC: (projectId) => `${secondaryUrl}/epc?projectId=${projectId}`,
  getESGInfo: (projectId) => `${secondaryUrl}/esg-data?projectId=${projectId}`,
  getESGReference: () => `${secondaryUrl}/esg-reference-values`,
  getPages: (projectId) => `${url}/projects-pages/?project_id=${projectId}`,
  list: (userId) => `${url}/assets/${userId}/get_asset_overview/`,
};
