import { Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IsAllowed } from "../../acl";
import apiUrls from "../../api/urls";
import { getAletrnativeImage } from "../../utils/buildingImages";
import { formatNumberBasedOnUser, valueOrAlternative } from "../../utils/namesManipulation";
import CustomizedDialogs from "../Dialog";
import { IconButton } from "../IconButton";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const AssetDetailedCard = ({
  building,
  buildingType,
  className,
  config,
  imageClassName,
  imgPath,
  isLoading,
  name,
  onClick,
  upload = true,
}) => {
  const { t } = useTranslation();
  const [imagePath, setImagePath] = useState(imgPath);
  if (!building) building = {};
  const { user } = useSelector((store) => store.user);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const token = user?.access_token;
  const url = apiUrls.buildingImage.post;
  const queryParams = [{ name: "project", value: building?.id }];
  const onSuccess = (resp) => {
    setHasError(false);
    setErrorMessage("");
    setImagePath(resp.image_file);
  };
  const onError = (xhr) => {
    setHasError(true);
    setErrorMessage(xhr.statusText);
  };

  useEffect(() => setImagePath(imgPath), [imgPath]);

  if (!config?.length) return;

  const [leftCol, rightCol] = config;

  return (
    <LoadingOrEmptyWrapper height="100%" showLoading={isLoading}>
      <Paper className={className} onClick={onClick}>
        <div className="relative">
          {/*  h-full flex justify-center */}
          {upload && IsAllowed("BUILDING_PROFILE.UPLOAD_IMAGE") && (
            <CustomizedDialogs
              Component={
                <IconButton
                  buttonProps={{
                    className: "absolute right-3 bottom-3 z-10",
                    size: "sm",
                    variant: "primary",
                  }}
                  iconProps={{
                    color: "white",
                    iconName: "Camera",
                  }}
                 />
              }
              errorMessage={errorMessage}
              formDataFileKeyName="image_file"
              hasError={hasError}
              headerText={t("general.dragToUpload")}
              headerTitle={t("general.uploadImage")}
              onError={onError}
              onSuccess={onSuccess}
              queryParams={queryParams}
              successMessage={t("general.successfulImgaeUpload")}
              token={token}
              url={url}
            />
          )}
          <img
            className={`max-h-[420px] min-h-[180px] w-full rounded object-contain object-center drop-shadow ${imageClassName}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = getAletrnativeImage(buildingType);
            }}
            src={imagePath}
          />
        </div>
        <h2 className="sentence-case t-heading-l mt-8 ">{name}</h2>
        <Stack className="mt-6 items-start justify-between">
          {[leftCol, rightCol].map((col) => {
            return (
              <ul className="w-[47%]">
                {col?.map((mapping, index) => {
                  return (
                    <li className="mb-4 border-0 border-b border-solid border-gray-100 pb-2" key={index}>
                      <h3 className="t-label-m mb-1 uppercase text-gray-400 ">
                        {t(`BuildingProfile.card.${mapping.label}`)}
                      </h3>
                      <p className="t-body-xl sentence-case">
                        {mapping.isNumeric
                          ? formatNumberBasedOnUser(valueOrAlternative(building[mapping.dataKey]))
                          : valueOrAlternative(building[mapping.dataKey])}
                        {mapping.renderUnit && <span>{mapping.renderUnit(building[mapping.dataKey])}</span>}
                      </p>
                    </li>
                  );
                })}
              </ul>
            );
          })}
        </Stack>
      </Paper>
    </LoadingOrEmptyWrapper>
  );
};
