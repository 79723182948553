import { Paper, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { cn } from "../../utils/styling";
import { EnergyEfficiencyText } from "../EnergyEfficiencyClass";
import { Icon } from "../Icon";

type EnergyEfficiencyClassTransitionProps = {
  className?: string;
  conf: any;
  statusQuo: number;
  target: number;
};

export const EnergyEfficiencyClassTransition = ({
  className,
  conf,
  statusQuo,
  target,
}: EnergyEfficiencyClassTransitionProps) => {
  return (
    <Stack className={cn("gap-4", className)}>
      <EnergyEfficiencyText conf={conf} energyConsumption={statusQuo} />
      <Icon color="var(--clr-gray-600)" iconName="ArrowThinRight" size="md" />
      <EnergyEfficiencyText conf={conf} energyConsumption={target} />
    </Stack>
  );
};

export const EmptyDataCard = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  return (
    <Paper className={`${className} h-full p-4`}>
      <span className="t-body-xl">{t("BuildingProfile.kpis.kpisNotListed")}</span>
    </Paper>
  );
};
