import styled from "styled-components";

export const LabelColumn = ({ barsInforamtions, height, marginBottom, status, title }) => {
  return (
    <Wrapper>
      <div className="article-title">
        <h3>{title}</h3>
      </div>
      <div className="energy-status">
        {barsInforamtions?.map((bar, index) => {
          const transparent = bar.rating !== status.rating;
          return (
            <div
              key={index}
              style={{
                height: height,
                marginBottom: marginBottom,
                opacity: `${transparent ? 0 : 1}`,
              }}
            >
              <svg height="100%" version="1.1" width="80%" xmlns="http://www.w3.org/2000/svg">
                <svg
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 100 100"
                  width="100%"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polygon className="p" fill={bar.color} points="0,50 40,100 100,100 100,0 40,0" />
                </svg>
                <text dominantBaseline="middle" fill="white" fontSize="1rem" textAnchor="middle" x="60%" y="50%">
                  {`${status.score} | ${status.rating}`}
                </text>
              </svg>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.article`
  width: 20%;
  text-align: center;
  border-left: 1px solid gray;
  .article-title {
    margin-bottom: 1rem;
  }
`;
