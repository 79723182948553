import { Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { IsAllowed } from "../acl";
import { resources } from "../acl/resources";
import { updateUserLanguage } from "../api/functions/all";
import LeaftechL from "../Asset/Images/leaftechLLg.svg";
import BackgroundImage from "../Asset/Images/NavLooperBG.png";
import { eventNames, withGAEventTracking } from "../functions/googleAnalytics";
import { Button } from "./ButtonNew";
import { IconButton } from "./IconButton";

export const Navbar = ({ className }) => {
  const { i18n } = useTranslation();
  const alternativeLanguage = i18n.language === "en" ? "de" : "en";
  const { user } = useSelector((store) => store.user);
  const { pathname } = useLocation();
  const pageName = pathname.includes("administration")
    ? "administration"
    : pathname.includes("contact")
    ? "contact"
    : "";
  const defaultNavLinks = [
    { eventName: eventNames.NAVBAR_HOME, iconName: "BuildingIcon", isActive: pageName === "", to: "/" },
    // {
    //   iconName: "SimulatorIcon",
    //   to: "simulator",
    //   isActive: pageName === "simulator",
    // },
    {
      eventName: eventNames.NAVBAR_CONTACT,
      iconName: "Contact",
      isActive: false,
      to: "mailto:service@leaftech.eu",
    },

    // { iconName: "ThreeDViewerIcon", to: "page1", isActive: false },
    {
      eventName: eventNames.NAVBAR_ADMINSTRATION,
      iconName: "Settings",
      isActive: pageName === "administration",
      to: "administration",
    },
    // { iconName: "SearchIcon", to: "page1", isActive: false },
    // { iconName: "ChartIcon", to: "page1", isActive: false },
  ];
  const [navLinks, setNavLinks] = useState(defaultNavLinks);

  const changeLink = (clickedNavLink) => {
    if (clickedNavLink.iconName === "Contact") return;
    const newNavlinks = navLinks?.map((navLink) => {
      if (navLink.iconName === clickedNavLink.iconName) return { ...navLink, isActive: true };
      else return { ...navLink, isActive: false };
    });
    setNavLinks(newNavlinks);
  };

  const settingsIsAllowed = IsAllowed(resources.NAVBAR.SETTINGS);

  const onChangeLanguage = () => {
    i18n.changeLanguage(alternativeLanguage);
    localStorage.setItem("language", alternativeLanguage);
    updateUserLanguage(user.user_id, alternativeLanguage);
  };
  return (
    <nav className={`${className} flex flex-col items-center bg-white px-1.5 py-6 shadow`}>
      <Stack className="h-20vh min-h-55 justify-between " flexDirection="column">
        {navLinks.slice(0, 1)?.map((navLink, index) => {
          if (navLink.to !== "administration" || settingsIsAllowed)
            return (
              <NavLink
                key={index}
                onClick={() => withGAEventTracking(navLink.eventName, {}, () => changeLink(navLink))}
                tabIndex={-1}
                to={navLink.to}
              >
                <IconButton
                  buttonProps={{
                    height: "4rem",
                    isActive: navLink.isActive,
                    size: "sm",
                    variant: "tertiary",
                    width: "4rem",
                  }}
                  iconProps={{
                    iconName: navLink.iconName,
                    style: { transform: "scale(1.1)" },
                  }}
                />
              </NavLink>
            );
        })}
        <div className="w-4/5 border border-solid border-gray-200" />
        {navLinks.slice(1)?.map((navLink, index) => {
          if (navLink.to !== "administration" || settingsIsAllowed)
            return (
              <NavLink
                key={index}
                onClick={() => withGAEventTracking(navLink.eventName, {}, () => changeLink(navLink))}
                tabIndex={-1}
                to={navLink.to}
              >
                <IconButton
                  buttonProps={{
                    height: "4rem",
                    isActive: navLink.isActive,
                    size: "sm",
                    variant: "tertiary",
                    width: "4rem",
                  }}
                  iconProps={{
                    iconName: navLink.iconName,
                    style: { transform: "scale(1.1)" },
                  }}
                />
              </NavLink>
            );
        })}
        <Button
          className="uppercase"
          onClick={() => {
            i18n.changeLanguage(alternativeLanguage);
            localStorage.setItem("language", alternativeLanguage);
            updateUserLanguage(user.user_id, alternativeLanguage);
          }}
          size="md"
          variant="tertiary"
        >
          {alternativeLanguage}
        </Button>
      </Stack>
      <img alt="Background" className="absolute bottom-0 h-28vh w-full" src={BackgroundImage} />
      <img alt="Logo" className="absolute bottom-6 z-50" src={LeaftechL} />
    </nav>
  );
};
