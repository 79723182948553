export default {
  footer: {
    copyright: "© Copyright 2023 Leaftech GmbH - All rights reserved.",
    imprint: "Imprint",
    privacyPolicy: "Privacy Policy",
  },
  forceLogoutText:
    "You've been logged out for data protection purposes. Please log in again to continue accessing your account.",
  forceLogoutTitle: "You’ve been logged out",
  header: {
    assets: "Assets",
    logout: "log out",
    logoutToast: "Successfully logged out",
  },
};
