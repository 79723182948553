import React from "react";

import { Button } from "./ButtonNew";
import { Icon } from "./Icon";
export const IconButton = ({ buttonProps, iconProps }) => {
  let { color, fill, stroke } = iconProps;
  const { disabled, isActive } = buttonProps;

  const disabledClr = "var(--clr-gray-500)";
  const activeClr = "var(--clr-secondary-blue-500)";

  if (fill) fill = disabled ? disabledClr : isActive ? activeClr : fill;
  if (stroke) stroke = disabled ? disabledClr : isActive ? activeClr : stroke;
  color = disabled ? disabledClr : isActive ? activeClr : color;

  return (
    <Button {...buttonProps} disabled={disabled} isActive={isActive}>
      <Icon {...iconProps} color={color} fill={fill} stroke={stroke} />
    </Button>
  );
};
