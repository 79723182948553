import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { logoutUser } from "../api/functions/all";
import { eventNames, withGAEventTracking } from "../functions/googleAnalytics";
import { Breadcrumb } from "./Breadcrumb";
import { Button } from "./ButtonNew";
import { Icon } from "./Icon";

const Header = ({ breadcrumbs, className }) => {
  const { t } = useTranslation();
  const { isLoggingOut } = useSelector((store) => store.user);

  return (
    <header className={`${className} mb-6 flex h-12 items-center justify-between border-b-blue-50 bg-white `}>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Button
        disabled={isLoggingOut}
        onClick={() =>
          withGAEventTracking(eventNames.HEADER_LOGOUT, {}, () => logoutUser(t("SharedLayout.header.logoutToast")))
        }
        size="md"
        variant="tertiary"
      >
        <Stack className="cursor-pointer">
          <p className="t-heading-s sentence-case mr-1.5">{t("SharedLayout.header.logout")}</p>
          <Icon className="exit" iconName="Exit" />
        </Stack>
      </Button>
    </header>
  );
};

export default Header;
