import * as Comlink from "comlink";
import { sum } from "lodash";
import "rsuite/dist/rsuite-no-reset.min.css";
import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "../../styles/dashboard.css";
import { deleteEnergyDashboardConfigs, postEnergyDashboardConfigs } from "../../api/functions/all";
import {
  useGetBuilding,
  useGetDashboardNew,
  useGetEnergyDashbordConfigs,
  useGetProjectInfo,
} from "../../api/hooks/allHooks";
// import { GeneralErrorBoundary } from "../ErrorBoundary";
import { FilterTypes } from "../../enums/components";
import {
  chartTypesEnums,
  colorCategoriesEnums,
  defaultColors,
  diagramTypes,
  diagramTypesDisplayOptions,
  getSortOptions,
  valuesEnums,
} from "../../enums/dynamicDashboard";
import {
  categoricalColumns,
  columnsAPINamesEnums,
  columnsDisplayNamesEnums,
  columnsUnits,
  dataMapping,
  ENERGY_DASHBOARD_NAME_API_BODY,
  FiltersNames,
  getEnergyDashboardColumnDisplayName,
  isRelevantOption,
  staticUtilities,
} from "../../enums/energyDashboard";
import { distinctFilter, minMaxByKey } from "../../utils/dataManipulation";
import { convertYearMonthToValidDate, getMonthNumber, getWeekdayNumber, weekDayMapping } from "../../utils/date";
import { getColorEntity, handleConfigChange, settingsOptions } from "../../utils/dynamicDashboard";
import { getFlattenData } from "../../utils/energyDashboard";
import { DynamicDashboardMultiTab } from "../chart/DynamicDashboardMultiTab";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

// const defaultDashboardConfigs = [
//   {
//     "type": "1",
//     "id": 0,
//     "x": 0,
//     "y": 8,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "line",
//     "title": "Energy Demand",
//     "xAxis": "hourOfYear",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "hourly",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-06-29T23:00:00.000Z",
//     "selectedUtilities": [
//       "coolingDemand_avg",
//       "electricityDemand_avg",
//       "heatingDemand_avg",
//       "hotWaterDemand_avg"
//     ],
//     "selectedScenarios": [
//       "Timestamps Occupancy-60"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 1,
//     "x": 0,
//     "y": 14,
//     "w": 6,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": "loadsLuminanceInternal_avg",
//         "sensorDisplayText": null,
//         "diagramType": "scatter",
//         "color": "#ea4d4d",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       },
//       {
//         "dataPoint": null,
//         "dataKey": "loadsSolarRadiation_avg",
//         "sensorDisplayText": null,
//         "diagramType": "scatter",
//         "color": "#65abec",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       },
//       {
//         "dataPoint": null,
//         "dataKey": "loadsVentilation_avg",
//         "sensorDisplayText": null,
//         "diagramType": "scatter",
//         "color": "#3fff0a",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "composed",
//     "title": "Cloud Diagram",
//     "xAxis": "loadsConduction_avg",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-02-26T00:00:00.000Z",
//     "selectedUtilities": null,
//     "selectedScenarios": [
//       "Timestamps Occupancy-60"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 2,
//     "x": 6,
//     "y": 14,
//     "w": 6,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": "ventilationInfiltrationTemperature_avg",
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "#f20d0d",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       },
//       {
//         "dataPoint": null,
//         "dataKey": "ventilationNaturalTemperature_avg",
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "#2ce407",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "histogram",
//     "title": "Ventilation Histogram",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-03-30T23:00:00.000Z",
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 4,
//     "x": 0,
//     "y": 20,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": "All values",
//     "chartType": "line",
//     "title": "Energy  load",
//     "xAxis": "dayOfYear",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-12-31T00:00:00.000Z",
//     "selectedUtilities": [
//       "heatingDemand_avg",
//       "coolingDemand_avg",
//       "electricityDemand_avg",
//       "hotWaterDemand_avg",
//       "loadsConduction_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 5,
//     "x": 4,
//     "y": 0,
//     "w": 8,
//     "h": 8,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "vertical",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "bar",
//     "title": "Annual Energy Demand",
//     "xAxis": "",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-07-30T23:00:00.000Z",
//     "selectedUtilities": [
//       "loadsLuminanceInternal_avg",
//       "loadsSolarRadiation_avg",
//       "loadsTechnicalAppliancesInternal_avg",
//       "loadsWallStorage_avg"
//     ],
//     "selectedScenarios": [
//       "Timestamps Occupancy-60",
//       "Timestamps Occupancy-0"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "right"
//   },
//   {
//     "type": "1",
//     "id": 6,
//     "x": 0,
//     "y": 26,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "bar",
//     "title": "Monthly Energy Demand",
//     "xAxis": "yearMonth",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": [
//       "ventilationInfiltrationTemperature_avg",
//       "ventilationMechanicalFlowRate_avg",
//       "ventilationMechanicalTemperature_avg"
//     ],
//     "selectedScenarios": [
//       "Timestamps Occupancy-60",
//       "Timestamps Occupancy-0"
//     ],
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "right"
//   },
//   {
//     "type": "1",
//     "id": 7,
//     "x": 6,
//     "y": 32,
//     "w": 6,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "doughnut",
//     "title": "Ventilation Distribution",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": [
//       "ventilationMechanicalTemperature_avg",
//       "ventilationNaturalTemperature_avg",
//       "ventilationMechanicalFlowRate_avg",
//       "ventilationInfiltrationTemperature_avg",
//       "ventilationInfiltrationFlowRate_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "right"
//   },
//   {
//     "type": "1",
//     "id": 8,
//     "x": 0,
//     "y": 39,
//     "w": 12,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "#ea0606",
//         "partitions": null,
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "heatmap",
//     "title": "Carpet Plot",
//     "xAxis": "dayOfYear",
//     "yAxis": "hourOfDay",
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "hourly",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-06-29T23:00:00.000Z",
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 9,
//     "x": 0,
//     "y": 46,
//     "w": 12,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "bar",
//     "title": "Thermal Load Profile",
//     "xAxis": "hourOfYear",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "hourly",
//     "startDate": "2020-01-01T00:00:00.000Z",
//     "endDate": "2020-06-29T23:00:00.000Z",
//     "selectedUtilities": [
//       "loadsPeopleInternal_avg",
//       "loadsSolarRadiation_avg",
//       "loadsTechnicalAppliancesInternal_avg",
//       "loadsWallStorage_avg",
//       "loadsVentilation_avg",
//       "loadsLuminanceInternal_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": []
//   },
//   {
//     "type": "1",
//     "id": 10,
//     "x": 0,
//     "y": 53,
//     "w": 12,
//     "h": 6,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "scenario",
//         "stacks": "dayOfWeek",
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "pie",
//     "title": "Days Consumption Comparison",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "bottom"
//   },
//   {
//     "type": "1",
//     "id": 11,
//     "x": 0,
//     "y": 0,
//     "w": 4,
//     "h": 8,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "sensor",
//         "stacks": "scenario",
//         "x": null,
//         "percentageBased": false,
//         "direction": "vertical",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "kpi",
//     "title": "Total Consumption",
//     "xAxis": null,
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": [
//       "coolingDemand_avg",
//       "electricityDemand_avg",
//       "heatingDemand_avg"
//     ],
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "bottom"
//   },
//   {
//     "type": "1",
//     "id": 12,
//     "x": 0,
//     "y": 32,
//     "w": 6,
//     "h": 7,
//     "minW": 3,
//     "minH": 3,
//     "maxH": 20,
//     "draggable": true,
//     "diagrams": [
//       {
//         "dataPoint": null,
//         "dataKey": null,
//         "sensorDisplayText": null,
//         "diagramType": null,
//         "color": "black",
//         "partitions": "scenario",
//         "stacks": null,
//         "x": null,
//         "percentageBased": false,
//         "direction": "horizontal",
//         "title": null
//       }
//     ],
//     "sortValue": null,
//     "chartType": "area",
//     "title": "Scenarios Monthly Consumption",
//     "xAxis": "yearMonth",
//     "yAxis": null,
//     "dataPoint": "consumption",
//     "dataLevel": "building",
//     "timeframe": "daily",
//     "startDate": null,
//     "endDate": null,
//     "selectedUtilities": null,
//     "selectedScenarios": null,
//     "selectedDaysOfWeek": [],
//     "selectedHoursOfDay": [],
//     "legendsPosition": "bottom"
//   }
// ]

const defaultPortfolioConfigsAndColorsConfigs = {
  colors: {
    Columns: {
      coolingDemand_avg: "#77e4c0",
      electricityDemand_avg: "#ed8002",
      heatingDemand_avg: "#bc19c8",
      hotWaterDemand_avg: "#0d1dfd",
    },
    Mixed: {},
    Values: {
      datetime: {},
      dayOfWeek: {},
      isWorkingDay: {},
      isWorkingHour: {},
      month: {},
      scenario: {},
      yearMonth: {},
    },
  },
  tabs: [
    {
      filters: {},
      selectedFiltersNames: [],
      settings: [
        {
          chartType: "kpi",
          dataPoint: "consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: "utility",
              percentageBased: false,
              stacks: "scenario",
              title: null,
            },
          ],
          draggable: true,
          h: 6,
          id: 0,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          selectedScenarios: [],
          selectedUtilities: [],
          sortValue: null,
          title: "Consumption",
          type: "1",
          w: 6,
          x: 6,
          xAxis: null,
          y: 0,
          yAxis: null,
        },
        {
          chartType: "pie",
          dataPoint: "consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: "utility",
              percentageBased: false,
              stacks: "scenario",
              title: null,
            },
          ],
          draggable: true,
          h: 6,
          id: 1,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          selectedUtilities: [],
          sortValue: null,
          title: null,
          type: "1",
          w: 6,
          x: 0,
          xAxis: null,
          y: 0,
          yAxis: null,
        },
        {
          chartType: "bar",
          dataPoint: "consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: "utility",
              percentageBased: false,
              stacks: "scenario",
              title: null,
            },
          ],
          draggable: true,
          h: 7,
          id: 2,
          legendsPosition: "right",
          maxH: 20,
          minH: 3,
          minW: 3,
          selectedUtilities: [],
          sortValue: null,
          title: null,
          type: "1",
          w: 12,
          x: 0,
          xAxis: "yearMonth",
          y: 6,
          yAxis: null,
        },
      ],
      tab: "Overview",
    },
    {
      filters: {},
      selectedFiltersNames: [],
      settings: [
        {
          chartType: "area",
          dataPoint: "consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: "utility",
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 6,
          id: 1,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          selectedUtilities: [],
          sortValue: "y",
          title: "All scenarios across week days",
          type: "1",
          w: 6,
          x: 6,
          xAxis: "dayOfWeek",
          y: 0,
          yAxis: null,
        },
        {
          chartType: "bar",
          dataPoint: "consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "vertical",
              partitions: "utility",
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 6,
          id: 2,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          selectedUtilities: [],
          sortValue: null,
          title: "Scenarios comparison",
          type: "1",
          w: 6,
          x: 0,
          xAxis: "scenario",
          y: 0,
          yAxis: null,
        },
      ],
      tab: "Tab 2",
    },
    {
      filters: {},
      selectedFiltersNames: [],
      settings: [
        {
          chartType: "line",
          dataPoint: "consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: "utility",
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 6,
          id: 0,
          invertXAxis: 1,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          selectedDaysOfWeek: [],
          selectedHoursOfDay: [],
          selectedScenarios: [],
          selectedUtilities: [],
          sortValue: "All values",
          timeframe: "hourly",
          title: "Simulated Load",
          type: "1",
          w: 12,
          x: 0,
          xAxis: "hourOfYear",
          y: 0,
          yAxis: null,
        },
        {
          chartType: "heatmap",
          dataPoint: "consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: null,
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 6,
          id: 1,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          sortValue: null,
          timeframe: "hourly",
          title: "Hours Load",
          type: "1",
          w: 12,
          x: 0,
          xAxis: "hourOfDay",
          y: 6,
          yAxis: "dayOfWeek",
        },
      ],
      tab: "Hourly",
    },
  ],
};
const adjustConfigs = (configs) => {
  if (configs) {
    const adjustedConfigs = {
      ...configs,
      tabs: configs?.tabs?.map((tab) => ({
        ...tab,
        settings: tab.settings?.map((setting) => {
          return {
            ...setting,
            endDate: setting?.endDate && new Date(setting?.endDate),
            startDate: setting?.startDate && new Date(setting?.startDate),
          };
        }),
      })),
    };
    return adjustedConfigs;
  }
};

const loadDashboardDefaultConfigs = () => {
  return adjustConfigs(defaultPortfolioConfigsAndColorsConfigs);
};

export const removeZeroFields = (data) => {
  if (!data?.length) return;
  let keys = Object.keys(data[0]);
  for (let key of keys) {
    let allZero = data.every((item) => item[key] === 0);
    if (allZero) {
      for (let obj of data) {
        delete obj[key];
      }
    }
  }
  return data;
};

const columnsOptionsGroups = [
  {
    group: "Temporal Features",
    options: [
      columnsAPINamesEnums.DATE_TIME,
      columnsAPINamesEnums.DAY_OF_MONTH,
      columnsAPINamesEnums.DAY_OF_WEEK,
      columnsAPINamesEnums.DAY_OF_YEAR,
      columnsAPINamesEnums.HOUR_OF_DAY,
      columnsAPINamesEnums.HOUR_OF_YEAR,
      columnsAPINamesEnums.MONTH,
      columnsAPINamesEnums.MONTH_NUMBER,
      columnsAPINamesEnums.YEAR,
      columnsAPINamesEnums.YEAR_MONTH,

      columnsAPINamesEnums.CET_HOUR,
    ],
  },
  {
    group: "Occupancy",
    options: [
      columnsAPINamesEnums.OCCUPANCY_PERCENTAGE_AVG,
      columnsAPINamesEnums.OCCUPANCY_PEOPLE_AVG,

      columnsAPINamesEnums.IS_WORKING_DAY,
      columnsAPINamesEnums.IS_WORKING_HOUR,
    ],
  },
  {
    group: "Temperature",
    options: [
      columnsAPINamesEnums.TEMPERATURE_INSIDE_CALCULATED_AVG,
      columnsAPINamesEnums.TEMPERATURE_INSIDE_MAXIMUM_AVG,
      columnsAPINamesEnums.TEMPERATURE_INSIDE_MEASURED_AVG,
      columnsAPINamesEnums.TEMPERATURE_INSIDE_MINIMUM_AVG,
      columnsAPINamesEnums.TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG,
      columnsAPINamesEnums.TEMPERATURE_OUTSIDE_AIR_AVG,

      columnsAPINamesEnums.TEMPERATURE_AIR_SUPPLY_AVG,
    ],
  },
  {
    group: "Volume Flow",
    options: [
      columnsAPINamesEnums.VENTILATION_INFILTRATION_FLOW_RATE,
      columnsAPINamesEnums.VENTILATION_INFILTRATION_TEMPERATURE_AVG,
      columnsAPINamesEnums.VENTILATION_MECHANICAL_FLOW_RATE,
      columnsAPINamesEnums.VENTILATION_MECHANICAL_TEMPERATURE_AVG,
      columnsAPINamesEnums.VENTILATION_NATURAL_FLOW_RATE,
      columnsAPINamesEnums.VENTILATION_NATURAL_TEMPERATURE_AVG,

      columnsAPINamesEnums.FLOW_RATE_ADDITIONAL_VENTILATION_AVG,
      columnsAPINamesEnums.FLOW_RATE_INFILTRATION_AVG,
      columnsAPINamesEnums.FLOW_RATE_MECHANICAL_VENTILATION_AVG,
      columnsAPINamesEnums.FLOW_RATE_NATURAL_VENTILATION_AVG,
    ],
  },
  {
    group: "Thermal Load",
    options: [
      columnsAPINamesEnums.LOAD_CONDUCTION_AVG,
      columnsAPINamesEnums.LOAD_LUMINANCE_INTERNAL_AVG,
      columnsAPINamesEnums.LOAD_PEOPLE_INTERNAL_AVG,
      columnsAPINamesEnums.LOAD_SOLAR_RADIATION_AVG,
      columnsAPINamesEnums.LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG,
      columnsAPINamesEnums.LOAD_VENTILATION_AVG,
      columnsAPINamesEnums.LOAD_WALLSTORAGE_AVG,

      columnsAPINamesEnums.LOADS_ADDITIONAL_VENTILATION_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_FLOOR_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_GROUND_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_WALLS_AVG,
      columnsAPINamesEnums.LOADS_CONDUCTION_WINDOWS_AVG,
      columnsAPINamesEnums.LOADS_INFILTRATION_AVG,
      columnsAPINamesEnums.LOADS_MECHANICAL_VENTILATION_AVG,
      columnsAPINamesEnums.LOADS_NATURAL_VENTILATION_AVG,
      columnsAPINamesEnums.LOADS_OTHER_AVG,
    ],
  },
  {
    group: "Heat Energy Demand",
    options: [
      columnsAPINamesEnums.HEATING_DEMAND,

      columnsAPINamesEnums.HEATING_DEMAND_AUXILIARY_SYSTEM_AVG,
      columnsAPINamesEnums.HEATING_DEMAND_MAIN_SYSTEM_AVG,
      columnsAPINamesEnums.HEATING_DEMAND_VENTILATION_SYSTEM_AVG,
    ],
  },
  {
    group: "Cooling Energy Demand",
    options: [
      columnsAPINamesEnums.COOLING_DEMAND,
      columnsAPINamesEnums.COOLING_DEMAND_AUXILIARY_SYSTEM_AVG,
      columnsAPINamesEnums.COOLING_DEMAND_MAIN_SYSTEM_AVG,
      columnsAPINamesEnums.COOLING_DEMAND_VENTILATION_SYSTEM_AVG,
      columnsAPINamesEnums.COOLING_ELECTRICITY_DEMAND,
    ],
  },
  {
    group: "Electricity Demand",
    options: [
      columnsAPINamesEnums.ELECTRICITY_DEMAND,
      columnsAPINamesEnums.ILLUMINATION_ELECTRICITY_DEMAND_AVG,
      columnsAPINamesEnums.OTHER_ELECTRICITY_DEMAND_AVG,
      columnsAPINamesEnums.TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG,
      columnsAPINamesEnums.VENTILATION_ELECTRICITY_DEMAND_AVG,
    ],
  },
  {
    group: "Hot Water Demand",
    options: [columnsAPINamesEnums.HOT_WATER_DEMAND],
  },
  {
    group: "Renewable Energy",
    options: [
      columnsAPINamesEnums.PV_ELECTRICITY_AVG,
      columnsAPINamesEnums.PV_OVERHEAD_AVG,
      columnsAPINamesEnums.PV_OWN_DEMAND,
    ],
  },

  {
    group: "Primary Energy Demand",
    options: [
      columnsAPINamesEnums.OIL_HEATING_AVG,
      columnsAPINamesEnums.NATURAL_GAS_AVG,
      columnsAPINamesEnums.GRID_ELECTRICITY_AVG,
      columnsAPINamesEnums.DISTRICT_HEATING_AVG,
    ],
  },
  {
    group: "Other",
    options: [valuesEnums.INDEX, valuesEnums.COUNT, columnsAPINamesEnums.UTILITY, columnsAPINamesEnums.SCENARIO],
  },
];

export const EnergyDashboardNewMultiTab = () => {
  const multithread = true;
  const { projectId } = useParams();

  const { data: building } = useGetBuilding(projectId);
  const areaForM2Calculations = building?.gross_asset_basement_included;

  const { data: projectInfo, isLoading: pIIsLoading, isSuccess } = useGetProjectInfo(projectId, {});

  const projectInfoParsed = useMemo(() => {
    let dataPoints = [],
      roomList = [],
      scenarios = [],
      utilities = [];
    if (isSuccess) {
      try {
        scenarios = JSON.parse(projectInfo[0].room_level_scenarios);
        dataPoints = JSON.parse(projectInfo[0].metrics);
        utilities = [
          {
            "display name": staticUtilities.map((utility) => dataMapping[utility]),
            metric: "consumption",
            values: staticUtilities,
          },
        ];
        roomList = JSON.parse(projectInfo[0].room_list);
      } catch {
        // Do nothing
      }
    }
    return { dataPoints, roomList, scenarios, utilities };
  }, [projectInfo]);

  let dataPoints = [],
    scenarios = [];

  if (isSuccess) {
    try {
      scenarios = JSON.parse(projectInfo[0].room_level_scenarios);
      dataPoints = JSON.parse(projectInfo[0].metrics);
    } catch {
      // Do nothing
    }
  }

  const inputs = {
    dataLevel: "building",
    enabled: isSuccess,
    endDate: new Date("2100-01-01"),
    interval: "hourly",
    projectId,
    quantities: dataPoints?.filter((e) => e.level === "building")?.map((e) => e.dataKey),
    scenarios,
    startDate: new Date("2000-01-01"),
  };

  let { allColumns: dataColumns, data, isLoading } = useGetDashboardNew(inputs);
  const generalDashboardDataRaw = useMemo(() => data || [], [projectInfo, isLoading]);

  let { generalDashboardData, numericalColumns } = useMemo(() => {
    let mappedData = [];
    generalDashboardDataRaw?.forEach((row) => {
      const mappedDataRow = { ...row, [columnsAPINamesEnums.COUNT]: 1 };
      mappedData.push(mappedDataRow);
    });
    dataColumns.push(columnsAPINamesEnums.COUNT);

    const numericalColumns = dataColumns.filter((col) => !categoricalColumns.includes(col));

    return { generalDashboardData: mappedData, numericalColumns };

    // Don't put generalDashboardDataRaw in the dependency array as it is not memoized in useGetDashboardNew (will casue infinite rerender)
  }, [projectInfo, isLoading]);

  const { max: maxDate, min: minDate } = minMaxByKey({
    data: generalDashboardData,
    key: "datetime",
  });

  const dynamicChartsRowHeight = 50;

  let { utilities } = projectInfoParsed;

  const relatedSensors = utilities?.find((info) => info.metric === "consumption");
  const allSensorsDataKeys = relatedSensors?.values ?? [];

  const getDataUtilities = (data) => {
    return allSensorsDataKeys.filter((u) => Object.keys(data?.[0] ?? {})?.includes(u));
  };

  const dataColumnsDisplayOptions = dataColumns?.map((colName) => getEnergyDashboardColumnDisplayName({ colName }));
  const numericalColumnsDisplayOptions = numericalColumns?.map((colName) =>
    getEnergyDashboardColumnDisplayName({ colName })
  );

  const dataUtilities = getDataUtilities(generalDashboardData);
  const dataUtilitiesDisplayNames = dataUtilities?.map((u) => getEnergyDashboardColumnDisplayName({ colName: u }));

  const allFilters = (updateChart, config) => [
    {
      adjustConfig: (config) => config,
      filterName: FiltersNames.SELECTED_SENSORS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: undefined,
      props: {
        displayOptions: dataUtilitiesDisplayNames,
        label: "Utilities",
        options: dataUtilities,
        optionsGroups: columnsOptionsGroups,
        width: "75%",
      },
      setConfig: updateChart,
      visible: () => !staticUtilities.includes(config[FiltersNames.SUB_DATAPOINT]),
    },
    {
      adjustConfig: (config) => config,
      filterName: FiltersNames.SELECTED_SCENARIOS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.SCENARIO,
      props: {
        displayOptions: scenarios,
        label: "Scenarios",
        options: scenarios,
        width: "75%",
      },
      setConfig: updateChart,
      visible: () => true,
    },
    {
      adjustConfig: (config) => ({ ...config, [FiltersNames.DATE_RANGE]: [config.startDate, config.endDate] }),
      filterName: FiltersNames.DATE_RANGE,
      filterType: FilterTypes.DATERANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.DATE_TIME,
      props: {
        appearance: "default",
        className: "w-3/4",
        cleanable: false,
        maxDate,
        minDate,
        placement: config.x < 6 ? "bottomEnd" : "bottomStart",
      },
      setConfig: (_filterName, dates) => {
        updateChart(FiltersNames.START_DATE, dates[0]);
        updateChart(FiltersNames.END_DATE, dates[1]);
      },
      visible: () => true,
    },
    {
      adjustConfig: (config) => config,
      filterName: FiltersNames.SELECTED_DAYS_OF_WEEK,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.DAY_OF_WEEK,
      props: {
        label: "Day",
        options: Object.values(weekDayMapping),
        width: "75%",
      },
      setConfig: updateChart,
      visible: () => true,
    },
    {
      adjustConfig: (config) => config,
      filterName: FiltersNames.SELECTED_HOURS_OF_DAY,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.HOUR_OF_DAY,
      props: {
        label: "Hour",
        options: [...Array(24).keys()],
        width: "75%",
      },
      setConfig: updateChart,
      visible: () => true,
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Working Day",
      filterName: FiltersNames.IS_WORKING_DAY,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      mainDataKey: columnsAPINamesEnums.IS_WORKING_DAY,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Working Day",
        options: [0, 1],
      },
      setConfig: updateChart,
      visible: () => true,
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Working Hour",
      filterName: FiltersNames.IS_WORKING_HOUR,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      mainDataKey: columnsAPINamesEnums.IS_WORKING_HOUR,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Working Hour",
        options: [0, 1],
      },
      setConfig: updateChart,
      visible: () => true,
    },
  ];

  const allSettings = (updateChart, config) => [
    {
      adjustConfig: (config) => config,
      filterLabel: "Chart title",
      filterName: FiltersNames.TITLE,
      filterType: FilterTypes.INPUT,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        height: "4rem",
        label: "Title",
        placeholder: "Title",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Data point",
      filterName: FiltersNames.SUB_DATAPOINT,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      mainDataKey: undefined,
      props: {
        displayOptions: numericalColumnsDisplayOptions,
        label: "Data point",
        options: numericalColumns,
        optionsGroups: columnsOptionsGroups,
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      useAsDataFilter: false,
      visible: () => isRelevantOption(config.chartType, settingsOptions.SUB_DATAPOINT),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "X Axis",
      filterName: FiltersNames.X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: [columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        label: "x-axis",
        options: [columnsAPINamesEnums.UTILITY, ...dataColumns],
        optionsGroups: columnsOptionsGroups,
      },
      setConfig: (updateChart) => updateChart,
      visible: () =>
        [chartTypesEnums.COMPOSED].includes(config.chartType) && isRelevantOption(config.chartType, settingsOptions.X),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "X Axis",
      filterName: FiltersNames.X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: [valuesEnums.INDEX, columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        label: "x-axis",
        options: [valuesEnums.INDEX, columnsAPINamesEnums.UTILITY, ...dataColumns],
        optionsGroups: columnsOptionsGroups,
      },
      setConfig: (updateChart) => updateChart,
      visible: () =>
        [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.HEATMAP, chartTypesEnums.LINE].includes(
          config.chartType
        ) && isRelevantOption(config.chartType, settingsOptions.X),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Aggregation",
      filterName: FiltersNames.AGGREGATE_X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: ["Don't aggregate", "Aggregate"],
        label: "Aggregation",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.AGGREGATE_X_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Invert X Axis",
      filterName: FiltersNames.INVERT_X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Invert X Axis",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Y Axis",
      filterName: FiltersNames.Y_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: [valuesEnums.INDEX, columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        label: "y-axis",
        options: [valuesEnums.INDEX, columnsAPINamesEnums.UTILITY, ...dataColumns],
        optionsGroups: columnsOptionsGroups,
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Invert Y Axis",
      filterName: FiltersNames.INVERT_Y_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Invert Y Axis",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Lengeds Position",
      filterName: FiltersNames.LEGEND_POSITION,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        displayOptions: ["Bottom", "Right"],
        label: "Lengeds position",
        options: ["bottom", "right"],
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Show labels",
      filterName: FiltersNames.SHOW_PIE_LABELS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        displayOptions: ["No", "Yes"],
        label: "Show labels",
        options: [0, 1],
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.SHOW_PIE_LABELS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Sort by",
      filterName: FiltersNames.SORT_VALUE,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
        label: "Sort by",
        options: getSortOptions({ chartType: config.chartType })?.options,
      },
      setConfig: (updateChart) => updateChart,
      visible: () => {
        return (
          isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
          !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
        );
      },
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Per m²",
      filterName: FiltersNames.PER_M2,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      mainDataKey: undefined,
      props: {
        displayOptions: ["No", "Yes"],
        label: "Per m²",
        options: [0, 1],
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      useAsDataFilter: false,
      visible: () => isRelevantOption(config.chartType, settingsOptions.PER_M2),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Y Axis",
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: [valuesEnums.INDEX, columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        label: "Y Axis",
        // options: allSensorsDataKeys,
        // displayOptions: allSensorsDisplayNames,
        options: [valuesEnums.INDEX, columnsAPINamesEnums.UTILITY, ...dataColumns],
        optionsGroups: columnsOptionsGroups,
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Scenario",
      filterName: FiltersNames.DIAGRAMS_SCENARIO,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      mainDataKey: columnsAPINamesEnums.SCENARIO,
      props: {
        displayOptions: scenarios,
        label: "Scenario",
        options: scenarios,
        width: "75%",
      },
      useAsDataFilter: false,
      visible: () => isRelevantOption(config.chartType, FiltersNames.DIAGRAMS_SCENARIO),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Diagram type",
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: Object.values(diagramTypesDisplayOptions).filter(
          (type) => type !== diagramTypesDisplayOptions.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0
        ),
        label: "Diagram type",
        options: Object.values(diagramTypes).filter(
          (type) => type !== diagramTypes.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0
        ),
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Partitions",
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: [columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        label: "Partitions",
        options: [columnsAPINamesEnums.UTILITY, ...dataColumns],
        optionsGroups: columnsOptionsGroups,
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Stacks",
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: [columnsDisplayNamesEnums.UTILITY, ...dataColumnsDisplayOptions],
        label: "Stacks",
        options: [columnsAPINamesEnums.UTILITY, ...dataColumns],
        optionsGroups: columnsOptionsGroups,
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Direction",
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["Horizontal", "Vertical"],
        label: "Direction",
        options: ["horizontal", "vertical"],
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
    },
    // {
    //   filterLabel: 'Color',
    //   filterName: FiltersNames.DIAGRAMS_COLOR,
    //   filterType: FilterTypes.COLOR_INPUT,
    //   adjustConfig: (config) => config,
    //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
    //   props: {
    //     className: "block w-3/4 ",
    //   },
    //   visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.COLOR, diagramIndex),
    //   isInDiagramsPart: true,
    // },
  ];

  const tempDataLevel = "building";

  const useGetSpecificData = (config) => {
    // let { dataLevel, dataPoint, endDate, startDate, timeframe } = config;
    // dataLevel = tempDataLevel;
    // const isDaily = timeframe === "daily" || !timeframe;

    // const inputs = {
    //   dataLevel,
    //   enabled: !(dataLevel === "building" && isDaily) && !!dataPoint,
    //   endDate: new Date("2100-01-01"),
    //   interval: timeframe,
    //   projectId,
    //   quantities: [dataPoint],
    //   scenarios,
    //   // startDate: isDaily ? new Date(minDate) : dates[0],
    //   // endDate: isDaily ? new Date(maxDate) : dates[1],
    //   startDate: new Date("2000-01-01"),
    // };
    // let { data: specificDataNotMemoized, isError, isLoading } = useGetDashboardNew(inputs);

    // const specificData = useMemo(() => {
    //   const mappedData = [];
    //   specificDataNotMemoized?.forEach((row) => {
    //     const mappedDataRow = { ...row, [columnsAPINamesEnums.COUNT]: 1 };
    //     mappedData.push(mappedDataRow);
    //   });
    //   return mappedData;
    // }, [isLoading, dataPoint, timeframe, startDate, endDate, minDate, maxDate, dataLevel, scenarios, projectId]);
    return { isError: false, isLoading: false, specificData: [] };
  };

  const getColumnSortFunction = useCallback(
    ({ acsending = true, aValue, bValue, dataKey }) => {
      let output = aValue > bValue ? 1 : -1;
      if (dataKey === columnsAPINamesEnums.DAY_OF_WEEK) {
        output = getWeekdayNumber(aValue) - getWeekdayNumber(bValue);
      } else if (dataKey === columnsAPINamesEnums.MONTH) {
        output = getMonthNumber(aValue) - getMonthNumber(bValue);
      } else if (dataKey === columnsAPINamesEnums.YEAR_MONTH)
        output = convertYearMonthToValidDate(aValue) > convertYearMonthToValidDate(bValue) ? 1 : -1;
      return acsending ? output : -output;
    },
    [generalDashboardData]
  );

  const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
    const {
      aggregateXAxis,
      chartType,
      dataPoint,
      diagrams,
      perM2,
      selectedScenarios,
      selectedUtilities,
      subDataPoint,
      xAxis,
      yAxis,
    } = config;
    return JSON.stringify([
      dataPoint,
      subDataPoint,
      selectedScenarios,
      selectedUtilities,
      chartType,
      diagrams,
      xAxis,
      yAxis,
      perM2,
      aggregateXAxis,
    ]);
  }, []);

  const dataTransformator = useCallback(
    (notScaledData, { chartType, dataPoint, diagrams, perM2, selectedUtilities, subDataPoint, xAxis, yAxis }) => {
      const { partitions, stacks } = diagrams?.[0];
      const utilitiesColName = columnsAPINamesEnums.UTILITY;

      const dataUtilities = getDataUtilities(notScaledData);

      let data;
      if (perM2) {
        data = notScaledData?.map((row) => {
          const scaledRow = {};
          Object.keys(row)?.forEach((col) => {
            const shouldBeScaled = dataUtilities?.includes(col) || col === columnsAPINamesEnums.CONSUMPTION;
            scaledRow[col] = shouldBeScaled ? row[col] / (areaForM2Calculations ?? 1) : row[col];
          });
          return scaledRow;
        });
      } else {
        data = notScaledData;
      }

      const activeUtilities = selectedUtilities?.length ? selectedUtilities : dataUtilities;
      let dataPartitions, dataStacks;
      dataPartitions = !partitions
        ? []
        : partitions === utilitiesColName
        ? activeUtilities
        : data?.map((e) => e[partitions])?.filter(distinctFilter);
      dataStacks = !stacks
        ? []
        : stacks === utilitiesColName
        ? activeUtilities
        : data?.map((e) => e[stacks])?.filter(distinctFilter);

      let flattenData;
      if ([partitions, stacks, xAxis, yAxis].includes(columnsAPINamesEnums.UTILITY)) {
        flattenData = getFlattenData({
          columnsToPivot: allSensorsDataKeys,
          data,
          newColumnName: utilitiesColName,
          valueName: dataPoint,
        });

        if (selectedUtilities?.length)
          flattenData = flattenData.filter((e) => selectedUtilities?.includes(e[utilitiesColName]));
      } else {
        flattenData = data?.map((row) => {
          const utilitiesValues = Object.keys(row)
            ?.filter((col) => staticUtilities.includes(col))
            ?.map((col) => row[col]);
          return { ...row, [dataPoint]: sum(utilitiesValues) };
        });
      }

      // if (selectedScenarios?.length)
      // flattenData = flattenData.filter((e) =>
      //   selectedScenarios?.includes(e[columnsNamesEnums.SCENARIO])
      // );

      if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {
        if (!subDataPoint) {
          flattenData = flattenData?.map((row) => {
            const filteredRow = {};
            if (partitions) filteredRow[partitions] = row[partitions];
            if (stacks) filteredRow[stacks] = row[stacks];
            return {
              ...filteredRow,
              dataPoint,
              [dataPoint]: row[dataPoint],
            };
          });
        } else {
          flattenData = staticUtilities.includes(subDataPoint) ? data : flattenData;
          flattenData = flattenData?.map((row) => {
            const filteredRow = {};
            if (partitions) filteredRow[partitions] = row[partitions];
            if (stacks) filteredRow[stacks] = row[stacks];
            return {
              ...filteredRow,
              dataPoint: subDataPoint,
              [dataPoint]: row[dataPoint],
              [subDataPoint]: row[subDataPoint],
            };
          });
        }
        return { dataPartitions, dataStacks, flattenData: flattenData || [] };
      } else if (
        [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.KPI, chartTypesEnums.LINE].includes(chartType)
      ) {
        if (!subDataPoint) {
          flattenData = flattenData?.map((row) => {
            const filteredRow = {};
            if (partitions) filteredRow[partitions] = row[partitions];
            if (stacks) filteredRow[stacks] = row[stacks];
            if (xAxis) filteredRow[xAxis] = row[xAxis];
            return {
              ...filteredRow,
              dataPoint,
              [dataPoint]: row[dataPoint],
            };
          });
        } else {
          flattenData = staticUtilities.includes(subDataPoint) ? data : flattenData;
          flattenData = flattenData?.map((row) => {
            const filteredRow = {};
            if (partitions) filteredRow[partitions] = row[partitions];
            if (stacks) filteredRow[stacks] = row[stacks];
            if (xAxis) filteredRow[xAxis] = row[xAxis];
            return {
              ...filteredRow,
              dataPoint: subDataPoint,
              [dataPoint]: row[dataPoint],
              [subDataPoint]: row[subDataPoint],
            };
          });
        }
        return { dataPartitions, dataStacks, flattenData: flattenData || [] };
      } else if (chartType === chartTypesEnums.HEATMAP) {
        if (!subDataPoint) {
          flattenData = flattenData?.map((row) => {
            const filteredRow = {};
            if (yAxis) filteredRow[yAxis] = row[yAxis];
            if (xAxis) filteredRow[xAxis] = row[xAxis];
            return {
              ...filteredRow,
              dataPoint,
              [dataPoint]: row[dataPoint],
            };
          });
        } else {
          flattenData = staticUtilities.includes(subDataPoint) ? data : flattenData;
          flattenData = flattenData?.map((row) => {
            const filteredRow = {};
            if (yAxis) filteredRow[yAxis] = row[yAxis];
            if (xAxis) filteredRow[xAxis] = row[xAxis];
            return {
              ...filteredRow,
              dataPoint: subDataPoint,
              [dataPoint]: row[dataPoint],
              [subDataPoint]: row[subDataPoint],
            };
          });
        }

        const xLabels =
          xAxis === utilitiesColName ? activeUtilities : data?.map((row) => row[xAxis])?.filter(distinctFilter);

        xLabels?.sort((a, b) => getColumnSortFunction({ aValue: a, bValue: b, dataKey: xAxis }));

        const yLabels =
          yAxis === utilitiesColName ? activeUtilities : data?.map((row) => row[yAxis])?.filter(distinctFilter);

        yLabels?.sort((a, b) => getColumnSortFunction({ aValue: a, bValue: b, dataKey: yAxis }));

        return { flattenData: flattenData || [], xLabels, yLabels };
      }

      return { flattenData: flattenData || [] };
    },
    [projectInfoParsed, building]
  );

  const dataTransformatorMultithread = useCallback(() => {
    const worker = new Worker(new URL("../../../src/flat.worker.js", import.meta.url));
    const dataTransformatorWorker = Comlink.wrap(worker);

    return {
      calculate: async (
        notScaledData,
        { chartType, dataPoint, diagrams, perM2, selectedUtilities, subDataPoint, xAxis, yAxis },
        requestId
      ) => {
        try {
          let result = await dataTransformatorWorker(notScaledData, {
            allSensorsDataKeys,
            areaForM2Calculations,
            chartType,
            columnsAPINamesEnums,
            dataPoint,
            dataUtilities: getDataUtilities(notScaledData),
            diagrams,
            perM2,
            selectedUtilities,
            staticUtilities,
            subDataPoint,
            xAxis,
            yAxis,
          });
          if (chartType === chartTypesEnums.HEATMAP) {
            let { flattenData, xLabels, yLabels } = result;
            xLabels?.sort((a, b) => getColumnSortFunction({ aValue: a, bValue: b, dataKey: xAxis }));
            yLabels?.sort((a, b) => getColumnSortFunction({ aValue: a, bValue: b, dataKey: yAxis }));

            result = { flattenData, xLabels, yLabels };
          }
          return { ...result, requestId };
        } finally {
          worker.terminate();
        }
      },
      terminate: () => {
        worker.terminate();
      },
    };
  }, [projectInfoParsed, building]);

  const getUnit = useCallback(({ quantity }) => columnsUnits?.[quantity], []);

  const getColorEntityDecorated = ({ chartType, diagrams, quantity }) => {
    if (staticUtilities.includes(quantity)) {
      return { categoryName: colorCategoriesEnums.COLUMNS, entityName: quantity };
    } else {
      return getColorEntity({ chartType, diagrams, quantity });
    }
  };

  const setFilteredData = () => {};

  let { data: savedPortfolioAndColorsConfigs, isError } = useGetEnergyDashbordConfigs(projectId);

  savedPortfolioAndColorsConfigs = savedPortfolioAndColorsConfigs?.config ?? loadDashboardDefaultConfigs();

  const getColumnDisplayNameCallback = useCallback((props) => getEnergyDashboardColumnDisplayName(props), []);

  const chartTypes = useMemo(
    () => [
      chartTypesEnums.AREA,
      chartTypesEnums.BAR,
      chartTypesEnums.COMPOSED,
      chartTypesEnums.DOUGHNUT,
      chartTypesEnums.HEATMAP,
      chartTypesEnums.HISTOGRAM,
      chartTypesEnums.KPI,
      chartTypesEnums.LINE,
      chartTypesEnums.PIE,
    ],
    []
  );

  const QueryClient = useQueryClient();
  const onSave = async (data) => {
    await postEnergyDashboardConfigs({ data: data, projectId });
    QueryClient.invalidateQueries(["energyDashbordConfigs", projectId, ENERGY_DASHBOARD_NAME_API_BODY]);
  };

  const onReset = async () => {
    await deleteEnergyDashboardConfigs({ projectId });
    QueryClient.invalidateQueries(["energyDashbordConfigs", projectId, ENERGY_DASHBOARD_NAME_API_BODY]);
  };

  return (
    <LoadingOrEmptyWrapper height="400px" showEmpty={isError} showLoading={isLoading || pIIsLoading}>
      <DynamicDashboardMultiTab
        allAvailableFilters={() => []}
        allFilters={allFilters}
        allSettings={allSettings}
        categoricalColumns={categoricalColumns}
        chartTypes={chartTypes}
        dataTransformator={dataTransformator}
        dataTransformatorMultithread={dataTransformatorMultithread}
        defaultColors={defaultColors}
        defaultPortfolioConfigsAndColorsConfigs={defaultPortfolioConfigsAndColorsConfigs}
        dynamicChartsRowHeight={dynamicChartsRowHeight}
        filteredData={generalDashboardData}
        filtersCategories={[]}
        generalDashboardData={generalDashboardData}
        getColorEntity={getColorEntityDecorated}
        getColumnDisplayName={getColumnDisplayNameCallback}
        getColumnSortFunction={getColumnSortFunction}
        getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
        getUnit={getUnit}
        hoverEffect={false}
        IdColumnName={columnsDisplayNamesEnums.ID}
        isItemHovered={() => true}
        multithread={multithread}
        numericalColumns={numericalColumns}
        onReset={onReset}
        onSave={onSave}
        // adjustConfigs={adjustConfigs}
        savedPortfolioAndColorsConfigs={savedPortfolioAndColorsConfigs}
        setFilteredData={setFilteredData}
        setTriggerFlag={() => {}}
        useGetSpecificData={useGetSpecificData}
      />
    </LoadingOrEmptyWrapper>
  );
};
