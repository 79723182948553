import { Paper } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { IsAllowed } from "../../acl";
import { useCheckFloorPlanAllFilesStatus } from "../../api/hooks/allHooks";
import apiUrls from "../../api/urls";
import { eventNames, trackGAEvent, withGAEventTracking } from "../../functions/googleAnalytics";
import { customApiCall } from "../../utils/axios";
import { convertDatetimeStringToDate } from "../../utils/date";
import { ComponentsCommonHeader } from "../ComponentsCommonHeader";
import { IconButton } from "../IconButton";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";
import { Table } from "../Table";

const downloadBuildingPassportFiles = (projectId) => {
  const url = apiUrls.layerFiles.buildingPassport.download(projectId);
  const fileName = "Floor plan.zip";
  const errorMessage = i18next.t("general.downloadFileProblem");
  customApiCall.download({ errorMessage, fileName, url });
};

export const BuildingProfileTable = ({ buildingFiles, className, isLoading, projectId, tableClassName }) => {
  const { t } = useTranslation();
  const { data: filesStatus } = useCheckFloorPlanAllFilesStatus(projectId);

  const filesTableColumns = [
    {
      cellType: null,
      dataKey: "name",
      downloadZipFile: (row) => null,
      headerClassName: "sentence-case",
      href: (row) => null,
      label: t("BuildingProfile.table.columns.name"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["name"],
    },
    {
      cellType: null,
      dataKey: "type",
      downloadZipFile: (row) => null,
      headerClassName: "sentence-case",
      href: (row) => null,
      label: t("BuildingProfile.table.columns.type"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["type"],
    },
    {
      cellType: null,
      dataKey: "_updated_at",
      downloadZipFile: (row) => null,
      headerClassName: "sentence-case",
      href: (row) => null,
      label: t("BuildingProfile.table.columns.date modified"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => {
        return convertDatetimeStringToDate(row["_updated_at"]);
      },
    },
    {
      cellType: "downloadFile",
      dataKey: "file_url",
      downloadZipFile: (row) => null,
      headerClassName: "sentence-case",
      href: (row) => row["file_url"],
      label: t("BuildingProfile.table.columns.files"),
      onCellClick: () => trackGAEvent(eventNames.BP_DOWNLOAD_SINGLE_FLOOR_PLANS),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => (
        <IconButton
          buttonProps={{
            disabled: !row.file_url,
            size: "sm",
            variant: "tertiary",
          }}
          iconProps={{
            color: "var(--clr-secondary-blue-500)",
            iconName: "Download",
            size: "md",
          }}
        />
      ),
    },
  ];
  return (
    <Paper className={`${className}   relative min-h-[20rem]`}>
      <ComponentsCommonHeader
        action={() =>
          withGAEventTracking(eventNames.BP_DOWNLOAD_ALL_FLOOR_PLANS, {}, () =>
            downloadBuildingPassportFiles(projectId)
          )
        }
        actionIsDisabled={filesStatus?.status !== 200}
        actionText={t("BuildingProfile.table.downloadAllFiles")}
        hasActionAccess={IsAllowed("BUILDING_PROFILE.DOWNLOAD_FILES")}
        title={t("BuildingProfile.table.title")}
      />
      <LoadingOrEmptyComponent showLoading={isLoading} />
      {!isLoading && (
        <Table
          className={`mt-6 ${tableClassName}`}
          data={buildingFiles}
          headers={filesTableColumns}
          idColumnName="id"
        />
      )}
    </Paper>
  );
};
