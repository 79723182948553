import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Icon } from "./Icon";
const BpIcon = styled("span")(({ theme }) => ({
  backgroundColor: "var(--clr-white)",
  border: "1px solid var(--clr-gray-700)",
  borderRadius: 2,

  height: 16,
  "input:hover ~ &": {
    border: "1px solid var(--clr-secondary-blue-300)",
    boxShadow:
      "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
  },
  width: 16,
}));

const BpCheckedIcon = styled(BpIcon)({
  "&:before": {
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='blue' /%3E%3C/svg%3E\")",
    content: '""',
    display: "block",
    height: 16,
    position: "absolute",
    right: "9px",
    top: "9px",
    width: 16,
  },
  backgroundColor: "var(--clr-white)",
  border: "1px solid var(--clr-secondary-blue-500)",
  "input:hover ~ &": {
    border: "1px solid var(--clr-secondary-blue-300)",
  },
});

export const DropdownBackup = ({
  displayOptions,
  height = "4rem",
  label,
  onBlur,
  onChange,
  options,
  selectProps,
  setValues,
  values,
  width,
  ...props
}) => {
  const { t } = useTranslation();

  const mapValuesToDisplayValues = (values, options, displayOptions) => {
    return values?.map((value) => {
      return displayOptions[options.indexOf(value)];
    });
  };
  if (!displayOptions) displayOptions = options;

  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value[value?.length - 1] === "all") {
      setValues(value?.length > options?.length ? [] : options);
    } else {
      setValues(typeof value === "string" ? value.split(",") : value);
    }
    if (onChange) onChange();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const ChevronDown = (props) => {
    return (
      <svg
        {...props}
        fill="none"
        height="6"
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "7%",
          top: "calc(50% - 0.3rem)",
          transform: isOpen ? "rotate(180deg)" : "none",
        }}
        viewBox="0 0 11 6"
        width="11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 0.750002L5.5 5.25L10 0.75" stroke="#1C1D20" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  };

  const ref = useRef(null);

  const allOptionsAreSelected = options.every((option) => values?.includes(option));

  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={ref}
      style={{ position: "relative", width: width }}
      {...props}
    >
      <FormControl sx={{ width: "100%" }} variant="filled">
        <InputLabel
          sx={{
            "::first-letter": {
              textTransform: "uppercase",
            },
            color: "var(--clr-gray-600)",
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "1.9rem",
            transform: "translate(1.2rem, 1rem) scale(1)",
          }}
        >
          {label}
        </InputLabel>
        <Select
          IconComponent={ChevronDown}
          MenuProps={{
            sx: {
              "&& .Mui-selected:not(:hover)": {
                backgroundColor: "var(--clr-secondary-blue-50)",
              },
              "& .MuiPaper-root": {
                borderRadius: "4px",
                padding: "0rem",
                width: `${ref.current ? ref.current.offsetWidth : ""}px !important`,
              },
            },
          }}
          multiple
          onBlur={onBlur}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          renderValue={() => {
            if (allOptionsAreSelected) {
              return t("All");
            } else {
              return mapValuesToDisplayValues(values, options, displayOptions).join(", ");
            }
          }}
          sx={{
            "&.Mui-focused": {
              backgroundColor: "white",
              border: "1px solid var(--clr-secondary-blue-500)",
            },
            "&:after": {
              border: "none",
            },
            "&:before": {
              border: "none",
            },
            "&:hover": {
              ":before": {
                border: "none !important",
              },
              backgroundColor: "var(--clr-white)",
              border: "1px solid var(--clr-secondary-blue-200)",
              boxShadow:
                "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
            },
            backgroundColor: "var(--clr-white)",
            border: `1px solid ${values?.length === 0 ? "var(--clr-gray-900)" : "var(--clr-leaftech-blue-500)"}`,
            borderRadius: "7px",
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: 400,
            height: height,
            lineHeight: "1.9rem",
          }}
          value={values}
          {...selectProps}
        >
          <MenuItem
            sx={{
              "&:focus:not(:hover)": {
                backgroundColor: "white",
              },
              "&:hover": {
                backgroundColor: "var(--clr-secondary-blue-50)",
              },
              backgroundColor: `${allOptionsAreSelected ? "var(--clr-secondary-blue-50)" : "var(--clr-white)"}`,
              marginLeft: "4px",
              marginRight: "4px",
              padding: "0.3rem",
            }}
            value="all"
          >
            <Checkbox
              checked={values?.length > 0 && allOptionsAreSelected}
              checkedIcon={<BpCheckedIcon />}
              color="default"
              disableRipple
              icon={<BpIcon />}
              inputProps={{ "aria-label": "Checkbox demo" }}
              sx={{
                "&:hover": { bgcolor: "transparent" },
              }}
            />
            <ListItemText
              disableTypography
              primary={t("all")}
              sx={{
                color: "var(--clr-gray-900)",
                fontFamily: "Nunito Sans",
                fontSize: "1.4rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "1.9rem",
                marginLeft: "0.3rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
            />
          </MenuItem>
          {options?.map((name, index) => (
            <MenuItem
              key={index}
              sx={{
                "&:focus:not(:hover)": {
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: "var(--clr-secondary-blue-50)",
                },
                backgroundColor: "var(--clr-white)",
                marginLeft: "4px",
                marginRight: "4px",
                minHeight: "auto",
                padding: "0.3rem",
              }}
              value={name}
            >
              <Checkbox
                checked={values?.indexOf(name) > -1}
                checkedIcon={<BpCheckedIcon />}
                color="default"
                disableRipple
                icon={<BpIcon />}
                inputProps={{ "aria-label": "Checkbox demo" }}
                sx={{
                  "&:hover": { bgcolor: "transparent" },
                }}
              />
              <ListItemText
                disableTypography
                primary={displayOptions[index]}
                sx={{
                  color: "var(--clr-gray-900)",
                  fontFamily: "Nunito Sans",
                  fontSize: "1.4rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "1.9rem",
                  marginLeft: "0.3rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  );
};

const MenuItemComponent = ({ checked, displayOption, grouped, key, value }) => {
  return (
    <MenuItem
      key={key}
      sx={{
        "&:focus:not(:hover)": {
          backgroundColor: "white",
        },
        "&:hover": {
          backgroundColor: "var(--clr-secondary-blue-50)",
        },
        backgroundColor: "var(--clr-white)",
        marginLeft: "4px",
        marginRight: "4px",
        minHeight: "auto",
        padding: grouped ? "0.3rem 1.5rem" : "0.3rem 0",
      }}
      value={value}
    >
      <Checkbox
        checked={checked}
        checkedIcon={<BpCheckedIcon />}
        color="default"
        disableRipple
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
      />
      <ListItemText
        disableTypography
        primary={displayOption}
        sx={{
          color: "var(--clr-gray-900)",
          fontFamily: "Nunito Sans",
          fontSize: "1.4rem",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "1.9rem",
          marginLeft: "0.3rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      />
    </MenuItem>
  );
};

export const Dropdown = ({
  displayOptions,
  height = "4rem",
  label,
  onBlur,
  onChange,
  options,
  optionsGroups,
  selectProps,
  setValues,
  values,
  width,
  ...props
}) => {
  const isGrouped = optionsGroups?.length;
  if (!isGrouped) optionsGroups = [{ options }];
  const { t } = useTranslation();

  const mapValuesToDisplayValues = (values, options, displayOptions) => {
    return values?.map((value) => {
      return displayOptions[options.indexOf(value)];
    });
  };
  if (!displayOptions) displayOptions = options;

  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value[value?.length - 1] === "all") {
      setValues(value?.length > options?.length ? [] : options);
    } else {
      setValues(typeof value === "string" ? value.split(",") : value);
    }
    if (onChange) onChange();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const ChevronDown = (props) => {
    return (
      <svg
        {...props}
        fill="none"
        height="6"
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "8%",
          top: "calc(50% - 0.3rem)",
          transform: isOpen ? "rotate(180deg)" : "none",
        }}
        viewBox="0 0 11 6"
        width="11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 0.750002L5.5 5.25L10 0.75" stroke="#1C1D20" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  };

  const ref = useRef(null);

  const [openGroups, setOpenGroups] = useState({});
  const toggleGroup = (group) => {
    setOpenGroups((prev) => ({ ...prev, [group]: !prev[group] }));
  };
  const allOptionsAreSelected = options.every((option) => values?.includes(option));

  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={ref}
      style={{ position: "relative", width: width }}
      {...props}
    >
      <FormControl sx={{ width: "100%" }} variant="filled">
        <InputLabel
          sx={{
            "::first-letter": {
              textTransform: "uppercase",
            },
            color: "var(--clr-gray-600)",
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "1.9rem",
            transform: "translate(1.2rem, 1rem) scale(1)",
          }}
        >
          {label}
        </InputLabel>
        <Select
          endAdornment={
            values?.length > 0 && (
              <InputAdornment className="mb-6 mr-1 w-0" position="end">
                <Icon className="h-3 w-3" iconName="Erase" onClick={() => setValues([])} />
              </InputAdornment>
            )
          }
          IconComponent={ChevronDown}
          MenuProps={{
            sx: {
              "&& .Mui-selected:not(:hover)": {
                backgroundColor: "var(--clr-secondary-blue-50)",
              },
              "& .MuiPaper-root": {
                borderRadius: "4px",
                padding: "0rem",
                width: `${ref.current ? ref.current.offsetWidth : ""}px !important`,
              },
            },
          }}
          multiple
          onBlur={onBlur}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          renderValue={() => {
            if (allOptionsAreSelected) {
              return t("All");
            } else {
              return mapValuesToDisplayValues(values, options, displayOptions).join(", ");
            }
          }}
          sx={{
            "&.Mui-focused": {
              backgroundColor: "white",
              border: "1px solid var(--clr-secondary-blue-500)",
            },
            "&:after": {
              border: "none",
            },
            "&:before": {
              border: "none",
            },
            "&:hover": {
              ":before": {
                border: "none !important",
              },
              backgroundColor: "var(--clr-white)",
              border: "1px solid var(--clr-secondary-blue-200)",
              boxShadow:
                "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
            },
            backgroundColor: "var(--clr-white)",
            border: `1px solid ${values?.length === 0 ? "var(--clr-gray-900)" : "var(--clr-leaftech-blue-500)"}`,
            borderRadius: "7px",
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontStyle: "normal",
            fontWeight: 400,
            height: height,
            lineHeight: "1.9rem",
          }}
          value={values}
          {...selectProps}
        >
          <MenuItem
            className={`mx-1 ${isGrouped && "ml-0 bg-gray-100 hover:bg-blue-200"} `}
            sx={{
              "&:focus:not(:hover)": {
                backgroundColor: "white",
              },
              "&:hover": {
                backgroundColor: "var(--clr-secondary-blue-50)",
              },
              backgroundColor: `${allOptionsAreSelected ? "var(--clr-secondary-blue-50)" : "var(--clr-white)"}`,
              padding: isGrouped ? "0.3rem 1.3rem" : "0.3rem 0",
            }}
            value="all"
          >
            <Checkbox
              checked={values?.length > 0 && allOptionsAreSelected}
              checkedIcon={<BpCheckedIcon />}
              color="default"
              disableRipple
              icon={<BpIcon />}
              inputProps={{ "aria-label": "Checkbox" }}
              sx={{
                "&:hover": { bgcolor: "transparent" },
              }}
            />
            <ListItemText
              disableTypography
              primary={t("all")}
              sx={{
                color: "var(--clr-gray-900)",
                fontFamily: "Nunito Sans",
                fontSize: "1.4rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "1.9rem",
                marginLeft: "0.3rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
            />
          </MenuItem>
          {optionsGroups.map((group) => {
            const groupOptions = options?.filter((name) => group?.options?.includes(name));
            const groupDisplayOptions = displayOptions.filter((_, index) => groupOptions.includes(options[index]));
            const OptionsComponent = groupOptions?.map((name, index) => {
              return MenuItemComponent({
                checked: values?.indexOf(name) > -1,
                displayOption: groupDisplayOptions[index],
                grouped: isGrouped,
                key: index,
                value: name,
              });
            });
            if (isGrouped && groupOptions?.length)
              return [
                <ListSubheader className="w-full p-1">
                  <Box
                    className={`${
                      openGroups[group.group] ? "bg-blue-100" : "bg-gray-100"
                    } flex w-full cursor-pointer items-center justify-between px-2 hover:bg-blue-200`}
                    onClick={() => toggleGroup(group.group)}
                  >
                    <span>
                      <Checkbox
                        checked={groupOptions?.every((option) => values?.includes(option))}
                        checkedIcon={<BpCheckedIcon />}
                        color="default"
                        disableRipple
                        icon={<BpIcon />}
                        onChange={() => {
                          const isAllSelected = groupOptions?.every((option) => values?.includes(option));
                          if (isAllSelected) setValues(values?.filter((v) => !groupOptions?.includes(v)));
                          else {
                            setValues([...values, ...groupOptions]);
                          }
                        }}
                        onClick={(e) => e.stopPropagation()}
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                      />
                      <span className="t-heading-s-700  ml-2">{group.group}</span>
                    </span>
                    <IconButton size="small">
                      {openGroups[group.group] ? <Icon iconName="ChevronDown" /> : <Icon iconName="ChevronRight" />}
                    </IconButton>
                  </Box>
                </ListSubheader>,
                openGroups[group.group] && OptionsComponent,
              ];
            else {
              return OptionsComponent;
            }
          })}
        </Select>
      </FormControl>
    </Wrapper>
  );
};

const Wrapper = styled("div")(() => ({
  "& .MuiInputLabel-shrink": {
    color: "var(--clr-gray-600)",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0.1em",
    lineHeight: "1.2rem",
    transform: "translate(1.2rem, 0.7rem) scale(0.75)",
  },

  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
}));
