import AssetOverview from "./AssetOverview";
import BuildingPassportSharedLayout from "./BuildingPassportSharedLayout";
import BuildingProfile from "./BuildingProfile";
import Certificates from "./Certificates";
import dynamicValues from "./dynamicValues";
import EnergyPerformance from "./EnergyPerformance";
import general from "./general";
import Login from "./Login";
import Modernization from "./Modernization";
import SharedLayout from "./SharedLayout";
import TechnicalData from "./TechnicalData";

export const de = {
  ...dynamicValues,
  AssetOverview,
  BuildingPassportSharedLayout,
  BuildingProfile,
  Certificates,
  EnergyPerformance,
  general,
  Login,
  Modernization,
  SharedLayout,
  TechnicalData,
};
