export const getDefaultActiveTab = (enabledTabs) => {
  return enabledTabs?.length > 0 ? enabledTabs[0] : "";
};

export const getEnabledTabs = (allParentsFiles) => {
  return allParentsFiles
    ?.filter((parent) => {
      return parent?.data?.data?.length > 0;
    })
    ?.map((parent) => parent.data.tab);
};

export const extractTabData = ({ activeTab, data }) => {
  const tabData = data?.find((i) => i?.data?.tab === activeTab);
  return tabData?.data?.data || [];
};

export const getSortType = (headerDataKey, sortColumn, isSortAscending) => {
  if (headerDataKey !== sortColumn) return "Sorting";
  if (isSortAscending) return "SortingAscending";
  return "SortingDescending";
};
export const isCellTypeDownload = (cellType) => cellType === "downloadFile" || cellType === "downloadZipFile";

export const shouldBeSentenceCase = (word) => {
  const isException = word === "CO₂ emission" || word === "U-Value" || word === "G-Value";
  return !isException;
};
