import { Stack } from "@mui/material";
import React, { useState } from "react";

import { sumByKeys } from "../../utils/dataManipulation";
import Bullet from "./Bullet";

export const VerticalStackedBarChartColorBar = ({ bottom = "-30px", className, config, data }) => {
  const [hoverBulletIndex, setHoverBulletIndex] = useState(null);
  const aggregatedPercentages = sumByKeys({ data, keys: ["percentage"] });
  const totalSum = aggregatedPercentages?.percentage || 1;

  return (
    <Stack
      className={`${className}  [&>*:first-child]:rounded-t-lg [&>*:last-child]:rounded-b-lg`}
      flexDirection="column"
    >
      {data?.map((row, index) => {
        return (
          <div
            className="relative w-full"
            key={index}
            onMouseEnter={() => setHoverBulletIndex(index)}
            onMouseLeave={() => setHoverBulletIndex(null)}
            style={{
              backgroundColor: config[row.label]?.color,
              height: `${(row.percentage * 100) / totalSum}%`,
            }}
          >
            {index === hoverBulletIndex && (
              <Bullet
                bottom={bottom}
                className="absolute"
                color={config[row.label]?.color}
                height="2.6rem"
                label={`${parseFloat(row.percentage.toFixed(1))}%`}
                left="50%"
                reverse={false}
                textColor={config[row.label]?.textColor}
                transform="translate(-50%, -50%)"
                // top="0px"
                type="circle"
                width="2.6rem"
              />
            )}
          </div>
        );
      })}
    </Stack>
  );
};
