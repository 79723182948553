import { Stack } from "@mui/material";
import React from "react";

import LegendBullet from "./LegendBullet";

export const GeneralChartLegend = ({ className, legends }) => {
  return (
    <Stack className={`flex-wrap ${className}`} spacing={4}>
      {legends?.map((legend, index) => {
        return (
          <Stack key={index} spacing={2}>
            <LegendBullet color={legend.color} height="0.8rem" type="circle" width="0.8rem" />
            <span className="t-body-xl">{legend.label}</span>
            <span className="t-body-xl text-gray-500">{legend.value}</span>
          </Stack>
        );
      })}
    </Stack>
  );
};
