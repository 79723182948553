import { Paper, Stack } from "@mui/material";
import "react-grid-layout/css/styles.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "rsuite/dist/rsuite-no-reset.min.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import "react-resizable/css/styles.css";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  deletePortfolioDashboardConfigs,
  postPortfolioDashboardConfigs,
} from "../api/functions/all";
import { useGetAllBuildings, useGetPortfolioDashboardConfigs } from "../api/hooks/allHooks";
import { Table } from "../components";
import { AssetCardModal } from "../components/AssetOverview/AssetCardModal";
import { DynamicDashboardMultiTab } from "../components/chart/DynamicDashboardMultiTab";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";
import {
  categoricalColumns,
  columnsAPINamesEnums,
  columnsDisplayNamesEnums,
  columnsUnits,
  dataNeededColumns,
  defaultColors,
  FiltersNames,
  getPortfolioColumnDisplayName,
  infoBoxAndTableNeededColumns,
  isRelevantOption,
  PORTFOLIO_NAME_IN_API_BODY,
} from "../enums/AssetOverview/portfolio";
import { FilterTypes } from "../enums/components";
import {
  chartTypesEnums,
  diagramTypes,
  diagramTypesDisplayOptions,
  getSortOptions,
  valuesEnums,
} from "../enums/dynamicDashboard";
import { eventNames, trackGAEvent } from "../functions/googleAnalytics";
import { distinctFilter, findDataRange, getUniqueValuesFromJson } from "../utils/dataManipulation";
import { getColorEntity, handleConfigChange, settingsOptions } from "../utils/dynamicDashboard";

const filtersCategories = [
  {
    categoryName: "Building profile",
    filtersNames: [
      columnsAPINamesEnums.PORTFOLIO,
      columnsAPINamesEnums.BUILDING_ID,
      columnsAPINamesEnums.BUILDING_NAME,
      columnsAPINamesEnums.USE_TYPE,
      columnsAPINamesEnums.BOUGHT_ON,
      columnsAPINamesEnums.SOLD_ON,
      columnsAPINamesEnums.RENTAL_UNITS,
      columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
      columnsAPINamesEnums.FLOOR_AMOUMT,
      columnsAPINamesEnums.HERITAGE_PROTECTED,
      columnsAPINamesEnums.BUILDING_TYPE_ID,
      columnsAPINamesEnums.BUILDING_TYPE,
      columnsAPINamesEnums.PRE_FABRICATED,
      columnsAPINamesEnums.LOGGIA,
      columnsAPINamesEnums.BALCONY,
      columnsAPINamesEnums.FOOTPRINT,
      columnsAPINamesEnums.CEILING_AREA,
      columnsAPINamesEnums.FACADE_AREA,
      columnsAPINamesEnums.PREMISE,
      columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
      columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      columnsAPINamesEnums.NET_AREA_WITH_BASEMENT,
      columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
      // columnsNamesEnums.ACCOUNTING_AREA,
    ],
  },
  {
    categoryName: "Geography",
    filtersNames: [
      columnsAPINamesEnums.ZIP,
      columnsAPINamesEnums.CITY,
      columnsAPINamesEnums.MAIN_DISTRICT,
      columnsAPINamesEnums.SUB_DISTRICT,
      columnsAPINamesEnums.FULL_ADDRESS,
      columnsAPINamesEnums.STREET,
    ],
  },
  {
    categoryName: "Energy & CO2",
    filtersNames: [
      columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A,
      columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A,
      columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE,
      columnsAPINamesEnums.NATURAL_GAS_AVAILABLE,
      columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      columnsAPINamesEnums.CO2_EMISSION,
      columnsAPINamesEnums.STRANDING_YEAR,
    ],
  },
];

const defaultPortfolioConfigsAndColorsConfigs = {
  colors: {
    Columns: {
      carbon_dioxide_emission_value: "#000000",
      "CO2 Emissions": "#141414",
      construction_year: "#23cbe1",
      "Year of construction": "#3df0b4",
    },
    Mixed: {},
    Values: {
      address: {},
      balcony: {},
      Balkone: {},
      building_type: {},
      "Building Name": {},
      "Building Type": {},
      City: {},
      city: {},
      descriptive_name: {},
      "EnA-Eff.kl": {
        "": "#dd2727",
        "No Data": "#2ec275",
      },
      energy_label: {},
      "Full Address": {},
      heritage_protected: {},
      "Heritage Protected": {},
      Loggia: {
        Y: "#61ff98",
      },
      loggia: {},
      main_district: {},
      "Main District": {},
      Portfolio: {},
      portfolio: {},
      post_code: {},
      pre_fabricated: {},
      "Pre Fabricated": {},
      Street: {},
      street: {},
      sub_district: {},
      "Sub District": {},
      type: {},
      "Use type": {},
      Zip: {},
    },
  },
  tabs: [
    {
      filters: {
        City: ["Berlin"],
        city: ["Berlin"],
        "CO2 Emissions": [0, 108.7],
        "Electrictiy consumption": [48.95, 54],
        "Energy consumption": [3.63, 66],
        "Energy Efficiency class": [null, "C", "A", "B"],
        "Gross area": [29000, 51430],
        "Heat energy consumption": [16.35, 123],
        "Stranding year": [2020, 2035],
        "Total Energy consumption": [37, 309.5618896484375],
      },
      selectedFiltersNames: ["City", "city"],
      settings: [
        {
          chartType: "miniMap",
          dataPoint: "energy_consumption",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: null,
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 7,
          id: 6,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          sortValue: null,
          title: null,
          type: "1",
          w: 12,
          x: 0,
          xAxis: null,
          y: 0,
          yAxis: null,
        },
        {
          chartType: "histogram",
          dataPoint: null,
          diagrams: [
            {
              color: "black",
              dataKey: "gross_asset_basement_included",
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: null,
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 7,
          id: 7,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          sortValue: null,
          title: "Gross Area Histogram",
          type: "1",
          w: 6,
          x: 6,
          xAxis: null,
          y: 7,
          yAxis: null,
        },
        {
          chartType: "histogram",
          dataPoint: "construction_year",
          diagrams: [
            {
              color: "black",
              dataKey: "construction_year",
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: null,
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 7,
          id: 8,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          sortValue: null,
          title: "Year of construction",
          type: "1",
          w: 6,
          x: 0,
          xAxis: null,
          y: 7,
          yAxis: null,
        },
        {
          chartType: "doughnut",
          dataPoint: "Count",
          diagrams: [
            {
              color: "black",
              dataKey: null,
              dataPoint: null,
              diagramType: null,
              direction: "horizontal",
              partitions: "main_district",
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 7,
          id: 9,
          legendsPosition: "right",
          maxH: 20,
          minH: 3,
          minW: 3,
          sortValue: null,
          title: null,
          type: "1",
          w: 6,
          x: 0,
          xAxis: null,
          y: 14,
          yAxis: null,
        },
        {
          aggregateXAxis: 0,
          chartType: "composed",
          dataPoint: null,
          diagrams: [
            {
              color: "black",
              dataKey: "carbon_dioxide_emission_value",
              dataPoint: null,
              diagramType: "scatter",
              direction: "horizontal",
              partitions: null,
              percentageBased: false,
              stacks: null,
              title: null,
            },
          ],
          draggable: true,
          h: 7,
          id: 10,
          legendsPosition: "bottom",
          maxH: 20,
          minH: 3,
          minW: 3,
          sortValue: null,
          title: "Energy vs. CO2",
          type: "1",
          w: 6,
          x: 6,
          xAxis: "energy_consumption",
          y: 14,
          yAxis: null,
        },
      ],
      tab: "Portfolio summary",
    },
  ],
};

const loadDashboardDefaultConfigs = () => {
  return defaultPortfolioConfigsAndColorsConfigs;
};

export const Portfolio = () => {
  useEffect(() => {
    trackGAEvent(eventNames.PORTFOLIO_VIEW);
  }, []);

  const { t } = useTranslation();
  const { projectId } = useParams();
  const { user } = useSelector((store) => store.user);
  const userId = user?.user_id;
  const { data, isLoading } = useGetAllBuildings(userId);

  const dataPreprocessing = useMemo(() => {
    const mappedData = [];
    const mappedInfoBoxAndTableData = [];
    data?.forEach((row) => {
      const mappedDataRow = {};
      const mappedInfoBoxAndTableRow = {};
      Object.keys(row).forEach((oldDataKey) => {
        let value = row[oldDataKey];

        // const newDataKey = dataMapping[oldDataKey]
        const newDataKey = oldDataKey;
        if (categoricalColumns.includes(newDataKey)) {
          value = value ?? valuesEnums.NO_DATA;
        }
        if (infoBoxAndTableNeededColumns.includes(newDataKey)) {
          mappedInfoBoxAndTableRow[newDataKey] = value;
        }

        if (dataNeededColumns.includes(newDataKey)) {
          mappedDataRow[newDataKey] = value;
        }
        mappedDataRow[columnsAPINamesEnums.COUNT] = 1;
      });

      mappedInfoBoxAndTableData.push(mappedInfoBoxAndTableRow);
      mappedData.push(mappedDataRow);
    });

    return [mappedData, mappedInfoBoxAndTableData];
  }, [data]);

  const [generalDashboardData, infoBoxesAndTableData] = useMemo(() => dataPreprocessing, [dataPreprocessing]);

  let dataColumns = [],
    numericalColumns = [];
  if (generalDashboardData?.length) {
    dataColumns = Object.keys(generalDashboardData[0]);
    numericalColumns = dataColumns.filter((col) => !categoricalColumns.includes(col));
  }

  const dataColumnsDisplayOptions = dataColumns?.map((colName) => getPortfolioColumnDisplayName({ colName }));

  const [filteredData, setFilteredData] = useState(generalDashboardData);

  const headers = [
    {
      cellType: null,
      dataKey: columnsAPINamesEnums.BUILDING_NAME,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: columnsDisplayNamesEnums.BUILDING_NAME,
      subValueTransformation: (row) => null,
      valueClassName: "py-1",
      valueTransformation: (row) => (
        <Stack gap={2}>
          <img className="h-12 w-12 object-contain object-center" src={row[columnsAPINamesEnums.IMAGE_URL_VALUE]} />
          <span>{row[columnsAPINamesEnums.BUILDING_NAME]}</span>
        </Stack>
      ),
    },
    {
      cellType: "",
      dataKey: columnsAPINamesEnums.PORTFOLIO,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: columnsDisplayNamesEnums.PORTFOLIO,
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row[columnsAPINamesEnums.PORTFOLIO],
    },
    {
      cellType: null,
      dataKey: columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row[columnsAPINamesEnums.HEAT_ENERGY_TYPE],
    },

    {
      cellType: null,
      dataKey: columnsAPINamesEnums.USE_TYPE,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: columnsDisplayNamesEnums.USE_TYPE,
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row[columnsAPINamesEnums.USE_TYPE],
    },
    {
      cellType: null,
      dataKey: columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row[columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION],
    },
    {
      cellType: null,
      dataKey: columnsAPINamesEnums.CO2_EMISSION,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      label: columnsDisplayNamesEnums.CO2_EMISSION,
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row[columnsAPINamesEnums.CO2_EMISSION],
    },
  ];

  const tableFilteredData = infoBoxesAndTableData.filter((tableDataRow) => {
    return filteredData?.some(
      (mainDataRow) => mainDataRow[columnsAPINamesEnums.ID] === tableDataRow[columnsAPINamesEnums.ID]
    );
  });

  let { data: savedPortfolioAndColorsConfigs, isError } = useGetPortfolioDashboardConfigs(userId);

  const savedPortfolioAndColorsConfigsM = useMemo(() => {
    return savedPortfolioAndColorsConfigs?.config ?? loadDashboardDefaultConfigs();
  }, [savedPortfolioAndColorsConfigs]);

  const dataColumnsOptionsGroups = filtersCategories?.map((category) => {
    const sortedOptions = [...category.filtersNames];

    sortedOptions.sort();
    return { group: category.categoryName, options: sortedOptions };
  });

  dataColumnsOptionsGroups.unshift({ group: "Count", options: [columnsAPINamesEnums.COUNT] });

  const allAvailableFilters = ({ allFiltersConfig, setConfig }) => [
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.PORTFOLIO,
      filterName: columnsAPINamesEnums.PORTFOLIO,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.PORTFOLIO,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.PORTFOLIO,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.PORTFOLIO),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.USE_TYPE,
      filterName: columnsAPINamesEnums.USE_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.USE_TYPE,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.USE_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.USE_TYPE),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ENERGY_EFFICIENCY_CLASS,
      filterName: columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.ENERGY_EFFICIENCY_CLASS,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
      filterName: columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.HEAT_ENERGY_TYPE,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.HEAT_ENERGY_TYPE),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.PREMISE,
      filterName: columnsAPINamesEnums.PREMISE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.PREMISE,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.PREMISE,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.PREMISE], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.FOOTPRINT,
      filterName: columnsAPINamesEnums.FOOTPRINT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.FOOTPRINT,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.FOOTPRINT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.FOOTPRINT], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.GROSS_AREA_WITH_BASEMENT,
      filterName: columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.GROSS_AREA_WITH_BASEMENT,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      filterName: columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT],
          defaultMin: 0,
        }),
      },
      setConfig,
    },

    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.FLOOR_AMOUMT,
      filterName: columnsAPINamesEnums.FLOOR_AMOUMT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.FLOOR_AMOUMT,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.FLOOR_AMOUMT,
        step: 1,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.FLOOR_AMOUMT],
          defaultMin: 0,
        }),
      },
      setConfig,
    },

    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.YEAR_OF_CONSTRUCTION,
      filterName: columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.YEAR_OF_CONSTRUCTION,
        step: 1,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.YEAR_OF_CONSTRUCTION],
          defaultMin: 1900,
        }),
      },
      setConfig,
    },

    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.STRANDING_YEAR,
      filterName: columnsAPINamesEnums.STRANDING_YEAR,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.STRANDING_YEAR,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.STRANDING_YEAR,
        step: 1,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.STRANDING_YEAR],
          defaultMin: 1900,
        }),
      },
      setConfig,
    },

    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      filterName: columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION],
          defaultMin: 0,
        }),
      },
      setConfig,
    },

    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.CO2_EMISSION,
      filterName: columnsAPINamesEnums.CO2_EMISSION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.CO2_EMISSION,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.CO2_EMISSION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.CO2_EMISSION], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      filterName: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      filterName: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      filterName: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_A,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      filterName: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    // ////////////////////////////////////////////////////////////////////////////////////

    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.HERITAGE_PROTECTED,
      filterName: columnsAPINamesEnums.HERITAGE_PROTECTED,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.HERITAGE_PROTECTED,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.HERITAGE_PROTECTED,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.HERITAGE_PROTECTED),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_TYPE_ID,
      filterName: columnsAPINamesEnums.BUILDING_TYPE_ID,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.BUILDING_TYPE_ID,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.BUILDING_TYPE_ID,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_TYPE_ID),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_TYPE,
      filterName: columnsAPINamesEnums.BUILDING_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.BUILDING_TYPE,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.BUILDING_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_TYPE),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.PRE_FABRICATED,
      filterName: columnsAPINamesEnums.PRE_FABRICATED,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.PRE_FABRICATED,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.PRE_FABRICATED,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.PRE_FABRICATED),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.LOGGIA,
      filterName: columnsAPINamesEnums.LOGGIA,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.LOGGIA,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.LOGGIA,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.LOGGIA),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.BALCONY,
      filterName: columnsAPINamesEnums.BALCONY,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.BALCONY,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.BALCONY,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BALCONY),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.CEILING_AREA,
      filterName: columnsAPINamesEnums.CEILING_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.CEILING_AREA,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.CEILING_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.CEILING_AREA], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.FACADE_AREA,
      filterName: columnsAPINamesEnums.FACADE_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.FACADE_AREA,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.FACADE_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.FACADE_AREA], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.NET_AREA_WITH_BASEMENT,
      filterName: columnsAPINamesEnums.NET_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.NET_AREA_WITH_BASEMENT,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.NET_AREA_WITH_BASEMENT,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.NET_AREA_WITH_BASEMENT],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      filterName: columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      filterName: columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.EPC_CONSUMPTION_KWH_M2A,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.DISTRICT_HEATING_AVAILABLE,
      filterName: columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.DISTRICT_HEATING_AVAILABLE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.NATURAL_GAS_AVAILABLE,
      filterName: columnsAPINamesEnums.NATURAL_GAS_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.NATURAL_GAS_AVAILABLE,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.NATURAL_GAS_AVAILABLE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.NATURAL_GAS_AVAILABLE),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.BOUGHT_ON,
      filterName: columnsAPINamesEnums.BOUGHT_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.BOUGHT_ON,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.BOUGHT_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.BOUGHT_ON], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.SOLD_ON,
      filterName: columnsAPINamesEnums.SOLD_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.SOLD_ON,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.SOLD_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.SOLD_ON], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.RENTAL_UNITS,
      filterName: columnsAPINamesEnums.RENTAL_UNITS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.RENTAL_UNITS,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.RENTAL_UNITS,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.RENTAL_UNITS], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_ID,
      filterName: columnsAPINamesEnums.BUILDING_ID,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.BUILDING_ID,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.BUILDING_ID,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_ID),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.BUILDING_NAME,
      filterName: columnsAPINamesEnums.BUILDING_NAME,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.BUILDING_NAME,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.BUILDING_NAME,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.BUILDING_NAME),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ZIP,
      filterName: columnsAPINamesEnums.ZIP,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.ZIP,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.ZIP,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.ZIP),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.CITY,
      filterName: columnsAPINamesEnums.CITY,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.CITY,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.CITY,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.CITY),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.MAIN_DISTRICT,
      filterName: columnsAPINamesEnums.MAIN_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.MAIN_DISTRICT,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.MAIN_DISTRICT,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.MAIN_DISTRICT),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.SUB_DISTRICT,
      filterName: columnsAPINamesEnums.SUB_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.SUB_DISTRICT,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.SUB_DISTRICT,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.SUB_DISTRICT),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.FULL_ADDRESS,
      filterName: columnsAPINamesEnums.FULL_ADDRESS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.FULL_ADDRESS,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.FULL_ADDRESS,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.FULL_ADDRESS),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.STREET,
      filterName: columnsAPINamesEnums.STREET,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.STREET,
      props: {
        className: "w-full",
        height: "4.8rem",
        label: columnsDisplayNamesEnums.STREET,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.STREET),
        width: "100%",
      },
      setConfig,
    },
  ];

  const dynamicChartsRowHeight = 50;
  const allSettings = (updateChart, config) => [
    {
      adjustConfig: (config) => config,
      filterLabel: "Chart title",
      filterName: FiltersNames.TITLE,
      filterType: FilterTypes.INPUT,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        height: "4rem",
        label: "Title",
        placeholder: "Title",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Data point",
      filterName: FiltersNames.DATAPOINT,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      mainDataKey: "dataPoint",
      props: {
        displayOptions: dataColumnsDisplayOptions,
        label: "Data point",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      useAsDataFilter: false,
      visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "X Axis",
      filterName: FiltersNames.X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: dataColumnsDisplayOptions,
        label: "x-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
      },
      setConfig: (updateChart) => updateChart,
      visible: () =>
        [chartTypesEnums.COMPOSED].includes(config.chartType) && isRelevantOption(config.chartType, settingsOptions.X),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "X Axis",
      filterName: FiltersNames.X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: dataColumnsDisplayOptions,
        label: "x-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
      },
      setConfig: (updateChart) => updateChart,
      visible: () =>
        [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.HEATMAP, chartTypesEnums.LINE].includes(
          config.chartType
        ) && isRelevantOption(config.chartType, settingsOptions.X),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Aggregation",
      filterName: FiltersNames.AGGREGATE_X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: ["Don't aggregate", "Aggregate"],
        label: "Aggregation",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.AGGREGATE_X_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Invert X Axis",
      filterName: FiltersNames.INVERT_X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Invert X Axis",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Y Axis",
      filterName: FiltersNames.Y_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: dataColumnsDisplayOptions,
        label: "y-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Invert Y Axis",
      filterName: FiltersNames.INVERT_Y_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Invert Y Axis",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Lengeds Position",
      filterName: FiltersNames.LEGEND_POSITION,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        displayOptions: ["Bottom", "Right"],
        label: "Lengeds position",
        options: ["bottom", "right"],
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Show labels",
      filterName: FiltersNames.SHOW_PIE_LABELS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        displayOptions: ["No", "Yes"],
        label: "Show labels",
        options: [0, 1],
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.SHOW_PIE_LABELS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Sort by",
      filterName: FiltersNames.SORT_VALUE,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
        label: "Sort by",
        options: getSortOptions({ chartType: config.chartType })?.options,
      },
      setConfig: (updateChart) => updateChart,
      visible: () => {
        return (
          isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
          !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
        );
      },
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Color bar range",
      filterName: settingsOptions.COLOR_BAR_RANGE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      isInDiagramsPart: false,
      mainDataKey: columnsAPINamesEnums.PREMISE,
      props: {
        changeByTyping: true,
        className: "block w-3/4 ",
        label: "Color bar range",
        ...findDataRange({ data: filteredData, dataKeys: [config?.dataPoint], defaultMin: 0 }),
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.COLOR_BAR_RANGE),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Y Axis",
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: dataColumnsDisplayOptions,
        label: "y-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Diagram type",
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: Object.values(diagramTypesDisplayOptions).filter(
          (type) => type !== diagramTypesDisplayOptions.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0
        ),
        label: "Diagram type",

        options: Object.values(diagramTypes).filter(
          (type) => type !== diagramTypes.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0
        ),
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Partitions",
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: dataColumnsDisplayOptions,
        label: "Partitions",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Stacks",
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: dataColumnsDisplayOptions,
        label: "Stacks",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Direction",
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["Horizontal", "Vertical"],
        label: "Direction",
        options: ["horizontal", "vertical"],
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
    },
    // {
    //   filterLabel: 'Color',
    //   filterName: FiltersNames.DIAGRAMS_COLOR,
    //   filterType: FilterTypes.COLOR_INPUT,
    //   adjustConfig: (config) => config,
    //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
    //   props: {
    //     className: "block w-3/4 ",
    //   },
    //   visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.COLOR, diagramIndex),
    //   isInDiagramsPart: true,
    // },
  ];
  const useGetSpecificData = (_config) => {
    return { isError: false, isLoading: false, specificData: [] };
  };
  const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
    const { chartType, dataPoint, diagrams, xAxis, yAxis } = config;

    return JSON.stringify([dataPoint, chartType, diagrams, xAxis, yAxis]);
  }, []);

  const dataTransformator = useCallback((data, { chartType, dataPoint, diagrams, xAxis, yAxis }) => {
    let output = [];
    const { partitions, stacks } = diagrams?.[0];
    if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {
      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = { flattenData: flattenData || [] };
    } else if (
      [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.KPI, chartTypesEnums.LINE].includes(chartType)
    ) {
      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = { flattenData: flattenData || [] };
    } else if (chartType === chartTypesEnums.HEATMAP) {
      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (yAxis) filteredRow[yAxis] = row[yAxis];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      const xLabels = data?.map((row) => row[xAxis])?.filter(distinctFilter);
      xLabels?.sort();

      const yLabels = data?.map((row) => row[yAxis])?.filter(distinctFilter);
      yLabels?.sort();

      output = { flattenData: flattenData || [], xLabels, yLabels };
    } else {
      output = { flattenData: data || [] };
    }

    return output;
  }, []);

  const getUnit = useCallback(({ quantity }) => columnsUnits?.[quantity], []);

  const IdColumnName = columnsAPINamesEnums.ID;

  const [triggerFlag, setTriggerFlag] = useState(false);

  const renderMiniMapObjectClickModal = ({ objects: clickedAssets, setObjects: setClickedAssets }) => {
    return clickedAssets?.map((clickedAsset, index) => {
      const id = clickedAsset[columnsAPINamesEnums.ID];
      const selectedBuilding = infoBoxesAndTableData?.find((building) => building?.[columnsAPINamesEnums.ID] === id);
      return (
        <AssetCardModal
          assetCardPosition={clickedAsset?.assetPosition}
          building={selectedBuilding}
          key={id}
          onCardClick={() => {
            window.open(`${window.location.origin}/details/${id}/buildingProfile`);
          }}
          onClose={() => setClickedAssets((clickedAssets) => clickedAssets.filter((asset) => asset?.id !== id))}
          triggerFlag={`${triggerFlag} ${id}`}
        />
      );
    });
  };

  const getColumnDisplayNameCallback = useCallback((props) => getPortfolioColumnDisplayName(props), []);

  const QueryClient = useQueryClient();
  const onSave = async (data) => {
    await postPortfolioDashboardConfigs({ data: data, userId });
    QueryClient.invalidateQueries(["portfolioDashboardConfigs", userId, PORTFOLIO_NAME_IN_API_BODY]);
  };

  const onReset = async () => {
    await deletePortfolioDashboardConfigs({ userId });
    QueryClient.invalidateQueries(["portfolioDashboardConfigs", userId, PORTFOLIO_NAME_IN_API_BODY]);
  };

  return (
    <>
      <Helmet>
        <title>Portfolio | Leaftech</title>
      </Helmet>
      <LoadingOrEmptyWrapper height="400px" showEmpty={isError} showLoading={isLoading}>
        <DynamicDashboardMultiTab
          allAvailableFilters={allAvailableFilters}
          allFilters={() => []}
          allSettings={allSettings}
          categoricalColumns={categoricalColumns}
          dataTransformator={dataTransformator}
          defaultColors={defaultColors}
          defaultPortfolioConfigsAndColorsConfigs={defaultPortfolioConfigsAndColorsConfigs}
          dynamicChartsRowHeight={dynamicChartsRowHeight}
          filteredData={filteredData}
          filtersCategories={filtersCategories}
          generalDashboardData={generalDashboardData}
          getColorEntity={getColorEntity}
          getColumnDisplayName={getColumnDisplayNameCallback}
          getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
          getUnit={getUnit}
          IdColumnName={columnsAPINamesEnums.ID}
          miniMapObjectsNameKey={columnsAPINamesEnums.BUILDING_NAME}
          numericalColumns={numericalColumns}
          onReset={onReset}
          onSave={onSave}
          renderMiniMapObjectClickModal={renderMiniMapObjectClickModal}
          savedPortfolioAndColorsConfigs={savedPortfolioAndColorsConfigsM}
          setFilteredData={setFilteredData}
          setTriggerFlag={setTriggerFlag}
          useGetSpecificData={useGetSpecificData}
        />
        <Paper className="mt-8">
          <h4 className="t-heading-m ">
            {tableFilteredData?.length} {t("AssetOverview.filters.buildings")}
          </h4>
          <Table
            className="relative mt-6"
            columnsMinWidth="10rem"
            data={[...tableFilteredData]}
            headers={headers}
            idColumnName="id"
            onRowClick={(row) => {
              window.open(`${window.location.origin}/details/${row[columnsAPINamesEnums.ID]}/buildingProfile`);
            }}
          />
        </Paper>
      </LoadingOrEmptyWrapper>
    </>
  );
};
