import { createContext } from "react";
import { Outlet } from "react-router-dom";

import { SecondaryNavbar } from "../components/BuildingPassport/SecondaryNavbar";

export const ScreenShotContext = createContext();

const navLinks = [
  {
    dataKey: "",
    linkName: "Asset Overview",
    to: "/",
  },
  {
    dataKey: "",
    linkName: "Portfolio",
    to: "portfolio",
  },
];

export const LandingPageSharedLayout = () => {
  return (
    <section>
      <SecondaryNavbar className="mb-8 mt-4" navLinks={navLinks} />
      <Outlet />
    </section>
  );
};
