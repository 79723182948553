// TODO:
// 1-After changing the structure of Icons, change styled part of this script.

import { createContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import { useGetBulidingActiveTabs } from "../api/hooks/allHooks";
import { BuildingPassportHeader } from "../components/BuildingPassport/Header";
import { SecondaryNavbar } from "../components/BuildingPassport/SecondaryNavbar";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";
import { setCurrentPageName } from "../features/routeName/routeNameSlice";
import { getPageName, isPageDisabled } from "../utils/navigation";

export const ScreenShotContext = createContext();

export const BuildingPassportSharedLayout = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      dataKey: "building_profile",
      linkName: t("BuildingPassportSharedLayout.building profile"),
      to: "buildingProfile",
    },
    {
      dataKey: "energy_co2",
      linkName: t("BuildingPassportSharedLayout.Energy & CO₂"),
      to: "energyPerformance",
    },
    // {
    //   linkName: t("BuildingPassportSharedLayout.certificates"),
    //   to: "certificates",
    //   dataKey: "certificates",
    // },
    {
      dataKey: "technical_data",
      linkName: t("BuildingPassportSharedLayout.technical Data"),
      to: "technicalData",
    },
    {
      dataKey: "modernization",
      linkName: t("BuildingPassportSharedLayout.modernization"),
      to: "modernization",
    },
  ];

  const componentRef = useRef();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { data, isLoading: tabsIsLoading } = useGetBulidingActiveTabs(projectId);
  const buildingActiveTabs = data || {};

  useEffect(() => {
    dispatch(setCurrentPageName("building passport"));
  }, []);

  const { pathname } = useLocation();
  const pageName = getPageName(pathname);

  const disabled = isPageDisabled({ buildingActiveTabs, navLinks, pageName });

  if (disabled) return <Navigate to="/" />;

  return (
    <LoadingOrEmptyWrapper height="100vh" showLoading={tabsIsLoading}>
      <div>
        <BuildingPassportHeader componentRef={componentRef} pageName={pageName} projectId={projectId} />
        <section>
          <SecondaryNavbar activeTabs={buildingActiveTabs} className="mb-8 mt-4" navLinks={navLinks} />
          <ScreenShotContext.Provider value={componentRef}>
            <Outlet />
          </ScreenShotContext.Provider>
        </section>
      </div>
    </LoadingOrEmptyWrapper>
  );
};
