import { Paper } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IsAllowed } from "../../acl";
import { resources } from "../../acl/resources";
import {
  useDeleteChildTableRow,
  useDeleteParentTableRow,
  useModifyChildTableCell,
  useModifyParentTableCell,
} from "../../api/hooks/allHooks";
import { getTechTableConfig } from "../../configs/TechnicalData";
import { getSelectedColumns } from "../../features/techData/techDataSlice";
import { eventNames, trackGAEvent } from "../../functions/googleAnalytics";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";
import { Table } from "../Table";
import { TableContainerHeader } from "./TableContainerHeader";

export const TechTable = ({ activeTab, data, enabledTabs, parentsAreLoading, projectId }) => {
  const { user } = useSelector((store) => store.user);
  const { colNames: userColumns, columnsIsLoading } = useSelector((store) => store.techData);

  const canDownload = IsAllowed("TECHNICAL_DATA.DOWNLOAD_FILES");
  const editable = IsAllowed(resources.TECHNICAL_DATA.EDIT_TABLE_CELL);
  const removeable = IsAllowed(resources.TECHNICAL_DATA.REMOVE_ROW);

  const dispatch = useDispatch();
  const { mutate: parentMutate } = useModifyParentTableCell();
  const { mutate: childMutate } = useModifyChildTableCell();

  const { mutate: parentDeleteRow } = useDeleteParentTableRow();
  const { mutate: childDeleteRow } = useDeleteChildTableRow();

  const config = getTechTableConfig({ activeTab, projectId });
  const cols = config[activeTab].columns;

  const colsState = userColumns[config[activeTab].dataKey];
  const filteredCols = cols?.filter((col) => {
    return colsState?.includes(col.dataKey) && (canDownload || col.cellType !== "downloadZipFile");
  });

  const childCols = config.children.columns;
  const defaultChildCols = childCols?.filter((col) => {
    return col.isInDefault && (canDownload || col.cellType !== "downloadFile");
  });

  useEffect(() => {
    dispatch(getSelectedColumns({ projectId, userId: user?.user_id }));
  }, []);

  const parentHandleBlur = ({ fieldName, rowId, value }) => {
    parentMutate({
      activeTab,
      data: { [fieldName]: value },
      projectId,
      rowId,
    });
  };

  const parentHandleDelete = ({ rowId }) => {
    parentDeleteRow({ activeTab, projectId, rowId });
  };

  const childHandleBlur = ({ fieldName, parentRowId, rowId, value }) => {
    childMutate({
      activeTab,
      data: { [fieldName]: value },
      layerId: rowId,
      projectId,
      rowId: parentRowId,
    });
  };

  const childHandleDelete = ({ layerId, rowId }) => {
    childDeleteRow({ activeTab, layerId, projectId, rowId });
  };

  return (
    <Paper className="relative min-h-50 w-full">
      {enabledTabs?.length > 0 && (
        <>
          <TableContainerHeader activeTab={activeTab} projectId={projectId} selectedColumns={colsState} />
          {!parentsAreLoading && !columnsIsLoading && (
            <Table
              childEditable={() => editable && i18next.language === "en"}
              childHeaders={defaultChildCols}
              childIdColumnName="id"
              childOnBlur={childHandleBlur}
              childOnDelete={childHandleDelete}
              childTableDataKey="layers"
              className="relative mt-6"
              columnsMinWidth="10rem"
              data={[...data]}
              editable={() => editable && i18next.language === "en"}
              foldTableOnChangeOfVariable={activeTab}
              headers={filteredCols}
              idColumnName="id"
              onBlur={parentHandleBlur}
              onDelete={parentHandleDelete}
              onExpand={() => trackGAEvent(eventNames.TD_EXPAND_FOLD_ROW)}
              rowRemoveable={() => removeable}
             />
          )}
        </>
      )}
      <LoadingOrEmptyComponent
        showEmpty={!(enabledTabs?.length > 0)}
        showLoading={parentsAreLoading || columnsIsLoading}
      />
    </Paper>
  );
};
