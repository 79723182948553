// TODO:
// 1-Replace ImageWrapper with suitable tailwind classes.

import { Card, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "../../styles/theme";
import { getAletrnativeImage } from "../../utils/buildingImages";
import { formatNumberBasedOnUser, valueOrAlternative } from "../../utils/namesManipulation";
import { EnergyEfficiencyClass } from "../EnergyEfficiencyClass";
import { Icon } from "../Icon";

const CardImageLabel = ({ className, label }) => {
  return (
    <ImageLabelWrapper className={className}>
      <Icon color="var(--clr-secondary-blue-600)" iconName="Tree" />
      <span className="t-numbers-xxs text-blue-500">{label}</span>
    </ImageLabelWrapper>
  );
};

export const AssetCard = ({
  city,
  className,
  climatePositive,
  consumption,
  emission,
  energyClass,
  imgPath,
  lastModernized,
  name,
  onClick,
  type,
  year,
}) => {
  const { t } = useTranslation();
  return (
    <article className={className}>
      <Card onClick={onClick}>
        {/* image-container */}
        <div className="relative">
          {/* image-label */}
          {climatePositive && <CardImageLabel className="absolute right-2 top-2" label="Clima positive" />}
          <img
            className="h-48 w-full rounded-md object-contain object-center"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = getAletrnativeImage(type);
            }}
            src={imgPath}
          />
        </div>
        {/* header */}
        <h2 className="t-heading-l ml-2 mt-4 capitalize  ">{valueOrAlternative(name)}</h2>
        {/* content */}
        <Stack className="mx-2 mt-3 items-start justify-between pb-2">
          {/* Left column */}
          <ul className="w-2/5">
            {/* City */}
            <li className="">
              <Stack className="mb-1.5" spacing={theme.spacing(3)}>
                <Icon className="ml-[1px] mr-1" color="var(--clr-gray-600)" iconName="LocationIcon" />
                <p className="sentence-case short-text t-body-s  ">{valueOrAlternative(city)}</p>
              </Stack>
            </li>
            {/* Type */}
            <li className="">
              <Stack className="mb-1.5" spacing={theme.spacing(3)}>
                <Icon color="var(--clr-gray-600)" iconName="BuildingTypeIcon" />
                <p className="sentence-case short-text t-body-s  ">{t(valueOrAlternative(type))}</p>
              </Stack>
            </li>
            {/* Construction year */}
            <li className="">
              <Stack className="mb-1.5" spacing={theme.spacing(3)}>
                <Icon color="var(--clr-gray-600)" iconName="ConstructionIcon" />
                <p className="sentence-case short-text t-body-s  ">
                  {valueOrAlternative(year)} {lastModernized && `(${lastModernized})`}
                </p>
              </Stack>
            </li>
            {/* Energy Label */}
            <li className="">
              <Stack spacing={theme.spacing(3)}>
                <Icon color="var(--clr-gray-600)" iconName="Energy" svgClassName="w-4 h-4" />
                {energyClass ? (
                  <EnergyEfficiencyClass className="!h-[2rem] !w-[2rem]" energyClass={energyClass} size="sm" />
                ) : (
                  <p className="t-label-l text-black">{t("AssetOverview.assetCard.pending")}</p>
                )}
              </Stack>
            </li>
          </ul>
          {/* Right column */}
          <ul className="text-right">
            {/* Energy rating */}
            <li>
              <p className=" t-label-m-caps  uppercase text-gray-400 ">{t("AssetOverview.assetCard.consumption")}</p>
              {consumption && (
                <p className="t-numbers-m  text-blue-500">
                  {formatNumberBasedOnUser(consumption, 1)}
                  {" kWh/m²/a"}
                </p>
              )}
              {!consumption && <p className="short-text t-body-s  ">{valueOrAlternative("")} </p>}
            </li>
            {/* Emission */}
            <li className="mt-3">
              <p className=" t-label-m-caps  text-gray-400 ">{t("AssetOverview.assetCard.emission")}</p>
              {emission && (
                <p className="t-numbers-m  text-blue-500">
                  {formatNumberBasedOnUser(emission, 1)}
                  {" kg CO₂/m²"}
                </p>
              )}
              {!emission && <p className="short-text t-body-s  ">{valueOrAlternative("")} </p>}
            </li>
          </ul>
        </Stack>
      </Card>
    </article>
  );
};

const ImageLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 0.6rem 0.5rem 0.8rem 0.5rem;
  background: var(--clr-secondary-blue-100);
  border-radius: 3px;
  text-transform: capitalize;
`;
