// TODO:
// 1-make breadcrumbs dynamic

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";

import { useGetBuilding } from "../api/hooks/allHooks";
import { Footer } from "../components/Footer";
import Header from "../components/Header";
import { Navbar } from "../components/Navbar";
export const SharedLayout = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();

  const { currentPageName } = useSelector((store) => store.routeName);
  const { data: building } = useGetBuilding(projectId);
  const showBuildingName = currentPageName !== "asset overview";
  const breadcrumbs = [
    { name: "level1", text: t("SharedLayout.header.assets"), url: "/" },
    {
      name: "level2",
      text: showBuildingName ? building?.name : null,
      url: showBuildingName ? `details/${projectId}/buildingProfile` : null,
    },
  ];

  return (
    <main className="pb-2">
      <Navbar className="fixed h-full w-16  " />
      <div className=" flex min-h-99vh flex-col justify-between  ">
        <Header breadcrumbs={breadcrumbs} className="ml-16 px-8 " />
        <section className="ml-16 px-8">
          <main className="m-auto max-w-screen-2xl pb-12">
            <Outlet />
          </main>
        </section>
        <Footer className="ml-24 mr-8 mt-auto" />
      </div>
    </main>
  );
};
