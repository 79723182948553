const loginTexts = {
  form: {
    consent: "I agree to ",
    fieldsMustBeFilled: "All fields must be filled",
    forgotText: "Forgot password?",
    help: "Reach out to us here.",
    noAccount: "Don't have an account?",
    password: {
      placeholder: "password",
    },
    signInText: "Sign in",
    toTermsAndConditions: "terms & conditions",
    username: {
      placeholder: "username",
    },
  },
  login: "login",
  logoText: "powered by",
  resetPassword: {
    cancel: "back to login",
    errorButtonText: "okay",
    errorText: "We could not reset your password, please write us an email.",
    errorTitle: "sorry!",
    label: "email",
    placeholder: "insert your email",
    submit: "reset password",
    successfulButtonText: "okay",
    successText: "check your email to reset your password",
    successTitle: "we've sent you an email",
    text: "don't worry, we'll email you the instructions to reset your password shortly.",
    title: "reset your password",
  },
  subtitle: "For the decarbonization of your building stock.",
  title: "Digital Twin platform",
  welcome: "welcome",
};
export default loginTexts;
