import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";

import { columnsAPINamesEnums } from "../../enums/AssetOverview/portfolio";
import { AssetDetailedCard } from "../BuildingProfile/AssetDetailedCard";
import { CustomModal } from "../CustomModal";
import { Icon } from "../Icon";

export const AssetCardModal = ({ assetCardPosition, building, onCardClick, onClose, triggerFlag }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (building) setModalOpen(true);
  }, [triggerFlag]);

  const assetCardConfig = [
    [
      { dataKey: columnsAPINamesEnums.BUILDING_NAME, label: "ASSET NAME" },
      { dataKey: columnsAPINamesEnums.USE_TYPE, label: "TYPE" },
      { dataKey: columnsAPINamesEnums.FULL_ADDRESS, label: "ADDRESS" },
      { dataKey: columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT, label: "GROSS ASSET SIZE" },
    ],
    [
      { dataKey: columnsAPINamesEnums.YEAR_OF_CONSTRUCTION, label: "YEAR OF CONSTRUCTION" },
      { dataKey: columnsAPINamesEnums.STRANDING_YEAR, label: "STRANDING YEAR" },
      { dataKey: columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS, label: "EPC CLASS" },
      { dataKey: columnsAPINamesEnums.CO2_EMISSION, label: "CO2 EMISSIONS" },
      { dataKey: columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION, label: "ENERGY CONSUMPTION" },
    ],
  ];
  return (
    <Draggable>
      <CustomModal
        closeIcon={false}
        disableBackdropClick
        disableEnforceFocus
        disableScrollLock
        height={0}
        hideBackdrop
        modalOpen={modalOpen}
        onClose={undefined}
        padding={0}
        setModalOpen={setModalOpen}
        slotProps={{
          backdrop: {
            sx: {
              display: "none",
            },
          },
          root: {
            sx: {
              bottom: "unset",
              left: "unset",
              right: 0,
              top: "60px",
              zIndex: 2000,
            },
          },
        }}
        style={{
          bottom: "unset",
          height: "fit-content",
          left: assetCardPosition?.x || 50,
          marginRight: "1.6rem",
          position: "fixed",
          top: 50,
          top: assetCardPosition?.y || 50,
          transform: "none",
        }}
        width={500}
      >
        <Stack className="h-6 cursor-grab bg-blue-50 pr-2" flexDirection="row-reverse">
          <Icon
            color="var(--clr-secondary-blue-500)"
            iconName="Close"
            onClick={() => {
              setModalOpen(false);
              onClose();
            }}
            size="sm"
          />
        </Stack>
        <AssetDetailedCard
          building={building}
          // name={t("BuildingProfile.card.title")}
          buildingType={building?.[columnsAPINamesEnums.USE_TYPE]}
          className="w-full cursor-pointer p-3 text-left hover:scale-[1.003] hover:shadow"
          config={assetCardConfig}
          imageClassName="h-50 w-full  object-cover"
          imgPath={building?.[columnsAPINamesEnums.IMAGE_URL_VALUE]}
          onClick={onCardClick}
          upload={false}
        />
      </CustomModal>
    </Draggable>
  );
};
