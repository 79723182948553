import { resources } from "./resources";

export const roles = {
  ADMIN: {
    permissions: [
      resources.NAVBAR.SETTINGS,
      resources.BUILDING_PROFILE.DOWNLOAD_FILES,
      resources.BUILDING_PROFILE.UPLOAD_IMAGE,
      resources.BUILDING_PROFILE.THREE_D_VIEWER,
      resources.TECHNICAL_DATA.DOWNLOAD_FILES,
      resources.TECHNICAL_DATA.UPLOAD_FILES,
      resources.TECHNICAL_DATA.SELECT_COLUMNS,
      resources.TECHNICAL_DATA.REMOVE_ROW,
      resources.TECHNICAL_DATA.EDIT_TABLE_CELL,
      resources.ENERGY_PERFORMANCE.DOWNLOAD_FILES,
      resources.CERTIFICATE.DOWNLOAD_FILES,
      resources.MODERNIZATION.DOWNLOAD_SCENARIOS,
      resources.MODERNIZATION.CUSTOM_SCENARIO_UPLOAD,
    ],
    roleId: 4,
  },
  ASSET_MANAGER: {
    permissions: [
      resources.BUILDING_PROFILE.DOWNLOAD_FILES,
      resources.BUILDING_PROFILE.UPLOAD_IMAGE,
      resources.BUILDING_PROFILE.THREE_D_VIEWER,
      resources.TECHNICAL_DATA.DOWNLOAD_FILES,
      resources.TECHNICAL_DATA.UPLOAD_FILES,
      resources.TECHNICAL_DATA.SELECT_COLUMNS,
      resources.ENERGY_PERFORMANCE.DOWNLOAD_FILES,
      resources.CERTIFICATE.DOWNLOAD_FILES,
      resources.MODERNIZATION.DOWNLOAD_SCENARIOS,
      resources.MODERNIZATION.CUSTOM_SCENARIO_UPLOAD,
      resources.GOOGLE_ANALYTICS,
    ],
    roleId: 2,
  },
  GUEST: {
    permissions: [],
    roleId: 0,
  },
  LOGGED_IN: {
    permissions: [
      resources.BUILDING_PROFILE.DOWNLOAD_FILES,
      resources.BUILDING_PROFILE.THREE_D_VIEWER,
      resources.TECHNICAL_DATA.DOWNLOAD_FILES,
      resources.TECHNICAL_DATA.SELECT_COLUMNS,
      resources.ENERGY_PERFORMANCE.DOWNLOAD_FILES,
      resources.CERTIFICATE.DOWNLOAD_FILES,
      resources.MODERNIZATION.DOWNLOAD_SCENARIOS,
      resources.GOOGLE_ANALYTICS,
    ],
    roleId: 1,
  },
  SUPERUSER_ADMIN: {
    permissions: [
      resources.NAVBAR.SETTINGS,
      resources.BUILDING_PROFILE.DOWNLOAD_FILES,
      resources.BUILDING_PROFILE.UPLOAD_IMAGE,
      resources.BUILDING_PROFILE.THREE_D_VIEWER,
      resources.TECHNICAL_DATA.DOWNLOAD_FILES,
      resources.TECHNICAL_DATA.UPLOAD_FILES,
      resources.TECHNICAL_DATA.SELECT_COLUMNS,
      resources.TECHNICAL_DATA.REMOVE_ROW,
      resources.TECHNICAL_DATA.EDIT_TABLE_CELL,
      resources.ENERGY_PERFORMANCE.DOWNLOAD_FILES,
      resources.CERTIFICATE.DOWNLOAD_FILES,
      resources.MODERNIZATION.DOWNLOAD_SCENARIOS,
      resources.MODERNIZATION.CUSTOM_SCENARIO_UPLOAD,
    ],
    roleId: 32,
  },
};
