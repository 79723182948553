import React from "react";

import { calculateLeft } from "../../utils/position";

export const BulletChartAxis = ({ axis, className }) => {
  const min = axis[0];
  const max = axis[axis.length - 1];

  return (
    <div className={className}>
      {axis?.map((num, index) => {
        const noOfNumbers = axis.length;
        let left = calculateLeft(num, min, max);
        const transformPercentage = index === 0 ? 0 : index === noOfNumbers - 1 ? 100 : 50;
        return (
          <span
            className="t-numbers-m absolute  "
            key={index}
            style={{
              left: `${left}%`,
              transform: `translateX(-${transformPercentage}%)`,
            }}
          >
            {num}
          </span>
        );
      })}
    </div>
  );
};
