import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";

export const RadioButton = ({ displayOptions, label, options, row, setValue, value }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl className={row && "flex-row"}>
      {label && <FormLabel className={`t-heading-s-700 sentence-case ${row && "mr-4"}`}>{label}</FormLabel>}
      <RadioGroup onChange={handleChange} row={row} value={value}>
        {options?.map((option, index) => {
          return <FormControlLabel control={<Radio />} key={index} label={displayOptions[index]} value={option} />;
        })}
      </RadioGroup>
    </FormControl>
  );
};
