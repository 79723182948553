export default {
  customization: {
    actionButton: "Submit",
    energy: "Energy",
    feedback: "Is there anything you'd like to add or share?",
    formIsBlank: "The form is blank!",
    PVSystem: "PV system",
    selectSentence: "Select the different measures you'd like to execute",
    shading: "Shading",
    smartShading: "Smart shading",
    title: "Configurate your own modernization scenario",
    wall: "Wall",
    wallInsulation: "Wall insulation",
    windowExchange: "Window exchange",
    windows: "Windows",
  },
  goals: {
    decarbonizationRoadmap: "Decarbonization Roadmap",
    noGoals: "There is no information available!.",
    roadmapKpis: "Modernization Roadmap KPIs",
    title: "Modernization goals",
  },
  scenarios: {
    actionButton: "Download report",
    costBenefit: {
      period: "Payback period",
      saving: "Enegy cost savings",
      title: "Cost-benefit analysis",
      years: "years",
    },
    financingButton: "Green financing",
    financingModalText: "Subsidized green financing can offset a portion of the capital cost.",
    impact: {
      carbonTitle: "Average CO₂ emission",
      energyTitle: "Average energy consumption",
      postModernization: "Post modernization",
      saving: "Savings",
      sectionTitle: "Impact",
      statusQuo: "Status quo",
    },
    noScenarios: "There is no information available!.",
    scenario: "scenario",
    sectionButton: "Download all scenarios",
    title: "Modernization scenarios",
    upgrades: "Upgrades",
  },
  strandingChart: {
    costOfMeasures: "Costs of measures",
  },
  wizard: {
    addRetrofit: "Add retrofit ",
    addRetrofitMeasure: "Add retrofit measure",
    crrem: "CRREM",
    deleteRetrofit: "Delete retrofit ",
    deleteRetrofitMeasure: "Delete retrofit measure",
    financialDashboard: "Financial Dashboard",
    newMeasureForm: {
      title: "Create new measure (values in /m²)",
    },
    retrofitMeasures: "Retrofit Measures",
  },
};
