import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";
import { AssetCard } from "./AssetCard";

const EmptyState = () => {
  const { t } = useTranslation();
  return <h1 className="t-heading-l t-gary-900 mt-8 h-100">{t("AssetOverview.noBuilding")}</h1>;
};
export const CardsContainer = ({ buildings, className, isLoading }) => {
  const noData = buildings?.length === 0 || !buildings;
  return (
    <>
      <LoadingOrEmptyComponent emptyComponent={EmptyState} height="40rem" showEmpty={noData} showLoading={isLoading} />
      <Grid className={className} container spacing={4}>
        {buildings?.map((building, index) => {
          return (
            <Grid item key={index} xl={3} xs={4}>
              <AssetCard
                city={building?.address}
                climatePositive={building?.climate_positive}
                consumption={building?.energy_consumption}
                emission={building?.carbon_dioxide_emission_value}
                energyClass={building?.energy_label}
                imgPath={building?.image_url_value}
                key={building?.id}
                lastModernized={building?.last_modernized}
                name={building?.descriptive_name}
                onClick={() => {
                  window.open(`${window.location.origin}/details/${building?.id}/buildingProfile`);
                }}
                type={building?.type}
                year={building?.construction_year}
               />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
