import { Stack } from "@mui/material";
import React from "react";

export const LabelValueInline = ({ className, label, labelProps, value, valueProps }) => {
  const { className: valueClassName, ...otherValueProps } = valueProps;
  const { className: labelClassName, ...otherLabelProps } = valueProps;
  return (
    <Stack className={`t-body-s mb-1 items-start ${className}`}>
      <div className={`min-w-[12rem] max-w-[33rem]  text-gray-600 ${labelClassName}`} {...otherLabelProps}>
        {label}:
      </div>
      <div className={`ml-1   ${valueClassName}`} {...otherValueProps}>
        {` ${value}`}
      </div>
    </Stack>
  );
};
