export default {
  assetCard: {
    consumption: "consumption",
    emission: "EMISSIONS",
    epc: "epc",
    pending: "pending",
  },
  filters: {
    buildings: "buildings",
    certification: "certification",
    "construction year": "construction year",
    "energy class": "energy class",
    location: "location",
    resetFilters: "Reset filters",
    search: "search",
    type: "type",
  },
  noBuilding: "There is no building to show!",
  title: "your assets",
};
