import React, { useCallback, useMemo } from "react";
import { createPortal } from "react-dom";

import { CustomModal } from "../CustomModal";
import { GeneralErrorBoundary } from "../ErrorBoundary";
import DynamicChartMemoized from "./DynamicChartMemoized";

export const DynamicChartFullScreenHandlerToBeAdded = ({ children, className, isFullScreen }) => {
  return createPortal(
    <div
      className={className}
      style={{
        backgroundColor: "red",
        height: isFullScreen ? "100vh" : "40vh",
        left: 0,
        padding: 0,
        position: "fixed",
        top: 0,
        width: isFullScreen ? "100vw" : "40vw",
        zIndex: 100,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export const DynamicChartFullScreenHandler = ({ children, className, isFullScreen }) => {
  if (isFullScreen)
    return (
      <div className={className} style={{ height: "100vh", width: "100vw" }}>
        <CustomModal closeIcon={false} height="100vh" modalOpen={true} padding="0" width="100vw">
          {children}
        </CustomModal>
      </div>
    );
  else return children;
};

export const DynamicChart = ({
  allFilters,
  allSettings,
  config,
  fullScreenChart,
  id,
  removeChart,
  specificDataGetter,
  updateChart,
  ...props
}) => {
  const removeChartMemoized = useCallback(removeChart, []);
  const fullScreenChartMemoized = useCallback(fullScreenChart, []);
  const updateChartMemoized = useCallback((key, value) => updateChart(key, value), []);

  const specificDataGetterMemoized = useCallback(specificDataGetter, []);

  // Pass this parameter to the DynamicChartMemoized only to make a rerender when changed.
  const userLanguage = localStorage.getItem("language");
  const configMemoized = useMemo(
    () => config,
    [
      JSON.stringify(
        Object.entries(config).filter(([key, _value]) => !["x", "y"].includes(key))
        // .map(([_key, value]) => value)
      ),
    ]
  );

  const itemFilters = useMemo(
    () =>
      allFilters(updateChartMemoized, config)?.map((filter) => {
        return {
          ...filter,
          config: filter.adjustConfig(config),
          setConfig: filter.setConfig,
        };
      }),
    [configMemoized]
  );

  const itemSettings = useMemo(
    () =>
      allSettings(updateChartMemoized, config)?.map((setting) => {
        return {
          ...setting,
          config: setting.adjustConfig(config),
          setConfig: !setting.isInDiagramsPart ? setting.setConfig(updateChartMemoized) : undefined,
          setConfigFn: setting.isInDiagramsPart ? setting.adjustSetConfigFn : undefined,
        };
      }),
    [configMemoized]
  );

  return (
    <GeneralErrorBoundary resetKeys={[config, props]}>
      <DynamicChartMemoized
        config={configMemoized}
        filters={itemFilters}
        id={id}
        onClose={removeChartMemoized}
        setConfig={updateChartMemoized}
        setFullScreenChartId={fullScreenChartMemoized}
        settings={itemSettings}
        specificDataGetter={specificDataGetterMemoized}
        userLanguage={userLanguage}
        {...props}
      />
    </GeneralErrorBoundary>
  );
};
