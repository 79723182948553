import AssetOverview from "./AssetOverview";
import BuildingPassportSharedLayout from "./BuildingPassportSharedLayout";
import BuildingProfile from "./BuildingProfile";
import Certificates from "./Certificates";
import EnergyPerformance from "./EnergyPerformance";
import general from "./general";
import Login from "./Login";
import Modernization from "./Modernization";
import Portfolio from "./Portfolio";
import SharedLayout from "./SharedLayout";
import TechnicalData from "./TechnicalData";

export const en = {
  AssetOverview,
  BuildingPassportSharedLayout,
  BuildingProfile,
  Certificates,
  EnergyPerformance,
  general,
  Login,
  Modernization,
  Portfolio,
  SharedLayout,
  TechnicalData,
};
