export const FilterTypes = Object.freeze({
  CHECKBOX: "checkbox",
  COLOR_INPUT: "colorInput",
  DATERANGE_PICKER: "daterangePicker",
  DROPDOWN_MULTI: "dropdownMulti",
  DROPDOWN_SINGLE: "dropdownSingle",
  INPUT: "input",
  NUMERIC_RANGE_PICKER: "numericRangePicker",
  RADIO: "radio",
});
