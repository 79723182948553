import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import styled from "styled-components";

import SearchIcon from "../Asset/Icons/search.svg";

export function SearchMui(setSearchTerm, className) {
  return (
    <Paper
      className={className}
      component="form"
      sx={{ alignItems: "center", display: "flex", p: "2px 4px", width: 400 }}
    >
      <InputBase
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search"
        sx={{ flex: 1, ml: 1 }}
      />
      <IconButton aria-label="search" sx={{ "&:hover": { bgcolor: "white" }, p: "10px" }} type="button">
        <img alt="search icon" src={SearchIcon} />
      </IconButton>
    </Paper>
  );
}

export const Search = ({ onChange, placeholder, value, width }) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const borderColor = isEmpty ? "var(--clr-gray-400)" : "var(--clr-gray-900)";
  const textColor = isEmpty ? "var(--clr-gray-400)" : "var(--clr-gray-900)";
  const labelColor = "var(--clr-gray-600)";
  return (
    <span
      style={{
        display: "block",
        width: width,
      }}
    >
      <CssTextField2
        bordercolor={borderColor}
        fullWidth
        id="filled-basic-password"
        InputLabelProps={{
          style: {
            color: labelColor,
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontWeight: 600,
            letterSpacing: "0.025rem",
            lineHeight: "1.4rem",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img alt="search icon" src={SearchIcon} />
            </InputAdornment>
          ),
        }}
        inputRef={value}
        label={placeholder}
        labelcolor={labelColor}
        onChange={() => {
          if (value.current === "") setIsEmpty(true);
          else {
            setIsEmpty(value.current.value === "");
          }
          onChange();
        }}
        placeholder={placeholder}
        textcolor={textColor}
        type="text"
        variant="filled"
      />
    </span>
  );
};

const CssTextField2 = styled(TextField)(({ bordercolor, labelcolor, textcolor }) => ({
  "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
    color: labelcolor,
    fontFamily: "Nunito Sans",
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: "0.025rem",
    lineHeight: "1.4rem",
  },
  "& .MuiInputBase-input.MuiFilledInput-input": {
    "&:before, &:after": {
      border: "none",
    },
    "&:focus": {
      // border: "3px solid var(--clr-secondary-blue-300)",
    },
  },
  "& .MuiInputBase-root.MuiFilledInput-root": {
    "&.Mui-focused": {
      border: "1px solid var(--clr-leaftech-blue-500)",
    },
    "&::placeholder": {
      color: "green",
    },
    "&:after": {
      border: "none",
    },
    "&:before, &:after": {
      border: "none",
    },
    "&:hover": {
      ":before": {
        border: "none",
      },
      border: "1px solid var(--clr-secondary-blue-200)",
      boxShadow:
        "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
    },
    backgroundColor: "var(--clr-white)",
    border: `1px solid ${bordercolor}`,
    borderRadius: "7px",
    color: textcolor,
    fontFamily: "Nunito Sans",
    fontSize: "1.4rem",
    fontWeight: "400",
    height: "4rem",
    letterSpacing: "0rem",
    lineHeight: "1.9rem",
  },
  "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,    input:-webkit-autofill:active":
    {
      transition: "background-color 5000s ease-in-out 0s",
    },
}));
