import { Paper, Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { formatNumberBasedOnUser } from "../utils/namesManipulation";
import { cn } from "../utils/styling";

type DataCardProps = {
  className?: string;
  kpi?: {
    name?: string;
    unit?: string;
    value?: number | ReactNode;
  };
};

export const DataCard = ({ className, kpi }: DataCardProps) => {
  return (
    <Paper className={cn("h-full p-4", className)}>
      <Stack className="h-full items-start justify-between" direction="column" spacing={6}>
        <h2 className="t-body-xl capitalize">{kpi?.name}</h2>
        {typeof kpi?.value === "object" ? (
          <>{kpi?.value}</>
        ) : (
          <p className="t-numbers-xl text-blue-500 ">
            {formatNumberBasedOnUser(kpi?.value) + " "}
            <span className="t-numbers-m-reg ">{kpi?.unit}</span>
          </p>
        )}
      </Stack>
    </Paper>
  );
};

export const EmptyDataCard = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  return (
    <Paper className={`${className} h-full p-4`}>
      <span className="t-body-xl">{t("BuildingProfile.kpis.kpisNotListed")}</span>
    </Paper>
  );
};
