import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getSelectedColumnsThunk } from "./techDataThunk";

export const getSelectedColumns = createAsyncThunk("techData/getSelectedColumns", getSelectedColumnsThunk);

const initialState = {
  activeTab: "",
  childFiles: [],
  childIsLoading: true,
  colNames: {
    energyMix: ["utility_mix", "cost_per_kwh", "cost_increase_per_year", "co2_emissions_per_kwh"],
    facade_features: ["description", "area", "last_checked", "files"],
    rooftop_features: ["description", "area", "last_checked", "files"],
    windows_features: ["window_type", "number_of_windows", "area", "u_value", "g_value", "last_checked"],
  },
  columnsIsLoading: false,
  parentFiles: [],
  parentIsLoading: true,
};

const techDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getSelectedColumns.pending, (state) => {
        state.columnsIsLoading = true;
      })
      .addCase(getSelectedColumns.fulfilled, (state, { payload }) => {
        if (payload?.length > 0) state.colNames = payload[0];
        state.columnsIsLoading = false;
      })
      .addCase(getSelectedColumns.rejected, (state, { payload }) => {
        state.columnsIsLoading = false;
      });
  },
  initialState,
  name: "techData",
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setSelectedColumns: (state, { payload }) => {
      state.colNames = { ...state.colNames, ...payload };
    },
  },
});

export const { setActiveTab, setSelectedColumns } = techDataSlice.actions;
export default techDataSlice.reducer;
