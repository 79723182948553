import { Paper } from "@mui/material";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetBuildingEPCInfo } from "../../api/hooks/allHooks";
import { WizardContext } from "../../context/Wizard";
import { energyEfficiencyClassConf } from "../../functions/AssetOverview";
import { distinctFilter } from "../../utils/dataManipulation";
import { sortData } from "../../utils/dynamicDashboard";
import { formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { getCSSVar } from "../../utils/styling";
import { DynamicEchartsChart } from "../chart/DynamicChartMemoized";

export const EnergyEfficiencyChartWithEchart = ({ chartHeight = 350, className, title }) => {
  const { projectId } = useParams();
  let { data: epcInfoAll } = useGetBuildingEPCInfo(projectId);
  epcInfoAll = epcInfoAll?.length ? epcInfoAll[0] : {};
  const classification = epcInfoAll?.classification;
  let maxEnergyValue = 600;

  let thresholds = [];
  let labels = [];
  let minEnergyValue;
  const labelsMapping = {
    a: "A",
    a_plus: "A+",
    b: "B",
    c: "C",
    d: "D",
    e: "E",
    f: "F",
    g: "G",
    h: "H",
  };

  if (classification) {
    const activeClassesNonZero = Object.keys(labelsMapping).filter(
      (className) => classification[`class_${className}_min`]
    );
    const lastActiveClass = activeClassesNonZero[activeClassesNonZero?.length - 1];
    maxEnergyValue = classification[`class_${lastActiveClass}_min`] * 1.2;
    for (const className in labelsMapping) {
      const isLastActiveClass = className === lastActiveClass;
      const classMin = classification[`class_${className}_min`];
      const classMax = classification[`class_${className}_max`];

      if (!classMin && !classMax) continue;
      if (minEnergyValue === undefined) {
        minEnergyValue = classMin;
        thresholds.push(classMin);
      }
      if (classMax && !isLastActiveClass) {
        labels.push(labelsMapping[className]);
        thresholds.push(classMax);
      }
      if (classMax && isLastActiveClass) {
        labels.push(labelsMapping[className]);
      }
      if (classMin && !classMax) {
        labels.push(labelsMapping[className]);
      }
      if (isLastActiveClass) break;
    }
    thresholds.push(maxEnergyValue);
  }

  const { data, yearRange } = useContext(WizardContext);

  const maxConsumption = Math.max(...data.map((yearData) => yearData.primaryModernized ?? 0));

  const energyEfficiencyClassConfAdjusted = energyEfficiencyClassConf
    .filter((energyClass) => labels?.includes(energyClass.rating))
    .map((energyClass) => {
      const classIndex = labels?.findIndex((label) => energyClass.rating === label);
      return {
        ...energyClass,
        max: thresholds?.[classIndex + 1],
      };
    });

  const dataWithEnergyLabels = data?.map((yearData) => {
    const energyValues = {};

    energyEfficiencyClassConfAdjusted.forEach((energyClass, i) => {
      const previousClassValue = energyEfficiencyClassConfAdjusted[i - 1]?.max;
      const currentClassValue =
        energyClass.max !== Infinity
          ? energyClass.max
          : Math.max(energyEfficiencyClassConfAdjusted[i - 1].max * 1.2, maxConsumption);
      const energyClassDiff = i === 0 ? energyClass.max : currentClassValue - previousClassValue;

      energyValues[energyClass.rating] = energyClassDiff;
    });
    return { ...yearData, ...energyValues };
  });

  const dataForChart = dataWithEnergyLabels?.filter(
    (yearData) => yearData.year <= yearRange[1] && yearData.year >= yearRange[0]
  );

  const sortedYears = sortData({ data: dataForChart, dataKey: "year", decsending: false })
    .map((row) => row.year)
    .filter(distinctFilter);

  const option = {
    animation: true,
    dataZoom: [
      {
        right: 0,
        type: "slider",
        width: 16,
        yAxisIndex: 0,
      },
    ],
    grid: {
      bottom: 70,
      containLabel: true,
      left: 20,
      right: 10,
      top: 55,
    },
    legend: {
      bottom: 0,
      left: 10,
      orient: "horizontal",
      right: 40,
      textStyle: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3rem",
        fontWeight: 600,
      },
    },

    series: [
      ...energyEfficiencyClassConfAdjusted.map((energyClass) => ({
        areaStyle: { color: energyClass.bgColor, opacity: 0.5 },
        color: energyClass.bgColor,
        data: sortedYears.map((_year, index) => [index, dataForChart[0][energyClass.rating]]),
        emphasis: {
          disabled: true,
        },
        label: {
          color: "#000",
          fontSize: 10,
          fontWeight: "bold",
          formatter: energyClass.rating,
          position: [-10, 0],
          show: true,
        },
        lineStyle: {
          color: getCSSVar("--clr-gray-500"),
        },
        name: energyClass.rating,
        silent: true,
        stack: "total",
        stackStrategy: "all",
        symbol: "none",
        type: "line",
      })),
      {
        color: "#00a",
        data: dataForChart.map((d) => {
          const index = sortedYears?.findIndex((year) => year === d.year);
          return [index, d.primaryRetrofit ?? null];
        }),
        lineStyle: { color: "#00a", width: 2 },
        name: "Retrofit",
        smooth: false,
        symbol: "none",
        type: "line",
      },
      {
        color: getCSSVar("--clr-secondary-blue-500"),
        data: dataForChart.map((d) => {
          const index = sortedYears?.findIndex((year) => year === d.year);
          return [index, d.primaryModernized ?? null];
        }),
        lineStyle: { color: getCSSVar("--clr-secondary-blue-500"), width: 2 },
        name: "Primary Energy",
        smooth: false,
        symbol: "none",
        type: "line",
      },
    ],
    tooltip: {
      formatter: (params) => {
        const year = Number(params?.[0]?.axisValue);
        const filtered = params
          .filter((p) => {
            return p.data?.[1] !== null && p.data?.[1] !== undefined && p.seriesName !== "Retrofit";
          })
          .map((p) => {
            const index = labels?.findIndex((label) => label === p.seriesName);
            if (index === -1) return;
            const value = thresholds[index + 1];
            if (value) return `${p.marker} ${p.seriesName}: ${formatNumberBasedOnUser(value)}`;
          })
          .join("<br/>");
        return `<h1>${year}</h1>
            ${filtered} `;
      },
      trigger: "axis",
    },
    xAxis: [
      {
        axisLabel: {
          fontFamily: "Exo 2",
          fontSize: 12,
          fontWeight: 400,
          rotate: 270,
        },
        data: sortedYears,
        type: "category",
      },
    ],
    yAxis: [
      {
        axisLabel: {
          fontFamily: "Exo 2",
          fontSize: 12,
          fontWeight: 400,
        },
        max: Math.ceil(Math.max(maxConsumption, thresholds[thresholds.length - 1]) / 10) * 10,
        min: 0,
        name: "kWh/m² NGF",
        nameLocation: "end",
        scale: false,
        type: "value",
      },
    ],
  };

  return (
    <Paper className={`relative  ${className}`}>
      <section className="flex items-start justify-between gap-6">
        <div className="w-full">
          {title && <h3 className="t-heading-m text-center">{title}</h3>}
          <div className="w-full" style={{ height: chartHeight }}>
            <DynamicEchartsChart option={option} />
          </div>
        </div>
      </section>
    </Paper>
  );
};
