import axios from "axios";
import { toast } from "react-toastify";

import { store } from "../store";
import { getUserFromLocalStorage, removeUserFromLocalStorage } from "./localStorage";

export const customRequestErrorHandler = ({ callbacks, error, thunkAPI }) => {
  if (error.response.status === 401) {
  }
  return thunkAPI.rejectWithValue(error.response.data.error);
};
export const clearStoreAndLocalStorage = () => {
  removeUserFromLocalStorage();
  store.dispatch({ type: "unauthorizedLogout" });
};

const apiRequest = async (props, requestType, useToken = true, headers = {}) => {
  try {
    let user = getUserFromLocalStorage();
    if (user && useToken) headers["Authorization"] = `Bearer ${user.access_token}`;
    let config = { headers };
    const resp = await axios[requestType](...props, config);
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      let currentUser = getUserFromLocalStorage();
      if (currentUser) toast.info("You have been logged out due to token expiration for your security.");
      clearStoreAndLocalStorage();
    }
    throw error;
  }
};
export const customApiCall = {
  delete: async function ({ headers = {}, url, useToken = true }) {
    const props = [url];
    const resp = await apiRequest(props, "delete", useToken);
    return resp.data;
  },
  download: async function ({ errorMessage, fileName, headers = {}, url, useToken = true }) {
    const user = getUserFromLocalStorage();
    if (user && useToken) {
      headers["Authorization"] = `Bearer ${user.access_token}`;
    }
    let config = { headers, responseType: "blob" };
    axios
      .get(url, config)
      .then((response) => {
        const result = new Blob([response.data]);
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        toast.error(errorMessage);
      });
  },
  get: async function ({ headers = {}, url, useToken = true }) {
    const props = [url];
    const resp = await apiRequest(props, "get", useToken);
    return resp.data;
  },
  head: async function ({ headers = {}, url, useToken = true }) {
    const props = [url];
    const resp = await apiRequest(props, "head", useToken);
    return resp;
  },
  patch: async function ({ data, headers = {}, url, useToken = true }) {
    const props = [url, data];
    const resp = await apiRequest(props, "patch", useToken);
    return resp.data;
  },
  post: async function ({ data, headers = {}, url, useToken = true }) {
    const props = [url, data];
    const resp = await apiRequest(props, "post", useToken, headers);
    return resp.data;
  },
};
