import "./English.css";

export const English = () => {
  return (
    <body className="ce16 doc-content">
      <p className="ce6">
        <span className="ce14">Privacy Policy</span>
      </p>
      <p className="ce1">
        <span className="ce7 title-text">
          Below you will find information about which personal data we use for which purposes in connection with your
          visit and use of our platform. If you have any further questions regarding the handling of your personal data,
          please feel free to contact the person responsible (contact details are provided in the section &quot;
          Information about the controller&quot;).
        </span>
      </p>
      <p className="ce1">
        <span className="ce7">The content of this privacy policy in overview:</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0 start" start="1">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">An overview of data protection</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">Concept of personal data</span>
      </p>
      <p className="ce6">
        <span className="ce3">Data collection on this website</span>
      </p>
      <p className="ce6">
        <span className="ce3">Analysis tools and tools provided by third parties</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0" start="2">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">Hosting</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">External hosting</span>
      </p>
      <p className="ce6">
        <span className="ce3">Order processing</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0" start="3">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">General information and mandatory information</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">
          Information about the responsible party (referred to as the &ldquo;controller&rdquo; in the GDPR)
        </span>
      </p>
      <p className="ce6">
        <span className="ce3">General information on the legal basis for the data processing on this website</span>
      </p>
      <p className="ce6">
        <span className="ce3">Storage duration</span>
      </p>
      <p className="ce6">
        <span className="ce3">Information on data transfer to the USA and other non-EU countries</span>
      </p>
      <p className="ce6">
        <span className="ce3">Revocation of your consent to the processing of data </span>
      </p>
      <p className="ce6">
        <span className="ce3">
          Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21
          DSGVO)
        </span>
      </p>
      <p className="ce6">
        <span className="ce3">Right of complaint to the competent supervisory authority</span>
      </p>
      <p className="ce6">
        <span className="ce3">Right to data portability </span>
      </p>
      <p className="ce6">
        <span className="ce3">Right to information, deletion and correction</span>
      </p>
      <p className="ce6">
        <span className="ce3">Right to demand processing restrictions</span>
      </p>
      <p className="ce6">
        <span className="ce3">SSL and/or TLS encryption</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0" start="4">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">Data collection on this website</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">Cookies</span>
      </p>
      <p className="ce6">
        <span className="ce3">Server log files</span>
      </p>
      <p className="ce6">
        <span className="ce3">Contact form</span>
      </p>
      <p className="ce6">
        <span className="ce3">Request by e-mail, telephone or fax</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0" start="5">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">Analysis tools and advertising</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">Google Tag Manager</span>
      </p>
      <p className="ce6">
        <span className="ce3">Google Analytics</span>
      </p>
      <p className="ce6">
        <span className="ce3">Hotjar</span>
      </p>
      <p className="ce6">
        <span className="ce3">Clarity</span>
      </p>
      <p className="ce6">
        <span className="ce3">Google Ads</span>
      </p>
      <p className="ce6">
        <span className="ce3">Google Conversion-Tracking&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0" start="6">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">Plug-ins and Tools</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">Google reCAPTCHA</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0" start="7">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">Online-based Audio and Video Conferences (Conference tools)</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">Data processing</span>
      </p>
      <p className="ce6">
        <span className="ce3">Purpose and legal basis</span>
      </p>
      <p className="ce6">
        <span className="ce3">Duration of storage</span>
      </p>
      <p className="ce6">
        <span className="ce3">Conference tools used</span>
      </p>
      <p className="ce6">
        <span className="ce3">Google Meet</span>
      </p>
      <p className="ce6">
        <span className="ce3">Order processing</span>
      </p>
      <ol className="ce10 lst-kix_list_3-0" start="8">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce7">Data collection on our customer platform</span>
        </li>
      </ol>
      <p className="ce6">
        <span className="ce3">Purpose of processing</span>
      </p>
      <p className="ce6">
        <span className="ce3">Legal basis</span>
      </p>
      <p className="ce6">
        <span className="ce3">Duration of data storage</span>
      </p>
      <p className="ce6">
        <span className="ce3">Google Analytics</span>
      </p>
      <p className="ce6">
        <span className="ce8">1. An overview of data protection</span>
      </p>
      <p className="ce6">
        <span className="ce2">Concept of personal data</span>
      </p>
      <p className="ce6">
        <span className="ce3">
          The following notices provide a simple overview of what happens to your personal data when you visit this
          website. &nbsp;According to the GDPR, &quot;personal data&quot; is any information relating to an identified
          or identifiable natural person; this includes, but is not limited to, your name, email address, telephone
          number, date of birth or address. &nbsp;Information about how you use this or other websites may also be
          personal data.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Data collection on this website</span>
      </p>
      <p className="ce6">
        <span className="c0">
          Who is the responsible party for the recording of data on this website (i.e., the &ldquo;controller&rdquo;)?
        </span>
      </p>
      <p className="ce6">
        <span className="ce3">
          The data on this website is processed by the operator of the website, whose contact information is available
          under section &ldquo;Information about the responsible party (referred to as the &ldquo;controller&rdquo; in
          the GDPR)&rdquo; in this Privacy Policy.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">How do we record your data?</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We collect your data as a result of your sharing of your data with us. This may, for instance be information
          you enter into our contact form.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Other data shall be recorded by our IT systems automatically or after you consent to its recording during your
          website visit. This data comprises primarily technical information (e.g., web browser, operating system, or
          time the site was accessed). This information is recorded automatically when you access this website.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Legal basis</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The legal basis of the processing is Art. 6 para. 1 lit. f GDPR. The aforementioned purpose constitutes the
          legitimate interest in data processing withing the meaning of Art. 6 (1) p. 1 lit. f GDPR.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Duration of data storage</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The data is deleted as soon as it is no longer required to achieve the purpose for which it was collected.
          This is usually the case after one month at the latest.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Possibility of objection</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Insofar as data is collected to the extent described, this is absolutely necessary for securing and operating
          the website. There is therefore no possibility to object.
        </span>
      </p>
      <p className="ce6">
        <span className="c0">What are the purposes we use your data for?</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          A portion of the information is generated to guarantee the error free provision of the website. Other data may
          be used to analyze your user patterns.
        </span>
      </p>
      <p className="ce6">
        <span className="c0">What rights do you have as far as your information is concerned?</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          You have the right to receive information about the source, recipients, and purposes of your archived personal
          data at any time without having to pay a fee for such disclosures. You also have the right to demand that your
          data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this
          consent at any time, which shall affect all future data processing. Moreover, you have the right to demand
          that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to
          log a complaint with the competent supervising agency.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Please do not hesitate to contact us at any time if you have questions about this or any other data protection
          related issues.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Analysis tools and tools provided by third parties</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          There is a possibility that your browsing patterns will be statistically analyzed when your visit this
          website. Such analyses are performed primarily with what we refer to as analysis programs.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          For detailed information about these analysis programs please consult our Data Protection Declaration below.
        </span>
      </p>
      <p className="ce6">
        <span className="ce8">2. Hosting</span>
      </p>
      <p className="ce6">
        <span className="ce3">We are hosting the content of our website at the following provider:</span>
      </p>
      <p className="ce6">
        <span className="ce2">External Hosting</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          This website is hosted externally. Personal data collected on this website are stored on the servers of the
          host. These may include, but are not limited to, IP addresses, contact requests, metadata and communications,
          contract information, contact information, names, web page access, and other data generated through a web
          site.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The external hosting serves the purpose of fulfilling the contract with our potential and existing customers
          (Art. 6(1)(b) GDPR) and in the interest of secure, fast, and efficient provision of our online services by a
          professional provider (Art. 6(1)(f) GDPR). If appropriate consent has been obtained, the processing is carried
          out exclusively on the basis of Art. 6 (1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the consent includes the
          storage of cookies or the access to information in the user&#39;s end device (e.g., device fingerprinting)
          within the meaning of the TTDSG. This consent can be revoked at any time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Our host(s) will only process your data to the extent necessary to fulfil its performance obligations and to
          follow our instructions with respect to such data.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">We are using the following host(s):</span>
      </p>
      <p className="ce6">
        <span className="ce3">
          Microsoft Limited UK
          <br />
          Microsoft Campus
          <br />
          <br />
          Thames Valley Park
          <br />
          Reading
          <br />
          Berkshire
          <br />
          RG6 1WG
          <br />
          UNITED KINGDOM
          <br />
          <br />
          Phone: (+44) 0344 800 2400
          <br />
          Fax: (+44) 0870 60 20 100
        </span>
      </p>
      <p className="ce6">
        <span className="c0">Order processing</span>
      </p>
      <p className="ce6">
        <span className="ce3">
          We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a
          contract mandated by data privacy laws that guarantees that they process personal data of our website visitors
          only based on our instructions and in compliance with the GDPR.
        </span>
      </p>
      <p className="ce6">
        <span className="ce8">3. General information and mandatory information</span>
      </p>
      <p className="ce6">
        <span className="ce2">
          Information about the responsible party (referred to as the &ldquo;controller&rdquo; in the GDPR)
        </span>
      </p>
      <p className="ce6">
        <span className="ce3">The data processing controller on this website is:</span>
      </p>
      <p className="ce6">
        <span className="ce3">
          Michael Dittel
          <br />
          Am Kr&ouml;gel 2<br />
          10179 Berlin
        </span>
      </p>
      <p className="ce6">
        <span className="ce3">
          Phone: +49 170 9529249
          <br />
          E-mail: contact@leaftech.eu
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The controller is the natural person or legal entity that single-handedly or jointly with others makes
          decisions as to the purposes of and resources for the processing of personal data (e.g., names, e-mail
          addresses, etc.).
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">General information on the legal basis for the data processing </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          To the extent that we obtain your consent for processing operations of personal data, Art. 6 para. 1 p.1 lit.
          a DSGVO serves as the legal basis for the processing of your personal data.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          When processing your personal data to fulfill a contract between you and Leaftech GmbH, Art. 6 (1) S.1 lit. b
          DSGVO serves as the legal basis. This also applies to processing operations that are necessary for the
          performance of pre-contractual measures.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If processing of personal data is necessary for compliance with a legal obligation to which our company is
          subject, Art. 6 (1) sentence 1 lit. c DSGVO serves as the legal basis.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If processing is necessary to protect a legitimate interest of our company or a third party and if the
          interests, fundamental rights and freedoms of the data subject do not override the first-mentioned interest,
          Art. 6 (1) sentence 1 lit. f DSGVO serves as the legal basis for the processing.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Storage duration</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Unless a more specific storage period has been specified in this privacy policy, your personal data will
          remain with us until the purpose for which it was collected no longer applies. If you assert a justified
          request for deletion or revoke your consent to data processing, your data will be deleted, unless we have
          other legally permissible reasons for storing your personal data (e.g., tax or commercial law retention
          periods); in the latter case, the deletion will take place after these reasons cease to apply.
        </span>
      </p>
      <p className="ce6 ce13">
        <span className="ce3" />
      </p>
      <p className="ce6">
        <span className="ce2">Information on data transfer to the USA and other non-EU countries</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Among other things, we use tools of companies domiciled in the United States or other from a data protection
          perspective non-secure non-EU countries. If these tools are active, your personal data may potentially be
          transferred to these non-EU countries and may be processed there. We must point out that in these countries, a
          data protection level that is comparable to that in the EU cannot be guaranteed. For instance, U.S.
          enterprises are under a mandate to release personal data to the security agencies and you as the data subject
          do not have any litigation options to defend yourself in court. Hence, it cannot be ruled out that U.S.
          agencies (e.g., the Secret Service) may process, analyze, and permanently archive your personal data for
          surveillance purposes. We have no control over these processing activities.
        </span>
      </p>
      <p className="ce1">
        <span className="ce4">
          There is no valid adequacy decision of the European Commission for the USA. We rely on standard contractual
          clauses for data transfers where necessary. Standard contractual clauses are written commitments between
          parties that can serve as a basis for data transfers from the EU to third countries by providing appropriate
          safeguards for data protection. Standard contractual clauses have been approved by the European Commission and
          cannot be modified by the parties using them. The standard contractual clauses issued by the European
          Commission can be found{" "}
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj&amp;sa=D&amp;source=editors&amp;ust=1689156579613622&amp;usg=AOvVaw0-F7aDdzKnDUb0GklKtsrI"
          >
            here
          </a>
        </span>
        <span className="ce4">, </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/EN/TXT/?uri%3DCELEX%253A32010D0087&amp;sa=D&amp;source=editors&amp;ust=1689156579613990&amp;usg=AOvVaw1v17QYK28LrBG7VVVj3Lam"
          >
            here
          </a>
        </span>
        <span className="ce4">&nbsp;and </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/EN/TXT/?uri%3DCELEX%253A32004D0915&amp;sa=D&amp;source=editors&amp;ust=1689156579614275&amp;usg=AOvVaw3hZp9dZ3ssWFPbVtrJrVwA"
          >
            here
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce6">
        <span className="ce2">Revocation of your consent to the processing of data</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          A wide range of data processing transactions are possible only subject to your express consent. You can also
          revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of
          any data collection that occurred prior to your revocation.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">
          Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21
          GDPR)
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          In the event that data are processed on the basis of art. 6(1)(e) or (f) GDPR, you have the right to at any
          time object to the processing of your personal data based on grounds arising from your unique situation. This
          also applies to any profiling based on these provisions. To determine the legal basis, on which any processing
          of data is based, please consult this data protection declaration. If you log an objection, we will no longer
          process your affected personal data, unless we are in a position to present compelling protection worthy
          grounds for the processing of your data, that outweigh your interests, rights and freedoms or if the purpose
          of the processing is the claiming, exercising or defence of legal entitlements (objection pursuant to art.
          21(1) GDPR).
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If your personal data is being processed in order to engage in direct advertising, you have the right to
          object to the processing of your affected personal data for the purposes of such advertising at any time. This
          also applies to profiling to the extent that it is affiliated with such direct advertising. If you object,
          your personal data will subsequently no longer be used for direct advertising purposes (objection pursuant to
          art. 21(2) GDPR).
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Right of complaint to the competent supervisory authority</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory
          agency, in particular in the member state where they usually maintain their domicile, place of work or at the
          place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other
          administrative or court proceedings available as legal recourses.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Right to data portability</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          You have the right to demand that we hand over any data we automatically process on the basis of your consent
          or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable
          format. If you should demand the direct transfer of the data to another controller, this will be done only if
          it is technically feasible.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Right to information, deletion and correction</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Within the scope of the applicable statutory provisions, you have the right to at any time demand information
          about your archived personal data, their source and recipients as well as the purpose of the processing of
          your data. You may also have a right to have your data rectified or eradicated. If you have questions about
          this subject matter or any other questions about personal data, please do not hesitate to contact us at any
          time.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Right to demand processing restrictions</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          You have the right to demand the imposition of restrictions as far as the processing of your personal data is
          concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in
          the following cases:
        </span>
      </p>
      <ul className="ce10 lst-kix_list_1-0 start">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">
            In the event that you should dispute the correctness of your data archived by us, we will usually need some
            time to verify this claim. During the time that this investigation is ongoing, you have the right to demand
            that we restrict the processing of your personal data.
          </span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">
            If the processing of your personal data was/is conducted in an unlawful manner, you have the option to
            demand the restriction of the processing of your data in lieu of demanding the eradication of this data.
          </span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">
            If we do not need your personal data any longer and you need it to exercise, defend or claim legal
            entitlements, you have the right to demand the restriction of the processing of your personal data instead
            of its eradication.
          </span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">
            If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be
            weighed against each other. As long as it has not been determined whose interests prevail, you have the
            right to demand a restriction of the processing of your personal data.
          </span>
        </li>
      </ul>
      <p className="ce1">
        <span className="ce3">
          If you have restricted the processing of your personal data, these data &ndash; with the exception of their
          archiving &ndash; may be processed only subject to your consent or to claim, exercise or defend legal
          entitlements or to protect the rights of other natural persons or legal entities or for important public
          interest reasons cited by the European Union or a member state of the EU.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">SSL and/or TLS encryption</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          For security reasons and to protect the transmission of confidential content, such as purchase orders or
          inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption
          program. You can recognize an encrypted connection by checking whether the address line of the browser
          switches from &ldquo;http://&rdquo; to &ldquo;https://&rdquo; and also by the appearance of the lock icon in
          the browser line.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.
        </span>
      </p>
      <p className="ce6">
        <span className="ce8">4. Data collection on this website</span>
      </p>
      <p className="ce6">
        <span className="ce2">Cookies</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Our websites and pages use what the industry refers to as &ldquo;cookies.&rdquo; Cookies are small data
          packages that do not cause any damage to your device. They are either stored temporarily for the duration of a
          session (session cookies) or they are permanently archived on your device (permanent cookies). Session cookies
          are automatically deleted once you terminate your visit. Permanent cookies remain archived on your device
          until you actively delete them, or they are automatically eradicated by your web browser.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          In some cases, it is possible that third-party cookies are stored on your device once you enter our site
          (third-party cookies). These cookies enable you or us to take advantage of certain services offered by the
          third party (e.g., cookies for the processing of payment services).
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Cookies have a variety of functions. Many cookies are technically essential since certain website functions
          would not work in the absence of the cookies (e.g., the shopping cart function or the display of videos). The
          purpose of other cookies may be the analysis of user patterns or the display of promotional messages.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Cookies, which are required for the performance of electronic communication transactions, or for the provision
          of certain functions you want to use (e.g., for the shopping cart function) or those that are necessary for
          the optimization (required cookies) of the website (e.g., cookies that provide measurable insights into the
          web audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal basis is cited. The
          operator of the website has a legitimate interest in the storage of required cookies to ensure the technically
          error free and optimized provision of the operator&rsquo;s services. If your consent to the storage of the
          cookies and similar recognition technologies has been requested, processing occurs exclusively on the basis of
          the consent obtained (Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG); this consent may be revoked at any time.
        </span>
      </p>
      <p className="ce6">
        <span className="ce3">
          You have the option to set up your browser in such a manner that you will be notified any time cookies are
          placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of
          cookies in certain cases or in general or activate the delete function for the automatic eradication of
          cookies when the browser closes. If cookies are deactivated, the functions of this website may be limited.
        </span>
      </p>
      <p className="ce6">
        <span className="ce3">
          In the event that third-party cookies are used or if cookies are used for analytical purposes, we will
          separately notify you in conjunction with this Data Protection Policy and, if applicable, ask for your
          consent.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Server log files</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The provider of this website and its pages automatically collects and stores information in so-called server
          log files, which your browser communicates to us automatically. The information comprises:
        </span>
      </p>
      <ul className="ce10 lst-kix_list_2-0 start">
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">The type and version of browser used</span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">The used operating system</span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">Referrer URL</span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">The hostname of the accessing computer</span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">The time of the server inquiry</span>
        </li>
        <li className="ce1 ce5 li-bullet-0">
          <span className="ce3">The IP address</span>
        </li>
      </ul>
      <p className="ce1">
        <span className="ce3">This data is not merged with other data sources.</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of the website has a legitimate interest
          in the technically error free depiction and the optimization of the operator&rsquo;s website. In order to
          achieve this, server log files must be recorded.
        </span>
      </p>
      <p className="ce1">
        <span className="ce2">Contact form</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If you submit inquiries to us via our contact form, the information provided in the contact form as well as
          any contact information provided therein will be stored by us in order to handle your inquiry and in the event
          that we have further questions. We will not share this information without your consent.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a
          contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is
          based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f)
          GDPR) or on your agreement (Art. 6(1)(a) GDPR) if this has been requested; the consent can be revoked at any
          time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The information you have entered into the contact form shall remain with us until you ask us to eradicate the
          data, revoke your consent to the archiving of data or if the purpose for which the information is being
          archived no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without
          prejudice to any mandatory legal provisions, in particular retention periods.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Request by e-mail, telephone, or fax</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name,
          request) will be stored and processed by us for the purpose of processing your request. We do not pass these
          data on without your consent.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a
          contract or is required for the performance of pre-contractual measures. In all other cases, the data are
          processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art.
          6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent can be
          revoked at any time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The data sent by you to us via contact requests remain with us until you request us to delete, revoke your
          consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request).
          Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.
        </span>
      </p>
      <p className="ce6">
        <span className="ce8">5. Analysis tools and advertising</span>
      </p>
      <p className="ce6">
        <span className="ce2">Google Tag Manager</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
          Ireland
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools and other
          technologies on our website. The Google Tag Manager itself does not create any user profiles, does not store
          cookies, and does not carry out any independent analyses. It only manages and runs the tools integrated via
          it. However, the Google Tag Manager does collect your IP address, which may also be transferred to
          Google&rsquo;s parent company in the United States.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate
          interest in the quick and uncomplicated integration and administration of various tools on his website. If
          appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a)
          GDPR and &sect; 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information
          in the user&rsquo;s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can
          be revoked at any time.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Google Analytics</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          This website uses functions of the web analysis service Google Analytics. The provider of this service is
          Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4, Ireland.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Google Analytics enables the website operator to analyze the behavior patterns of website visitors. To that
          end, the website operator receives a variety of user data, such as pages accessed, time spent on the page, the
          utilized operating system and the user&rsquo;s origin. This data is assigned to the respective end device of
          the user. An assignment to a user-ID does not take place.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Furthermore, Google Analytics allows us to record your mouse and scroll movements and clicks, among other
          things. Google Analytics uses various modeling approaches to augment the collected data sets and uses machine
          learning technologies in data analysis.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user
          behavior patterns (e.g., cookies or device fingerprinting). The website use information recorded by Google is,
          as a rule transferred to a Google server in the United States, where it is stored.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and &sect; 25(1)
          TTDSG. You may revoke your consent at any time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce4">
          Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission.
          Details can be found here:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://privacy.google.com/businesses/controllerterms/mccs/&amp;sa=D&amp;source=editors&amp;ust=1689156579619434&amp;usg=AOvVaw0--Jp1PwEaAG0gQaPtAl86"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce6">
        <span className="c0">Browser plug-in</span>
      </p>
      <p className="ce1">
        <span className="ce4">
          You can prevent the recording and processing of your data by Google by downloading and installing the browser
          plugin available under the following link:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1689156579619956&amp;usg=AOvVaw1zgq9b0GrJgDTbg5AKbWPg"
          >
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce1">
        <span className="ce4">
          For more information about the handling of user data by Google Analytics, please consult Google&rsquo;s Data
          Privacy Declaration at:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://support.google.com/analytics/answer/6004245?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1689156579620300&amp;usg=AOvVaw338uFrgjTxAKYuZVsPh3YR"
          >
            https://support.google.com/analytics/answer/6004245?hl=en
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce6">
        <span className="c0">Google Signals</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We use Google Signals. Whenever you visit our website, Google Analytics records, among other things, your
          location, the progression of your search and YouTube progression as well as demographic data (site visitor
          data). This data may be used for customized advertising with the assistance of Google Signal. If you have a
          Google account, your site visitor information will be linked to your Google account by Google Signal and used
          to send you customized promotional messages. The data is also used to compile anonymized statistics of our
          users&rsquo; online patterns.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Contract data processing</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We have executed a contract data processing agreement with Google and are implementing the stringent
          provisions of the German data protection agencies to the fullest when using Google Analytics.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Hotjar</span>
      </p>
      <p className="ce1">
        <span className="ce4">
          This website utilizes Hotjar. The provider is Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia Zammit
          Street, St Julians STJ 1000, Malta, Europe (website:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://www.hotjar.com/&amp;sa=D&amp;source=editors&amp;ust=1689156579621068&amp;usg=AOvVaw3sZHjwJgNKChjyCMUup7i_"
          >
            https://www.hotjar.com
          </a>
        </span>
        <span className="ce3">).</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Hotjar is a tool used to analyze your user patterns on this website. Hotjar allows us to for instance record
          your mouse and scroll movements as well as your click. During this process, Hotjar also has the capability to
          determine how long your cursor remained in a certain position. Based on this information, Hotjar compiles
          so-called Heatmaps, that make possible to determine which parts of the website the website visitor reviews
          with preference.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We are also able to determine how long you have stayed on a page of this website and when you left. We can
          also determine at which point you suspended making entries into a contact form (so-called conversion funnels).
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Furthermore, Hotjar can be deployed to obtain direct feedback from website visitors. This function aims at the
          improvement of the website offerings of the website operator.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Hotjar uses technologies that make it possible to recognize the user for the purpose of analyzing the user
          patterns (e.g., cookies or the deployment of device fingerprinting).
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of
          Art. 6(1)(a) GDPR and &sect; 25 TTDSG (German Telecommunications Act). Such consent may be revoked at any
          time. If your consent was not obtained, the use of the service will occur on the basis of Art. 6(1)(f) GDPR;
          the website operator has a legitimate interest in the analysis of user patterns to optimize both, the web
          presentation and the operator&rsquo;s advertising activities.
        </span>
      </p>
      <p className="ce6">
        <span className="c0">Deactivation of Hotjar</span>
      </p>
      <p className="ce1">
        <span className="ce4">
          If you would like to deactivate the recording of data by Hotjar, please click on the link below and follow the
          instructions provided under the link:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://www.hotjar.com/policies/do-not-track/&amp;sa=D&amp;source=editors&amp;ust=1689156579622043&amp;usg=AOvVaw0roMhXiSseTFIl7no7tuVN"
          >
            https://www.hotjar.com/policies/do-not-track/
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Please keep in mind that you will have to separately deactivate Hotjar for every browser and every device.
        </span>
      </p>
      <p className="ce1">
        <span className="ce4">
          For more detailed information about Hotjar and the data to be recorded, please consult the Data Privacy
          Declaration of Hotjar under the following link:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://www.hotjar.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1689156579622369&amp;usg=AOvVaw3gu54OyBS80gjGYMl1X2EK"
          >
            https://www.hotjar.com/privacy
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce1">
        <span className="c0">Data processing</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a
          contract mandated by data privacy laws that guarantees that they process personal data of our website visitors
          only based on our instructions and in compliance with the GDPR.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Clarity</span>
      </p>
      <p className="ce1">
        <span className="ce4">
          This website uses Clarity. The provider is the Microsoft Ireland Operations Limited, One Microsoft Place,
          South County Business Park, Leopardstown, Dublin 18, Ireland,&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://docs.microsoft.com/en-us/clarity/&amp;sa=D&amp;source=editors&amp;ust=1689156579622879&amp;usg=AOvVaw1t4jkqJ12DNFhL5bMSyHAW"
          >
            https://docs.microsoft.com/en-us/clarity/
          </a>
        </span>
        <span className="ce3">&nbsp;(hereinafter referred to as &ldquo;Clarity&rdquo;).</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Clarity is a tool to analyze user patterns on this website. Clarity records in particular cursor movements and
          compiles graphics that show on which parts of the website users are scrolling with great frequency (heatmaps).
          Clarity can also record sessions so that we can watch the use of the site in the form of videos. Moreover, we
          receive information on the general user conduct within our website.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Clarity uses technologies that make it possible to recognize users for the purpose of analyzing user patterns
          (e.g., cookies or use of device fingerprinting). Your personal data will be archived on Microsoft servers
          (Microsoft Azure Cloud Service) in the United States.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of
          Art. 6(1)(a) GDPR and &sect; 25 TTDSG (German Telecommunications Act). Such consent may be revoked at any
          time. If your consent was not obtained, the use of the service will occur on the basis of Art. 6(1)(f) GDPR;
          the website operator has a legitimate interest in the effective analysis of user patterns.
        </span>
      </p>
      <p className="ce6">
        <span className="ce4">For more details on Clarity&rsquo;s data privacy policy, please see:&nbsp;</span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://docs.microsoft.com/en-us/clarity/faq&amp;sa=D&amp;source=editors&amp;ust=1689156579623353&amp;usg=AOvVaw0BfeEhEBty3cMQ9W5PeZFc"
          >
            https://docs.microsoft.com/en-us/clarity/faq
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce6">
        <span className="c0">Data processing</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a
          contract mandated by data privacy laws that guarantees that they process personal data of our website visitors
          only based on our instructions and in compliance with the GDPR.
        </span>
      </p>
      <p className="ce6">
        <span className="ce2">Google Ads</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The website operator uses Google Ads. Google Ads is an online promotional program of Google Ireland Limited
          (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4, Ireland.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Google Ads enables us to display ads in the Google search engine or on third-party websites, if the user
          enters certain search terms into Google (keyword targeting). It is also possible to place targeted ads based
          on the user data Google has in its possession (e.g., location data and interests; target group targeting). As
          the website operator, we can analyze these data quantitatively, for instance by analyzing which search terms
          resulted in the display of our ads and how many ads led to respective clicks.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and &sect; 25(1)
          TTDSG. You may revoke your consent at any time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce4">
          Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission.
          Details can be found here:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://policies.google.com/privacy/frameworks&amp;sa=D&amp;source=editors&amp;ust=1689156579624012&amp;usg=AOvVaw2ol-vC-mXCv0wGkfu9Hz8O"
          >
            https://policies.google.com/privacy/frameworks
          </a>
        </span>
        <span className="ce4">&nbsp;and&nbsp;</span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://privacy.google.com/businesses/controllerterms/mccs/&amp;sa=D&amp;source=editors&amp;ust=1689156579624212&amp;usg=AOvVaw2qqLlwylIpA5r2s4XyZ5xf"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce6">
        <span className="ce2">Google Conversion-Tracking</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          This website uses Google Conversion Tracking. The provider of this service is Google Ireland Limited
          (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4, Ireland.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          With the assistance of Google Conversion Tracking, we are in a position to recognize whether the user has
          completed certain actions. For instance, we can analyze the how frequently which buttons on our website have
          been clicked and which products are reviewed or purchased with particular frequency. The purpose of this
          information is to compile conversion statistics. We learn how many users have clicked on our ads and which
          actions they have completed. We do not receive any information that would allow us to personally identify the
          users. Google as such uses cookies or comparable recognition technologies for identification purposes.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and &sect; 25(1)
          TTDSG. You may revoke your consent at any time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce4">
          For more information about Google Conversion Tracking, please review Google&rsquo;s data protection policy
          at:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1689156579624766&amp;usg=AOvVaw1QjNAE4UWnMPD1nd7eAmwA"
          >
            https://policies.google.com/privacy?hl=en
          </a>
        </span>
      </p>
      <p className="ce6">
        <span className="ce8">6. Plug-ins and Tools</span>
      </p>
      <p className="ce6">
        <span className="ce2">Google reCAPTCHA</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We use &ldquo;Google reCAPTCHA&rdquo; (hereinafter referred to as &ldquo;reCAPTCHA&rdquo;) on this website.
          The provider is Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4, Ireland.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The purpose of reCAPTCHA is to determine whether data entered on this website (e.g., information entered into
          a contact form) is being provided by a human user or by an automated program. To determine this, reCAPTCHA
          analyzes the behavior of the website visitors based on a variety of parameters. This analysis is triggered
          automatically as soon as the website visitor enters the site. For this analysis, reCAPTCHA evaluates a variety
          of data (e.g., IP address, time the website visitor spent on the site or cursor movements initiated by the
          user). The data tracked during such analyses are forwarded to Google.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          reCAPTCHA analyses run entirely in the background. Website visitors are not alerted that an analysis is
          underway.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Data are stored and analyzed on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest
          in the protection of the operator&rsquo;s websites against abusive automated spying and against SPAM. If
          appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a)
          GDPR and &sect; 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information
          in the user&rsquo;s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can
          be revoked at any time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce4">
          For more information about Google reCAPTCHA please refer to the Google Data Privacy Declaration and Terms Of
          Use under the following links:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1689156579625546&amp;usg=AOvVaw03ujaVE59IwXTN28tiucxA"
          >
            https://policies.google.com/privacy?hl=en
          </a>
        </span>
        <span className="ce4">&nbsp;and&nbsp;</span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://policies.google.com/terms?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1689156579625746&amp;usg=AOvVaw2O4J9Ra49jSIH0doXwpziZ"
          >
            https://policies.google.com/terms?hl=en
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce6">
        <span className="ce8">7. Online-based Audio and Video Conferences (Conference tools)</span>
      </p>
      <p className="ce6">
        <span className="c0">Data processing</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We use online conference tools, among other things, for communication with our customers. The tools we use are
          listed in detail below. If you communicate with us by video or audio conference using the Internet, your
          personal data will be collected and processed by the provider of the respective conference tool and by us. The
          conferencing tools collect all information that you provide/access to use the tools (email address and/or your
          phone number). Furthermore, the conference tools process the duration of the conference, start and end (time)
          of participation in the conference, number of participants and other &ldquo;context information&rdquo; related
          to the communication process (metadata).
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Furthermore, the provider of the tool processes all the technical data required for the processing of the
          online communication. This includes, in particular, IP addresses, MAC addresses, device IDs, device type,
          operating system type and version, client version, camera type, microphone or loudspeaker and the type of
          connection.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Should content be exchanged, uploaded, or otherwise made available within the tool, it is also stored on the
          servers of the tool provider. Such content includes, but is not limited to, cloud recordings, chat/ instant
          messages, voicemail uploaded photos and videos, files, whiteboards, and other information shared while using
          the service.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Please note that we do not have complete influence on the data processing procedures of the tools used. Our
          possibilities are largely determined by the corporate policy of the respective provider. Further information
          on data processing by the conference tools can be found in the data protection declarations of the tools used,
          and which we have listed below this text.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Purpose and legal bases</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The conference tools are used to communicate with prospective or existing contractual partners or to offer
          certain services to our customers (Art. 6(1)(b) GDPR). Furthermore, the use of the tools serves to generally
          simplify and accelerate communication with us or our company (legitimate interest in the meaning of Art.
          6(1)(f) GDPR). Insofar as consent has been requested, the tools in question will be used on the basis of this
          consent; the consent may be revoked at any time with effect from that date.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Duration of storage</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Data collected directly by us via the video and conference tools will be deleted from our systems immediately
          after you request us to delete it, revoke your consent to storage, or the reason for storing the data no
          longer applies. Stored cookies remain on your end device until you delete them. Mandatory legal retention
          periods remain unaffected.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We have no influence on the duration of storage of your data that is stored by the operators of the conference
          tools for their own purposes. For details, please directly contact the operators of the conference tools.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Conference tools used</span>
      </p>
      <p className="ce1">
        <span className="ce3">We employ the following conference tools:</span>
      </p>
      <p className="ce1">
        <span className="ce2">Google Meet</span>
      </p>
      <p className="ce1">
        <span className="ce4">
          We use Google Meet. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
          For details on data processing, please see the Google privacy policy:&nbsp;
        </span>
        <span className="ce9">
          <a
            className="ce12"
            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1689156579627229&amp;usg=AOvVaw2e0D_tO-tOgjjCYGa85zjs"
          >
            https://policies.google.com/privacy?hl=en
          </a>
        </span>
        <span className="ce3">.</span>
      </p>
      <p className="ce1">
        <span className="c0">Data processing</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a
          contract mandated by data privacy laws that guarantees that they process personal data of our website visitors
          only based on our instructions and in compliance with the GDPR.
        </span>
      </p>
      <p className="ce6">
        <span className="ce8">8. Data collection on our customer platform</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          When you create an account with us to use our Customer Platform, we collect personal information associated
          with your account, including your name, contact information, account information, payment card information,
          and transaction history (collectively, &quot;Account Information&quot;).
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Purpose of processing</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Part of the data is collected to ensure error-free provision and contractual use of the Customer Platform.
          Other data may be used to analyze your user behavior.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Legal basis</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The legal basis of the processing is Art. 6 para. 1 p. 1 lit. b DSGVO. &nbsp;The stated purpose represents the
          fulfillment of a contract within the meaning of Art. 6 para. 1 p. 1 lit. b DSGVO.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Duration of data storage</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          We will delete your data that we have received in the course of contacting you as soon as they are no longer
          required to achieve the purpose for which they were collected, i.e. your request has been fully processed and
          no further communication with you is required or requested.
        </span>
      </p>
      <p className="ce1">
        <span className="c0">Google Analytics</span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The Customer Platform uses functions of the web analytics service Google Analytics. The provider is Google
          Ireland Limited (&quot;Google&quot;), Gordon House, Barrow Street, Dublin 4, Ireland.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Google Analytics enables the website operator to analyze the behavior of website visitors. In doing so, the
          website operator receives various usage data, such as page views, dwell time, operating systems used and the
          origin of the user. This data is assigned to the respective end device of the user. An assignment to a user ID
          does not take place.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Furthermore, we can use Google Analytics to record your mouse and scroll movements and clicks, among other
          things. Furthermore, Google Analytics uses various modeling approaches to supplement the data records
          collected and uses machine learning technologies in data analysis.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Google Analytics uses technologies that enable the recognition of the user for the purpose of analyzing user
          behavior (e.g. cookies or device fingerprinting). The information collected by Google about the use of this
          website is usually transferred to a Google server in the USA and stored there.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          The use of this service is based on your consent according to Art. 6 para. 1 lit. a DSGVO and &sect; 25 para.
          1 TTDSG. The consent can be revoked at any time.
        </span>
      </p>
      <p className="ce1">
        <span className="ce3">
          Data transfer to the USA is based on the standard contractual clauses of the EU Commission. Details can be
          found here: https://privacy.google.com/businesses/controllerterms/mccs/.
        </span>
      </p>
    </body>
  );
};
