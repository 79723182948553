import { Paper } from "@mui/material";
import React, { useContext } from "react";

import { WizardContext } from "../../context/Wizard";
import { columnsAPINamesEnums } from "../../enums/Modernization/financialDashboard";
import { getSingleMeasureFinancialValuesTimeSeries } from "../../functions/Modernization";
import { aggregatedSumValue, echartsDefaultTooltipFormatter } from "../../utils/dynamicDashboard";
import { getCSSVar } from "../../utils/styling";
import { DynamicEchartsChart } from "../chart/DynamicChartMemoized";

export const RetrofitsFinancialTimeSeriesChartWithEchart = ({
  chartHeight = 350,
  className,
  selectedMeauresRiched,
  title,
}) => {
  const { yearRange } = useContext(WizardContext);

  const allSelectedMeauresRichedTimeSeries = selectedMeauresRiched?.map((selectedMeaure) =>
    getSingleMeasureFinancialValuesTimeSeries({
      endYear: 2050,
      initialValues: {
        foreignCapital: selectedMeaure[columnsAPINamesEnums.FOEIGN_CAPITAL],
        inflationRatePercentage: selectedMeaure[columnsAPINamesEnums.INFLATION_RATE],
        initialEnergySaving:
          selectedMeaure[columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS] +
          selectedMeaure[columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS],
        initialMaintenanceCosts: selectedMeaure[columnsAPINamesEnums.MAINTENANCE_COST],
        initialOtherSavings: selectedMeaure[columnsAPINamesEnums.OTHER_COST_SAVINGS],
        initialServicesCosts: selectedMeaure[columnsAPINamesEnums.SERVICES_COST],
        interestRatePercentage: selectedMeaure[columnsAPINamesEnums.INTEREST_RATE],
        loanRepaymentTime: selectedMeaure[columnsAPINamesEnums.REPAYMENT_TIME],
        ownCapital: selectedMeaure[columnsAPINamesEnums.OWN_CAPITAL],
        subsidies: selectedMeaure[columnsAPINamesEnums.SUBSIDIES],
      },
      startYear: selectedMeaure.year,
    })
  );

  const aggregateKeys = [
    "energySavings",
    "foreignCapitalCost",
    "loanRepayment",
    "maintenanceCosts",
    "otherSavings",
    "servicesCosts",
    "foreignCapital",
    "ownCapital",
    "subsidies",
  ];
  const SelectedMeasuresTimeSeriesAggregated = aggregatedSumValue({
    aggregateColumns: aggregateKeys,
    data: allSelectedMeauresRichedTimeSeries?.reduce((total, current) => [...total, ...current], []),
    groupByColumns: ["year"],
  });

  SelectedMeasuresTimeSeriesAggregated?.forEach((yearData) => {
    yearData.cashFlow =
      yearData["energySavings"] +
      yearData["foreignCapitalCost"] +
      yearData["loanRepayment"] +
      yearData["maintenanceCosts"] +
      yearData["otherSavings"] +
      yearData["servicesCosts"] -
      yearData["ownCapital"];
  });

  const diagrams = [
    {
      color: getCSSVar("--clr-leaftech-blue-500"),
      dataKey: "foreignCapital",
      name: "Foreign Capital [€]",
    },
    {
      color: getCSSVar("--clr-secondary-blue-500"),
      dataKey: "ownCapital",
      name: "Own Capital [€]",
    },
    {
      color: getCSSVar("--clr-vivid-orchid-400"),
      dataKey: "subsidies",
      name: "Subsidies [€]",
    },
    {
      color: getCSSVar("--clr-cathode-green-600"),
      dataKey: "energySavings",
      name: "Energy Savings [€/a]",
    },
    {
      color: getCSSVar("--clr-cathode-green-900"),
      dataKey: "otherSavings",
      name: "Other Savings [€/a]",
    },
    {
      color: getCSSVar("--clr-star-600"),
      dataKey: "servicesCosts",
      name: "Services Costs [€/a]",
    },
    {
      color: getCSSVar("--clr-tangerine-800"),
      dataKey: "foreignCapitalCost",
      name: "Foreign Capital Cost [€/a]",
    },
    {
      color: getCSSVar("--clr-mystic-red-600"),
      dataKey: "loanRepayment",
      name: "Loan Repayment [€/a]",
    },
    {
      color: getCSSVar("--clr-tangerine-400"),
      dataKey: "maintenanceCosts",
      name: "Maintenance Costs [€/a]",
    },
  ];

  const dataForChart = [];
  for (let currentYear = yearRange[0]; currentYear <= yearRange[1]; currentYear++) {
    const currentYearData = SelectedMeasuresTimeSeriesAggregated?.find((yearData) => yearData.year === currentYear);
    if (currentYearData) {
      dataForChart.push(currentYearData);
    } else {
      dataForChart.push({ year: currentYear });
    }
  }

  const option = {
    animation: true,
    dataZoom: [
      {
        right: 0,
        type: "slider",
        width: 16,
        yAxisIndex: 0,
      },
    ],
    grid: {
      bottom: 70,
      containLabel: true,
      left: 15,
      right: 10,
      top: 55,
    },
    legend: {
      bottom: 0,
      left: 10,
      orient: "horizontal",
      right: 40,
      textStyle: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3rem",
        fontWeight: 600,
      },
    },
    series: [
      ...diagrams.map((diagram) => ({
        color: diagram.color,
        data: dataForChart?.map((d) => d[diagram.dataKey]),
        itemStyle: {
          color: diagram.color,
        },
        name: diagram.name,
        stack: "total",
        type: "bar",
      })),
      {
        data: dataForChart?.map((d) => d.cashFlow),
        lineStyle: {
          color: getCSSVar("--clr-secondary-blue-500"),
          width: 2,
        },
        name: "Cash Flow",
        type: "line",
      },
    ],
    tooltip: {
      trigger: "axis",
      valueFormatter: echartsDefaultTooltipFormatter,
    },
    xAxis: [
      {
        axisPointer: {
          type: "shadow",
        },
        boundaryGap: true,
        data: dataForChart?.map((d) => d.year),
        name: "Year",
        nameGap: 25,
        nameLocation: "center",
        type: "category",
      },
    ],
    yAxis: [
      {
        axisLine: {
          show: true,
        },
        name: "€",
        nameGap: 10,
        nameLocation: "end",
        nameRotate: 0,
        nameTextStyle: {},
        type: "value",
      },
    ],
  };

  return (
    <Paper className={`relative  ${className}`}>
      <section className="flex items-start justify-between gap-6">
        <div className="w-full">
          <div className="w-full" style={{ height: chartHeight }}>
            <DynamicEchartsChart option={option} title={title} />
          </div>
        </div>
      </section>
    </Paper>
  );
};
