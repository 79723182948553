import commercialBuildingImage from "../Asset/Images/Buildings/commercialBuilding.png";
import officeBuldingImage from "../Asset/Images/Buildings/officeBulding.png";
import publicBuildingImage from "../Asset/Images/Buildings/publicBuilding.png";
import residentialBuildingImage from "../Asset/Images/Buildings/residentialBuilding.png";

export const getAletrnativeImage = (type) => {
  if (type === "office") return officeBuldingImage;
  if (type === "commercial") return commercialBuildingImage;
  if (type === "public") return publicBuildingImage;
  return residentialBuildingImage;
};
