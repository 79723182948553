import { Paper, Stack } from "@mui/material";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { postTranslation } from "../api/functions/all";
import { useGetTranslations, useModifyTranslation } from "../api/hooks/allHooks";
import { ButtonNew, Checkbox, CustomModal, Icon, Input, Table } from "../components";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";

const headers = [
  {
    cellType: null,
    dataKey: "word",
    downloadZipFile: (row) => null,
    href: (row) => null,
    isReadOnly: true,
    label: "word",
    subValueTransformation: (row) => null,
    valueTransformation: (row) => row["word"],
  },
  {
    cellType: null,
    dataKey: "translation",
    downloadZipFile: (row) => null,
    href: (row) => null,
    label: "translation",
    subValueTransformation: (row) => null,
    valueTransformation: (row) => row["translation"],
  },
];

export const Administration = () => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const word = useRef(null);
  const translation = useRef(null);

  const { data, isLoading } = useGetTranslations();

  const translations = Object.keys(data || {}).map((word, index) => {
    return { translation: data[word], word };
  });

  const { mutate } = useModifyTranslation();

  const handleBlur = (parentRowId, rowId, fieldName, fieldValue) => {
    mutate({ data: { [rowId]: fieldValue }, method: "patch" });
  };

  const hanleSubmit = (e) => {
    e.preventDefault();
    const method = "post";
    const data = { [word.current?.value]: translation.current?.value };
    const resp = postTranslation({ data, method });
    resp
      .then(() => {
        queryClient.invalidateQueries("getTranslations");
        toast.success("Successfully added.");
        if (word.current?.value) word.current.value = "";
        if (translation.current?.value) translation.current.value = "";
      })
      .catch((error) => {
        toast.error(JSON.stringify(error?.response?.data));
      });
  };

  const filterTranslations = () => {
    const filtered = translations?.filter((row) => {
      if (filter === "all") return true;
      return filter === "translated" ? !!row.translation : !row.translation;
    });
    return filtered;
  };
  useEffect(() => {
    setModalOpen(i18n.language === "de");
  }, [i18n.language]);

  return (
    <LoadingOrEmptyWrapper height="90vh" showLoading={isLoading}>
      <div>
        <form>
          <Paper>
            <h2 className="t-heading-l mb-4">Add new translation</h2>
            <Stack className="justify-between">
              <ButtonNew onClick={hanleSubmit} size="sm" variant="primary" width="6%">
                <Icon fill="white" iconName="Add" stroke="white" />
                <p> Add </p>
              </ButtonNew>
              <Input
                className=""
                height="4rem"
                inputRef={word}
                label="English"
                placeholder="Expression"
                type="text"
                width="46%"
              />
              <Input
                className=""
                height="4rem"
                inputRef={translation}
                label="German"
                placeholder="Translation"
                type="text"
                width="46%"
              />
            </Stack>
          </Paper>
        </form>
        <Paper className="mt-8">
          <Stack className="items-start justify-between">
            <h2 className="t-heading-l mb-4">All translations</h2>
            <Stack className="items-center" gap="3.2rem">
              <Checkbox checked={filter === "all"} onChange={(e) => setFilter("all")}>
                <span className="ml-2 inline-block">All</span>
              </Checkbox>
              <Checkbox checked={filter === "translated"} onChange={(e) => setFilter("translated")}>
                <span className="ml-2 inline-block">Translated</span>
              </Checkbox>
              <Checkbox checked={filter === "not translated"} onChange={(e) => setFilter("not translated")}>
                <span className="ml-2 inline-block">Not Translated</span>
              </Checkbox>
            </Stack>
          </Stack>
          <Table
            className="mt-8  h-100"
            data={filterTranslations(translations)}
            editable={true}
            headers={headers}
            idColumnName="word"
            onBlur={handleBlur}
            rowRemoveable={false}
          />
        </Paper>
        <CustomModal className="relative" height="21.6rem" modalOpen={modalOpen} setModalOpen={setModalOpen}>
          <p className="t-heading-m text-gray-800">Please change language to EN to see the words correctly.</p>
          <ButtonNew
            className="absolute left-1/2 mt-8 capitalize"
            onClick={() => i18next.changeLanguage("en")}
            size="md"
            style={{ transform: "translateX(-50%)" }}
            type="button"
            variant="primary"
          >
            Change language
          </ButtonNew>
        </CustomModal>
      </div>
    </LoadingOrEmptyWrapper>
  );
};
