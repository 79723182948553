export default {
  nav: {
    "building automation": "Gebäudeautomation",
    "building automationDetails": "Gebäudeautomationdetails",
    "building automationFilesDownload": "Alle Gebäudeautomationdateien herunterladen",
    "building envelope": "Building envelope",
    "cooling system": "Kühlsystem",
    "cooling systemDetails": "Kühlsystemdetails",
    "cooling systemFilesDownload": "Alle Kühlsystemdateien herunterladen",
    energyMix: "Energiemix",
    "energy supply system": "Energieversorgungssystem",
    "heating system": "Heizsystem",
    "heating systemDetails": "Heizsystemdetails",
    "heating systemFilesDownload": "Alle Heizsystemdateien herunterladen",

    "hot water": "Warmwassersystem",
    "hot waterDetails": "Warmwassersystemdetails",
    "hot waterFilesDownload": "Alle Warmwassersystemdateien herunterladen",
    other: "Weiteres",
    "PV system": "PV Anlage",
    "PV systemDetails": "PV Anlagedetails",
    "PV systemFilesDownload": "Alle PV Anlagedateien herunterladen",
    rooftop: "Dach",
    rooftopDetails: "Dachdetails",

    rooftopFilesDownload: "Alle Dachdateien herunterladen",
    "shading system": "Beschattungssystem",
    "shading systemDetails": "Beschattungssystemdetails",
    "shading systemFilesDownload": "Alle Beschattungssystemdateien herunterladen",
    wall: "Wand",
    wallDetails: "Wanddetails",
    wallFilesDownload: "Alle Wanddateien herunterladen",
    windows: "Fenster",
    // TODO: quality check needed.
    windowsDetails: "Fensterdetails",
    // TODO: quality check needed.
    windowsFilesDownload: "Alle Fensterdateien herunterladen",
  },
  noInfoAvailable: "Es liegen keine Informationen vor!",
  table: {
    columns: {
      Area: "Fläche",
      Colour: "Farbe",
      "Created at": "Created at",
      Density: "Dichte",
      Description: "Beschreibung",
      files: "Datei",
      Files: "Datei",
      gValue: "G-Wert",
      "Heat capacity": "Wärmekapazität",
      Id: "Id",
      Installed: "Installed",
      lastChecked: "Zuletzt überprüft",
      Layer: "Schicht",
      Modernization: "Modernisierung",
      numberOfWindows: "Anzahl der Fenster",
      "Thermal conductivity": "Wärmeleitfähigkeit",

      Thickness: "Dicke",
      "Updated at": "Updated at",
      uValue: "U-Wert",
      windowType: "Fenstertyp",
    },
    title: {},
  },
};
