import React from "react";
import styled from "styled-components";

import { Icon } from "./Icon";

export const SecondaryNavLink = ({ children, className, isActive, isDisabled, onClick }) => {
  if (!isActive)
    return (
      <Wrapper
        className={`t-body-xl-400 ${className}`}
        disabled={isDisabled}
        isActive={isActive}
        onClick={(event) => {
          event.currentTarget.blur();
        }}
      >
        <span>{children}</span>
      </Wrapper>
    );
  else {
    return (
      <Wrapper
        className={`t-body-xl-400 ${className}`}
        disabled={isDisabled}
        isActive={isActive}
        onClick={(event) => {
          event.currentTarget.blur();
        }}
      >
        <span>{children}</span>
        <Icon color="white" iconName="ChevronRight" size="md" />
      </Wrapper>
    );
  }
};

const Wrapper = styled("button")(({ isActive }) => ({
  ".home__icon path": {
    stroke: "var(--clr-white)",
  },
  ":disabled": {
    color: "var(--clr-gray-300)",
  },
  ":focus": {
    background: "var(--clr-secondary-blue-50)",
    border: "2px solid var(--clr-secondary-blue-200)",
    outline: "none",
  },
  ":hover:enabled": {
    background: `${isActive ? "var(--clr-secondary-blue-500)" : "var(--clr-secondary-blue-50)"}`,
    cursor: "pointer",
  },
  alignItems: "center",
  backgroundColor: `${isActive ? "var(--clr-secondary-blue-500)" : "transparent"}`,
  border: "2px solid transparent",
  // border: "none",
  borderRadius: "4px",
  color: `${isActive ? "var(--clr-white)" : "var(--clr-secondary-blue-500)"}`,
  display: "flex",
  justifyContent: "space-between",
  outline: "none",
  padding: "1rem 0.8rem 1rem 1.2rem",
  "span:first-letter": {
    textTransform: "uppercase",
  },
  textAlign: "left",
}));
