export default {
  adding: "Adding",
  addNewChart: "add new chart",
  apply: "Apply",
  browse: "Browse",
  cancel: "cancel",
  colors: "colors",
  delete: "Delete",
  deleteConfirmMessage: "Are you sure to delete?",
  deleting: "Deleting",
  downloadFileProblem: "Sorry! There was a problem downloading the files.",
  dragToUpload: "drag & drop your picture or browse to upload",
  dragToUploadFile: "Drag & drop your file or browse to upload.",
  duplicate: "Duplicate",
  edit: "Edit",
  failedReset: "Reset failed. Please try again later!",
  failedSave: "Save failed. Please try again later!",
  form: {
    filledFieldsConstraint: "{{requiredFields}} must be filled!",
  },
  getDataProblem: "There was a problem getting data!",
  loadingDataPleaseWait: "Loading data, please wait...",
  noData: "No Data!",
  noRecords: "There are no records to show!",
  OK: "Okay",
  painting: "Painting",
  perM2: "Per m²",
  processingData: "Processing Data",
  resetAnyway: "Reset anyway",
  resetToDefault: "reset To Default",
  run: "Run",
  save: "save",
  sorry: "sorry",
  submit: "Submit",
  successfulFileUpload: "Your file has successfully been uploaded!",
  successfulImgaeUpload: "your image has successfully been uploaded!",
  successfulReset: "Reset successfully!",
  successfulSave: "Saved successfully!",
  upload: "upload",
  uploadFiles: "upload files",
  uploadImage: "upload image",
  uploadingFile: "uploadingFile",
  yes: "Yes",
};
