export default {
  buildingPassport: "Building passport",
  "building profile": "building profile",
  certificates: "certificates",
  downloadAllFiles: "Download all files",
  downloadScenarios: "Download scenarios",
  "Energy & CO₂": "Energy & CO₂",
  energyConsultant: "Energy consultant",
  modernization: "modernization",
  "technical Data": "technical Data",
  threeDViewer: "3D viewer",
  uploadFiles: "Upload files",
};
