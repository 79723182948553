import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState } from "react";
import styled from "styled-components";

import SearchIcon from "../Asset/Icons/search.svg";
import { Icon } from "./Icon";
export const Input = ({
  className,
  endAdornment,
  height,
  id,
  inputRef,
  label,
  onChange,
  placeholder,
  type,
  value,
  width,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(!value);
  const [HasError, setHasError] = useState(false);
  const inputBorderColor = HasError ? "var(--clr-mystic-red)" : isEmpty ? "var(--clr-gray-400)" : "var(--clr-gray-900)";

  const inputLabelColor = HasError ? "var(--clr-mystic-red)" : "var(--clr-gray-600)";
  return (
    <span
      className={className}
      style={{
        display: "block",
        width: width,
      }}
      {...props}
    >
      <CustomTextField
        autoComplete="on"
        bordercolor={inputBorderColor}
        displayshrinkedlabel={type === "search" ? "false" : "true"}
        fullWidth
        height={height}
        id={id}
        InputLabelProps={{
          style: {
            color: inputLabelColor,
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontWeight: 600,
            letterSpacing: "0.025rem",
            lineHeight: "1.4rem",
            textTransform: "capitalize",
            transform: type === "search" ? "translate(1.2rem, 1.4rem)" : "",
          },
        }}
        InputProps={endAdornment ? { endAdornment } : null}
        inputRef={inputRef}
        label={label}
        labelcolor={inputLabelColor}
        onChange={(e) => {
          if (inputRef)
            if (inputRef.current === "") setIsEmpty(true);
            else {
              setIsEmpty(inputRef.current.value === "");
            }
          else setIsEmpty(e.target.value === "");
          if (onChange) onChange(e);
        }}
        placeholder={placeholder}
        textcolor={inputBorderColor}
        type={`${type === "search" ? "" : type}`}
        value={value}
        variant="filled"
      />
    </span>
  );
};

export const PasswordInput = ({ className, height, id, inputRef, label, onChange, placeholder, width }) => {
  const [showPassword, setShowPassword] = useState(false);
  const endAdornment = showPassword ? "on" : "off";
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Input
      className={className}
      endAdornment={
        <InputAdornment position="end">
          {showPassword ? (
            <Icon
              className="cursor-pointer"
              iconName="EyeClose"
              onClick={handleClickShowPassword}
              size="sm"
              svgClassName="w-4 h-4"
            />
          ) : (
            <Icon
              className="cursor-pointer"
              iconName="EyeOpen"
              onClick={handleClickShowPassword}
              size="sm"
              svgClassName="w-4 h-4"
            />
          )}
        </InputAdornment>
      }
      height={height}
      id={id}
      inputRef={inputRef}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      type={showPassword ? "text" : "password"}
      width={width}
    />
  );
};

export const SearchInput = ({
  className,
  height,
  id,
  label,
  onBlur,
  onChange,
  placeholder,
  style,
  type,
  value,
  width,
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [HasError, setHasError] = useState(false);
  const inputBorderColor = HasError ? "var(--clr-mystic-red)" : isEmpty ? "var(--clr-gray-400)" : "var(--clr-gray-900)";

  const inputLabelColor = HasError ? "var(--clr-mystic-red)" : "var(--clr-gray-600)";
  return (
    <span
      className={className}
      style={{
        display: "block",
        width: width,
        ...style,
      }}
    >
      <CustomTextField
        bordercolor={inputBorderColor}
        displayshrinkedlabel={type === "search" ? "false" : "true"}
        fullWidth
        height={height}
        id={id}
        InputLabelProps={{
          style: {
            color: inputLabelColor,
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontWeight: 600,
            letterSpacing: "0.025rem",
            lineHeight: "1.4rem",
            textTransform: "capitalize",
            transform: type === "search" ? "translate(1.2rem, 1.4rem)" : "",
          },
        }}
        InputProps={
          type === "search"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <img alt="Search Icon" src={SearchIcon} />
                  </InputAdornment>
                ),
              }
            : null
        }
        label={label}
        labelcolor={inputLabelColor}
        onBlur={() => {
          if (onBlur) onBlur();
        }}
        onChange={(e) => {
          setIsEmpty(e.target.value === "");
          if (onChange) onChange(e);
        }}
        placeholder={placeholder}
        textcolor={inputBorderColor}
        type={`${type === "search" ? "" : type}`}
        value={value}
        variant="filled"
      />
    </span>
  );
};

export const LabelLessInput = ({
  autoFocus,
  className,
  height,
  id,
  onBlur,
  onChange,
  onKeyDown,
  placeholder,
  style,
  type,
  value,
  width,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [HasError, setHasError] = useState(false);
  const inputBorderColor = HasError ? "var(--clr-mystic-red)" : isEmpty ? "var(--clr-gray-400)" : "var(--clr-gray-900)";

  const textColor = "var(--clr-gray-900)";

  return (
    <span
      className={className}
      style={{
        display: "block",
        width: width,
        ...style,
      }}
      {...props}
    >
      <LabelLessTextField
        autoFocus={autoFocus}
        bordercolor={inputBorderColor}
        displayshrinkedlabel="false"
        fullWidth
        height={height}
        id={id}
        onBlur={onBlur}
        onChange={(e) => {
          setIsEmpty(e.target.value === "");
          if (onChange) onChange(e);
        }}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        textcolor={textColor}
        type={type}
        value={value}
        variant="filled"
      />
    </span>
  );
};

export const ColorInput = ({ className, color, inputRef, setColor }) => {
  return (
    <input
      className={`block  cursor-pointer ${className}`}
      onChange={(e) => setColor(e.target.value)}
      ref={inputRef}
      type="color"
      value={color}
    />
  );
};

const CustomTextField = styled(TextField)(({ bordercolor, displayshrinkedlabel, height, labelcolor, textcolor }) => ({
  "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
    color: labelcolor,
    fontFamily: "Nunito Sans",
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: "0.025rem",
    lineHeight: "1.4rem",
  },
  "& .MuiInputBase-input.MuiFilledInput-input": {
    "&:before, &:after": {
      border: "none",
    },

    transform: displayshrinkedlabel === "true" ? "" : "translate(0rem, -0.8rem)",
  },
  "& .MuiInputBase-root.MuiFilledInput-root": {
    "&.Mui-focused": {
      border: "1px solid var(--clr-leaftech-blue-500)",
    },
    "&:after": {
      border: "none",
    },
    "&:before, &:after": {
      border: "none",
    },
    "&:hover": {
      border: "1px solid var(--clr-secondary-blue-200)",
      boxShadow:
        "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
    },
    backgroundColor: "var(--clr-white)",
    border: `1px solid ${bordercolor}`,
    borderRadius: "7px",
    color: textcolor,
    fontFamily: "Nunito Sans",

    fontSize: "1.4rem",
    fontWeight: "400",
    height: height,
    letterSpacing: "0rem",
    lineHeight: "1.9rem",
  },
  "& .MuiInputLabel-shrink": {
    display: displayshrinkedlabel === "true" ? "block" : "none",
  },
  "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,    input:-webkit-autofill:active":
    {
      transition: "background-color 720000s ease-in-out 0s",
    },
}));

const LabelLessTextField = styled(TextField)(({ bordercolor, displayshrinkedlabel, height, textcolor }) => ({
  "& .MuiInputBase-input.MuiFilledInput-input": {
    "&:before, &:after": {
      border: "none",
    },

    transform: displayshrinkedlabel === "true" ? "" : "translate(0rem, -0.8rem)",
  },
  "& .MuiInputBase-root.MuiFilledInput-root": {
    "&.Mui-focused": {
      border: "1px solid var(--clr-leaftech-blue-500)",
    },
    "&:after": {
      border: "none",
    },
    "&:before, &:after": {
      border: "none",
    },
    "&:hover": {
      border: "1px solid var(--clr-secondary-blue-200)",
      boxShadow:
        "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
    },
    backgroundColor: "var(--clr-white)",
    border: `1px solid ${bordercolor}`,
    borderRadius: "7px",
    color: textcolor,
    fontFamily: "Nunito Sans",

    fontSize: "1.4rem",
    fontWeight: "400",
    height: height,
    letterSpacing: "0rem",
    lineHeight: "1.9rem",
  },
  "& .MuiInputLabel-shrink": {
    display: displayshrinkedlabel === "true" ? "block" : "none",
  },
  "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,    input:-webkit-autofill:active":
    {
      transition: "background-color 72000s ease-in-out 0s",
    },
}));
