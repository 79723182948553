import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { Wizard } from "../components/Modernization/Wizard";
import { WizardContextProvider } from "../context/Wizard";
import { eventNames, trackGAEvent } from "../functions/googleAnalytics";

export const Modernization = () => {
  const { projectId } = useParams();

  useEffect(() => {
    trackGAEvent(eventNames.MODERN_VIEW);
  }, []);

  return (
    <>
      <Helmet>
        <title>Modernization | Leaftech</title>
      </Helmet>
      <WizardContextProvider projectId={projectId}>
        <Wizard projectId={projectId} />
      </WizardContextProvider>
    </>
  );
};
