import { useState } from "react";

export const DropdownSingleSelectNative = ({
  className,
  disabled,
  displayOptions,
  disposeAfterSelection,
  label,
  onChange,
  options,
  placeholder,
  selectedValue,
  setSelectedValue,
  style,
}) => {
  if (!displayOptions) displayOptions = options;
  const [internalValue, setInternalValue] = useState(selectedValue);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(value);
    if (onChange) onChange(event);

    if (disposeAfterSelection) setInternalValue("");
  };

  return (
    <div className={`w-full ${className}`} style={style}>
      <label htmlFor="a">{label}</label>
      <select
        className={`w-full border-none bg-transparent px-2 ${disabled ? "" : "cursor-pointer"}`}
        disabled={disabled}
        onChange={handleChange}
        value={disposeAfterSelection ? internalValue : selectedValue}
      >
        {placeholder && (
          <option hidden selected value="">
            {placeholder}
          </option>
        )}
        {selectedValue && (
          <option disabled hidden value={selectedValue}>
            {selectedValue}
          </option>
        )}
        {options.map((_, index) => {
          return <option value={options[index]}> {displayOptions[index]}</option>;
        })}
      </select>
    </div>
  );
};
