export default {
  assetCard: {
    emission: "EMISSIONEN",
    epc: "epc",
    pending: "In bearbeitung",
  },

  filters: {
    buildings: "Immobilien",
    certification: "Zertifizierung",
    "construction year": "Baujahr",
    "energy class": "Energieklasse",
    location: "Standort",
    resetFilters: "Filter zurücksetzen",
    search: "Suche",
    type: "Kategorie",
  },
  noBuilding: "Kein Gebäude kann angezeigt werden.",
  title: "Ihre Liegenschaften",
};
