import { formatNumberBasedOnUser } from "../../utils/namesManipulation";

export const ColorBar = ({ className, colorMax, colorMin, max, min }) => {
  return (
    <div className={`fixed right-5 top-1/2 flex h-3/4 w-8 -translate-y-1/2 flex-col items-center ${className}`}>
      <div className="t-subheading-m mb-1">{formatNumberBasedOnUser(max)}</div>
      <div
        className="h-full w-5 rounded-md"
        style={{
          background: `linear-gradient(#f00, #ff0, #0f0)`,
        }}
       />
      <div className="t-subheading-m mt-1">{formatNumberBasedOnUser(min)}</div>
    </div>
  );
};
