import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import { DateRangePicker } from "rsuite";

export const DateRangePickerNew = (props) => {
  let { className, maxDate, minDate } = props;
  minDate = new Date(minDate);
  maxDate = new Date(maxDate);

  const predefinedRanges = [
    {
      disabled: new Date() < minDate || new Date() > maxDate, //To be consistent with other items.
      label: "Today",
      placement: "left",
      value: [new Date(), new Date()],
    },
    {
      disabled: addDays(new Date(), -1) < minDate || addDays(new Date(), -1) > maxDate, //To be consistent with other items.
      label: "Yesterday",
      placement: "left",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      disabled: endOfWeek(new Date()) < minDate || startOfWeek(new Date()) > maxDate,
      label: "This week",
      placement: "left",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
    },
    {
      disabled: new Date() < minDate || subDays(new Date(), 6) > maxDate,
      label: "Last 7 days",
      placement: "left",
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      disabled: new Date() < minDate || subDays(new Date(), 29) > maxDate,
      label: "Last 30 days",
      placement: "left",
      value: [subDays(new Date(), 29), new Date()],
    },
    {
      disabled: new Date() < minDate || startOfMonth(new Date()) > maxDate,
      label: "This month",
      placement: "left",
      value: [startOfMonth(new Date()), new Date()],
    },
    {
      disabled: endOfMonth(addMonths(new Date(), -1)) < minDate || startOfMonth(addMonths(new Date(), -1)) > maxDate,
      label: "Last month",
      placement: "left",
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    },
    {
      disabled: new Date() < minDate || new Date(new Date().getFullYear(), 0, 1) > maxDate,
      label: "This year",
      placement: "left",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    },
    {
      disabled:
        new Date(new Date().getFullYear(), 0, 0) < minDate || new Date(new Date().getFullYear() - 1, 0, 1) > maxDate,
      label: "Last year",
      placement: "left",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    },
    {
      label: "All time",
      placement: "left",
      value: [new Date(minDate || "2000-01-01"), new Date(maxDate || new Date())],
    },
  ];

  return <DateRangePicker className={className} editable={true} ranges={predefinedRanges} {...props} />;
};
