import { sum } from "lodash";
import { useRef, useState } from "react";

import { chartTypesEnums, colorCategoriesEnums, valuesEnums } from "../enums/dynamicDashboard";
import { capitalizeFirstLetter, formatNumberBasedOnUser } from "./namesManipulation";

export const handleConfigChange = (index, diagrams, key, newValue, updateChart) => {
  const newConfig = { ...diagrams[index], [key]: newValue };
  const updatedConfig = diagrams?.map((diagram, i) => {
    return i !== index ? diagram : newConfig;
  });
  updateChart("diagrams", updatedConfig);
};

export const diagramInitialConfig = {
  color: "black",
  dataKey: null,
  dataPoint: null,
  diagramType: null,
  direction: "horizontal",
  partitions: null,
  percentageBased: false,
  stacks: null,
  title: null,
};

export const settingsOptions = Object.freeze({
  AGGREGATE_X_AXIS: "aggregateXAxis",
  COLOR: "color",
  COLOR_BAR_RANGE: "colorBarRange",
  DATA_POINT: "dataPoint",
  DIAGRAM_TYPE: "diagramType",
  DIRECTION: "direction",
  HEIGHT_100: "height100",
  INVERT_X_AXIS: "invertXAxis",
  INVERT_Y_AXIS: "invertYAxis",
  IS_WORKING_DAY: "isWorkingDay",
  IS_WORKING_HOUR: "isWorkingHour",
  LEGENDS_POSITION: "legendsPosition",
  PARTITIONS: "partitions",
  PER_M2: "perM2",
  QUANTITY: "quantity",

  SHOW_PIE_LABELS: "showPieLabels",
  SORT_BY: "sortBy",
  STACKS: "stacks",
  SUB_DATAPOINT: "subDataPoint",
  TITLE: "title",
  X: "x",
  Y: "y",
});

export const aggregatedSumValue = ({ aggregateColumns, data, groupByColumns }) => {
  if (!data?.length > 0) return;
  if (!groupByColumns?.filter((e) => e !== null && e !== undefined)?.length) {
    return [
      data?.reduce((acc, cur) => {
        aggregateColumns.forEach((col) => {
          acc[col] = (acc[col] || 0) + (cur[col] || 0);
        });
        return acc;
      }, {}),
    ];
  }

  return Object.values(
    data?.reduce((acc, cur) => {
      const key = groupByColumns?.map((col) => cur[col]).join("|");
      if (!acc[key]) {
        acc[key] = Object.fromEntries(groupByColumns?.map((col) => [col, cur[col]]));
        aggregateColumns.forEach((col) => {
          acc[key][col] = cur[col];
        });
      } else {
        aggregateColumns.forEach((col) => {
          acc[key][col] += cur[col] || 0;
        });
      }
      return acc;
    }, {})
  );
};

export const pivotData = (data, pivotColumn, valueColumn) => {
  if (!data?.length || !pivotColumn) return [];
  const pivotedData = {};
  const keyColumns = Object.keys(data[0]).filter((col) => col !== pivotColumn && col !== valueColumn);
  data?.forEach((entry) => {
    const key = keyColumns?.map((col) => entry[col]).join("-");
    if (!pivotedData[key]) {
      pivotedData[key] = {};
      keyColumns?.forEach((col) => {
        pivotedData[key][col] = entry[col];
      });
    }
    pivotedData[key][entry[pivotColumn]] = entry[valueColumn];
  });
  return Object.values(pivotedData);
};

export const useDynamicDashboard = ({ chartsConfigs, defaultChart, defaultDashboardConfigs }) => {
  const [allChartsConfigs, setAllChartsConfigs] = useState(chartsConfigs);
  const scrollRef = useRef();

  const addChart = () => {
    const maxY = allChartsConfigs?.length ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h)) : -1;
    const maxId = allChartsConfigs?.length ? Math.max(...allChartsConfigs?.map((l) => l.id)) : -1;
    const newChart = {
      ...defaultChart,
      id: maxId + 1,
      y: maxY + 1,
    };

    setAllChartsConfigs([...allChartsConfigs, newChart]);
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const removeChart = (id) => {
    setAllChartsConfigs((allChartsConfigs) => allChartsConfigs.filter((l) => l.id !== id));
  };

  const updateChart = (id, key, value) => {
    setAllChartsConfigs((allChartsConfigs) => {
      const updatedCharts = allChartsConfigs.map((l) => {
        if (l.id !== id) return l;
        else return { ...l, [key]: value };
      });
      return updatedCharts;
    });
  };

  const resetDashboardConfigs = () => {
    setAllChartsConfigs(defaultDashboardConfigs);
  };

  return {
    addChart,
    allChartsConfigs,
    removeChart,
    resetDashboardConfigs,
    scrollRef,
    setAllChartsConfigs,
    updateChart,
  };
};

export const useDynamicDashboardMultiTab = ({ allTabschartsConfigs, defaultChart, defaultDashboardConfigs }) => {
  const [allTabsAllChartsConfigs, setAllTabsAllChartsConfigs] = useState(allTabschartsConfigs);
  const scrollRef = useRef();

  const tabAddChart = (tabIndex) => () => {
    const tabAllChartsConfigs = allTabsAllChartsConfigs[tabIndex];
    const maxY = tabAllChartsConfigs?.length ? Math.max(...tabAllChartsConfigs?.map((l) => l.y + l.h)) : -1;
    const maxId = tabAllChartsConfigs?.length ? Math.max(...tabAllChartsConfigs?.map((l) => l.id)) : -1;
    const newChart = {
      ...defaultChart,
      id: maxId + 1,
      y: maxY + 1,
    };

    const newAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
      if (index !== tabIndex) return item;
      return [...item, newChart];
    });

    setAllTabsAllChartsConfigs(newAllTabsChartsConfigs);
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const tabRemoveChart = (tabIndex) => (id) => {
    setAllTabsAllChartsConfigs((allTabsAllChartsConfigs) => {
      const filteredAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
        if (index !== tabIndex) return item;
        return item.filter((l) => l.id !== id);
      });
      return filteredAllTabsChartsConfigs;
    });
  };

  const tabUpdateChart = (tabIndex) => (id, key, value) => {
    setAllTabsAllChartsConfigs((allTabsAllChartsConfigs) => {
      const updatedAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
        if (index !== tabIndex) return item;
        const updatedCharts = item.map((l) => {
          if (l.id !== id) return l;
          else return { ...l, [key]: value };
        });
        return updatedCharts;
      });
    });
  };

  const resetDashboardConfigs = () => {
    setAllTabsAllChartsConfigs(defaultDashboardConfigs);
  };

  return {
    allTabsAllChartsConfigs,
    resetDashboardConfigs,
    scrollRef,
    setAllTabsAllChartsConfigs,
    tabAddChart,
    tabRemoveChart,
    tabUpdateChart,
  };
};

export const getMapPointColor = ({ colorScale, max, min, opacity = 1, value }) => {
  value = Math.max(min, Math.min(value, max));

  const ratio = min === max ? 1 : (value - min) / (max - min);
  if (colorScale) {
    const scaled = colorScale(ratio);
    const rgb = scaled._rgb;

    const r = Math.round(255 * (1 - ratio));
    const g = Math.round(255 * ratio);
    const b = 0;

    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
  } else return "rgb(0,0,0,1)";
};

export const getAxisNameWithUnit = ({ dataKey, unit }) => {
  return `${dataKey ? dataKey : ""} ${unit ? "(" + unit + ")" : ""}`;
};

export const sortData = ({ data, dataKey, decsending }) => {
  if (!data?.length || !dataKey) return data;
  const sorted = [...data];
  sorted.sort((a, b) => {
    if (decsending) return a[dataKey] < b[dataKey] ? 1 : -1;
    else return a[dataKey] < b[dataKey] ? -1 : 1;
  });
  return sorted;
};

export const sortDataAccumulative = ({ data, dataKeys, decsending }) => {
  if (!data?.length || !dataKeys?.length) return data;
  const sorted = [...data];
  sorted.sort((a, b) => {
    const accA = sum(dataKeys.map((key) => a[key]));
    const accB = sum(dataKeys.map((key) => b[key]));
    if (decsending) return accA < accB ? 1 : -1;
    else return accA < accB ? -1 : 1;
  });
  return sorted;
};

const predefinedSteps = [
  0.001, 0.002, 0.005, 0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2000, 5000, 10000,
  25000, 50000, 100000, 250000, 500000, 1000000, 2500000, 5000000, 10000000, 25000000, 50000000,
];

export const generateAxisValues = (minValue, maxValue, step) => {
  if (minValue === undefined || maxValue === undefined) return;
  let bestStep;
  const range = maxValue - minValue;
  if (step !== undefined) bestStep = step;
  else {
    const ticksNos = [...Array(5).keys()]?.map((e) => e + 5);
    const upperBounds = predefinedSteps?.map((step) => {
      for (const tickNo of ticksNos) {
        if (step * tickNo > range) return step * tickNo;
      }
      return null;
    });
    const bestStepIndex = upperBounds.indexOf(Math.min(...upperBounds.filter((e) => e !== null)));
    bestStep = predefinedSteps[bestStepIndex];
  }
  if (bestStep === undefined || bestStep === null) return;
  const axisValues = [];
  const roundedMinValue = Math.floor(minValue / bestStep) * bestStep;
  for (let i = 0; i <= Math.ceil(range / bestStep) + 2; i++) {
    axisValues.push(roundedMinValue + i * bestStep);
    if (roundedMinValue + i * bestStep >= maxValue) break;
  }
  return axisValues;
};

export const getColorEntity = ({ chartType, diagrams, quantity }) => {
  let categoryName, subcategoryName;
  let entityName = typeof quantity === "string" ? quantity || valuesEnums.NO_DATA : quantity ?? valuesEnums.NO_DATA;

  if (!chartType || !diagrams) return { categoryName, entityName, subcategoryName };

  let isMixed, isStacked, partitions, stacks;
  if (diagrams?.length) {
    stacks = diagrams[0]?.stacks;
    partitions = diagrams[0]?.partitions;
    isMixed = partitions && stacks;
    isStacked = partitions || stacks;
  }

  if ([chartTypesEnums.COMPOSED, chartTypesEnums.HEATMAP, chartTypesEnums.HISTOGRAM].includes(chartType)) {
    categoryName = colorCategoriesEnums.COLUMNS;
  }

  if ([chartTypesEnums.AREA, chartTypesEnums.LINE].includes(chartType)) {
    categoryName = partitions ? colorCategoriesEnums.VALUES : colorCategoriesEnums.COLUMNS;
    subcategoryName = partitions ? partitions : "";
  }

  if ([chartTypesEnums.BAR, chartTypesEnums.DOUGHNUT, chartTypesEnums.KPI, chartTypesEnums.PIE].includes(chartType)) {
    if (isMixed) {
      categoryName = colorCategoriesEnums.MIXED;
      subcategoryName = `(${stacks})-(${partitions})`;
    } else if (isStacked) {
      categoryName = colorCategoriesEnums.VALUES;
      subcategoryName = stacks ?? partitions;
    } else {
      categoryName = colorCategoriesEnums.COLUMNS;
      subcategoryName = "";
    }
  }
  return { categoryName, entityName, subcategoryName };
};

export const extractPartitionsAndStacksName = (input) => {
  const pattern = /^\((\w+)\)-\((\w+)\)$/;
  const match = input.match(pattern);

  if (match?.length > 2) {
    return [match[1], match[2]];
  }
  return null;
};

export const getColumnDisplayName = ({ colName, dataMapping }) => {
  try {
    const defaultOutput = dataMapping[colName];
    if (defaultOutput !== undefined && defaultOutput !== null) return defaultOutput;
    if (colName !== undefined && colName !== null) {
      const partitionStacked = extractPartitionsAndStacksName(colName);
      if (partitionStacked?.length > 1) {
        const stack = dataMapping[partitionStacked[0]] ?? capitalizeFirstLetter(partitionStacked[0]);
        const partition = dataMapping[partitionStacked[1]] ?? capitalizeFirstLetter(partitionStacked[1]);
        return partition && stack ? `(${stack})-(${partition})` : capitalizeFirstLetter(colName);
      } else if (colName.includes("___") && colName.includes(":")) {
        return getSeriesMixedDisplayName({ dataMapping, seriesMixedName: colName });
      }
      return capitalizeFirstLetter(colName);
    }
  } catch {
    return colName;
  }
};

export const getSeriesMixedName = ({ filters, seriesName }) => {
  const hasFilters = filters?.length;
  if (!hasFilters) return seriesName;
  else {
    const filtersDisplayName = filters?.map(
      ([filterName, filterValue]) => `${filterName.replace("diagramFilter_", "")}:${filterValue}`
    );
    return seriesName + "___" + filtersDisplayName?.join("___");
  }
};

export const getSeriesMixedDisplayName = ({ dataMapping, seriesMixedName }) => {
  let seriesParts = seriesMixedName.split("___");
  if (seriesParts?.length > 1) {
    const qunatity = seriesParts.shift();
    const filtersDisplayName = seriesParts?.map((filter) => {
      const [filterName, filterValue] = filter.split(":");
      return `${capitalizeFirstLetter(dataMapping[filterName] ?? filterName)}:${capitalizeFirstLetter(
        dataMapping[filterValue] ?? filterValue
      )}`;
    });
    return capitalizeFirstLetter(dataMapping[qunatity] ?? qunatity) + "___" + filtersDisplayName?.join("___");
  } else {
    return seriesMixedName;
  }
};

export const getDataKeyFromDiagramFilterName = ({ filterName }) => {
  return filterName.replace(valuesEnums.DIAGRAM_FILTER_PREFIX, "");
};

const relevantConfigOptions = {
  [chartTypesEnums.AREA]: [
    settingsOptions.TITLE,
    settingsOptions.DATA_POINT,
    settingsOptions.SUB_DATAPOINT,
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.HEIGHT_100,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.SORT_BY,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.BAR]: [
    settingsOptions.TITLE,
    settingsOptions.DATA_POINT,
    settingsOptions.SUB_DATAPOINT,
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.SORT_BY,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.COMPOSED]: [
    settingsOptions.TITLE,
    settingsOptions.DATA_POINT,
    settingsOptions.X,
    settingsOptions.QUANTITY,
    settingsOptions.DIAGRAM_TYPE,
    settingsOptions.SORT_BY,
    settingsOptions.COLOR,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.AGGREGATE_X_AXIS,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.DOUGHNUT]: [
    settingsOptions.TITLE,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DATA_POINT,
    settingsOptions.SUB_DATAPOINT,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.SHOW_PIE_LABELS,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.HEATMAP]: [
    settingsOptions.TITLE,
    settingsOptions.DATA_POINT,
    settingsOptions.SUB_DATAPOINT,
    settingsOptions.X,
    settingsOptions.Y,
    settingsOptions.COLOR,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.HISTOGRAM]: [
    settingsOptions.TITLE,
    settingsOptions.QUANTITY,
    settingsOptions.SORT_BY,
    settingsOptions.COLOR,
    settingsOptions.DATA_POINT,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.KPI]: [
    settingsOptions.TITLE,
    settingsOptions.DATA_POINT,
    settingsOptions.SUB_DATAPOINT,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DIRECTION,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.LINE]: [
    settingsOptions.TITLE,
    settingsOptions.DATA_POINT,
    settingsOptions.SUB_DATAPOINT,
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.SORT_BY,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.PER_M2,
  ],
  [chartTypesEnums.PIE]: [
    settingsOptions.TITLE,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DATA_POINT,
    settingsOptions.SUB_DATAPOINT,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.SHOW_PIE_LABELS,
    settingsOptions.PER_M2,
  ],
};

export const isRelevantOption = (chartType, option, index) => {
  if (index > 0 && chartType !== chartTypesEnums.HISTOGRAM && chartType !== chartTypesEnums.COMPOSED) return false;
  return relevantConfigOptions[chartType]?.includes(option);
};

export const getArrayLongestElement = (array) => {
  if (!array?.filter((e) => e !== undefined && e !== null)) return;
  if (array?.some((e) => typeof e === "string"))
    return array?.reduce((total, current) => (total?.length > current?.length ? total : current), "");
  else if (array?.some((e) => typeof e === "boolean")) return 5;
  else if (array?.some((e) => typeof e === "number")) return Math.max(...array?.map((s) => s ?? 0));
  else return 5;
};

export const dynamicDashboardGetRelatedItems = ({ data, hoveredItem }) => {
  if (!hoveredItem) return;
  const { config, params, point } = hoveredItem;
  const { chartType, diagrams, xAxis, yAxis } = config;
  let isMixed, isStacked, partitions, stacks;
  if (diagrams?.length) {
    stacks = diagrams[0]?.stacks;
    partitions = diagrams[0]?.partitions;
    isMixed = partitions && stacks;
    isStacked = partitions || stacks;
  }

  if (chartType === chartTypesEnums.MINI_MAP) {
    return [point];
  }
  if (chartType === chartTypesEnums.HISTOGRAM) {
    const { dataPoint, index, rangeString } = point;
    const valueRange = rangeString?.split("-")?.map((numString) => parseFloat(numString));
    const [min, max] = valueRange;
    return data.filter((row) => {
      const value = row[dataPoint];
      return (value > min || (value === min && index === 0)) && value <= max;
    });
  }
  return data?.filter((row) => areItemsSame({ chartType, diagrams, item1: point, item2: row, xAxis, yAxis }));
};

export const dynamicDashboardIsItemHovered = ({
  chartType,
  diagrams,
  hoveredItem,
  hoveredItemRelatedItems,
  IdColumnName,
  row,
  xAxis,
  yAxis,
}) => {
  let isMixed, isStacked, partitions, stacks;
  if (diagrams?.length) {
    stacks = diagrams[0]?.stacks;
    partitions = diagrams[0]?.partitions;
    isMixed = partitions && stacks;
    isStacked = partitions || stacks;
  }
  const relatedItems = hoveredItemRelatedItems;

  if (chartType === chartTypesEnums.MINI_MAP && hoveredItem?.config?.chartType === chartTypesEnums.MINI_MAP)
    return true;
  else if (chartType === chartTypesEnums.MINI_MAP) {
    return relatedItems?.some((item) => item[IdColumnName] === row?.[IdColumnName]);
  } else if (chartType === chartTypesEnums.HISTOGRAM) {
    const { dataPoint, index, rangeString } = row;
    const interval = rangeString?.split("-")?.map((numString) => parseFloat(numString));
    const [min, max] = interval;
    return relatedItems?.some((item) => {
      const value = item[dataPoint];
      return (value > min || (value === min && index === 0)) && value <= max;
    });
  } else {
    return relatedItems?.some((item) => areItemsSame({ chartType, diagrams, item1: item, item2: row, xAxis, yAxis }));
  }
};

const areItemsSame = ({ chartType, diagrams, item1, item2, xAxis, yAxis }) => {
  const { partitions, stacks } = diagrams?.[0];
  const settingsMapping = {
    [settingsOptions.PARTITIONS]: partitions,
    [settingsOptions.STACKS]: stacks,
    [settingsOptions.X]: xAxis,
    [settingsOptions.Y]: yAxis,
  };
  for (const [settingName, settingValue] of Object.entries(settingsMapping)) {
    if (isRelevantOption(chartType, settingName) && !(item1?.[settingValue] === item2?.[settingValue] || !settingValue))
      return false;
  }
  return true;
};

export const echartsDefaultTooltipFormatter = (value) => formatNumberBasedOnUser(value);
