import { CSSProperties, FC } from "react";

import { getEnergyClassItem } from "../functions/AssetOverview";

type EnergyEfficiencyProps = {
  className?: string;
  conf?: any;
  energyClass?: string;
  energyConsumption?: number;
  label?: string;
  size?: "lg" | "md" | "sm";
  style?: CSSProperties;
};

export const EnergyEfficiencyClass: FC<EnergyEfficiencyProps> = ({
  className,
  conf,
  energyClass,
  energyConsumption,
  label,
  size = "md",
  style,
}) => {
  const energyClassItem = getEnergyClassItem({ conf, energyClass, energyConsumption });
  if (!energyClassItem) return null;

  const bgColor = energyClassItem.bgColor as string;
  const textColor = energyClassItem.textColor as string;
  const energyLabel = energyClassItem.rating as string;

  return (
    <div
      className={className}
      style={{
        alignItems: "center",
        backgroundColor: bgColor,
        borderRadius: "5px",
        color: textColor,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        textAlign: "center",
        ...style,
      }}
    >
      <span className={`${size === "sm" ? "t-heading-s" : "t-heading-l2"}`}>
        {label && <span className="t-body-xl absolute left-2 top-1">{label}</span>}
        <span className="uppercase">{energyLabel}</span>
      </span>
    </div>
  );
};

export const EnergyEfficiencyText: FC<Omit<EnergyEfficiencyProps, "label" | "style">> = ({
  className,
  conf,
  energyClass,
  energyConsumption,
  size = "md",
}) => {
  const energyClassItem = getEnergyClassItem({ conf, energyClass, energyConsumption });
  if (!energyClassItem) return null;

  const textColor = energyClassItem.bgColor as string;
  const energyLabel = energyClassItem.rating as string;

  return (
    <span
      className={`${size === "sm" ? "t-heading-s" : size === "md" ? "t-heading-l" : "t-heading-l2"} ${className}`}
      style={{ color: textColor }}
    >
      {energyLabel}
    </span>
  );
};
