const arrayToUpperCase = (array) => {
  return array?.map((element) => {
    return typeof element === "string" ? element.toUpperCase() : element;
  });
};
const elementToUpperCase = (element) => {
  return typeof element === "string" ? element.toUpperCase() : element;
};

const isValueInFilterValues = (value, filterValues) => {
  return arrayToUpperCase(filterValues).includes(elementToUpperCase(value)) || filterValues?.length === 0;
};
const doesArrayIntersectWithFilterValues = (array, filterValues) => {
  return filterValues?.length === 0 || arrayIntersection(array, filterValues)?.length > 0;
};

const arrayIntersection = (array1, array2) => {
  if (!array1 || !array2) return [];
  const array2Uppercase = arrayToUpperCase(array2);
  return array1?.filter((element) => array2Uppercase?.includes(elementToUpperCase(element)));
};

export const filterDataBySinlgeValueFilter = (
  data,
  filterValues,
  lookUpField,
  nestedLookUpField = null,
  nullAlternative
) => {
  if (!data) return [];
  return data?.filter((row) => {
    if (!nestedLookUpField) {
      const dataValue = row[lookUpField] ? row[lookUpField] : nullAlternative;
      return isValueInFilterValues(dataValue, filterValues);
    } else {
      const nestedArray = row[lookUpField]?.map((nestedRow) => nestedRow[nestedLookUpField]);
      if (nestedArray && nestedArray.length === 0) nestedArray.push(nullAlternative);
      return doesArrayIntersectWithFilterValues(nestedArray, filterValues);
    }
  });
};
export const filterDataBySearchTerm = (data, searchTerm, lookUpField) => {
  if (!data) return [];
  if (searchTerm === "") return data;
  let filteredData = data?.filter((row) => {
    return elementToUpperCase(row[lookUpField]).includes(elementToUpperCase(searchTerm));
  });
  return filteredData;
};

const lowestUpperBound = (number, array) => {
  const allUpper = array?.filter((x) => x > number) || [];
  const upperBound = Math.min(...allUpper);
  return upperBound ? upperBound : "inf";
};

export const filterDataByYear = (data, filterValues, yearBreakPoints, lookUpField) => {
  if (!data) return [];
  return data?.filter((row) => {
    const dataYear = row[lookUpField];

    if (filterValues.length === 0) return true;
    if (!dataYear) return filterValues.includes(null);
    return lowestUpperBound(dataYear, filterValues) === lowestUpperBound(dataYear, yearBreakPoints);
  });
};
export const sumByKeys = ({ data, keys }) => {
  if (!data) return;
  const sumByKey = {};

  data?.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (keys?.includes(key)) {
        if (isNumeric(obj[key])) sumByKey[key] = (sumByKey[key] || 0) + obj[key];
      }
    });
  });
  return sumByKey;
};

export const minMaxByKey = ({ data, key }) => {
  if (!data) return;

  try {
    const minMax = data.reduce(
      (total, current) => {
        if (!total.min || current[key] < total.min) total = { ...total, min: current[key] };
        if (!total.max || current[key] > total.max) total = { ...total, max: current[key] };
        return total;
      },
      { max: data[0][key], min: data[0][key] }
    );
    return minMax;
  } catch (error) {
    return { max: null, min: null };
  }
};

export const sum = (array) => {
  if (!array?.length > 0) return;
  if (array?.length === 1) return array[0];
  return array[0] + sum(array.slice(1));
};

export const distinctFilter = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const getUniqueValuesFromJson = (data, key) => {
  return data?.map((row) => row[key])?.filter(distinctFilter);
};

export const findDataRange = ({ data, dataKeys, defaultMax, defaultMin }) => {
  const { max, min } = data?.reduce(
    (total, current) => {
      for (const dataKey of dataKeys) {
        if (total.min === null || (current[dataKey] !== null && current[dataKey] < total.min))
          total.min = current[dataKey];
        if (total.max === null || (current[dataKey] !== null && current[dataKey] > total.max))
          total.max = current[dataKey];
      }
      return total;
    },
    { max: null, min: null }
  );
  return { max: max ?? defaultMax, min: min ?? defaultMin };
};

export const updateTableCell = (tableData, rowId, dataKey, newValue, idColumnName) => {
  const updatedData = tableData?.map((row) => {
    if (row[idColumnName] === rowId) return { ...row, [dataKey]: newValue };
    else {
      return row;
    }
  });
  return updatedData;
};

export const filterDataToDateRange = ({ data, dateKey, endDate, startDate }) => {
  if (!data || data?.length === 0 || !startDate || !endDate || !dateKey) return data;
  const filteredData = data.filter((row) => {
    const date = new Date(row[dateKey]);
    return date <= endDate && date >= startDate;
  });
  return filteredData;
};

export const convertDailyToMonthly = ({ data, dateKey }) => {
  if (!data || data?.length === 0 || !dateKey) return data;

  const monthlyData = {};

  data.forEach((item) => {
    const date = new Date(item[dateKey]);
    const yearMonth = date.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    });

    if (!monthlyData[yearMonth]) {
      monthlyData[yearMonth] = { [dateKey]: yearMonth };
    }

    Object.entries(item).forEach(([field, value]) => {
      if (field !== dateKey) {
        monthlyData[yearMonth][field] = (monthlyData[yearMonth][field] || 0) + value;
      }
    });
  });
  return Object.values(monthlyData);
};

export const getArraysCrossPointIndex = (arr1, arr2) => {
  const diff = arr1.map((_, index) => arr1[index] - arr2[index]);
  const crossPointIndex = [...Array(diff?.length).keys()].find((_, index) => {
    return (diff[index] >= 0 && diff[index + 1] <= 0) || (diff[index] <= 0 && diff[index + 1] >= 0);
  });
  return crossPointIndex;
};

export const getSegmentsCrossPointCoords = (segA, segB) => {
  const [[xA1, yA1], [xA2, yA2]] = segA;
  const [[xB1, yB1], [xB2, yB2]] = segB;
  const mA = (yA2 - yA1) / (xA2 - xA1);
  const mB = (yB2 - yB1) / (xB2 - xB1);
  const x = (-mB * xB1 + yB1 - yA1 + mA * xA1) / (mA - mB);
  const y = mA * (x - xA1) + yA1;
  return [x, y];
};
export const deepEqual = (a, b) => {
  if (a === b) return true;
  if (a instanceof Date && b instanceof Date && a.getTime() !== b.getTime()) return false;
  if (Object(a) !== a && Object(b) !== b && a !== b) return false;
  if (typeof a !== typeof b) return false;
  if (!(a instanceof Object) || !(b instanceof Object)) return false;
  if (Array.isArray(a) && Array.isArray(b) && a.length !== b.length) return false;

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) return false;

  for (const key of aKeys) {
    if (!bKeys.includes(key)) return false;
    if (!deepEqual(a[key], b[key])) return false;
  }

  return true;
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const deleteKeysFromObjects = (array, keys) => {
  return array.map((obj) => {
    const newObj = { ...obj };
    keys.forEach((key) => {
      delete newObj[key];
    });
    return newObj;
  });
};

export const keepKeysFromObjects = (array, keys) => {
  return array.map((obj) => {
    const newObj = {};
    keys.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  });
};
