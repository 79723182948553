import { Grid, Modal, Paper, Stack } from "@mui/material";
import chroma from "chroma-js";
import * as Comlink from "comlink";
import ReactECharts from "echarts-for-react";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { isEqual, reverse } from "lodash";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { FaMapLocationDot } from "react-icons/fa6";
import { GiHistogram } from "react-icons/gi";
import { IoBarChartOutline, IoPieChartOutline } from "react-icons/io5";
import { LiaChartAreaSolid } from "react-icons/lia";
import { PiChartDonutThin, PiChartLineLight } from "react-icons/pi";
import { TbChartHistogram, TbSum } from "react-icons/tb";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { Legend, ResponsiveContainer, Tooltip } from "recharts";

import { Button } from "../../components/ButtonNew";
import { chartTypesEnums, diagramTypes, valuesEnums } from "../../enums/dynamicDashboard";
import { setDataToDownload } from "../../features/energyPerformance/energyPerformanceSliceNew";
import { distinctFilter, findDataRange, isNumeric, keepKeysFromObjects } from "../../utils/dataManipulation";
import {
  aggregatedSumValue,
  diagramInitialConfig,
  echartsDefaultTooltipFormatter,
  generateAxisValues,
  getArrayLongestElement,
  getAxisNameWithUnit,
  getDataKeyFromDiagramFilterName,
  getMapPointColor,
  getSeriesMixedName,
  pivotData,
  settingsOptions,
  sortData,
  sortDataAccumulative,
} from "../../utils/dynamicDashboard";
import { formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { DynamicFilterFactory } from "../DynamicFormInput";
import { EmptyErrorBoundary, TextErrorBoundary } from "../ErrorBoundary";
import { Icon } from "../Icon";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";
import { MyMap } from "../map/Map";
import { ColorBar } from "./Colorbar";
import LegendItem from "./LegendItem";
// import JSONfn from 'json-fn';

const useDynamicDashboardCalculations = () => {
  const [isInTransformingAndAggregating, setIsInTransformingAndAggregating] = useState(false);
  const [isAggregating, setIsAggregating] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);
  const [updatedOption, setUpdatedOption] = useState({});
  const [isPainting, setIsPainting] = useState(false);

  const isProcessing = isInTransformingAndAggregating || isAggregating || isTransforming;

  const dataTransformatorDecorator = async (dataTransformator) => {
    try {
      setIsInTransformingAndAggregating(true);
      setIsPainting(false);
      setIsTransforming(true);
      await dataTransformator();
    } finally {
      setIsTransforming(false);
    }
  };

  const dataAggregatorDecorator = async (dataAggregater, worker) => {
    try {
      setIsPainting(false);
      setIsAggregating(true);
      await dataAggregater();
    } finally {
      setIsAggregating(false);
      worker.terminate();
    }
  };

  const setOption = (newOptions) => {
    if (!isAggregating && !isTransforming) {
      setIsPainting(true);
      setIsInTransformingAndAggregating(false);
      setUpdatedOption(newOptions);
    }
  };

  return {
    dataAggregatorDecorator,
    dataTransformatorDecorator,
    isAggregating,
    isPainting,
    isProcessing,
    isTransforming,
    option: updatedOption,
    setIsAggregating,
    setIsPainting,
    setIsProcessing: setIsInTransformingAndAggregating,
    setIsTransforming,
    setOption,
  };
};

const DynamicComboChartMultithread = ({ isConfigSetting, xDataKey, ...props }) => {
  const dispatch = useDispatch();
  const {
    chartsConfigs,
    config,
    data: withoutIndexData,
    dataTransformatorMultithread,
    getColor,
    getColumnDisplayName,
    getColumnSortFunction,
    getDataTransformatorMemoDependencyArray,
    getUnit,
    hoverEffect,
    id,
    isFullScreen,
    isItemHovered,
    legendsPosition,
    onClick,
    onHover,
    onLegendSelect,
    onMouseOut,
    somethingIsHovered,
    sort,
    title,
  } = props;
  const { aggregateXAxis, diagrams, invertXAxis, invertYAxis } = config;
  const selectedConfigs = chartsConfigs?.filter((config) => config.dataKey && config.diagramType);

  const diagramsFiltersNames = Object.keys(diagrams?.[0])?.filter((key) =>
    key.startsWith(valuesEnums.DIAGRAM_FILTER_PREFIX)
  );
  const diagramsActiveFiltersNames = diagramsFiltersNames?.filter((col) =>
    selectedConfigs?.some((conf) => conf[col] !== undefined)
  );

  const diagramsFiltersDataKeys = diagramsActiveFiltersNames?.map((filterName) =>
    getDataKeyFromDiagramFilterName({ filterName })
  );
  const diagramsSegments = diagramsFiltersDataKeys?.filter(distinctFilter) || [];

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });

  const [flattenData, setFlattenData] = useState([]);

  const {
    dataAggregatorDecorator,
    dataTransformatorDecorator,
    isPainting,
    isProcessing,
    option,
    setIsPainting,
    setOption,
  } = useDynamicDashboardCalculations();

  const activeWorkerRef = useRef(null);
  const workerId = useRef(0);
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1;

    const workerInstance = dataTransformatorMultithread();
    activeWorkerRef.current = workerInstance;
    const getTransformedData = async () => {
      const { flattenData: newFlattenData, requestId } = await workerInstance.calculate(
        withoutIndexData,
        { ...config },
        workerId.current
      );
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData);
      }
    };

    if (!withoutIndexData?.length || isConfigSetting) return;
    dataTransformatorDecorator(getTransformedData);

    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };
  }, [JSON.stringify(dataTransformatorDependency), withoutIndexData, isConfigSetting]);

  const xIsNumeric = flattenData?.some((row) => isNumeric(row[xDataKey]));

  const aggregate = aggregateXAxis === undefined ? !xIsNumeric : aggregateXAxis;

  const [allAggregatedData, SetAllAggregatedData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [xAxisValues, SetXAxisValues] = useState([]);
  const [data, setData] = useState([]);

  const sortAggregatedDataOnX = (aggregatedData) => {
    const sorted = aggregatedData?.toSorted((a, b) =>
      getColumnSortFunction({
        aValue: a[xDataKey],
        bValue: b[xDataKey],
        dataKey: xDataKey,
      })
    );
    return sorted;
  };

  const activeAggregaterWorkerRef = useRef(null);
  const aggregaterWorkerId = useRef(0);
  useEffect(
    () => {
      if (activeAggregaterWorkerRef.current) {
        activeAggregaterWorkerRef.current?.terminate();
      }

      aggregaterWorkerId.current += 1;

      const worker = new Worker(new URL("../../../src/comboChart.worker.js", import.meta.url));
      activeAggregaterWorkerRef.current = worker;

      const runWorker = async (...props) => {
        const comboChartDataAggregater = Comlink.wrap(worker);
        const result = await comboChartDataAggregater(...props);
        return result;
      };

      const aggregater = async () => {
        let {
          aggregatedData,
          data: dataTemp,
          requestId,
          sortedData: sortedDataTemp,
          xAxisValues: xAxisValuesTemp,
        } = await runWorker(
          {
            aggregate,
            aggregateColumns: selectedConfigs?.map((config) => config.dataKey)?.filter(distinctFilter),
            dataKeys: selectedConfigs?.map((config) => config.dataKey),
            flattenData,
            groupByColumns: [xDataKey, ...diagramsSegments],
            sort,
            xDataKey,
            xIsNumeric,
          },
          getColumnSortFunction ? Comlink.proxy(sortAggregatedDataOnX) : undefined,
          aggregaterWorkerId.current
        );

        if (requestId === aggregaterWorkerId.current) {
          SetAllAggregatedData(aggregatedData);
          setSortedData(sortedDataTemp);
          SetXAxisValues(xAxisValuesTemp);
          setData(dataTemp);
        }
      };

      if (!flattenData?.length || isConfigSetting) return;
      dataAggregatorDecorator(aggregater, worker);

      return () => {
        worker.terminate();
      };
    },
    [flattenData, xDataKey, sort, JSON.stringify(selectedConfigs), isConfigSetting]
    // [partitions, stacks, sort, flattenData]
  );

  const xIsCategory = selectedConfigs?.some((selectedConfig) => selectedConfig.diagramType === diagramTypes.BAR);

  let xAxis = xIsNumeric
    ? {
        inverse: invertXAxis,
        type: xIsCategory ? "category" : "value",
      }
    : {
        axisPointer: {
          type: "shadow",
        },
        data: xAxisValues,
        inverse: invertXAxis,
      };

  const xUnit = getUnit({ config, quantity: xDataKey });
  const xAxisName = getAxisNameWithUnit({
    dataKey: getColumnDisplayName({ colName: xDataKey }),
    unit: xUnit,
  });
  xAxis = [{ ...xAxis, name: xAxisName, nameGap: 25, nameLocation: "center" }];

  let yAxis = xIsNumeric
    ? [
        {
          inverse: invertYAxis,
        },
      ]
    : [
        {
          inverse: invertYAxis,
          type: "value",
        },
      ];

  const yAxisUnits = selectedConfigs.map((config) =>
    getAxisNameWithUnit({
      unit: getUnit({ config, quantity: config.dataKey }),
    })
  );
  const yAxisUnitUnique = yAxisUnits.filter(distinctFilter);
  const yAxisUnitText = yAxisUnitUnique.join(" - ");
  yAxis = [{ ...yAxis[0], name: yAxisUnitText }];

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return;
    const newOptions = {
      series: selectedConfigs?.map((selectedConfig, i) => {
        const activeFiltersNames = diagramsFiltersNames?.filter((col) => selectedConfig[col] !== undefined);
        const activeFiltersNamesAndValues = activeFiltersNames?.map((col) => [col, selectedConfig[col]]);

        const seriesMixedName = getSeriesMixedName({
          filters: activeFiltersNamesAndValues,
          seriesName: selectedConfig.dataKey,
        });
        const color =
          getColor &&
          getColor({
            chartType: config?.chartType,
            diagrams,
            quantity: seriesMixedName,
          });
        const seriesRelatedData = sortedData?.filter((row) => {
          let output = true;
          activeFiltersNames?.forEach((filterName, index) => {
            const selectedValue = selectedConfig[filterName];

            const dataColName = diagramsFiltersDataKeys[index];
            const rowValue = row[dataColName];
            if (selectedValue !== rowValue) output = false;
          });
          return output;
        });
        let seriesAggregatedData = !aggregate
          ? seriesRelatedData
          : aggregatedSumValue({
              aggregateColumns: [selectedConfig.dataKey],
              data: seriesRelatedData,
              groupByColumns: [xDataKey],
            });

        return {
          areaStyle: selectedConfig.diagramType === chartTypesEnums.AREA ? {} : undefined,
          color: color ?? getItemColor(i),
          // data: xIsNumeric ? sortedData?.map((row, index) => ({
          //   value: [row[xDataKey], row[selectedConfig?.dataKey]],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // })) : sortedData?.map((row) => ({
          //   value: row[selectedConfig?.dataKey],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // }))
          data: seriesAggregatedData?.map((row, index) => ({
            itemStyle: {
              opacity:
                somethingIsHovered &&
                isItemHovered &&
                !isItemHovered({
                  chartType: config?.chartType,
                  diagrams,
                  row,
                  selectedConfig,
                  xAxis: config?.xAxis,
                  yAxis: config?.yAxis,
                })
                  ? 0.05
                  : 1,
            },
            row,
            value: [row[xDataKey], row[selectedConfig?.dataKey]],
          })),
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
          large: true,
          largeThreshold: 1000,
          maxValue: Math.max(...seriesAggregatedData?.map((row) => row[selectedConfig?.dataKey] ?? 0)),
          name: seriesMixedName,

          progressive: 500,
          progressiveThreshold: 1000,
          tooltip: {
            trigger: "item",
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          type: selectedConfig.diagramType === chartTypesEnums.AREA ? chartTypesEnums.LINE : selectedConfig.diagramType,
        };
      }),
      xAxis,
      yAxis,
    };
    setOption({ ...newOptions });
  }, [
    somethingIsHovered,
    allAggregatedData,
    JSON.stringify(selectedConfigs),
    diagrams,
    invertYAxis,
    sortedData,
    config,
    getColor,
    invertXAxis,
    isConfigSetting,
  ]);

  const dataKeysForDownload = selectedConfigs?.map((config) => config.dataKey);
  useEffect(() => {
    try {
      if (dataKeysForDownload) {
        dataKeysForDownload?.push(xDataKey);
        let dataToDownload = keepKeysFromObjects(xIsNumeric ? data : allAggregatedData, dataKeysForDownload);
        dispatch(setDataToDownload({ data: dataToDownload, id: id }));
      }
    } catch {
      //Do nothing
    }
  }, [JSON.stringify(dataKeysForDownload), data, allAggregatedData, id]);

  const longestY = getArrayLongestElement(option.series?.map((s) => s.maxValue ?? 0));

  if (isConfigSetting) return;
  return (
    <DynamicEchartsChartWithLoader
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isFullScreen={isFullScreen}
      isPainting={isPainting}
      isProcessing={isProcessing}
      legendsPosition={legendsPosition}
      longestY={longestY}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onFinished={() => {
        setIsPainting(false);
      }}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
      setIsPainting={setIsPainting}
      title={title}
    />
  );
};

const DynamicComboChart = ({ xDataKey, ...props }) => {
  const dispatch = useDispatch();
  const {
    chartsConfigs,
    config,
    data: withoutIndexData,
    dataTransformator,
    getColor,
    getColumnDisplayName,
    getColumnSortFunction,
    getDataTransformatorMemoDependencyArray,
    getUnit,
    hoverEffect,
    id,
    isFullScreen,
    isItemHovered,
    legendsPosition,
    onClick,
    onHover,
    onLegendSelect,
    onMouseOut,
    somethingIsHovered,
    sort,
    title,
  } = props;
  const { aggregateXAxis, diagrams, invertXAxis, invertYAxis } = config;
  const selectedConfigs = chartsConfigs?.filter((config) => config.dataKey && config.diagramType);

  const diagramsFiltersNames = Object.keys(diagrams?.[0])?.filter((key) =>
    key.startsWith(valuesEnums.DIAGRAM_FILTER_PREFIX)
  );
  const diagramsActiveFiltersNames = diagramsFiltersNames?.filter((col) =>
    selectedConfigs?.some((conf) => conf[col] !== undefined)
  );

  const diagramsFiltersDataKeys = diagramsActiveFiltersNames?.map((filterName) =>
    getDataKeyFromDiagramFilterName({ filterName })
  );
  const diagramsSegments = diagramsFiltersDataKeys?.filter(distinctFilter) || [];

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });
  let { flattenData } = useMemo(
    () => dataTransformator(withoutIndexData, { ...config }),
    [dataTransformatorDependency, withoutIndexData]
  );

  const xIsNumeric = flattenData?.some((row) => isNumeric(row[xDataKey]));

  const aggregate = aggregateXAxis === undefined ? !xIsNumeric : aggregateXAxis;
  const { aggregatedData, data, sortedData } = useMemo(() => {
    if (!flattenData?.length || !xDataKey) return {};

    const data = flattenData?.map((d, index) => ({
      ...d,
      index,
    }));

    let aggregatedData = !aggregate
      ? data
      : aggregatedSumValue({
          aggregateColumns: selectedConfigs?.map((config) => config.dataKey)?.filter(distinctFilter),
          data,
          groupByColumns: [xDataKey, ...diagramsSegments],
        });

    if (sort === "y") {
      aggregatedData = sortDataAccumulative({
        data: aggregatedData,
        dataKeys: selectedConfigs?.map((config) => config.dataKey),
      });
    } else {
      if (getColumnSortFunction)
        aggregatedData.sort((a, b) =>
          getColumnSortFunction({
            aValue: a[xDataKey],
            bValue: b[xDataKey],
            dataKey: xDataKey,
          })
        );
      else aggregatedData = sortData({ data: aggregatedData, dataKey: xDataKey });
    }

    // Remove hardcoded value
    const sortedData =
      xDataKey !== "index" || !sort ? aggregatedData : aggregatedData?.map((d, index) => ({ ...d, index }));

    return { aggregatedData, data, sortedData };
  }, [flattenData, xDataKey, sort, JSON.stringify(selectedConfigs)]);

  const xAxisValues = useMemo(() => {
    return !xIsNumeric && sortedData?.map((row) => row[xDataKey]).filter(distinctFilter);
  }, [xDataKey, sortedData]);

  const xIsCategory = selectedConfigs?.some((selectedConfig) => selectedConfig.diagramType === diagramTypes.BAR);

  let xAxis = xIsNumeric
    ? {
        inverse: invertXAxis,
        type: xIsCategory ? "category" : "value",
      }
    : {
        axisPointer: {
          type: "shadow",
        },
        data: xAxisValues,
        inverse: invertXAxis,
      };

  const xUnit = getUnit({ config, quantity: xDataKey });
  const xAxisName = getAxisNameWithUnit({
    dataKey: getColumnDisplayName({ colName: xDataKey }),
    unit: xUnit,
  });
  xAxis = [{ ...xAxis, name: xAxisName, nameGap: 25, nameLocation: "center" }];

  let yAxis = xIsNumeric
    ? [
        {
          inverse: invertYAxis,
        },
      ]
    : [
        {
          inverse: invertYAxis,
          type: "value",
        },
      ];

  const yAxisUnits = selectedConfigs.map((config) =>
    getAxisNameWithUnit({
      unit: getUnit({ config, quantity: config.dataKey }),
    })
  );
  const yAxisUnitUnique = yAxisUnits.filter(distinctFilter);
  const yAxisUnitText = yAxisUnitUnique.join(" - ");
  yAxis = [{ ...yAxis[0], name: yAxisUnitText }];

  const option = useMemo(() => {
    return {
      series: selectedConfigs?.map((selectedConfig, i) => {
        const activeFiltersNames = diagramsFiltersNames?.filter((col) => selectedConfig[col] !== undefined);
        const activeFiltersNamesAndValues = activeFiltersNames?.map((col) => [col, selectedConfig[col]]);

        const seriesMixedName = getSeriesMixedName({
          filters: activeFiltersNamesAndValues,
          seriesName: selectedConfig.dataKey,
        });
        const color =
          getColor &&
          getColor({
            chartType: config?.chartType,
            diagrams,
            quantity: seriesMixedName,
          });
        const seriesRelatedData = sortedData?.filter((row) => {
          let output = true;
          activeFiltersNames?.forEach((filterName, index) => {
            const selectedValue = selectedConfig[filterName];

            const dataColName = diagramsFiltersDataKeys[index];
            const rowValue = row[dataColName];
            if (selectedValue !== rowValue) output = false;
          });
          return output;
        });
        let seriesAggregatedData = !aggregate
          ? seriesRelatedData
          : aggregatedSumValue({
              aggregateColumns: [selectedConfig.dataKey],
              data: seriesRelatedData,
              groupByColumns: [xDataKey],
            });

        return {
          areaStyle: selectedConfig.diagramType === chartTypesEnums.AREA ? {} : undefined,
          color: color ?? getItemColor(i),
          // data: xIsNumeric ? sortedData?.map((row, index) => ({
          //   value: [row[xDataKey], row[selectedConfig?.dataKey]],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // })) : sortedData?.map((row) => ({
          //   value: row[selectedConfig?.dataKey],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // }))
          data: seriesAggregatedData?.map((row, index) => ({
            itemStyle: {
              opacity:
                somethingIsHovered &&
                isItemHovered &&
                !isItemHovered({
                  chartType: config?.chartType,
                  diagrams,
                  row,
                  selectedConfig,
                  xAxis: config?.xAxis,
                  yAxis: config?.yAxis,
                })
                  ? 0.05
                  : 1,
            },
            row,
            value: [row[xDataKey], row[selectedConfig?.dataKey]],
          })),
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
          maxValue: Math.max(...seriesAggregatedData?.map((row) => row[selectedConfig?.dataKey] ?? 0)),
          name: seriesMixedName,
          tooltip: {
            trigger: "item",
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          type: selectedConfig.diagramType === chartTypesEnums.AREA ? chartTypesEnums.LINE : selectedConfig.diagramType,
        };
      }),
      xAxis,
      yAxis,
    };
  }, [somethingIsHovered, JSON.stringify(selectedConfigs), config?.xAxis, diagrams, sortedData, config, getColor]);

  const dataKeysForDownload = selectedConfigs?.map((config) => config.dataKey);

  useEffect(() => {
    if (dataKeysForDownload) {
      dataKeysForDownload?.push(xDataKey);
      let dataToDownload = keepKeysFromObjects(xIsNumeric ? data : aggregatedData, dataKeysForDownload);
      dispatch(setDataToDownload({ data: dataToDownload, id: id }));
    }
  }, [JSON.stringify(dataKeysForDownload), data, aggregatedData, id]);

  const longestY = getArrayLongestElement(option.series?.map((s) => s.maxValue ?? 0));
  return (
    <DynamicEchartsChart
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isFullScreen={isFullScreen}
      legendsPosition={legendsPosition}
      longestY={longestY}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
      title={title}
    />
  );
};

const generateHistData = ({ axisValues, data, dataKeys, filters }) => {
  if (!data?.length || !axisValues?.length) return;
  const histData = [];
  for (const i in axisValues) {
    if (i == 0) continue;
    const min = axisValues[i - 1];
    const max = axisValues[i];
    const label = `${min} - ${max}`;
    const allCounts = {};
    for (const dataKeyIndex in dataKeys) {
      const dataKey = dataKeys[dataKeyIndex];
      const dataKeyFilters = filters[dataKeyIndex];

      const count = data
        ?.filter((row) => {
          for (const [filterName, filterValue] of dataKeyFilters) {
            if (row[filterName] !== filterValue && filterValue !== undefined) return false;
          }
          return true;
        })
        ?.reduce((total, current) => {
          const value = current[dataKey];
          if ((value > min || (value === min && i === 1)) && value <= max) total++;
          return total;
        }, 0);
      allCounts[dataKey] = count;
    }
    histData.push({ name: label, ...allCounts });
  }
  return histData;
};

const DynamicHistogramChart = (props) => {
  const {
    chartsConfigs,
    config,
    data,
    getColor,
    getColumnDisplayName,
    hoverEffect,
    id,
    isFullScreen,
    isItemHovered,
    legendsPosition,
    onClick,
    onHover,
    onLegendSelect,
    onMouseOut,
    somethingIsHovered,
    sort,
    title,
  } = props;
  const { invertXAxis, invertYAxis } = config;

  const diagramsFiltersNames = Object.keys(chartsConfigs?.[0])?.filter((key) =>
    key.startsWith(valuesEnums.DIAGRAM_FILTER_PREFIX)
  );

  const dispatch = useDispatch();
  const dataKeys = chartsConfigs?.filter((chartConfig) => chartConfig)?.map((chartConfig) => chartConfig.dataKey);
  const dataFilters = chartsConfigs
    ?.filter((chartConfig) => chartConfig)
    ?.map((chartConfig) => {
      const activeFiltersNames = diagramsFiltersNames?.filter((col) => chartConfig[col] !== undefined);
      const activeFiltersDataKeysAndValues = activeFiltersNames?.map((col) => [
        getDataKeyFromDiagramFilterName({ filterName: col }),
        chartConfig[col],
      ]);
      return activeFiltersDataKeysAndValues;
    });

  const { histData } = useMemo(() => {
    const minMax = findDataRange({ data, dataKeys });
    const { max, min } = minMax ?? {};
    const axisValues = generateAxisValues(min, max)?.map(echartsDefaultTooltipFormatter);

    let histData = generateHistData({
      axisValues,
      data,
      dataKeys,
      filters: dataFilters,
    });
    if (sort === "y") {
      histData = sortDataAccumulative({ data: histData, dataKeys });
    }

    return { histData };
  }, [data, JSON.stringify(chartsConfigs), sort]);

  var option = useMemo(() => {
    return {
      series: chartsConfigs?.map((chartConfig, i) => {
        const activeFiltersNames = diagramsFiltersNames?.filter((col) => chartConfig[col] !== undefined);
        const activeFiltersNamesAndValues = activeFiltersNames?.map((col) => [col, chartConfig[col]]);
        const seriesMixedName = getSeriesMixedName({
          filters: activeFiltersNamesAndValues,
          seriesName: chartConfig.dataKey,
        });
        const color =
          getColor &&
          getColor({
            chartType: config?.chartType,
            diagrams: chartsConfigs,
            quantity: seriesMixedName,
          });
        return {
          color: color ?? getItemColor(i),
          data: histData?.map((row, index) => {
            return {
              itemStyle: {
                opacity:
                  somethingIsHovered &&
                  isItemHovered &&
                  !isItemHovered({
                    chartType: config?.chartType,
                    diagrams: chartsConfigs,
                    row: {
                      dataPoint: chartConfig?.dataKey,
                      index,
                      rangeString: row.name,
                    },
                    xAxis: config?.xAxis,
                    yAxis: config?.yAxis,
                  })
                    ? 0.05
                    : 1,
              },
              row: {
                dataPoint: chartConfig?.dataKey,
                index,
                rangeString: row.name,
              },
              value: row[chartConfig?.dataKey],
            };
          }),
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
          maxValue: Math.max(...[histData ?? []]?.map((row) => row[chartConfig?.dataKey] ?? 0)),
          name: seriesMixedName,
          stack: i,
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          type: "bar",
        };
      }),
      xAxis: [
        {
          data: histData?.map((row) => row.name),
          inverse: invertXAxis,
        },
      ],
      yAxis: [
        {
          inverse: invertYAxis,
          name: valuesEnums.COUNT,
          type: "value",
        },
      ],
    };
  }, [
    somethingIsHovered,
    histData,
    invertXAxis,
    invertYAxis,
    JSON.stringify(chartsConfigs),
    config?.yAxis,
    config?.xAxis,
    getColor,
  ]);

  useEffect(() => {
    dispatch(
      setDataToDownload({
        data: histData?.map((row) => ({ ...row, name: `[${row?.name}]` })),
        id,
      })
    );
  }, [histData, id]);

  return (
    <DynamicEchartsChart
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isFullScreen={isFullScreen}
      legendsPosition={legendsPosition}
      longestY={Math.max(...option.series?.map((s) => s.maxValue ?? 0))}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
      title={title}
    />
  );
};

// const echartsDefaultTooltipNameFormatter = (params, getColumnDisplayName) => {
//   const name = getColumnDisplayName({ colName: !params.dimensionNames?.length ? params.name : params.seriesName })
//   const value = formatNumberBasedOnUser(params.value)
//   return params.marker + ' ' + name + ': ' + value
//   return `
//   ${params.marker} ${params.seriesName}: <br/><strong>${params.name}</strong>
//   Value: <strong>${value}</strong>
// `;
// }

const legendsWidth = 200;
const legendsWidthFullScreen = 400;

const echartsDefaultOptions = {
  animation: false,
  dataZoom: {
    bottom: 30,
    height: 16,
    type: "slider",
  },
  toolbox: {
    feature: {
      saveAsImage: { show: true },
    },
  },
  tooltip: {
    axisPointer: {
      crossStyle: {
        color: "#999",
      },
      type: "cross",
    },
    position: "top",
    trigger: "item",
  },
};

const legendConfigAtRight = {
  grid: {
    bottom: 70,
    containLabel: true,
    left: 40,
    right: legendsWidth + 30,
    top: 55,
  },
  legend: {
    align: "right",
    left: "right",
    orient: "vertical",
    textStyle: {
      fontFamily: "Nunito Sans",
      fontSize: "1.5rem",
      fontWeight: 600,
      overflow: "truncate",
      width: legendsWidth,
    },
    top: 40,
    type: "scroll",
  },
};

const legendsConfigAtBottom = {
  grid: {
    bottom: 70,
    containLabel: true,
    left: 40,
    right: 20,
    top: 55,
  },
  legend: {
    bottom: 0,
    left: 10,
    orient: "horizontal",
    right: 40,
    textStyle: {
      fontFamily: "Nunito Sans",
      fontSize: "1.3rem",
      fontWeight: 600,
      width: legendsWidth,
    },
    type: "scroll",
  },
};

export const DynamicEchartsChart = ({ option, ...props }) => {
  const eChartsRef = useRef();

  let mergedOptions = {
    ...echartsDefaultOptions,
    title: {
      left: "center",
      text: props.title,
    },
  };

  if (props?.legendsPosition === "right") {
    mergedOptions = { ...mergedOptions, ...legendConfigAtRight };
    if (props.isFullScreen) {
      mergedOptions = {
        ...mergedOptions,
        grid: {
          ...mergedOptions.grid,
          right: legendsWidthFullScreen + 30,
        },
        legend: {
          ...mergedOptions.legend,
          textStyle: {
            ...mergedOptions.legend.textStyle,
            width: legendsWidthFullScreen,
          },
        },
      };
    }
  } else {
    mergedOptions = { ...mergedOptions, ...legendsConfigAtBottom };
  }

  mergedOptions = {
    ...mergedOptions,
    legend: {
      ...mergedOptions.legend,
      formatter: (name) => {
        if (props.getLegendDisplayName) return props.getLegendDisplayName?.(name) ?? name;
        return name;
      },
    },
  };

  if (option)
    mergedOptions = {
      ...mergedOptions,
      ...option,
      grid: { ...mergedOptions?.grid, ...option?.grid },
    };

  if (mergedOptions?.yAxis) {
    let maxLabelLength;
    if (typeof props?.longestY === "number" && isNumeric(parseInt(props?.longestY)))
      maxLabelLength = parseInt(props?.longestY)?.toString()?.length;
    else if (typeof props?.longestY === "string") {
      maxLabelLength = props?.longestY?.length;
    } else {
      maxLabelLength = 5;
    }

    // Just a silly formulae for getting a gap for length of a number or text. Make it better if you can
    const nameGap = maxLabelLength ** 0.4 * 30 + maxLabelLength ** 0.7 * 4;

    const isYInverted = mergedOptions?.yAxis?.[0]?.inverse;
    const bottomGrid = mergedOptions?.grid?.bottom;
    const topGrid = mergedOptions?.grid?.top;
    mergedOptions = {
      ...mergedOptions,
      grid: {
        ...mergedOptions?.grid,
        bottom: isYInverted ? bottomGrid + 20 : bottomGrid,
        top: isYInverted ? topGrid - 20 : topGrid,
      },
      yAxis: [
        {
          axisLabel: {
            formatter: (value) => props.getLegendDisplayName?.(value) ?? value,
          },
          // nameGap: mergedOptions?.yAxis?.[0]?.inverse ? 40 : 15,
          // min: 'dataMin',
          boundaryGap: true,
          nameGap,
          nameLocation: "middle",
          nameRotate: 90,
          nameTextStyle: {
            verticalAlign: "left",
          },
          scale: true,
          ...mergedOptions?.yAxis?.[0],
        },
      ],
    };
  }

  if (mergedOptions?.xAxis) {
    mergedOptions = {
      ...mergedOptions,
      xAxis: [
        {
          axisLabel: {
            formatter: (value) => props.getLegendDisplayName?.(value) ?? value,
          },
          boundaryGap: true,
          scale: true,
          ...mergedOptions?.xAxis?.[0],
        },
      ],
    };
  }

  let clickTimeout = null;
  useEffect(() => {
    if (eChartsRef && eChartsRef.current) {
      const chartInstance = eChartsRef.current.getEchartsInstance();

      chartInstance.setOption(mergedOptions, true);

      const handleLegendSelect = (params) => {
        if (clickTimeout) {
          clearTimeout(clickTimeout);
          clickTimeout = null;

          props.onLegendSelect?.(params);

          chartInstance.dispatchAction({
            name: params.name,
            type: "legendSelect",
          });
        } else {
          clickTimeout = setTimeout(() => {
            clickTimeout = null;
          }, 250);
        }
      };

      chartInstance.on("legendselectchanged", handleLegendSelect);
      chartInstance.on("mouseover", props.onHover);
      chartInstance.on("mouseout", props.onMouseOut);
      chartInstance.on("click", props.onClick);

      if (props.onFinished) {
        if (chartInstance) {
          setTimeout(() => {
            props.onFinished();
          }, 500);
        }
      }

      return () => {
        chartInstance.off("legendselectchanged", handleLegendSelect);
        chartInstance.off("mouseover", props.onHover);
        chartInstance.off("mouseout", props.onMouseOut);
        chartInstance.off("click", props.onClick);
      };
    }
  }, [option, props]);

  return <ReactECharts option={mergedOptions} ref={eChartsRef} style={{ height: "100%", width: "100%" }} {...props} />;
};

const DynamicPieChart = ({ chartsConfigs, data, ...props }) => {
  let {
    chartType,
    config,
    dataPoint,
    dataTransformator,
    getColor,
    getColumnDisplayName,
    getColumnSortFunction,
    getDataTransformatorMemoDependencyArray,
    height,
    hoverEffect,
    id,
    isFullScreen,
    isItemHovered,
    legendsPosition,
    onClick,
    onHover,
    onLegendSelect,
    onMouseOut,
    somethingIsHovered,
    title,
    width,
  } = props;
  const dispatch = useDispatch();

  let { invertXAxis, showPieLabels, subDataPoint, xAxis, yAxis } = config;
  if (subDataPoint) dataPoint = subDataPoint;
  const diagramConfig = chartsConfigs[0];
  const { partitions, stacks } = diagramConfig;

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });
  let {
    dataPartitions: preCalculatedPartitions,
    dataStacks: preCalculatedStacks,
    flattenData,
  } = useMemo(() => dataTransformator(data, { ...config }), [dataTransformatorDependency, data]);

  const { allAggregatedData, dataStacks } = useMemo(() => {
    // let flattenData = dataTransformator(data, { dataPoint, chartType, diagrams: chartsConfigs })

    let dataStacks;
    if (!stacks) dataStacks = [];
    else {
      dataStacks = preCalculatedStacks ?? flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
    }

    const stacksSortFunction = (a, b) => {
      if (getColumnSortFunction) return getColumnSortFunction({ aValue: a, bValue: b, dataKey: stacks });
      return a > b ? 1 : -1;
    };
    dataStacks.sort(stacksSortFunction);

    let allAggregatedData = [];

    if (!dataStacks.length) {
      allAggregatedData.push(
        aggregatedSumValue({
          aggregateColumns: [dataPoint],
          data: flattenData,
          groupByColumns: [partitions],
        })
      );
    } else {
      for (const stack of dataStacks) {
        const filteredData = flattenData.filter((e) => e[stacks] === stack);

        allAggregatedData.push(
          aggregatedSumValue({
            aggregateColumns: [dataPoint],
            data: filteredData,
            groupByColumns: [partitions],
          })
        );
      }
    }

    return { allAggregatedData, dataStacks };
  }, [data, dataTransformatorDependency, dataPoint, chartType, partitions, stacks, xAxis, invertXAxis, subDataPoint]);

  const noOfCharts = allAggregatedData.length;

  let availableWidth = legendsPosition === "right" ? width - legendsWidth : width;
  const xOuterRadius = availableWidth / (3 * noOfCharts + 1);
  const yOuterRadius = height / 3;
  const outerRadius = Math.min(xOuterRadius, yOuterRadius);
  const marginX = availableWidth - (3 * noOfCharts + 1) * outerRadius;

  const isDoughnut = chartType === chartTypesEnums.DOUGHNUT;
  const doughnutRadiusUnit = Math.min(availableWidth / (5 * noOfCharts + 0.25), height / (5 * noOfCharts + 0.25));

  const partitionsSortFunction = (a, b) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({
        aValue: a[partitions],
        bValue: b[partitions],
        dataKey: partitions,
      });
    return a[partitions] < b[partitions] ? -1 : 1;
  };

  var option = useMemo(() => {
    const getValueForRepresentation = (dataPoint, stack, partition) => {
      if (stacks && partitions) {
        return `(${stack})-(${partition})`;
      } else if (stacks) {
        return stack;
      } else if (partitions) {
        return partition;
      } else {
        return dataPoint;
      }
    };

    return {
      series: allAggregatedData?.map((data, stackIndex) => {
        const stack = dataStacks[stackIndex];

        const sortedDataWithIndex = data.map((row, indexBackup) => ({
          ...row,
          indexBackup,
        }));
        sortedDataWithIndex.sort(partitionsSortFunction);
        return {
          avoidLabelOverlap: true,
          center: [
            isDoughnut
              ? `${(100 * availableWidth) / (2 * width)}%`
              : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
            "50%",
          ],
          color: sortedDataWithIndex?.map((row, partitionIndex) => {
            let partition, valueForRepresentation;
            partition = row[partitions];
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition);

            const color =
              getColor &&
              getColor({
                chartType,
                diagrams: chartsConfigs,
                quantity: valueForRepresentation,
              });
            return color
              ? color
              : stacks
              ? getItemColor(stackIndex, partitions ? partitionIndex : undefined)
              : getItemColor(partitionIndex);
          }),
          data: sortedDataWithIndex?.map((row, i) => {
            let partition, valueForRepresentation;
            partition = row[partitions];
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition);

            return {
              itemStyle: {
                opacity:
                  somethingIsHovered &&
                  isItemHovered &&
                  !isItemHovered({
                    chartType,
                    diagrams: chartsConfigs,
                    row: { ...row, [partitions]: partition, [stacks]: stack },
                    xAxis,
                    yAxis,
                  })
                    ? 0.05
                    : 1,
              },
              name: valueForRepresentation,
              row: hoverEffect ? { ...row, [partitions]: partition, [stacks]: stack } : {},
              value: row[dataPoint],
            };
          }),
          emphasis: {
            label: {
              fontSize: 14,
              fontWeight: "bold",
              position: "inside",
              show: true,
            },
          },
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
          itemStyle: {
            borderColor: "#fff",
            borderRadius: isDoughnut ? 4 : 6,
            borderWidth: 1,
          },
          label: {
            formatter: showPieLabels ? "{b}({d}%)" : "{d}%",
            position: showPieLabels ? "outside" : "inside",
            show: true,
            // formatter: '{d}%'
          },
          labelLine: {
            show: true,
          },
          name: partitions,
          radius: [
            isDoughnut ? (2 * stackIndex + 1) * doughnutRadiusUnit : 0,
            isDoughnut ? (2 * stackIndex + 2) * doughnutRadiusUnit : outerRadius,
          ],
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          type: "pie",
        };
      }),
      title: [
        {
          left: "center",
          text: title,
        },
        ...dataStacks?.map((stackName, stackIndex) => ({
          left: isDoughnut ? "50%" : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
          subtext: !isDoughnut && getColumnDisplayName({ colName: stackName }),
          textAlign: "center",
          top: height / 2 - outerRadius - 40,
        })),
      ],
      tooltip: {
        trigger: "item",
      },
    };
  }, [
    somethingIsHovered,
    allAggregatedData,
    dataPoint,
    subDataPoint,
    chartType,
    partitions,
    stacks,
    xAxis,
    invertXAxis,
    getColor,
  ]);

  useEffect(() => {
    const dataToDownload = allAggregatedData?.reduce((total, current, stackIndex) => {
      const name = dataStacks[stackIndex];
      return [...total, ...current.map((r) => ({ ...r, name }))];
    }, []);

    dispatch(setDataToDownload({ data: dataToDownload, id: id }));
  }, [allAggregatedData, dataStacks, id]);

  return (
    <DynamicEchartsChart
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isFullScreen={isFullScreen}
      legendsPosition={legendsPosition}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
    />
  );
};

const DynamicPieChartMultithread = ({ chartsConfigs, data, ...props }) => {
  let {
    chartType,
    config,
    dataPoint,
    dataTransformator,
    dataTransformatorMultithread,
    getColor,
    getColumnDisplayName,
    getColumnSortFunction,
    getDataTransformatorMemoDependencyArray,
    height,
    hoverEffect,
    id,
    isConfigSetting,
    isFullScreen,
    isItemHovered,
    legendsPosition,
    onClick,
    onHover,
    onLegendSelect,
    onMouseOut,
    somethingIsHovered,
    title,
    width,
  } = props;
  const dispatch = useDispatch();

  let { invertXAxis, showPieLabels, subDataPoint, xAxis, yAxis } = config;
  if (subDataPoint) dataPoint = subDataPoint;
  const diagramConfig = chartsConfigs[0];
  const { partitions, stacks } = diagramConfig;

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });

  const [flattenData, setFlattenData] = useState([]);
  const {
    dataAggregatorDecorator,
    dataTransformatorDecorator,
    isPainting,
    isProcessing,
    option,
    setIsPainting,
    setOption,
  } = useDynamicDashboardCalculations();

  const activeWorkerRef = useRef(null);
  const workerId = useRef(0);
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1;

    const workerInstance = dataTransformatorMultithread();
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const { flattenData: newFlattenData, requestId } = await workerInstance.calculate(
        data,
        { ...config },
        workerId.current
      );
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData);
      }
    };

    if (!data?.length || isConfigSetting) return;
    dataTransformatorDecorator(getTransformedData);

    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };
  }, [JSON.stringify(dataTransformatorDependency), data, isConfigSetting]);

  const [allAggregatedData, SetAllAggregatedData] = useState([]);
  const [dataStacks, SetDataStacks] = useState([]);

  const stacksSortFunction = (a, b) => {
    if (getColumnSortFunction) return getColumnSortFunction({ aValue: a, bValue: b, dataKey: stacks });
    return a > b ? 1 : -1;
  };

  const aggregaterWorkerRef = useRef(null);
  const aggregaterWorkerId = useRef(0);
  useEffect(() => {
    if (aggregaterWorkerRef.current) {
      aggregaterWorkerRef.current.terminate();
    }

    aggregaterWorkerId.current += 1;
    const worker = new Worker(new URL("../../../src/pieChart.worker.js", import.meta.url));
    aggregaterWorkerRef.current = worker;

    const runWorker = async (...props) => {
      const pieChartDataAggregater = Comlink.wrap(worker);
      const result = await pieChartDataAggregater(...props);
      return result;
    };
    const aggregater = async () => {
      let {
        allAggregatedData: allAggregatedDataTemp,
        dataStacks: dataStacksTemp,
        requestId,
      } = await runWorker(
        { dataPoint, flattenData, partitions, stacks },
        Comlink.proxy(stacksSortFunction),
        aggregaterWorkerId.current
      );
      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(allAggregatedDataTemp);
        SetDataStacks(dataStacksTemp);
      }
    };

    if (!flattenData?.length || isConfigSetting) return;
    dataAggregatorDecorator(aggregater, worker);
    return () => {
      worker.terminate();
    };
  }, [
    data,
    dataTransformatorDependency,
    dataPoint,
    chartType,
    partitions,
    stacks,
    xAxis,
    invertXAxis,
    subDataPoint,
    flattenData,
    isConfigSetting,
  ]);

  const noOfCharts = allAggregatedData.length;

  let availableWidth = legendsPosition === "right" ? width - legendsWidth : width;
  const xOuterRadius = availableWidth / (3 * noOfCharts + 1);
  const yOuterRadius = height / 3;
  const outerRadius = Math.min(xOuterRadius, yOuterRadius);
  const marginX = availableWidth - (3 * noOfCharts + 1) * outerRadius;

  const isDoughnut = chartType === chartTypesEnums.DOUGHNUT;
  const doughnutRadiusUnit = Math.min(availableWidth / (5 * noOfCharts + 0.25), height / (5 * noOfCharts + 0.25));

  const partitionsSortFunction = (a, b) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({
        aValue: a[partitions],
        bValue: b[partitions],
        dataKey: partitions,
      });
    return a[partitions] < b[partitions] ? -1 : 1;
  };

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return;

    const getValueForRepresentation = (dataPoint, stack, partition) => {
      if (stacks && partitions) {
        return `(${stack})-(${partition})`;
      } else if (stacks) {
        return stack;
      } else if (partitions) {
        return partition;
      } else {
        return dataPoint;
      }
    };
    var optionMemoized = {
      series: allAggregatedData?.map((data, stackIndex) => {
        const stack = dataStacks[stackIndex];

        const sortedDataWithIndex = data.map((row, indexBackup) => ({
          ...row,
          indexBackup,
        }));
        sortedDataWithIndex.sort(partitionsSortFunction);
        return {
          avoidLabelOverlap: true,
          center: [
            isDoughnut
              ? `${(100 * availableWidth) / (2 * width)}%`
              : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
            "50%",
          ],
          color: sortedDataWithIndex?.map((row, partitionIndex) => {
            let partition, valueForRepresentation;
            partition = row[partitions];
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition);

            const color =
              getColor &&
              getColor({
                chartType,
                diagrams: chartsConfigs,
                quantity: valueForRepresentation,
              });
            return color
              ? color
              : stacks
              ? getItemColor(stackIndex, partitions ? partitionIndex : undefined)
              : getItemColor(partitionIndex);
          }),
          data: sortedDataWithIndex?.map((row, i) => {
            let partition, valueForRepresentation;
            partition = row[partitions];
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition);

            return {
              itemStyle: {
                opacity:
                  somethingIsHovered &&
                  isItemHovered &&
                  !isItemHovered({
                    chartType,
                    diagrams: chartsConfigs,
                    row: { ...row, [partitions]: partition, [stacks]: stack },
                    xAxis,
                    yAxis,
                  })
                    ? 0.05
                    : 1,
              },
              name: valueForRepresentation,
              row: hoverEffect ? { ...row, [partitions]: partition, [stacks]: stack } : {},
              value: row[dataPoint],
            };
          }),
          emphasis: {
            label: {
              fontSize: 14,
              fontWeight: "bold",
              position: "inside",
              show: true,
            },
          },
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
          itemStyle: {
            borderColor: "#fff",
            borderRadius: isDoughnut ? 4 : 6,
            borderWidth: 1,
          },
          label: {
            formatter: showPieLabels ? "{b}({d}%)" : "{d}%",
            position: showPieLabels ? "outside" : "inside",
            show: true,
            // formatter: '{d}%'
          },
          labelLine: {
            show: true,
          },
          name: partitions,
          radius: [
            isDoughnut ? (2 * stackIndex + 1) * doughnutRadiusUnit : 0,
            isDoughnut ? (2 * stackIndex + 2) * doughnutRadiusUnit : outerRadius,
          ],
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          type: "pie",
        };
      }),
      title: [
        {
          left: "center",
          text: title,
        },
        ...dataStacks?.map((stackName, stackIndex) => ({
          left: isDoughnut ? "50%" : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
          subtext: !isDoughnut && getColumnDisplayName({ colName: stackName }),
          textAlign: "center",
          top: height / 2 - outerRadius - 40,
        })),
      ],
      tooltip: {
        trigger: "item",
      },
    };

    setOption(optionMemoized);
  }, [
    somethingIsHovered,
    allAggregatedData,
    dataPoint,
    subDataPoint,
    chartType,
    partitions,
    stacks,
    xAxis,
    invertXAxis,
    getColor,
    isConfigSetting,
  ]);

  useEffect(() => {
    const dataToDownload = allAggregatedData?.reduce((total, current, stackIndex) => {
      const name = dataStacks[stackIndex];
      return [...total, ...current.map((r) => ({ ...r, name }))];
    }, []);
    dispatch(setDataToDownload({ data: dataToDownload, id: id }));
  }, [allAggregatedData, dataStacks, id]);

  if (isConfigSetting) return;
  return (
    <DynamicEchartsChartWithLoader
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isFullScreen={isFullScreen}
      isPainting={isPainting}
      isProcessing={isProcessing}
      legendsPosition={legendsPosition}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onFinished={() => {
        setIsPainting(false);
      }}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
      setIsPainting={setIsPainting}
    />
  );
};

const LegendItemWithValue = ({
  bulletHeight,
  bulletType = "rectangle",
  bulletWidth,
  className,
  color,
  direction,
  hasBullet,
  label,
  value,
  ...props
}) => {
  return (
    <Stack className={`legend w-full justify-between ${className} `} flexDirection={direction} spacing={4} {...props}>
      <LegendItem
        bulletHeight={bulletHeight ?? (hasBullet ? "0.4rem" : 0)}
        bulletType={bulletType}
        bulletWidth={bulletWidth ?? (hasBullet ? "0.8rem" : 0)}
        color={color}
        label={label}
      />
      <span className=" t-body-n text-gray-500">
        {/* {value >= 1000 ? addCommaToNumber(parseInt(value)) : value?.toFixed(2)}{" "} */}
        {formatNumberBasedOnUser(value)}
      </span>
    </Stack>
  );
};

const DynamicKPIMultithread = ({
  chartsConfigs,
  config,
  data: withoutIndexData,
  dataTransformator,
  dataTransformatorMultithread,
  getColor,
  getColumnDisplayName,
  getColumnSortFunction,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  isConfigSetting,
  isItemHovered,
  onHover,
  onLegendSelect,
  onMouseOut,
  somethingIsHovered,
  ...props
}) => {
  let { chartType, dataPoint, height, id, title, xDataKey } = props;
  const { subDataPoint } = config;
  if (subDataPoint) dataPoint = subDataPoint;
  const dispatch = useDispatch();
  const diagramConfig = chartsConfigs[0];
  let { direction, partitions, percentageBased, stacks } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null;
  if (chartType === chartTypesEnums.KPI) xDataKey = null;

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });

  const [flattenData, setFlattenData] = useState([]);
  const [preCalculatedStacks, setPreCalculatedStacks] = useState([]);
  const [preCalculatedPartitions, setPreCalculatedPartitions] = useState([]);

  const { dataAggregatorDecorator, dataTransformatorDecorator, isProcessing, option, setOption } =
    useDynamicDashboardCalculations();

  const activeWorkerRef = useRef(null);
  const workerId = useRef(0);
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1;

    const workerInstance = dataTransformatorMultithread();
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const {
        dataPartitions: preCalculatedPartitions,
        dataStacks: preCalculatedStacks,
        flattenData: newFlattenData,
        requestId,
      } = await workerInstance.calculate(data, { ...config }, workerId.current);
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData);
        setPreCalculatedStacks(preCalculatedStacks);
        setPreCalculatedPartitions(preCalculatedPartitions);
      }
    };

    if (!data?.length || isConfigSetting) return;
    dataTransformatorDecorator(getTransformedData);

    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };
  }, [JSON.stringify(dataTransformatorDependency), withoutIndexData]);

  const [allAggregatedData, SetAllAggregatedData] = useState([]);
  const [dataStacks, SetDataStacks] = useState([]);
  const [dataPartitions, SetDataPartitions] = useState([]);

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction) return getColumnSortFunction({ aValue: a, bValue: b, dataKey });
    return a < b ? -1 : 1;
  };

  const activeAggregaterWorkerRef = useRef(null);
  const aggregaterWorkerId = useRef(0);
  useEffect(() => {
    if (activeAggregaterWorkerRef.current) {
      activeAggregaterWorkerRef.current.terminate();
    }

    aggregaterWorkerId.current += 1;
    const worker = new Worker(new URL("../../../src/kpiChart.worker.js", import.meta.url));
    activeAggregaterWorkerRef.current = worker;
    const runWorker = async (...props) => {
      const kpiChartDataAggregater = Comlink.wrap(worker);
      const result = await kpiChartDataAggregater(...props);
      return result;
    };

    const aggregater = async () => {
      let {
        allAggregatedData: allAggregatedDataTemp,
        dataPartitions: dataPartitionsTemp,
        dataStacks: dataStacksTemp,
        requestId,
      } = await runWorker(
        {
          dataPoint,
          flattenData,
          isStacked,
          partitions,
          preCalculatedPartitions,
          preCalculatedStacks,
          stacks,
          xDataKey,
        },
        aggregaterWorkerId.current
      );
      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(allAggregatedDataTemp);
        SetDataStacks(dataStacksTemp);
        SetDataPartitions(dataPartitionsTemp);
      }
    };

    if (!flattenData?.length || isConfigSetting) return;
    dataAggregatorDecorator(aggregater, worker);

    return () => {
      worker.terminate();
    };
  }, [
    withoutIndexData,
    dataTransformatorDependency,
    dataPoint,
    subDataPoint,
    stacks,
    partitions,
    xDataKey,
    flattenData,
    isConfigSetting,
  ]);
  const isVertical = direction === "vertical";

  useEffect(() => {
    dispatch(setDataToDownload({ data: allAggregatedData, id: id }));
  }, [allAggregatedData, id]);

  const unsortedDataStacks = [...dataStacks];
  const unsortedDataPartitions = [...dataPartitions];

  dataStacks.sort((a, b) => sortFunction(a, b, stacks));
  dataPartitions.sort((a, b) => sortFunction(a, b, partitions));

  const dataPointUnit = getUnit({ config, quantity: dataPoint });
  const dataPointColor = getColor ? getColor({ chartType, diagrams: chartsConfigs, quantity: dataPoint }) : undefined;

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return;
    let configsCalculations = {};

    if (partitions && !stacks) {
      dataPartitions?.forEach((p, j) => {
        const unsortedPartitionIdx = unsortedDataPartitions.findIndex((partition) => partition === p);
        const color = getColor && getColor({ chartType, diagrams: chartsConfigs, quantity: p });
        const isTransparent =
          somethingIsHovered &&
          isItemHovered &&
          !isItemHovered({
            chartType,
            diagrams: chartsConfigs,
            row: { [partitions]: p },
          });

        configsCalculations[p] = {
          color: color ?? getItemColor(0, j),
          isTransparent,
          value: allAggregatedData[0][p],
        };
      });
    }

    if (partitions && stacks) {
      dataStacks?.forEach((s, i) => {
        const unsortedStackIdx = unsortedDataStacks.findIndex((stack) => stack === s);
        configsCalculations[s] = {};
        dataPartitions?.forEach((p, j) => {
          const unsortedPartitionIdx = unsortedDataPartitions.findIndex((partition) => partition === p);
          const valueForColor = `(${s})-(${p})`;
          const color =
            getColor &&
            getColor({
              chartType,
              diagrams: chartsConfigs,
              quantity: valueForColor,
            });
          const isTransparent =
            somethingIsHovered &&
            isItemHovered &&
            !isItemHovered({
              chartType,
              diagrams: chartsConfigs,
              row: { [partitions]: p, [stacks]: s },
            });
          configsCalculations[s][p] = {
            color: color ?? getItemColor(i, j),
            isTransparent,
            value: allAggregatedData[0][`(${s})-(${p})`],
            valueForColor,
          };
        });
      });
    }

    setOption(configsCalculations);
  }, [
    withoutIndexData,
    flattenData,
    allAggregatedData,
    somethingIsHovered,
    dataPoint,
    partitions,
    stacks,
    subDataPoint,
    getColor,
    isConfigSetting,
  ]);

  return (
    <div className="relative h-full w-full">
      <h4 className="t-body-n mt-4 text-center capitalize">
        {getAxisNameWithUnit({ dataKey: title, unit: dataPointUnit })}
      </h4>
      {isProcessing && <ChartLoader className="absolute left-1 top-1  w-full" isCalculating={true} />}
      {!isProcessing && (
        <Stack
          className="w-full overflow-x-auto overflow-y-auto px-4"
          flexDirection={isVertical ? "column" : "row"}
          height={height - 48}
        >
          {!isStacked ? (
            <LegendItemWithValue
              bulletHeight="1.2rem"
              bulletType="circle"
              bulletWidth="1.2rem"
              className={`w-full py-1 ${onLegendSelect ? "cursor-pointer" : ""} }`}
              color={dataPointColor ?? getItemColor(0)}
              direction="column"
              hasBullet={true}
              label={getColumnDisplayName({ colName: dataPoint })}
              onClick={onLegendSelect ? () => onLegendSelect({ config, params: { name: dataPoint } }) : undefined}
              value={allAggregatedData?.[0]?.[dataPoint]}
            />
          ) : partitions && !stacks ? (
            dataPartitions?.map((p, i) => {
              const value = option?.[p]?.value;
              const color = option?.[p]?.color;
              const isTransparent = option?.[p]?.isTransparent;

              return (
                <LegendItemWithValue
                  bulletHeight="1.2rem"
                  bulletType="circle"
                  bulletWidth="1.2rem"
                  className={`w-full py-1  ${isTransparent ? "opacity-[0.2]" : "opacity-[1]"}
            ${onLegendSelect ? "cursor-pointer" : ""}  ${somethingIsHovered && !isTransparent && "bg-blue-50"}`}
                  color={color}
                  direction={isVertical ? "row" : "column"}
                  hasBullet={true}
                  label={getColumnDisplayName({ colName: p })}
                  onClick={onLegendSelect ? () => onLegendSelect({ config, params: { name: p } }) : undefined}
                  onMouseEnter={onHover ? () => onHover({ config, point: { [partitions]: p } }) : undefined}
                  onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
                  value={value}
                />
              );
            })
          ) : (
            <Stack
              className="h-full w-full"
              flexDirection={isVertical ? "column" : "row"}
              justifyContent="space-between"
            >
              {dataStacks?.map((s, i) => {
                return (
                  <div className="t-body-n h-full w-full p-4 text-center capitalize">
                    <p className="mt-2">{getColumnDisplayName({ colName: s })}</p>
                    <Stack className="w-full" flexDirection="column" justifyContent="space-between">
                      {dataPartitions?.map((p, j) => {
                        const value = option?.[s]?.[p]?.value;
                        const valueForColor = option?.[s]?.[p]?.valueForColor;
                        const color = option?.[s]?.[p]?.color;
                        const isTransparent = option?.[s]?.[p]?.isTransparent;

                        return (
                          <LegendItemWithValue
                            bulletHeight="1.2rem"
                            bulletType="circle"
                            bulletWidth="1.2rem"
                            className={`w-full py-1 ${isTransparent ? "opacity-[0.2]" : "opacity-[1]"}
                           ${onLegendSelect ? "cursor-pointer" : ""}  ${
                              somethingIsHovered && !isTransparent && "bg-blue-50"
                            }`}
                            color={color}
                            hasBullet={true}
                            label={getColumnDisplayName({ colName: p })}
                            onClick={
                              onLegendSelect
                                ? () =>
                                    onLegendSelect({
                                      config,
                                      params: { name: valueForColor },
                                    })
                                : undefined
                            }
                            onMouseEnter={
                              onHover
                                ? () =>
                                    onHover({
                                      config,
                                      point: { [partitions]: p, [stacks]: s },
                                    })
                                : undefined
                            }
                            onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
                            value={value}
                          />
                        );
                      })}
                    </Stack>
                  </div>
                );
              })}
            </Stack>
          )}
          <Legend />
          <Tooltip />
        </Stack>
      )}
    </div>
  );
};

const DynamicKPI = ({
  chartsConfigs,
  config,
  data: withoutIndexData,
  dataTransformator,
  getColor,
  getColumnDisplayName,
  getColumnSortFunction,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  isItemHovered,
  onHover,
  onLegendSelect,
  onMouseOut,
  somethingIsHovered,
  ...props
}) => {
  let { chartType, dataPoint, height, id, title, xDataKey } = props;
  const { subDataPoint } = config;
  if (subDataPoint) dataPoint = subDataPoint;
  const dispatch = useDispatch();
  const diagramConfig = chartsConfigs[0];
  let { direction, partitions, percentageBased, stacks } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null;
  if (chartType === chartTypesEnums.KPI) xDataKey = null;

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });
  let {
    dataPartitions: preCalculatedPartitions,
    dataStacks: preCalculatedStacks,
    flattenData,
  } = useMemo(() => dataTransformator(data, { ...config }), [dataTransformatorDependency, withoutIndexData]);

  let { dataPartitions, dataStacks, kpiAggregatedDataMemoized } = useMemo(() => {
    let dataStacks;
    if (!stacks) dataStacks = [];
    else {
      dataStacks = preCalculatedStacks ?? flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
    }

    let dataPartitions;
    if (!partitions) dataPartitions = [];
    else {
      dataPartitions = preCalculatedPartitions ?? flattenData?.map((e) => e[partitions])?.filter(distinctFilter);
    }

    const pivotColumn = partitions && stacks ? `(${stacks})-(${partitions})` : partitions ?? stacks;
    if (partitions && stacks) {
      flattenData = flattenData?.map((e) => ({
        ...e,
        [partitions]: null,
        [pivotColumn]: `(${e[stacks]})-(${e[partitions]})`,
        [stacks]: null,
      }));
    }

    let kpiAggregatedData;
    if (!isStacked) {
      kpiAggregatedData = aggregatedSumValue({
        aggregateColumns: [dataPoint],
        data: flattenData,
        groupByColumns: [],
      });
    } else {
      // var pivotColumnValues = flattenData
      //   ?.map((e) => e[pivotColumn])
      //   ?.filter(distinctFilter);
      let pivotColumnValuesAll = dataStacks?.map((s) => dataPartitions?.map((p) => `(${s})-(${p})`));
      pivotColumnValuesAll = pivotColumnValuesAll?.reduce((total, current) => [...total, ...current], []);

      var pivotColumnValues =
        pivotColumn === partitions
          ? dataPartitions
          : pivotColumn === stacks
          ? dataStacks
          : pivotColumnValuesAll?.filter((sp) => flattenData?.some((row) => row[pivotColumn] === sp));

      // Remove hardcoded value
      flattenData = aggregatedSumValue({
        aggregateColumns: [dataPoint],
        data: flattenData,
        groupByColumns: [xDataKey, pivotColumn, "dataPoint"],
      });

      const pivotedData = pivotData(flattenData, pivotColumn, dataPoint);
      kpiAggregatedData = aggregatedSumValue({
        aggregateColumns: pivotColumnValues,
        data: pivotedData,
        groupByColumns: [],
      });
    }

    return {
      dataPartitions,
      dataStacks,
      kpiAggregatedDataMemoized: kpiAggregatedData,
    };
  }, [withoutIndexData, dataTransformatorDependency, dataPoint, subDataPoint, stacks, partitions, xDataKey]);
  const isVertical = direction === "vertical";

  useEffect(() => {
    dispatch(setDataToDownload({ data: kpiAggregatedDataMemoized, id: id }));
  }, [kpiAggregatedDataMemoized, id]);

  const unsortedDataStacks = [...dataStacks];
  const unsortedDataPartitions = [...dataPartitions];

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction) return getColumnSortFunction({ aValue: a, bValue: b, dataKey });
    return a < b ? -1 : 1;
  };

  dataStacks.sort((a, b) => sortFunction(a, b, stacks));
  dataPartitions.sort((a, b) => sortFunction(a, b, partitions));

  const dataPointUnit = getUnit({ config, quantity: dataPoint });
  const dataPointColor = getColor ? getColor({ chartType, diagrams: chartsConfigs, quantity: dataPoint }) : undefined;

  const configsCalculationsMemoized = useMemo(() => {
    let configsCalculations = {};

    if (partitions && !stacks) {
      dataPartitions?.forEach((p, j) => {
        const unsortedPartitionIdx = unsortedDataPartitions.findIndex((partition) => partition === p);
        const color = getColor && getColor({ chartType, diagrams: chartsConfigs, quantity: p });
        const isTransparent =
          somethingIsHovered &&
          isItemHovered &&
          !isItemHovered({
            chartType,
            diagrams: chartsConfigs,
            row: { [partitions]: p },
          });

        configsCalculations[p] = {
          color: color ?? getItemColor(0, j),
          isTransparent,
          value: kpiAggregatedDataMemoized[0][p],
        };
      });
    }

    if (partitions && stacks) {
      dataStacks?.forEach((s, i) => {
        const unsortedStackIdx = unsortedDataStacks.findIndex((stack) => stack === s);
        configsCalculations[s] = {};
        dataPartitions?.forEach((p, j) => {
          const unsortedPartitionIdx = unsortedDataPartitions.findIndex((partition) => partition === p);
          const valueForColor = `(${s})-(${p})`;
          const color =
            getColor &&
            getColor({
              chartType,
              diagrams: chartsConfigs,
              quantity: valueForColor,
            });
          const isTransparent =
            somethingIsHovered &&
            isItemHovered &&
            !isItemHovered({
              chartType,
              diagrams: chartsConfigs,
              row: { [partitions]: p, [stacks]: s },
            });
          configsCalculations[s][p] = {
            color: color ?? getItemColor(i, j),
            isTransparent,
            value: kpiAggregatedDataMemoized[0][`(${s})-(${p})`],
            valueForColor,
          };
        });
      });
    }
    return configsCalculations;
  }, [withoutIndexData, dataPoint, subDataPoint, stacks, partitions, getColor, config]);

  return (
    <>
      <h4 className="t-body-n mt-4 text-center capitalize">
        {getAxisNameWithUnit({ dataKey: title, unit: dataPointUnit })}
      </h4>
      <Stack
        className="w-full overflow-x-auto overflow-y-auto px-4"
        flexDirection={isVertical ? "column" : "row"}
        height={height - 48}
      >
        {!isStacked ? (
          <LegendItemWithValue
            bulletHeight="1.2rem"
            bulletType="circle"
            bulletWidth="1.2rem"
            className={`w-full py-1 ${onLegendSelect ? "cursor-pointer" : ""} }`}
            color={dataPointColor ?? getItemColor(0)}
            direction="column"
            hasBullet={true}
            label={getColumnDisplayName({ colName: dataPoint })}
            onClick={onLegendSelect ? () => onLegendSelect({ config, params: { name: dataPoint } }) : undefined}
            value={kpiAggregatedDataMemoized?.[0]?.[dataPoint]}
          />
        ) : partitions && !stacks ? (
          dataPartitions?.map((p, i) => {
            const value = configsCalculationsMemoized?.[p]?.value;
            const color = configsCalculationsMemoized?.[p]?.color;
            const isTransparent = configsCalculationsMemoized?.[p]?.isTransparent;

            return (
              <LegendItemWithValue
                bulletHeight="1.2rem"
                bulletType="circle"
                bulletWidth="1.2rem"
                className={`w-full py-1  ${isTransparent ? "opacity-[0.2]" : "opacity-[1]"}
            ${onLegendSelect ? "cursor-pointer" : ""}  ${somethingIsHovered && !isTransparent && "bg-blue-50"}`}
                color={color}
                direction={isVertical ? "row" : "column"}
                hasBullet={true}
                label={getColumnDisplayName({ colName: p })}
                onClick={onLegendSelect ? () => onLegendSelect({ config, params: { name: p } }) : undefined}
                onMouseEnter={onHover ? () => onHover({ config, point: { [partitions]: p } }) : undefined}
                onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
                value={value}
              />
            );
          })
        ) : (
          <Stack className="h-full w-full" flexDirection={isVertical ? "column" : "row"} justifyContent="space-between">
            {dataStacks?.map((s, i) => {
              return (
                <div className="t-body-n h-full w-full p-4 text-center capitalize">
                  <p className="mt-2">{getColumnDisplayName({ colName: s })}</p>
                  <Stack className="w-full" flexDirection="column" justifyContent="space-between">
                    {dataPartitions?.map((p, j) => {
                      const value = configsCalculationsMemoized?.[s]?.[p]?.value;
                      const valueForColor = configsCalculationsMemoized?.[s]?.[p]?.valueForColor;
                      const color = configsCalculationsMemoized?.[s]?.[p]?.color;
                      const isTransparent = configsCalculationsMemoized?.[s]?.[p]?.isTransparent;

                      return (
                        <LegendItemWithValue
                          bulletHeight="1.2rem"
                          bulletType="circle"
                          bulletWidth="1.2rem"
                          className={`w-full py-1 ${isTransparent ? "opacity-[0.2]" : "opacity-[1]"}
                           ${onLegendSelect ? "cursor-pointer" : ""}  ${
                            somethingIsHovered && !isTransparent && "bg-blue-50"
                          }`}
                          color={color}
                          hasBullet={true}
                          label={getColumnDisplayName({ colName: p })}
                          onClick={
                            onLegendSelect
                              ? () =>
                                  onLegendSelect({
                                    config,
                                    params: { name: valueForColor },
                                  })
                              : undefined
                          }
                          onMouseEnter={
                            onHover
                              ? () =>
                                  onHover({
                                    config,
                                    point: { [partitions]: p, [stacks]: s },
                                  })
                              : undefined
                          }
                          onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
                          value={value}
                        />
                      );
                    })}
                  </Stack>
                </div>
              );
            })}
          </Stack>
        )}
        <Legend />
        <Tooltip />
      </Stack>
    </>
  );
};

const DynamicStackedChart = ({
  chartsConfigs,
  config,
  data: withoutIndexData,
  dataTransformator,
  getDataTransformatorMemoDependencyArray,
  ...props
}) => {
  let {
    chartType,
    dataPoint,
    getColor,
    getColumnDisplayName,
    getColumnSortFunction,
    getUnit,
    hoverEffect,
    id,
    isFullScreen,
    isItemHovered,
    legendsPosition,
    onClick,
    onHover,
    onLegendSelect,
    onMouseOut,
    somethingIsHovered,
    sort,
    title,
    xDataKey,
  } = props;

  const dispatch = useDispatch();
  const { invertXAxis, invertYAxis, subDataPoint, xAxis, yAxis } = config;

  if (subDataPoint) dataPoint = subDataPoint;

  const diagramConfig = chartsConfigs[0];
  let { direction, partitions, percentageBased, stacks } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null;
  if (chartType === chartTypesEnums.KPI) xDataKey = null;

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });
  let {
    dataPartitions: preCalculatedPartitions,
    dataStacks: preCalculatedStacks,
    flattenData,
  } = useMemo(() => dataTransformator(data, { ...config }), [dataTransformatorDependency, withoutIndexData]);

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction) return getColumnSortFunction({ aValue: a, bValue: b, dataKey });
    return a < b ? -1 : 1;
  };

  const { allAggregatedData, dataPartitions, dataStacks, pivotColumnValues, xAxisValues } = useMemo(() => {
    let dataStacks;
    if (!stacks) dataStacks = [];
    else {
      dataStacks = preCalculatedStacks ?? flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
      dataStacks.sort((a, b) => sortFunction(a, b, stacks));
    }
    let dataPartitions;
    if (!partitions) dataPartitions = [];
    else {
      dataPartitions = preCalculatedPartitions ?? flattenData?.map((e) => e[partitions])?.filter(distinctFilter);
      dataPartitions.sort((a, b) => sortFunction(a, b, partitions));
    }

    let allAggregatedData = [];
    const pivotColumn =
      chartType === chartTypesEnums.BAR
        ? partitions && stacks
          ? `(${stacks}) - (${partitions})`
          : partitions || stacks
        : partitions;

    if (partitions && stacks && chartType === chartTypesEnums.BAR) {
      flattenData = flattenData?.map((e) => ({
        ...e,
        [partitions]: null,
        [pivotColumn]: `Stack=${e[stacks]}&Partition=${e[partitions]}`,
        [stacks]: null,
      }));
    }

    if (!isStacked) {
      allAggregatedData = aggregatedSumValue({
        aggregateColumns: [dataPoint],
        data: flattenData,
        groupByColumns: [xDataKey],
      });
    } else {
      let pivotColumnValuesAll = dataStacks?.map((s) => dataPartitions?.map((p) => `Stack=${s}&Partition=${p}`));
      pivotColumnValuesAll = pivotColumnValuesAll?.reduce((total, current) => [...total, ...current], []);

      var pivotColumnValues =
        pivotColumn === partitions
          ? dataPartitions
          : pivotColumn === stacks
          ? dataStacks
          : pivotColumnValuesAll?.filter((sp) => flattenData?.some((row) => row[pivotColumn] === sp));

      // Remove hardcoded value
      flattenData = aggregatedSumValue({
        aggregateColumns: [dataPoint],
        data: flattenData,
        groupByColumns: [xDataKey, pivotColumn, "dataPoint"],
      });

      const pivotedData = pivotData(flattenData, pivotColumn, dataPoint);

      allAggregatedData = aggregatedSumValue({
        aggregateColumns: pivotColumnValues,
        data: pivotedData,
        groupByColumns: [xDataKey],
      });
    }

    // TODO: Remove this static values (Use enums at least)
    let xAxisValues = [];
    if (!sort) sort = "x";

    if ([chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.LINE].includes(chartType)) {
      if (sort === "x" || sort === undefined) {
        if (getColumnSortFunction)
          allAggregatedData.sort((a, b) =>
            getColumnSortFunction({
              aValue: a[xDataKey],
              bValue: b[xDataKey],
              dataKey: xDataKey,
            })
          );
        else
          allAggregatedData = sortData({
            data: allAggregatedData,
            dataKey: xDataKey,
          });
      }
      // Remove Hardcoded value
      if (sort === "y" || sort === "All values") {
        if (isStacked)
          allAggregatedData = sortDataAccumulative({
            data: allAggregatedData,
            dataKeys: pivotColumnValues,
          });
        else
          allAggregatedData = sortData({
            data: allAggregatedData,
            dataKey: dataPoint,
          });
      }

      // Remove Hardcoded value
      xAxisValues = xDataKey ? allAggregatedData?.map((row) => row[xDataKey]) : ["Total"];
    }
    if (chartType === chartTypesEnums.LINE) {
      // Remove Hardcoded value
      if (isStacked && sort === "All values") {
        const sorted = {};
        pivotColumnValues.forEach((v) => {
          const pivotValues = allAggregatedData?.map((row) => row[v]);
          pivotValues.sort((a, b) => a - b);
          sorted[v] = pivotValues;
        });
        const sortedAllAggregatedData = [];
        for (const i in allAggregatedData) {
          const sortedIthIndex = {};
          for (const v of pivotColumnValues) {
            sortedIthIndex[v] = sorted[v][i];
          }
          sortedAllAggregatedData.push({ i, ...sortedIthIndex });
        }
        allAggregatedData = sortedAllAggregatedData;
        // Remove Hardcoded value
        xAxisValues = xDataKey ? allAggregatedData?.map((row, index) => index) : ["Total"];
      }
    }

    return {
      allAggregatedData,
      dataPartitions,
      dataStacks,
      pivotColumnValues,
      xAxisValues,
    };
  }, [
    withoutIndexData,
    dataTransformatorDependency,
    dataPoint,
    subDataPoint,
    chartType,
    partitions,
    stacks,
    xDataKey,
    sort,
  ]);

  const isVertical = direction === "vertical";

  const oneToNArray = [...(Array(xAxisValues?.length)?.keys() ?? [])];

  let xAxisOption = !isVertical
    ? {
        axisPointer: {
          type: "shadow",
        },
        data:
          chartType === chartTypesEnums.LINE && sort === "All values"
            ? invertXAxis
              ? reverse(oneToNArray)
              : oneToNArray
            : xAxisValues,
        inverse: invertXAxis,
      }
    : {
        inverse: invertXAxis,
        scale: false,
        type: "value",
      };

  const xUnit = getUnit({ config, quantity: xDataKey });
  const xAxisName = getAxisNameWithUnit({
    dataKey: getColumnDisplayName({ colName: xDataKey }),
    unit: xUnit,
  });

  const dataPointUnit = getUnit({ quantity: dataPoint });
  const yAxisName = getAxisNameWithUnit({
    dataKey: getColumnDisplayName({ colName: dataPoint }),
    unit: dataPointUnit,
  });

  xAxisOption = {
    ...xAxisOption,
    name: !isVertical ? xAxisName : yAxisName,
    nameGap: 25,
    nameLocation: "center",
  };

  let yAxisOption = isVertical
    ? {
        axisPointer: {
          type: "shadow",
        },
        data:
          chartType === chartTypesEnums.LINE && sort === "All values"
            ? invertXAxis
              ? reverse(oneToNArray)
              : oneToNArray
            : xAxisValues,
        inverse: invertYAxis,
      }
    : {
        inverse: invertYAxis,
        name: getColumnDisplayName({ colName: dataPoint }),
        scale: false,
        type: "value",
      };

  yAxisOption = { ...yAxisOption, name: !isVertical ? yAxisName : xAxisName };
  const dataPointColor = getColor ? getColor({ chartType, diagrams: chartsConfigs, quantity: dataPoint }) : undefined;

  let partitionsCounter = -1;

  var option = useMemo(() => {
    return {
      series: !isStacked
        ? [
            {
              areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
              color: dataPointColor ?? getItemColor(0),
              data: allAggregatedData?.map((row) => {
                return {
                  itemStyle: {
                    opacity:
                      somethingIsHovered &&
                      isItemHovered &&
                      !isItemHovered({
                        chartType,
                        diagrams: chartsConfigs,
                        row,
                        xAxis,
                        yAxis,
                      })
                        ? 0.05
                        : 1,
                  },
                  row: hoverEffect ? row : {},
                  value: row[dataPoint],
                };
              }),
              maxValue: Math.max(...allAggregatedData?.map((row) => row[dataPoint] ?? 0)),
              name: dataPoint,
              tooltip: {
                valueFormatter: echartsDefaultTooltipFormatter,
              },
              type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            },
          ]
        : pivotColumnValues?.map((value, i) => {
            let partition, stack, valueForRepresentation;
            if (stacks && partitions) {
              partition = value?.split("&Partition=")?.[1];
              stack = value?.split("&Partition=")?.[0]?.replace("Stack=", "");
              valueForRepresentation = `(${stack})-(${partition})`;

              if (i > 0) {
                const previousValue = pivotColumnValues[i - 1];
                const previousStack = previousValue?.split("&Partition=")?.[0]?.replace("Stack=", "");
                if (previousStack !== stack) partitionsCounter = 0;
                else {
                  partitionsCounter++;
                }
              } else {
                partitionsCounter++;
              }
            } else {
              partition = partitions ? value : undefined;
              stack = stacks ? value : undefined;
              valueForRepresentation = value;
            }

            const stackIndex = dataStacks?.findIndex((s) => stack === s);
            const color =
              getColor &&
              getColor({
                chartType,
                diagrams: chartsConfigs,
                quantity: valueForRepresentation,
              });
            return {
              areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
              color: color
                ? color
                : stacks && partitions
                ? getItemColor(stackIndex, partitionsCounter)
                : getItemColor(i),
              // data: allAggregatedData?.filter((row, i) => i < 10)?.map((row) => {
              data: allAggregatedData?.map((row) => {
                return {
                  itemStyle: {
                    opacity:
                      somethingIsHovered &&
                      isItemHovered &&
                      !isItemHovered({
                        chartType,
                        diagrams: chartsConfigs,
                        row: {
                          ...row,
                          [partitions]: partition,
                          [stacks]: stack,
                        },
                        xAxis,
                        yAxis,
                      })
                        ? 0.05
                        : 1,
                  },
                  row: hoverEffect ? { ...row, [partitions]: partition, [stacks]: stack } : {},
                  value: row[value],
                };
              }),
              emphasis: {
                focus: hoverEffect ? undefined : "series",
              },
              maxValue: Math.max(...allAggregatedData?.map((row) => row[value] ?? 0)),
              stack:
                chartType === chartTypesEnums.LINE ? null : chartType === chartTypesEnums.AREA ? "0" : stack ?? "0",
              tooltip: {
                valueFormatter: echartsDefaultTooltipFormatter,
              },
              type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            };
          }),
      xAxis: [xAxisOption],
      yAxis: [yAxisOption],
    };
  }, [
    somethingIsHovered,
    allAggregatedData,
    dataPoint,
    chartType,
    partitions,
    stacks,
    xAxis,
    yAxis,
    invertYAxis,
    subDataPoint,
    dataPoint,
    invertXAxis,
    pivotColumnValues,
    getColor,
  ]);

  useEffect(() => {
    dispatch(setDataToDownload({ data: allAggregatedData, id }));
  }, [id, allAggregatedData]);

  const longestY = getArrayLongestElement(isVertical ? xAxisValues : option.series?.map((s) => s.maxValue ?? 0));

  return (
    <DynamicEchartsChart
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isFullScreen={isFullScreen}
      legendsPosition={legendsPosition}
      longestY={longestY}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
      title={title}
    />
  );
};

const DynamicStackedChartMultithread = ({
  chartsConfigs,
  config,
  data: withoutIndexData,
  dataTransformator,
  dataTransformatorMultithread,
  getDataTransformatorMemoDependencyArray,
  isConfigSetting,
  ...props
}) => {
  let {
    chartType,
    dataPoint,
    getColor,
    getColumnDisplayName,
    getColumnSortFunction,
    getUnit,
    hoverEffect,
    id,
    isFullScreen,
    isItemHovered,
    legendsPosition,
    onClick,
    onHover,
    onLegendSelect,
    onMouseOut,
    somethingIsHovered,
    sort,
    title,
    xDataKey,
  } = props;
  const dispatch = useDispatch();
  const { invertXAxis, invertYAxis, subDataPoint, xAxis, yAxis } = config;

  if (subDataPoint) dataPoint = subDataPoint;

  const diagramConfig = chartsConfigs[0];
  let { direction, partitions, percentageBased, stacks } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null;
  if (chartType === chartTypesEnums.KPI) xDataKey = null;

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });

  const [flattenData, setFlattenData] = useState([]);
  const [preCalculatedStacks, setPreCalculatedStacks] = useState([]);
  const [preCalculatedPartitions, setPreCalculatedPartitions] = useState([]);

  const {
    dataAggregatorDecorator,
    dataTransformatorDecorator,
    isPainting,
    isProcessing,
    option,
    setIsPainting,
    setOption,
  } = useDynamicDashboardCalculations();

  const activeWorkerRef = useRef(null);
  const workerId = useRef(0);
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1;

    const workerInstance = dataTransformatorMultithread();
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const {
        dataPartitions: preCalculatedPartitions,
        dataStacks: preCalculatedStacks,
        flattenData: newFlattenData,
        requestId,
      } = await workerInstance.calculate(data, { ...config }, workerId.current);
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData);
        setPreCalculatedStacks(preCalculatedStacks);
        setPreCalculatedPartitions(preCalculatedPartitions);
      }
    };

    if (!data?.length || isConfigSetting) return;
    dataTransformatorDecorator(getTransformedData);
    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };
  }, [JSON.stringify(dataTransformatorDependency), withoutIndexData, isConfigSetting]);

  const [allAggregatedData, SetAllAggregatedData] = useState([]);
  const [dataStacks, SetDataStacks] = useState([]);
  const [xAxisValues, SetXAxisValues] = useState([]);
  const [pivotColumnValues, SetPivotColumnValues] = useState([]);

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction) return getColumnSortFunction({ aValue: a, bValue: b, dataKey });
    return a < b ? -1 : 1;
  };

  const sortAggregatedDataOnX = (aggregatedData) => {
    const sorted = aggregatedData?.toSorted((a, b) =>
      getColumnSortFunction({
        aValue: a[xDataKey],
        bValue: b[xDataKey],
        dataKey: xDataKey,
      })
    );
    return sorted;
  };

  const sortStacksAndPartitions = (array, colName) => {
    return array?.toSorted((a, b) => sortFunction(a, b, colName));
  };

  const activeAggregaterWorkerRef = useRef(null);
  const aggregaterWorkerId = useRef(0);
  useEffect(
    () => {
      if (activeAggregaterWorkerRef.current) {
        activeAggregaterWorkerRef.current.terminate();
      }

      aggregaterWorkerId.current += 1;
      const worker = new Worker(new URL("../../../src/stackedChart.worker.js", import.meta.url));
      activeAggregaterWorkerRef.current = worker;
      const runWorker = async (...props) => {
        const stackedChartDataAggregater = Comlink.wrap(worker);
        const result = await stackedChartDataAggregater(...props);
        return result;
      };

      const aggregater = async () => {
        let { allAggregatedDataTemp, dataStacksTemp, pivotColumnValuesTemp, requestId, xAxisValuesTemp } =
          await runWorker(
            {
              chartType,
              dataPoint,
              flattenData,
              isStacked,
              partitions,
              preCalculatedPartitions,
              preCalculatedStacks,
              sort,
              stacks,
              xDataKey,
            },
            getColumnSortFunction ? Comlink.proxy(sortAggregatedDataOnX) : undefined,
            Comlink.proxy(sortStacksAndPartitions),
            aggregaterWorkerId.current
          );

        if (requestId === aggregaterWorkerId.current) {
          SetAllAggregatedData(allAggregatedDataTemp);
          SetDataStacks(dataStacksTemp);
          SetXAxisValues(xAxisValuesTemp);
          SetPivotColumnValues(pivotColumnValuesTemp);
        }
      };

      if (!flattenData?.length || isConfigSetting) return;
      dataAggregatorDecorator(aggregater, worker);

      return () => {
        worker.terminate();
      };
    },
    [
      withoutIndexData,
      dataTransformatorDependency,
      dataPoint,
      subDataPoint,
      chartType,
      partitions,
      stacks,
      xDataKey,
      sort,
      flattenData,
      isConfigSetting,
    ]
    // [partitions, stacks, sort, flattenData]
  );

  const isVertical = direction === "vertical";

  const oneToNArray = [...(Array(xAxisValues?.length)?.keys() ?? [])];

  let xAxisOption = !isVertical
    ? {
        axisPointer: {
          type: "shadow",
        },
        data:
          chartType === chartTypesEnums.LINE && sort === "All values"
            ? invertXAxis
              ? reverse(oneToNArray)
              : oneToNArray
            : xAxisValues,
        inverse: invertXAxis,
      }
    : {
        inverse: invertXAxis,
        scale: false,
        type: "value",
      };

  const xUnit = getUnit({ config, quantity: xDataKey });
  const xAxisName = getAxisNameWithUnit({
    dataKey: getColumnDisplayName({ colName: xDataKey }),
    unit: xUnit,
  });

  const dataPointUnit = getUnit({ quantity: dataPoint });
  const yAxisName = getAxisNameWithUnit({
    dataKey: getColumnDisplayName({ colName: dataPoint }),
    unit: dataPointUnit,
  });

  xAxisOption = {
    ...xAxisOption,
    name: !isVertical ? xAxisName : yAxisName,
    nameGap: 25,
    nameLocation: "center",
  };

  let yAxisOption = isVertical
    ? {
        axisPointer: {
          type: "shadow",
        },
        data:
          chartType === chartTypesEnums.LINE && sort === "All values"
            ? invertXAxis
              ? reverse(oneToNArray)
              : oneToNArray
            : xAxisValues,
        inverse: invertYAxis,
      }
    : {
        inverse: invertYAxis,
        name: getColumnDisplayName({ colName: dataPoint }),
        scale: false,
        type: "value",
      };

  yAxisOption = { ...yAxisOption, name: !isVertical ? yAxisName : xAxisName };
  const dataPointColor = getColor ? getColor({ chartType, diagrams: chartsConfigs, quantity: dataPoint }) : undefined;

  let partitionsCounter = -1;

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return;
    const newOptions = {
      series: !isStacked
        ? [
            {
              areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
              color: dataPointColor ?? getItemColor(0),
              data: allAggregatedData?.map((row) => {
                return {
                  itemStyle: {
                    opacity:
                      somethingIsHovered &&
                      isItemHovered &&
                      !isItemHovered({
                        chartType,
                        diagrams: chartsConfigs,
                        row,
                        xAxis,
                        yAxis,
                      })
                        ? 0.05
                        : 1,
                  },
                  row: hoverEffect ? row : {},
                  value: row[dataPoint],
                };
              }),
              maxValue: Math.max(...allAggregatedData?.map((row) => row[dataPoint] ?? 0)),
              name: dataPoint,
              tooltip: {
                valueFormatter: echartsDefaultTooltipFormatter,
              },
              type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            },
          ]
        : pivotColumnValues?.map((value, i) => {
            let partition, stack, valueForRepresentation;
            if (stacks && partitions) {
              partition = value?.split("&Partition=")?.[1];
              stack = value?.split("&Partition=")?.[0]?.replace("Stack=", "");
              valueForRepresentation = `(${stack})-(${partition})`;

              if (i > 0) {
                const previousValue = pivotColumnValues[i - 1];
                const previousStack = previousValue?.split("&Partition=")?.[0]?.replace("Stack=", "");
                if (previousStack !== stack) partitionsCounter = 0;
                else {
                  partitionsCounter++;
                }
              } else {
                partitionsCounter++;
              }
            } else {
              partition = partitions ? value : undefined;
              stack = stacks ? value : undefined;
              valueForRepresentation = value;
            }

            const stackIndex = dataStacks?.findIndex((s) => stack === s);
            const color =
              getColor &&
              getColor({
                chartType,
                diagrams: chartsConfigs,
                quantity: valueForRepresentation,
              });
            return {
              areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
              color: color
                ? color
                : stacks && partitions
                ? getItemColor(stackIndex, partitionsCounter)
                : getItemColor(i),
              // data: allAggregatedData?.filter((row, i) => i < 10)?.map((row) => {
              data: allAggregatedData?.map((row) => {
                return {
                  itemStyle: {
                    opacity:
                      somethingIsHovered &&
                      isItemHovered &&
                      !isItemHovered({
                        chartType,
                        diagrams: chartsConfigs,
                        row: {
                          ...row,
                          [partitions]: partition,
                          [stacks]: stack,
                        },
                        xAxis,
                        yAxis,
                      })
                        ? 0.05
                        : 1,
                  },
                  row: hoverEffect ? { ...row, [partitions]: partition, [stacks]: stack } : {},
                  value: row[value],
                };
              }),
              emphasis: {
                focus: hoverEffect ? undefined : "series",
              },
              // 35 -  12 - 8
              // sampling: 'lttb',
              large: true,
              largeThreshold: 1000,
              maxValue: Math.max(...allAggregatedData?.map((row) => row[value] ?? 0)),
              name: valueForRepresentation,
              progressive: 500,
              progressiveThreshold: 1000,
              stack:
                chartType === chartTypesEnums.LINE ? null : chartType === chartTypesEnums.AREA ? "0" : stack ?? "0",
              tooltip: {
                valueFormatter: echartsDefaultTooltipFormatter,
              },
              type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            };
          }),
      xAxis: [xAxisOption],
      yAxis: [yAxisOption],
    };
    setOption({ ...newOptions });
  }, [
    somethingIsHovered,
    allAggregatedData,
    dataPoint,
    chartType,
    partitions,
    stacks,
    xAxis,
    yAxis,
    invertYAxis,
    subDataPoint,
    dataPoint,
    invertXAxis,
    pivotColumnValues,
    getColor,
    isConfigSetting,
  ]);

  useEffect(() => {
    dispatch(setDataToDownload({ data: allAggregatedData, id }));
  }, [id, allAggregatedData]);

  const longestY = getArrayLongestElement(isVertical ? xAxisValues : option.series?.map((s) => s.maxValue ?? 0));

  if (isConfigSetting) return;
  return (
    <DynamicEchartsChartWithLoader
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isFullScreen={isFullScreen}
      isPainting={isPainting}
      isProcessing={isProcessing}
      legendsPosition={legendsPosition}
      longestY={longestY}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onFinished={() => {
        setIsPainting(false);
      }}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
      setIsPainting={setIsPainting}
      title={title}
    />
  );
};

const ChartLoader = ({ className, isCalculating }) => {
  const { t } = useTranslation();
  const message = isCalculating ? t("general.processingData") : t("general.painting");

  return (
    <div className={className}>
      <Stack className="t-heading-m w-full gap-1">
        <Icon className="icon-opacity" iconName={isCalculating ? "WrenchScrewDriver" : "PaintBrush"} />
        <p className="">{message}...</p>
      </Stack>
    </div>
  );
};

const DynamicEchartsChartWithLoader = ({ isPainting, isProcessing, setIsPainting, ...echartProps }) => {
  return (
    <div className="relative h-full w-full">
      {isPainting && <ChartLoader className="absolute left-1 top-1  w-full" />}
      {isProcessing && !isPainting && <ChartLoader className="absolute left-1 top-1  w-full" isCalculating={true} />}
      {!isProcessing && (
        <DynamicEchartsChart
          {...echartProps}
          onFinished={() => {
            setIsPainting(false);
          }}
        />
      )}
    </div>
  );
};

const DynamicHeatmap = ({
  chartsConfigs,
  config,
  data,
  dataTransformator,
  getColor,
  getColumnDisplayName,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  id,
  isItemHovered,
  onClick,
  onHover,
  onLegendSelect,
  onMouseOut,
  somethingIsHovered,
  title,
  xDataKey,
  yDataKey,
  ...props
}) => {
  const dispatch = useDispatch();
  let dataPoint = props.dataPoint;

  let { chartType, invertXAxis, invertYAxis, subDataPoint, xAxis, yAxis } = config;
  if (subDataPoint) dataPoint = subDataPoint;

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });
  let { flattenData, xLabels, yLabels } = useMemo(
    () => dataTransformator(data, { ...config }),
    [dataTransformatorDependency, data]
  );

  const { aggregatedData, xLabelsMemoized, xyzData, yLabelsMemoized } = useMemo(() => {
    const aggregatedData = aggregatedSumValue({
      aggregateColumns: [dataPoint],
      data: flattenData,
      groupByColumns: [xDataKey, yDataKey],
    });

    const xyzData = [];
    yLabels.forEach((y, yIndex) => {
      xLabels.forEach((x, xIndex) => {
        const xyRecordIndex = aggregatedData.findIndex((r) => r[xDataKey] === x && r[yDataKey] === y);
        const z = xyRecordIndex === -1 ? 0 : aggregatedData[xyRecordIndex][dataPoint];
        xyzData.push([xIndex, yIndex, z || 0]);
      });
    });

    return {
      aggregatedData,
      xLabelsMemoized: xLabels,
      xyzData,
      yLabelsMemoized: yLabels,
    };
  }, [data, dataTransformatorDependency, dataPoint, subDataPoint, xDataKey, yDataKey]);

  const defaultColor = "red";
  const color = getColor && getColor({ chartType, diagrams: chartsConfigs, quantity: dataPoint });
  const dataPointUnit = getUnit({ config, quantity: dataPoint });

  const xLabelsMemoizedDisplayName = xLabels?.map((x) => getColumnDisplayName({ colName: x }));
  const yLabelsMemoizedDisplayName = yLabels?.map((y) => getColumnDisplayName({ colName: y }));

  var option = useMemo(() => {
    if (!isNumeric(Math.min(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])))) return {};

    return {
      grid: {
        bottom: 60,
        containLabel: true,
        right: 70,
        top: 40,
      },
      legend: {
        bottom: 0,
        formatter: (name) =>
          getAxisNameWithUnit({
            dataKey: getColumnDisplayName({ colName: name }),
            unit: dataPointUnit,
          }),
        itemStyle: {
          color: color ?? defaultColor,
        },
        left: 10,
        right: 40,
        show: true,
      },
      series: [
        {
          data: xyzData.map((xyz) => {
            const xLabel = xLabelsMemoized[xyz[0]];
            const yLabel = yLabelsMemoized[xyz[1]];
            return {
              itemStyle: {
                opacity:
                  somethingIsHovered &&
                  isItemHovered &&
                  !isItemHovered({
                    chartType,
                    diagrams: chartsConfigs,
                    row: { [xDataKey]: xLabel, [yDataKey]: yLabel },
                    xAxis,
                    yAxis,
                  })
                    ? 0
                    : 1,
              },
              row: { [xDataKey]: xLabel, [yDataKey]: yLabel },
              value: xyz,
            };
          }),
          emphasis: {
            // itemStyle: {
            //   shadowBlur: 10,
            // },
          },
          label: {
            show: false,
          },
          name: dataPoint,
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          type: "heatmap",
        },
      ],
      tooltip: {
        position: "top",
      },
      visualMap: {
        calculable: true,
        inRange: {
          color: ["white", color ?? defaultColor],
        },
        left: "right",
        max: Math.max(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
        min: Math.min(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
        orient: "vertical",
        top: 40,
      },
      xAxis: [
        {
          data: xLabelsMemoizedDisplayName,
          inverse: invertXAxis,
          name: getColumnDisplayName({ colName: xDataKey }),
          nameGap: 20,
          nameLocation: "center",
          splitArea: {
            show: true,
          },
        },
      ],
      yAxis: [
        {
          data: yLabelsMemoizedDisplayName,
          inverse: invertYAxis,
          name: getColumnDisplayName({ colName: yDataKey }),
          splitArea: {
            show: true,
          },
        },
      ],
    };
  }, [
    somethingIsHovered,
    aggregatedData,
    dataPoint,
    subDataPoint,
    xAxis,
    yAxis,
    invertYAxis,
    invertXAxis,
    getColor,
    config,
  ]);

  useEffect(() => {
    dispatch(setDataToDownload({ data: aggregatedData, id }));
  }, [id, aggregatedData]);

  const longestY = getArrayLongestElement(option.yAxis[0]?.data);
  if (option.series[0]?.data?.length)
    return (
      <DynamicEchartsChart
        getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
        longestY={longestY}
        onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
        onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
        onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
        onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
        option={option}
        title={title}
      />
    );
};

const DynamicHeatmapMultithread = ({
  chartsConfigs,
  config,
  data,
  dataTransformator,
  dataTransformatorMultithread,
  getColor,
  getColumnDisplayName,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  id,
  isConfigSetting,
  isItemHovered,
  onClick,
  onHover,
  onLegendSelect,
  onMouseOut,
  somethingIsHovered,
  title,
  xDataKey,
  yDataKey,
  ...props
}) => {
  const dispatch = useDispatch();
  let dataPoint = props.dataPoint;

  let { chartType, invertXAxis, invertYAxis, subDataPoint, xAxis, yAxis } = config;
  if (subDataPoint) dataPoint = subDataPoint;

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({
    config,
  });

  const [flattenData, setFlattenData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [yLabels, setYLabels] = useState([]);

  const {
    dataAggregatorDecorator,
    dataTransformatorDecorator,
    isPainting,
    isProcessing,
    option,
    setIsPainting,
    setOption,
  } = useDynamicDashboardCalculations();

  const activeWorkerRef = useRef(null);
  const workerId = useRef(0);
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1;

    const workerInstance = dataTransformatorMultithread();
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const {
        flattenData: newFlattenData,
        requestId,
        xLabels,
        yLabels,
      } = await workerInstance.calculate(data, { ...config }, workerId.current);
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData);
        setXLabels(xLabels);
        setYLabels(yLabels);
      }
    };

    if (!data?.length || isConfigSetting) return;
    dataTransformatorDecorator(getTransformedData);

    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };
  }, [JSON.stringify(dataTransformatorDependency), data, isConfigSetting]);

  const [allAggregatedData, SetAllAggregatedData] = useState([]);
  const [xyzData, SetXYZData] = useState([]);

  const activeAggregaterWorkerRef = useRef(null);
  const aggregaterWorkerId = useRef(0);
  useEffect(() => {
    if (activeAggregaterWorkerRef.current) {
      activeAggregaterWorkerRef.current.terminate();
    }

    aggregaterWorkerId.current += 1;
    const worker = new Worker(new URL("../../../src/heatmapChart.worker.js", import.meta.url));
    activeAggregaterWorkerRef.current = worker;
    const runWorker = async (...props) => {
      const heatmapChartDataAggregater = Comlink.wrap(worker);
      const result = await heatmapChartDataAggregater(...props);
      return result;
    };

    const aggregater = async () => {
      let { allAggregatedData, requestId, xyzData } = await runWorker(
        {
          dataPoint,
          flattenData,
          xDataKey,
          xLabels,
          yDataKey,
          yLabels,
        },
        aggregaterWorkerId.current
      );

      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(allAggregatedData);
        SetXYZData(xyzData);
      }
    };

    if (!flattenData?.length || isConfigSetting) return;
    dataAggregatorDecorator(aggregater, worker);

    return () => {
      worker.terminate();
    };
  }, [data, dataTransformatorDependency, dataPoint, subDataPoint, xDataKey, flattenData, isConfigSetting]);

  const defaultColor = "red";
  const color = getColor && getColor({ chartType, diagrams: chartsConfigs, quantity: dataPoint });
  const dataPointUnit = getUnit({ config, quantity: dataPoint });

  const xLabelsMemoizedDisplayName = xLabels?.map((x) => getColumnDisplayName({ colName: x }));
  const yLabelsMemoizedDisplayName = yLabels?.map((y) => getColumnDisplayName({ colName: y }));

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length || !xyzData?.length) return;

    let newOptions = {};
    if (isNumeric(Math.min(...(allAggregatedData?.map((row) => row[dataPoint]) ?? []))))
      newOptions = {
        grid: {
          bottom: 60,
          containLabel: true,
          right: 70,
          top: 40,
        },
        legend: {
          bottom: 0,
          formatter: (name) =>
            getAxisNameWithUnit({
              dataKey: getColumnDisplayName({ colName: name }),
              unit: dataPointUnit,
            }),
          itemStyle: {
            color: color ?? defaultColor,
          },
          left: 10,
          right: 40,
          show: true,
        },
        series: [
          {
            data: xyzData.map((xyz) => {
              const xLabel = xLabels[xyz[0]];
              const yLabel = yLabels[xyz[1]];
              return {
                itemStyle: {
                  opacity:
                    somethingIsHovered &&
                    isItemHovered &&
                    !isItemHovered({
                      chartType,
                      diagrams: chartsConfigs,
                      row: { [xDataKey]: xLabel, [yDataKey]: yLabel },
                      xAxis,
                      yAxis,
                    })
                      ? 0
                      : 1,
                },
                row: { [xDataKey]: xLabel, [yDataKey]: yLabel },
                value: xyz,
              };
            }),
            emphasis: {
              // itemStyle: {
              //   shadowBlur: 10,
              // },
            },
            label: {
              show: false,
            },
            name: dataPoint,
            tooltip: {
              valueFormatter: echartsDefaultTooltipFormatter,
            },
            type: "heatmap",
          },
        ],
        tooltip: {
          position: "top",
        },
        visualMap: {
          calculable: true,
          inRange: {
            color: ["white", color ?? defaultColor],
          },
          left: "right",
          max: Math.max(...(allAggregatedData?.map((row) => row[dataPoint]) ?? [])),
          min: Math.min(...(allAggregatedData?.map((row) => row[dataPoint]) ?? [])),
          orient: "vertical",
          top: 40,
        },
        xAxis: [
          {
            data: xLabelsMemoizedDisplayName,
            inverse: invertXAxis,
            name: getColumnDisplayName({ colName: xDataKey }),
            nameGap: 20,
            nameLocation: "center",
            splitArea: {
              show: true,
            },
          },
        ],
        yAxis: [
          {
            data: yLabelsMemoizedDisplayName,
            inverse: invertYAxis,
            name: getColumnDisplayName({ colName: yDataKey }),
            splitArea: {
              show: true,
            },
          },
        ],
      };
    setOption({ ...newOptions });
  }, [
    somethingIsHovered,
    allAggregatedData,
    dataPoint,
    xAxis,
    yAxis,
    invertYAxis,
    ,
    invertXAxis,
    subDataPoint,
    getColor,
    config,
    isConfigSetting,
  ]);

  useEffect(() => {
    dispatch(setDataToDownload({ data: allAggregatedData, id }));
  }, [id, allAggregatedData]);
  const longestY = getArrayLongestElement(option?.yAxis?.[0]?.data);

  if (isConfigSetting) return;
  return (
    <DynamicEchartsChartWithLoader
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      isPainting={isPainting}
      isProcessing={isProcessing}
      longestY={longestY}
      onClick={onClick ? (params) => onClick({ config, params, point: params?.data?.row }) : undefined}
      onFinished={() => {
        setIsPainting(false);
      }}
      onHover={onHover ? (params) => onHover({ config, params, point: params?.data?.row }) : undefined}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ config, params }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ config, params }) : undefined}
      option={option}
      setIsPainting={setIsPainting}
      title={title}
    />
  );
};

const DynamicMiniMap = ({
  config,
  data,
  dataPoint,
  eventHandlers,
  getColumnDisplayName,
  getUnit,
  id,
  isItemHovered,
  miniMapObjectsNameKey,
  somethingIsHovered,
}) => {
  const dispatch = useDispatch();
  const { chartType, diagrams, xAxis, yAxis } = config;
  const colorScale = chroma.scale(["#0f0", "#ff0", "#f00"]);

  const userMinMax = config?.[settingsOptions.COLOR_BAR_RANGE];

  const { dataWithColorAndValueMemoized, max, min } = useMemo(() => {
    const nonNullValues = data?.filter((point) => isNumeric(point[dataPoint]));
    const { max: dataMax, min: dataMin } = findDataRange({
      data: nonNullValues,
      dataKeys: [dataPoint],
    });
    const min = userMinMax?.[0] ?? dataMin;
    const max = userMinMax?.[1] ?? dataMax;

    const dataWithColorAndValueMemoized = data?.map((point) => {
      const opacity =
        somethingIsHovered &&
        isItemHovered &&
        !isItemHovered({
          chartType,
          diagrams,
          row: point,
          xAxis,
          yAxis,
        })
          ? 0
          : 1;
      return {
        ...point,
        center: [point.latitude, point.longitude],
        color: getMapPointColor({
          colorScale,
          max,
          min,
          opacity,
          value: point[dataPoint],
        }),
        name: point[miniMapObjectsNameKey],
        value: point[dataPoint],
      };
    });
    return { dataWithColorAndValueMemoized, max, min };
  }, [data, dataPoint]);

  const colorMin = getMapPointColor({ max, min, value: min });
  const colorMax = getMapPointColor({ max, min, value: max });

  const dataPointUnit = getUnit({ config, quantity: dataPoint });
  const dataPointUnitText = getAxisNameWithUnit({ unit: dataPointUnit });

  const screenshotRef = useRef();

  useEffect(() => {
    const dataToDownload = dataWithColorAndValueMemoized?.map((point) => {
      return {
        center: point.center,
        color: point.color,
        [dataPoint]: point.value,
      };
    });
    dispatch(setDataToDownload({ data: dataToDownload, id }));
  }, [id, dataWithColorAndValueMemoized]);

  const eventHandlersMemoized = useCallback(eventHandlers(data, config), [JSON.stringify([data, config])]);
  return (
    <div className="h-full w-full">
      <Icon
        className="absolute right-1 top-[2px]  z-10"
        color="var(--clr-gray-500)"
        iconName="Download"
        onClick={() => {
          html2canvas(screenshotRef.current, {
            useCORS: true,
          }).then((canvasSnapshot) => {
            const screenshot = canvasSnapshot.toDataURL("image/png");
            saveAs(screenshot, `${dataPoint}.png`);
          });
        }}
        size="md"
      />
      <Stack className="relative h-full w-full" flexDirection="column" ref={screenshotRef}>
        <div className="h-8 text-center">
          <span className="t-heading-l mb-1">{getColumnDisplayName({ colName: dataPoint }) + dataPointUnitText}</span>
        </div>
        <div className="relative w-full flex-1">
          {/* Pass key to the map for force rezooming when only data gets updated */}
          <MyMap
            data={dataWithColorAndValueMemoized}
            eventHandlers={eventHandlersMemoized}
            key={data}
            rerenderVariable={JSON.stringify([data, dataPoint])}
            resizeTriggerVariables={JSON.stringify([config.w, config.h])}
          />
          <ColorBar className=" absolute right-2 z-[999]" colorMax={colorMax} colorMin={colorMin} max={max} min={min} />
        </div>
      </Stack>
    </div>
  );
};

const ChartType = ({ chartType, multithread, xDataKey = "", ...props }) => {
  if (!props.chartsConfigs?.length) return;
  if (chartType === chartTypesEnums.COMPOSED) {
    if (!props?.data?.length || !xDataKey) return;
    return multithread ? (
      <DynamicComboChartMultithread {...{ xDataKey, ...props }} />
    ) : (
      <DynamicComboChart {...{ xDataKey, ...props }} />
    );
  }

  if (chartType === chartTypesEnums.HISTOGRAM) {
    if (!props?.chartsConfigs?.filter((k) => k.dataKey)?.length) return;
    return <DynamicHistogramChart {...{ ...props }} />;
  }

  if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {
    if (!props?.chartsConfigs.length) return;
    return multithread ? (
      <DynamicPieChartMultithread {...{ ...props, chartType }} />
    ) : (
      <DynamicPieChart {...{ ...props, chartType }} />
    );
  }

  if ([chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.LINE].includes(chartType))
    return multithread ? (
      <DynamicStackedChartMultithread {...{ ...props, chartType, xDataKey }} />
    ) : (
      <DynamicStackedChart {...{ ...props, chartType, xDataKey }} />
    );

  if (chartType === chartTypesEnums.KPI)
    return multithread ? (
      <DynamicKPIMultithread {...{ ...props, chartType, xDataKey }} />
    ) : (
      <DynamicKPI {...{ ...props, chartType, xDataKey }} />
    );

  //TODO: dataPoint change doesn't rerender the heatmap? Why error?
  if (chartType === chartTypesEnums.HEATMAP && props.dataPoint) {
    if (!props?.dataPoint || !xDataKey || !props?.yDataKey) return;
    return multithread ? (
      <DynamicHeatmapMultithread {...{ xDataKey, ...props }} />
    ) : (
      <DynamicHeatmap {...{ xDataKey, ...props }} />
    );
  }
  if (chartType === chartTypesEnums.MINI_MAP && props.dataPoint) {
    return <DynamicMiniMap {...{ xDataKey, ...props }} />;
  }
};

const colorGroups = [
  "secondary-blue",
  "mystic-red",
  "vivid-orchid",
  "plunge",
  "star",
  "gray",
  "leaftech-blue",
  "tangerine",
  "cathode-green",
  "bright-indigo",
];

const spectrum = [600, 400, 200, 800];
let flatColors = [];
spectrum.forEach((s) => {
  colorGroups.forEach((group) => {
    flatColors.push(`--clr-${group}-${s}`);
  });
});

const stackedColors = spectrum.map((s) => {
  return colorGroups.map((group) => `--clr-${group}-${s}`);
});

const getItemColor = (stackIdx, partitionIdx) => {
  var style = getComputedStyle(document.documentElement);
  const isStacked = stackIdx >= 0;
  const isPartitioned = partitionIdx >= 0;

  if (!isStacked && !isPartitioned) {
    return style.getPropertyValue(flatColors[0]).trim();
  }
  if (!(isStacked && isPartitioned)) {
    return style.getPropertyValue(flatColors[(stackIdx ?? partitionIdx) % flatColors.length]).trim();
  }
  const validStackIdx = stackIdx % stackedColors.length;
  const validPartitionIdx = partitionIdx % stackedColors[0].length;

  return style.getPropertyValue(stackedColors[validStackIdx][validPartitionIdx]);
};

const DynamicChartSettings = ({ diagrams, selectedChartType, setDiagrams, settings }) => {
  const handleAddDiagram = () => {
    setDiagrams([...diagrams, diagramInitialConfig]);
  };

  const diagramsSettings = settings?.filter((setting) => setting.isInDiagramsPart);
  const otherSettings = settings?.filter((setting) => !setting.isInDiagramsPart);
  const hasMultipleDiagrams = [chartTypesEnums.COMPOSED, chartTypesEnums.HISTOGRAM].includes(selectedChartType);

  return (
    <Stack className="mt-4 w-full gap-2 " direction="column">
      {otherSettings?.map((setting) => {
        if (!setting.visible()) return;
        const { filterType, ...args } = setting;
        const filterFactory = new DynamicFilterFactory(filterType, args);
        const filterInstance = filterFactory.createFilterClassInstance();
        if (filterInstance) {
          const FilterComponent = filterInstance.createComponent();
          return (
            <Stack className="w-full" justifyContent="space-between" key={setting.filterName}>
              <span className="t-heading-m block w-1/3">{setting.filterLabel} :</span>
              {FilterComponent}
            </Stack>
          );
        }
      })}
      {diagrams?.map((diagram, index) => {
        return (
          <>
            {hasMultipleDiagrams && (
              <Stack className="mt-1 items-center" gap={2}>
                <Icon
                  color="red"
                  iconName="Close"
                  onClick={() => setDiagrams(diagrams.filter((_d, i) => i !== index))}
                  size="sm"
                />
                <span className="t-heading-m block">Diagram {index + 1}:</span>
              </Stack>
            )}
            {diagramsSettings?.map((setting) => {
              if (!setting.visible(index)) return;
              setting.config = diagram;
              setting.setConfig = setting.setConfigFn(index);

              const { filterType, ...args } = setting;
              const filterFactory = new DynamicFilterFactory(filterType, args);
              const filterInstance = filterFactory.createFilterClassInstance();
              if (filterInstance) {
                const FilterComponent = filterInstance.createComponent();
                return (
                  <Stack className="w-full" justifyContent="space-between" key={setting.filterName}>
                    <span className="t-heading-m block w-1/3">{setting.filterLabel} :</span>
                    {FilterComponent}
                  </Stack>
                );
              }
            })}
          </>
        );
      })}
      {hasMultipleDiagrams && (
        <Button className="mt-1" onClick={handleAddDiagram} size="sm" variant="primary">
          + Add Diagram
        </Button>
      )}
    </Stack>
  );
};

const ChartFilters = ({ filters }) => {
  return (
    <Stack flexDirection="column" gap={2}>
      {filters?.map((filter) => {
        if (!filter.visible()) return;
        const { filterType, ...args } = filter;
        const filterFactory = new DynamicFilterFactory(filterType, args);
        const filterInstance = filterFactory.createFilterClassInstance();
        if (filterInstance) {
          const FilterComponent = filterInstance.createComponent();
          return FilterComponent;
        }
      })}
    </Stack>
  );
};

const ChartNavbar = ({
  enterFullScreenMode,
  exitFullScreenMode,
  handleClick,
  hasFilters,
  id,
  isFullScreen,
  isVisible,
  onClose,
  setChartSelectionIsOpen,
  setIsVisible,
}) => {
  const configsIconsColor = "var(--clr-secondary-blue-500)";
  const items = [
    {
      color: "var(--clr-mystic-red-500)",
      iconName: "Trash",
      onClick: onClose,
    },
    {
      iconName: isFullScreen ? "FullscreenExit" : "Fullscreen",
      onClick: isFullScreen ? exitFullScreenMode : enterFullScreenMode,
      stroke: true,
    },
    {
      iconName: isVisible ? "EyeClose" : "EyeOpen",
      onClick: () => setIsVisible(!isVisible),
    },
    {
      iconName: "Settings",
      onClick: () => handleClick("settings"),
    },
    {
      iconName: "Filter",
      inactive: !hasFilters,
      onClick: () => handleClick("filters"),
    },
    {
      iconName: "Piechart",
      onClick: (e) => {
        setChartSelectionIsOpen((chartSelectionIsOpen) => !chartSelectionIsOpen);
      },
    },
  ];

  const dataToDownload = useSelector((store) => store.energyPerformanceNew).dataToDownload?.[id];
  return (
    <Stack className="h-6 w-full cursor-grab items-center gap-4  rounded bg-blue-50 px-2 " flexDirection="row-reverse">
      {items?.map((item) => {
        if (!item.inactive)
          return (
            <Icon
              className="cancelDrag  cursor-pointer "
              color={item.color || configsIconsColor}
              iconName={item.iconName}
              onClick={item.onClick}
              stroke={item.stroke && configsIconsColor}
              svgClassName="w-4 h-4 hover:scale-[1.3] transition"
            />
          );
      })}
      <EmptyErrorBoundary>
        {dataToDownload && (
          <CSVLink data={dataToDownload}>
            <Icon
              className="cancelDrag  cursor-pointer"
              color={configsIconsColor}
              iconName="Download"
              size="md"
              svgClassName="hover:scale-[2] transition"
            />
          </CSVLink>
        )}
      </EmptyErrorBoundary>
    </Stack>
  );
};

const DynamicChartNavbarAndSidebar = forwardRef(function DynamicChartNavbarAndSidebarWithRef(props, ref) {
  const {
    chartType,
    diagrams,
    filters,
    id,
    isConfigSetting,
    isFullScreen,
    isVisible,
    multithread,
    onClose,
    setChartSelectionIsOpen,
    setDiagrams,
    setFullScreenChartId,
    setIsConfigSetting,
    setIsVisible,
    settings,
    xCoord,
  } = props;

  const { t } = useTranslation();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [section, setSection] = useState(null);
  const handleClick = (selectedSection) => {
    setSection(selectedSection);
    setOpenSidebar(true);
    // setIsConfigSetting(true)
  };

  useImperativeHandle(ref, () => ({
    childHandleClick: handleClick,
  }));

  const hasFilters = filters?.length > 0;
  return (
    <>
      <ChartNavbar
        enterFullScreenMode={() => setFullScreenChartId(id)}
        exitFullScreenMode={() => setFullScreenChartId(null)}
        handleClick={handleClick}
        hasFilters={hasFilters}
        id={id}
        isFullScreen={isFullScreen}
        isVisible={isVisible}
        onClose={() => {
          if (onClose) onClose();
        }}
        setChartSelectionIsOpen={setChartSelectionIsOpen}
        setIsVisible={setIsVisible}
      />
      {openSidebar && (
        <Modal
          disable
          onClose={() => {
            setOpenSidebar(false);
            // setIsConfigSetting(false)
          }}
          open={openSidebar}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: "#00000050",
              },
            },
          }}
        >
          <Paper
            className={`cancelDrag fixed bottom-8 top-8 z-[6] w-[60rem] overflow-y-auto rounded ${
              xCoord < 6 ? "right-8" : "left-8"
            }`}
            style={{
              backdropFilter: "blur(10px)",
              background: "rgba(255,255,255,0.6)",
            }}
          >
            {multithread && (
              <Stack className="mb-4 justify-between">
                <div className=" t-heading-m">
                  {/* <span>{t('EnergyPerformance.dashboard.liveCalculationMode')}</span> */}
                  {/* <Switch checked={isLiveMode} onChange={(e) => setIsLiveMode(e.target.checked)} /> */}
                  <Button
                    className={!isConfigSetting && "!bg-red-500"}
                    disabled={isConfigSetting}
                    onClick={() => {
                      setIsConfigSetting(true);
                      // setIsLiveMode(false)
                    }}
                    size="md"
                    variant="primary"
                  >
                    <Stack className="capitalize" gap={2}>
                      <Icon iconName="Pause" size="md" />
                      {t("EnergyPerformance.dashboard.pauseInstantCalculation")}
                    </Stack>
                  </Button>
                </div>
                <Button
                  className={isConfigSetting && "!bg-green-500"}
                  disabled={!isConfigSetting}
                  onClick={() => {
                    setIsConfigSetting(false);
                    setOpenSidebar(false);
                    // setIsLiveMode(true)
                  }}
                  size="md"
                  variant="primary"
                >
                  <Stack gap={2}>
                    <Icon iconName="Play" size="md" />
                    {t("general.run")}
                  </Stack>
                </Button>
              </Stack>
            )}
            {section === "settings" && (
              <DynamicChartSettings
                diagrams={diagrams}
                selectedChartType={chartType}
                setDiagrams={setDiagrams}
                settings={settings}
              />
            )}
            {section === "filters" && <ChartFilters filters={filters} />}
          </Paper>
        </Modal>
      )}
    </>
  );
});

const DynamicChartBody = ({
  chartType,
  config,
  dataPoint,
  dataTransformator,
  dataTransformatorMultithread,
  diagrams,
  eventHandlers,
  filters,
  generalData,
  getColor,
  getColumnDisplayName,
  getColumnSortFunction,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  height,
  hoverEffect,
  id,
  isConfigSetting,
  isError,
  isItemHovered,
  isLoading,
  legendsPosition,
  miniMapObjectsNameKey,
  multithread,
  onClick,
  onHover,
  onLegendSelect,
  onMouseOut,
  settings,
  somethingIsHovered,
  sortValue,
  specificData,
  title,
  width,
  xAxis,
  yAxis,
}) => {
  const { t } = useTranslation();
  const data = specificData?.length ? specificData : generalData;

  const [filteredData, setFilteredData] = useState(data);

  const triggerFitleringDependency = JSON.stringify([
    filters?.filter((f) => f.mainDataKey)?.map((f) => f?.config[f?.filterName]),
    settings?.filter((s) => s.mainDataKey && s.useAsDataFilter)?.map((s) => s?.config[s?.filterName]),
  ]);

  const piechartDependency = JSON.stringify(config.chartType === chartTypesEnums.PIE && config);

  useEffect(() => {
    if (!isLoading && !isConfigSetting) {
      const unsortedData = [...data];
      let partiallyFilteredData = unsortedData;
      filters.forEach((filter) => {
        const { filterType, mainDataKey, ...args } = filter;
        if (!mainDataKey) return;
        const filterFactory = new DynamicFilterFactory(filterType, args);
        const filterInstance = filterFactory.createFilterClassInstance();
        partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey);
      });
      settings.forEach((setting) => {
        const { filterType, mainDataKey, useAsDataFilter, ...args } = setting;
        if (!mainDataKey || !useAsDataFilter) return;
        const filterFactory = new DynamicFilterFactory(filterType, args);
        const filterInstance = filterFactory.createFilterClassInstance();
        partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey);
      });

      setFilteredData(partiallyFilteredData);
    }
  }, [
    generalData,
    isLoading,
    specificData,
    triggerFitleringDependency,
    config?.w,
    config?.h,
    piechartDependency,
    isConfigSetting,
  ]);

  return (
    <div className="relative h-full">
      <LoadingOrEmptyWrapper
        height="100%"
        loadingText={t("general.loadingDataPleaseWait")}
        showEmpty={!data?.length || isError}
        showLoading={isLoading}
      >
        <ResponsiveContainer height="100%" width="100%">
          {/* <Profiler id={chartType + Math.random()} onRender={(id, phase, actualDuration) => {
            console.log(chartType, actualDuration);
          }}> */}
          <ChartType
            chartsConfigs={diagrams}
            chartType={chartType}
            config={config}
            data={filteredData}
            dataPoint={dataPoint}
            dataTransformator={dataTransformator}
            dataTransformatorMultithread={dataTransformatorMultithread}
            eventHandlers={eventHandlers}
            getColor={getColor}
            getColumnDisplayName={getColumnDisplayName}
            getColumnSortFunction={getColumnSortFunction}
            getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
            getUnit={getUnit}
            height={height}
            hoverEffect={hoverEffect}
            id={id}
            isConfigSetting={isConfigSetting}
            isItemHovered={isItemHovered}
            legendsPosition={legendsPosition}
            miniMapObjectsNameKey={miniMapObjectsNameKey}
            multithread={multithread}
            onClick={onClick}
            onHover={onHover}
            onLegendSelect={onLegendSelect}
            onMouseOut={onMouseOut}
            somethingIsHovered={somethingIsHovered}
            sort={sortValue}
            sortBy={xAxis === "index" ? sortValue : xAxis}
            title={title}
            width={width}
            xDataKey={xAxis}
            yDataKey={yAxis}
          />
          {/* </Profiler> */}
        </ResponsiveContainer>
      </LoadingOrEmptyWrapper>
    </div>
  );
};

const ChartTypeSelection = ({ chartOptions, handleChartSelection }) => {
  return (
    <Grid className="mt-1 h-full w-full" container justifyContent="space-evenly" spacing={2}>
      {chartOptions?.map((option, index) => {
        const IconComponent = option.icon;
        return (
          <Grid className="transition hover:scale-[1.2]" item key={index} xs={3}>
            <Stack className="items-center justify-center">
              <Stack
                className="h-15 w-15 cursor-pointer rounded-xl bg-[#f5f5ff] p-2 hover:bg-blue-100 "
                direction="column"
                onClick={() => {
                  handleChartSelection(option.type);
                }}
              >
                <IconComponent color={option.color} size={32} />
                <span className="t-label-m">{option.label} </span>
              </Stack>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

// The userLanguage parameter is used only to make a rerender when changed.
// TODO: Use a better approach
const DynamicChartMemoized = ({
  chartTypes,
  config,
  dataTransformator,
  dataTransformatorMultithread,
  eventHandlers,
  filters,
  generalData,
  getColor,
  getColumnDisplayName,
  getColumnSortFunction,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  height,
  hoverEffect,
  id,
  isFullScreen,
  isItemHovered,
  miniMapObjectsNameKey,
  multithread,
  onClick,
  onClose,
  onHover,
  onLegendSelect,
  onMouseOut,
  setConfig,
  setFullScreenChartId,
  settings,
  somethingIsHovered,
  specificDataGetter,
  userLanguage,
  xCoord,
}) => {
  console.log(id);
  // console.log('DynamicChartMemoized')

  // const dispatch = useDispatch()

  // const config = useSelector(store => store.dynamicDashboard.Portfolio?.allConfigs?.[id], shallowEqual)

  // const setConfig = useCallback((key, value) => dispatch(setSingleConfig({ id, config: { ...config, [key]: value }, appName: 'Portfolio' }))
  //   , [config])
  const { chartType, dataPoint, diagrams, legendsPosition, sortValue, title, xAxis, yAxis } = config;

  const setChartType = (newValue) => setConfig("chartType", newValue);

  const { isError, isLoading, specificData } = specificDataGetter(config);
  const [chartSelectionIsOpen, setChartSelectionIsOpen] = useState(!chartType);

  const navbarAndSidebarRef = useRef();
  const handleChartSelection = (type) => {
    setChartType(type);
    setChartSelectionIsOpen(false);
    navbarAndSidebarRef.current?.childHandleClick("settings");
  };

  const color = "var(--clr-secondary-blue-400)";
  const allAvailableChartOptions = [
    {
      color,
      icon: LiaChartAreaSolid,
      label: "Area",
      type: chartTypesEnums.AREA,
    },
    {
      color,
      icon: PiChartDonutThin,
      label: "Doughnut",
      type: chartTypesEnums.DOUGHNUT,
    },
    {
      color,
      icon: IoPieChartOutline,
      label: "Pie",
      type: chartTypesEnums.PIE,
    },
    {
      color,
      icon: GiHistogram,
      label: "Histogram",
      type: chartTypesEnums.HISTOGRAM,
    },
    {
      color,
      icon: TbChartHistogram,
      label: "Combo",
      type: chartTypesEnums.COMPOSED,
    },
    {
      color,
      icon: IoBarChartOutline,
      label: "Bar",
      type: chartTypesEnums.BAR,
    },
    {
      color,
      icon: PiChartLineLight,
      label: "Line",
      type: chartTypesEnums.LINE,
    },
    {
      color,
      icon: TbSum,
      label: "KPI",
      type: chartTypesEnums.KPI,
    },
    {
      color,
      icon: TfiLayoutGrid4Alt,
      label: "Heatmap",
      type: chartTypesEnums.HEATMAP,
    },
    {
      color,
      icon: FaMapLocationDot,
      label: "Map",
      type: chartTypesEnums.MINI_MAP,
    },
  ];

  const chartOptions = allAvailableChartOptions.filter(
    (chartOption) => !chartTypes || chartTypes?.includes(chartOption.type)
  );

  const [isVisible, setIsVisible] = useState(true);

  const setDiagrams = (newValue) => setConfig("diagrams", newValue);

  const [isConfigSetting, setIsConfigSetting] = useState(false);

  return (
    <>
      <Paper className="h-full p-4">
        <div className={`h-full w-full ${chartSelectionIsOpen && "bg-white"}`}>
          <DynamicChartNavbarAndSidebar
            chartType={chartType}
            diagrams={diagrams}
            filters={filters}
            id={id}
            isConfigSetting={isConfigSetting}
            isFullScreen={isFullScreen}
            isVisible={isVisible}
            multithread={multithread}
            onClose={onClose}
            ref={navbarAndSidebarRef}
            setChartSelectionIsOpen={setChartSelectionIsOpen}
            setDiagrams={setDiagrams}
            setFullScreenChartId={setFullScreenChartId}
            setIsConfigSetting={setIsConfigSetting}
            setIsVisible={setIsVisible}
            settings={settings}
            xCoord={xCoord}
          />
          <TextErrorBoundary>
            {isVisible && (
              <div className=" cancelDrag" style={{ height: "calc(100% - 24px)" }}>
                {chartSelectionIsOpen && (
                  <ChartTypeSelection chartOptions={chartOptions} handleChartSelection={handleChartSelection} />
                )}
                {!chartSelectionIsOpen && !isConfigSetting && (
                  <DynamicChartBody
                    chartType={chartType}
                    config={config}
                    dataPoint={dataPoint}
                    dataTransformator={dataTransformator}
                    dataTransformatorMultithread={dataTransformatorMultithread}
                    diagrams={diagrams}
                    eventHandlers={eventHandlers}
                    filters={filters}
                    generalData={generalData}
                    getColor={getColor}
                    getColumnDisplayName={getColumnDisplayName}
                    getColumnSortFunction={getColumnSortFunction}
                    getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
                    getUnit={getUnit}
                    height={height}
                    hoverEffect={hoverEffect}
                    id={id}
                    // isConfigSetting={isConfigSetting && !isLiveMode}
                    isConfigSetting={isConfigSetting}
                    isError={isError}
                    isItemHovered={isItemHovered}
                    isLoading={isLoading}
                    legendsPosition={legendsPosition}
                    miniMapObjectsNameKey={miniMapObjectsNameKey}
                    multithread={multithread}
                    onClick={onClick}
                    onHover={onHover}
                    onLegendSelect={onLegendSelect}
                    onMouseOut={onMouseOut}
                    settings={settings}
                    somethingIsHovered={somethingIsHovered}
                    sortValue={sortValue}
                    specificData={specificData}
                    title={title}
                    width="100%"
                    xAxis={xAxis}
                    yAxis={yAxis}
                  />
                )}
              </div>
            )}
          </TextErrorBoundary>
        </div>
      </Paper>
    </>
  );
};

export default React.memo(DynamicChartMemoized, isEqual);
