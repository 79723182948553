import React from "react";

const LegendBullet = ({ color, height, type, width }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        borderRadius: type === "circle" ? "50%" : "0",
        height: height,
        width: width,
      }}
     />
  );
};

export default LegendBullet;
