export const getFlattenData = ({ columnsToPivot, data, newColumnName, valueName }) => {
  const flattenData = [];
  for (const row of data) {
    const rowCopy = { ...row };
    for (const col of columnsToPivot) delete rowCopy[col];
    for (const key of Object.keys(row)) {
      if (columnsToPivot?.includes(key)) {
        const flatItem = {
          ...rowCopy,
          [newColumnName]: key,
          [valueName]: row[key],
        };
        flattenData.push(flatItem);
      }
    }
  }
  return flattenData;
};
