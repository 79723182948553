import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  breakpoints: {
    values: {
      "2xl": 1440,
      lg: 1230,
      loginChangeBackground: 1160,
      md: 900,
      sm: 600,
      xl: 1330,
      xl2: 1400,
      xs: 0,
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        onKeyDown: (event) => {
          if (event.code === "Space" || event.code === "Enter") {
            event.currentTarget.click();
          }
        },
        sx: {
          ":active": {
            background: "var(--clr-gray-50)",
            outline: "1px solid var(--clr-gray-100)",
          },
          ":focus:not(:active)": {
            outline: "3px solid var(--clr-leaftech-blue-200)",
          },
          ":hover": {
            border: "1px solid var(--clr-leaftech-blue-100)",
            boxShadow: "var(--box-shadow-up-hover)",
          },
          backgroundColor: "var(--clr-white)",
          border: "1px solid transparent",
          borderRadius: "8px",
          boxShadow: "var(--box-shadow-elevation-100)",
          cursor: "pointer",
          outline: "1px solid transparent",
          padding: "1.6rem",
          transition: "all 0.15s ease-out",
        },
        tabIndex: 0,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          padding: "4px 8px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        sx: {
          backgroundColor: "var(--clr-white)",
          border: "1px solid var(--clr-gray-100)",
          borderRadius: "10px",
          color: "inherit",
          padding: "2.4rem",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          padding: "4px 8px",
        },
      },
    },
    MuiStack: {
      defaultProps: {
        alignItems: "center",
        direction: "row",
        justifyContent: "flex-start",
        useFlexGap: true,
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        sx: {
          "&.Mui-selected": {
            backgroundColor: "var(--clr-secondary-blue-300)",
            border: "none",
            color: "var(--clr-white)",
            outline: "none",
          },
          "&:hover:not(.Mui-selected)": {
            backgroundColor: "var(--clr-secondary-blue-100)",
          },
          background: "transparent",
          borderRadius: 2,
          color: "var(--clr-secondary-blue-300)",
          color: "black",
          cursor: "pointer",
          display: "flex",
          fontWeight: 900,
          justifyContent: "center",
          margin: "0 4px",
          textAlign: "center",
        },
      },
    },

    MuiTabs: {
      defaultProps: {
        centered: true,
        indicatorColor: "transparent",
        sx: {
          alignItems: "center",
          backgroundColor: "var(--clr-secondary-blue-50)",
          // borderRadius: 2,
          display: "flex",
          height: "24px",
          justifyContent: "space-between",
          padding: "8px 0px",
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        sx: {
          fontFamily: "Nunito Sans",
          fontSize: "1.4rem",
          fontWeight: 400,
          letterSpacing: "0em",
          lineHeight: "2rem",
        },
      },
    },
  },
  spacing: (factor) => `${0.4 * factor}rem`,
});
