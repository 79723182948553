import { Paper, Stack } from "@mui/material";
import { sum } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

import {
  useAddMeasure,
  useAddSelectedMeasure,
  useDeleteMeasure,
  useDeleteSelectedMeasure,
  useGetBuilding,
  useGetBuildingEPCInfo,
  useGetTechData,
  useModifyMeasure,
  useModifySelectedMeasure,
} from "../../api/hooks/allHooks";
import { WizardContext } from "../../context/Wizard";
import { FilterTypes } from "../../enums/components";
import {
  columnsAPINamesEnums,
  columnsDisplayNamesEnums,
  columnsUnits,
  getFinancialDashboardColumnDisplayName,
} from "../../enums/Modernization/financialDashboard";
import { energyEfficiencyClassConf } from "../../functions/AssetOverview";
import { eventNames, trackGAEvent, withGAEventTracking } from "../../functions/googleAnalytics";
import { getSingleMeasureFinancialValuesTimeSeries } from "../../functions/Modernization";
import { extractTabData, getEnabledTabs } from "../../functions/TechnicalData";
import { sumByKeys } from "../../utils/dataManipulation";
import { aggregatedSumValue, sortData } from "../../utils/dynamicDashboard";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { addCommaToNumber, formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { cn } from "../../utils/styling";
import { KPIs } from "../BuildingProfile/KPIs";
import { Button } from "../ButtonNew";
import { Checkbox } from "../Checkbox";
import { CustomModal } from "../CustomModal";
import { DataCard } from "../DataCard";
import { Dropdown } from "../Dropdown";
import { DropdownSingleSelectNative } from "../DropdownSingleSelectNative";
import { Icon } from "../Icon";
import { NumericRangePicker } from "../NumericRangePicker";
import { Table } from "../Table";
import { TechTable } from "../TechnicalData/TechTable";
import CreatRetrofitMeasureForm from "./CreatRetrofitMeasureForm";
import EditRetrofitMeasureForm from "./EditRetrofitMeasureForm";
import { EnergyEfficiencyChartWithEchart } from "./EnergyEfficiencyChartWithEcharts";
import { EnergyEfficiencyClassTransition } from "./EnergyEfficiencyClassTransition";
import { FinancialDashbaord } from "./FinancialDashboard";
import { RetrofitsFinancialTimeSeriesChartWithEchart } from "./RetrofitsFinancialTimeSeriesChartWithEcharts";
import { SectionChips } from "./SectionChips";
import { StrandingChartWithEchart } from "./StrandingChartWithEchart";
// TODOs:
// 1-Better choice of dropdown and oneTime dropdown
// 2-modularize
// 3-new row a part of main table
// 4-new row isloading state
// 5-selected retrofits hover state vice versa
// 7-empty new row onBlur
// 8-selected retrofits row another color
// 9-invalidate selected retro by change in all retro
// 10-userbased remove/modify all retros
// 11-Dropdown full width

const TimelineItem = ({
  budget,
  DetailsButton,
  displayOptions,
  onChange,
  onDelete,
  options,
  retrofit,
  retrofitDisplayOptions,
  retrofitOptions,
  unit,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <Paper
      className={` relative h-37 w-60  p-4 pt-5`}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      {showDelete && (
        <div
          className="absolute right-1/2 top-1 block translate-x-1/2  cursor-pointer"
          onClick={onDelete}
          style={{
            backgroundColor: "red",
            borderRadius: "50%",
            padding: "5px",
          }}
        >
          <Icon color="white" iconName="Close" />
        </div>
      )}
      <DropdownSingleSelectNative
        className="t-body-xl mt-4"
        displayOptions={retrofitDisplayOptions}
        onChange={(e) => onChange({ fieldName: "name", value: e.target.value })}
        options={retrofitOptions}
        placeholder="Select Retrofit measure"
        selectedValue={retrofit?.name}
        setSelectedValue={() => {}}
        width="60px"
      />
      <DropdownSingleSelectNative
        className="t-numbers-xl mt-2 text-plunge-800"
        displayOptions={displayOptions}
        onChange={(e) => onChange({ fieldName: "year", value: e.target.value })}
        options={options}
        placeholder="Select year"
        selectedValue={retrofit?.year}
        setSelectedValue={() => {}}
        width="60px"
      />
      <p className="t-body-xs mt-3">
        {unit} {addCommaToNumber(Number(budget))}{" "}
      </p>
      <DetailsButton className="t-heading-m absolute bottom-3 right-3" row={retrofit} />
    </Paper>
  );
};

const NewTimelineItem = ({ onChange, retrofitDisplayOptions, retrofitOptions }) => {
  return (
    <Paper className={` relative flex h-35  w-60 items-center p-4`}>
      <DropdownSingleSelectNative
        className="t-body-xl"
        displayOptions={retrofitDisplayOptions}
        onChange={(e) => onChange({ value: e.target.value })}
        options={retrofitOptions}
        placeholder="Select Retrofit measure"
        selectedValue=""
        setSelectedValue={() => {}}
        width="60px"
      />
    </Paper>
  );
};

const AddNewTimeLineItem = ({ className, onClick }) => {
  return (
    <Paper className="h-35 w-60 cursor-pointer bg-gray-100" onClick={onClick}>
      <Stack className={`h-full items-center justify-center  ${className}`}>
        <Icon
          iconName="Add"
          size="lg"
          style={{
            transform: "scale(2)",
          }}
        />
      </Stack>
    </Paper>
  );
};

const perM2ToNormal = ({ buildingArea, perM2ToData }) => {
  const columnsToBeScaled = [
    columnsAPINamesEnums.INVESTMENTS,
    columnsAPINamesEnums.SERVICES_COST,
    columnsAPINamesEnums.COST_SAVINGS,
    columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS,
    columnsAPINamesEnums.ELECTRICITY_REDUCTION,
    columnsAPINamesEnums.HEAT_REDUCTION,
    columnsAPINamesEnums.TOTAL_COST,
    columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION,
    columnsAPINamesEnums.OWN_CAPITAL,
    columnsAPINamesEnums.FOEIGN_CAPITAL,
    columnsAPINamesEnums.SUBSIDIES,
    columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS,
    columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS,
    columnsAPINamesEnums.MAINTENANCE_COST,
    columnsAPINamesEnums.OTHER_COST_SAVINGS,
    columnsAPINamesEnums.EMBODIED_CARBON,
    columnsAPINamesEnums.REDUCTION_ELECTRICITY_END,
    columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY,
    columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND,
    columnsAPINamesEnums.REDUCTION_ENERGY_END,
    columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY,
    columnsAPINamesEnums.REDUCTION_HEAT_END,
    columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY,
  ];
  return perM2ToData?.map((row) => {
    const scaled = { ...row };
    Object.keys(row)
      ?.filter((col) => columnsToBeScaled.includes(col))
      ?.forEach((col) => {
        scaled[col] = Math.round(scaled[col] * buildingArea);
      });
    return scaled;
  });
};

export const Wizard = ({ projectId }) => {
  const user = getUserFromLocalStorage();
  const { t } = useTranslation();
  const {
    data,
    dataEmission,
    isAllmeasuresLoading,
    isSelectedMesuresLoading,
    measures,
    quantity,
    section,
    selectedMeaures,
    setYearRange,
    yearRange,
  } = useContext(WizardContext);

  const { data: building } = useGetBuilding(projectId);
  const buildingGrossArea = building?.gross_asset_basement_included;

  const [isPerM2, setIsPerM2] = useState(true);
  const [isYearRangeOpen, setIsYearRangeOpen] = useState(false);
  const getPerM2AdjustedUnit = (perM2Unit) => (isPerM2 || !perM2Unit ? perM2Unit : perM2Unit.replace("/m²", ""));

  const [dataYears, setDataYears] = useState([]);

  ///////////// All retrofit measures table
  const [scrollDown, setScrollDown] = useState(false);

  const unit = getPerM2AdjustedUnit("€/m²");

  const isInputInData = (input, data, dataKey) => {
    if (!input || !data?.length > 0) return false;
    for (const row of data) {
      if (row[dataKey] === input) return true;
    }
    return false;
  };

  const DetailsButton = ({ className, row }) => {
    const correspondingRetrofit = measures?.find((measure) => measure?.name === row?.name);
    const pdfUrl = correspondingRetrofit?.pdf_url;
    const styleClass = pdfUrl ? "cursor-pointer text-[#00b0f0]" : "cursor-text text-[#ccc]";
    let content = {};
    if (correspondingRetrofit) {
      for (const key of Object.keys(mapping)) {
        content[key] = correspondingRetrofit[mapping[key]];
      }
    }
    return (
      <a
        className={` ${styleClass} ${className}`}
        href={pdfUrl}
        onClick={() => {
          setModalContent(content);
          trackGAEvent(eventNames.MODERN_RETROFIT_DETAILS);
          // if (pdfUrl) setShowModal(true);
        }}
        rel="noreferrer"
        target="_blank"
      >
        Details
      </a>
    );
  };

  const componentAndSubcomponentOptions = {
    "Building Envelope": ["Window", "Ceiling", "Facade", "Doors", "Floor", "Other", "Walls", "Grounding"],
    "Building Technology": [
      "Luminance",
      "Ventilation System",
      "Heat Distribution System",
      "Cooling Distribution System",
      "Building Management",
      "Building Automation",
      "Other",
    ],
    "Energy System": [
      "Central Heating System",
      "Central Cooling Systems",
      "Central Electricity System",
      "PV-System",
      "Photovoltaic",
      "Energy Storage",
      "Energy System Design",
      "Renewable Energy",
      "Other",
    ],
    Other: ["Other"],
    Services: ["Data Services", "Consulting", "Education", "Cleaning", "Maintenance", "Other"],
  };

  const componentOptions = Object.keys(componentAndSubcomponentOptions);
  const getSubCopmponentOptions = (component) => componentAndSubcomponentOptions[component] ?? [];

  const retrofitTypeOptions = ["Optimization", "Replacement", "Modernization", "Addition", "Other"];

  let headers = [
    {
      dataKey: columnsAPINamesEnums.NAME,
      errorTransformation: (row, data, input) => {
        const rowId = row.id;
        const correspondingRow = data?.find((row) => row.id === rowId);
        if (!input) return "Can not be blank!";
        if (correspondingRow) if (correspondingRow?.name === input) return "";
        return isInputInData(input, data, "name") ? "Select another name, it’s already selected." : "";
      },
      isInDefault: true,
      placeholder: "+ " + t("Modernization.wizard.addRetrofitMeasure"),
    },
    {
      cellType: "dropdown",
      dataKey: columnsAPINamesEnums.TYPE_OF_MESSURE,
      options: () => retrofitTypeOptions,
      placeholder: columnsDisplayNamesEnums.TYPE_OF_MESSURE,
    },
    {
      cellType: "dropdown",
      dataKey: columnsAPINamesEnums.COMPONENT,
      options: () => componentOptions,
      placeholder: "Component",
    },
    {
      cellType: "dropdown",
      dataKey: columnsAPINamesEnums.SUB_COMPONENT,
      options: (row) => getSubCopmponentOptions(row["component"]),
      placeholder: columnsDisplayNamesEnums.SUB_COMPONENT,
    },
    {
      dataKey: columnsAPINamesEnums.MEASURE_DESCRIPTION,
      isInDefault: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.ADAPTATION,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.ELECTRICITY_REDUCTION,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.HEAT_REDUCTION,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REDUCTION_ELECTRICITY_END,
      editable: () => false,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REDUCTION_HEAT_END,
      editable: () => false,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY,
      editable: () => false,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY,
      editable: () => false,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND,
      editable: () => false,
      isInDefault: true,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REDUCTION_ENERGY_END,
      editable: () => false,
      isInDefault: true,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY,
      editable: () => false,
      isInDefault: true,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS,
      editable: () => false,
      isInDefault: true,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.EMBODIED_CARBON,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.ROI,
      editable: () => false,
      isInDefault: true,
      isReadOnly: true,
    },
    ///////////////////////////// ARR [years]
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.INFLATION_RATE,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS,
      editable: () => false,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS,
      editable: () => false,
      isReadOnly: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.OTHER_COST_SAVINGS,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.MAINTENANCE_COST,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.SERVICES_COST,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.INVESTMENTS,
      isInDefault: true,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.OWN_CAPITAL,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.SUBSIDIES,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.FOEIGN_CAPITAL,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.INTEREST_RATE,
    },
    {
      cellType: "dropdown",
      dataKey: columnsAPINamesEnums.LOAN_TYPE,
      options: () => ["Annuity loan", "Repayment loan", "Fixed loan"],
      placeholder: columnsDisplayNamesEnums.LOAN_TYPE,
    },
    {
      contentType: "number",
      dataKey: columnsAPINamesEnums.REPAYMENT_TIME,
    },
    // {
    //   dataKey: columnsAPINamesEnums.COST_SAVINGS,
    //   contentType: 'number',
    //   isInDefault: true,
    //   editable: (row) => false,
    //   isReadOnly: true,
    // },
    {
      cellType: "downloadZipFile",
      dataKey: "",
      downloadZipFile: () => null,
      href: () => null,
      isReadOnly: true,
      label: "Documentation",
      valueTransformation: (row) => <DetailsButton row={row} />,
    },
  ];

  headers = headers.map((header) => {
    const displayName = getFinancialDashboardColumnDisplayName({ colName: header.dataKey });
    const unit = getPerM2AdjustedUnit(columnsUnits[header.dataKey]);
    const label = `${displayName} ` + (unit ? ` [${unit}] ` : "");
    const valueTransformation = (row) =>
      header.contentType === "number" ? formatNumberBasedOnUser(row[header.dataKey]) : row[header.dataKey];

    return {
      ...header,
      editable: header.editable?.() ?? true,
      isInDefault: header.isInDefault ?? false,
      label: header.label ?? label,
      valueTransformation: header.valueTransformation ?? valueTransformation,
    };
  });

  const createNewMeasureFormItems = headers
    .filter((header) => header.editable && !header.isReadOnly)
    .map((header) => ({
      filterName: header.dataKey,
      filterType: header.cellType === "dropdown" ? FilterTypes.DROPDOWN_SINGLE : FilterTypes.INPUT,
      optionsFn: (formData) => header.options?.(formData),
      props: {
        label: header.label,
        type: header.contentType ?? "string",
      },
    }));

  const [selectedColumns, setSelectedColumns] = useState(headers.filter((h) => h.isInDefault).map((h) => h.dataKey));
  const selectedHeaders = headers.filter((h) => selectedColumns.includes(h.dataKey));

  const [measuresScaled, setMeasuresScaled] = useState([]);
  const [selectedMeauresScaled, setSelectedMeauresScaled] = useState([]);

  useEffect(() => {
    setMeasuresScaled(isPerM2 ? measures : perM2ToNormal({ buildingArea: buildingGrossArea, perM2ToData: measures }));
  }, [measures, isPerM2]);

  useEffect(() => {
    setSelectedMeauresScaled(
      isPerM2 ? selectedMeaures : perM2ToNormal({ buildingArea: buildingGrossArea, perM2ToData: selectedMeaures })
    );
  }, [selectedMeaures, isPerM2]);

  const { mutate: addMeasure } = useAddMeasure();

  const handleNewRowBlur = ({ fieldName, value }) => {
    if (!value) return;
    addMeasure({
      data: { [fieldName]: value, project: projectId, user: user?.user_id },
      onSettled: () => {
        setTimeout(() => {
          setScrollDown(false);
        }, 500);
      },
      onSuccess: () => {
        setScrollDown(true);
      },
      projectId,
    });
  };

  const handleDuplicateRow = ({ rowId }) => {
    const selectedMeasure = measures?.find((measure) => measure.id === rowId);
    if (!selectedMeasure?.name) return;
    let newName = selectedMeasure.name + " Copied";
    if (measures?.map((measure) => measure.name)?.includes(newName)) {
      newName =
        newName + " " + (Number(measures?.filter((measure) => measure.name?.indexOf(newName) > -1)?.length ?? 0) + 1);
    }

    addMeasure({
      data: { ...selectedMeasure, name: newName, project: projectId, user: user?.user_id },
      onSettled: () => {
        setTimeout(() => {
          setScrollDown(false);
        }, 500);
      },
      onSuccess: () => {
        setScrollDown(true);
      },
      projectId,
    });
  };

  const { mutate: deleteMeasure } = useDeleteMeasure();
  const handleDelete = ({ rowId }) => {
    deleteMeasure({ projectId, rowId });
  };

  const { mutate: modifyMeasure } = useModifyMeasure();
  const handleBlur = ({ fieldName, rowId, value }) => {
    modifyMeasure({
      data: { [fieldName]: value },
      projectId,
      rowId,
    });
  };

  // ==================================
  // Selected Retrofit measures
  // ==================================
  const [showModal, setShowModal] = useState(false);
  const mapping = {
    avg_carbon_dioxide_emission_post_modernization: null,
    avg_carbon_dioxide_emission_saving: "carbon_dioxide_reduction",
    avg_carbon_dioxide_emission_status_qo: null,
    avg_energy_consumption_post_modernization: null,
    avg_energy_consumption_saving: "utility_cost_reduction",
    avg_energy_consumption_status_quo: null,
    capex: "investments",
    energy_cost_saving: "services_cost",
    payback_period: "roi",
    pdf_url_value: "pdf_url",
    scenario_financing_label: null,
    scenario_number: "name",
    upgrades: null,
  };

  const [modalContent, setModalContent] = useState({});

  const measureOptions = () => {
    if (!measures?.length > 0 && !selectedMeaures?.length > 0) return [];
    const allMeasuresNames = measures?.map((row) => row.name);
    const selectedMeasuresNames = selectedMeaures?.map((row) => row.name);
    const availableOptions = allMeasuresNames?.filter((m) => !selectedMeasuresNames?.includes(m));
    return availableOptions;
  };

  const [isAddingNewRow, setIsAddingNewRow] = useState(false);

  const { isLoading: isAdding, mutate: addSelectedMeasure } = useAddSelectedMeasure();
  const handleNewSelectedRowBlur = ({ fieldName, value }) => {
    addSelectedMeasure({
      data: { [fieldName]: value, project: projectId, user: user?.user_id },
      projectId,
    });
  };

  const { isLoading: isDeleting, mutate: deleteSelectedMeasure } = useDeleteSelectedMeasure();
  const handleDeleteSelectedMeasure = ({ rowId }) => {
    deleteSelectedMeasure({ projectId, rowId });
  };

  const { mutate: modifySelectedMeasure } = useModifySelectedMeasure();
  const handleSelectedMeasureBlur = ({ fieldName, rowId, value }) => {
    modifySelectedMeasure({
      data: { [fieldName]: value },
      projectId,
      rowId,
    });
  };
  function SampleNextArrow(props) {
    const { className, onClick, style } = props;
    return <div className={className} onClick={onClick} style={{ ...style, background: "white", display: "block" }} />;
  }

  function SamplePrevArrow(props) {
    const { className, onClick, style } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, color: "black" }}
        // style={style}
      >
        <p style={{ color: "black", display: "block", height: "20px" }}>pre</p>
      </div>
    );
  }

  const settings = {
    centerMode: true,
    className: "center",
    draggable: true,
    infinite: false,
    initialSlide: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 3,
    swipeToSlide: true,
    variableWidth: true,
  };

  const sortTimeline = (data) => {
    if (!data?.length > 0) return data;
    const dataCopy = [...data];
    dataCopy.sort((a, b) => {
      if (a.year === null || a.year === undefined) return Infinity;
      if (b.year === null || b.year === undefined) return -Infinity;
      return a.year - b.year;
    });
    return dataCopy;
  };

  const selectedMeauresRiched = useMemo(() => {
    return selectedMeaures
      ?.filter((measure) => !!measure.year)
      ?.map((measure) => {
        const correspondingMeasure = measuresScaled?.find((m) => measure.name === m.name);
        if (!correspondingMeasure) return measure;
        return {
          ...measure,
          ...correspondingMeasure,
          [columnsAPINamesEnums.COST_SAVINGS]:
            correspondingMeasure[columnsAPINamesEnums.OTHER_COST_SAVINGS] +
            correspondingMeasure[columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS] +
            correspondingMeasure[columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS],
        };
      });
  }, [selectedMeaures, measuresScaled]);

  const kpisLabelsAndKeysMapping = [
    {
      dataKey: columnsAPINamesEnums.COST_SAVINGS,
      label: columnsDisplayNamesEnums.COST_SAVINGS,
      round: true,
      unit,
    },
    {
      dataKey: columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS,
      label: columnsDisplayNamesEnums.CARBON_DIOXIDE_SAVINGS,
      round: true,
      unit: getPerM2AdjustedUnit("kg/m²"),
    },
    {
      dataKey: columnsAPINamesEnums.OWN_CAPITAL,
      label: columnsDisplayNamesEnums.OWN_CAPITAL,
      round: true,
      unit,
    },
    {
      dataKey: columnsAPINamesEnums.FOEIGN_CAPITAL,
      label: columnsDisplayNamesEnums.FOEIGN_CAPITAL,
      round: true,
      unit,
    },
    {
      dataKey: columnsAPINamesEnums.SUBSIDIES,
      label: columnsDisplayNamesEnums.SUBSIDIES,
      round: true,
      unit,
    },
  ];

  kpisLabelsAndKeysMapping.forEach((item) => {
    item.label = "total " + item.label;
  });

  const filteredSelectedMeauresRiched = selectedMeauresRiched?.filter(
    (yearData) => yearData.year <= yearRange[1] && yearData.year >= yearRange[0]
  );
  let kpisData = sumByKeys({
    data: filteredSelectedMeauresRiched,
    keys: kpisLabelsAndKeysMapping.map((item) => item.dataKey),
  });

  if (!filteredSelectedMeauresRiched?.length) {
    kpisData = {
      [columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS]: 0,
      [columnsAPINamesEnums.COST_SAVINGS]: 0,
      [columnsAPINamesEnums.FOEIGN_CAPITAL]: 0,
      [columnsAPINamesEnums.OWN_CAPITAL]: 0,
      [columnsAPINamesEnums.SUBSIDIES]: 0,
    };
  }

  const [isTableFullscreen, setIsTableFullscreen] = useState(false);
  const [isAddMeasureModalOpen, setIsAddMeasureModalOpen] = useState(false);
  const [isEditMeasureModalOpen, setIsEditMeasureModalOpen] = useState(false);
  const [measureForEdit, setMeasureForEdit] = useState();

  const statusQuo = sortData({ data, dataKey: "year", decsending: false }).find(
    (item) => !!item.year && item.year >= yearRange[0]
  )?.current;
  const target = sortData({ data, dataKey: "year", decsending: true }).find(
    (item) => !!item.year && item.year <= yearRange[1]
  )?.primaryModernized;

  const allSelectedMeauresRichedTimeSeries = selectedMeauresRiched?.map((selectedMeaure) =>
    getSingleMeasureFinancialValuesTimeSeries({
      endYear: 2050,
      initialValues: {
        inflationRatePercentage: selectedMeaure[columnsAPINamesEnums.INFLATION_RATE],
        initialCostsSavings: selectedMeaure[columnsAPINamesEnums.COST_SAVINGS],
        interestRatePercentage: selectedMeaure[columnsAPINamesEnums.INTEREST_RATE],
      },
      startYear: selectedMeaure.year,
    })
  );

  const aggregateKeys = ["costSavings"];
  const SelectedMeasuresTimeSeriesAggregated = aggregatedSumValue({
    aggregateColumns: aggregateKeys,
    data: allSelectedMeauresRichedTimeSeries?.reduce((total, current) => [...total, ...current], []),
    groupByColumns: ["year"],
  });

  const filtered = SelectedMeasuresTimeSeriesAggregated?.filter(
    (yearData) => yearData.year <= yearRange[1] && yearData.year >= yearRange[0]
  );
  const aggregated = sumByKeys({ data: filtered, keys: aggregateKeys });
  kpisData[columnsAPINamesEnums.COST_SAVINGS] = aggregated?.["costSavings"];

  const emissionSavings = dataEmission
    ?.filter((yearData) => yearData.year <= yearRange[1] && yearData.year >= yearRange[0] && !!yearData.id)
    ?.map((yearData) => {
      const modernized = Math.min(
        ...(dataEmission
          ?.filter((row) => row.year === yearData.year && row.modernized !== undefined)
          ?.map((row) => row.modernized) ?? [])
      );

      return isFinite(modernized) ? yearData.current - modernized : 0;
    });
  kpisData[columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS] = sum(emissionSavings ?? []);
  if (!isPerM2)
    kpisData[columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS] =
      kpisData[columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS] * buildingGrossArea;

  let { data: epcInfoAll } = useGetBuildingEPCInfo(projectId);
  epcInfoAll = epcInfoAll?.length ? epcInfoAll[0] : {};
  const classification = epcInfoAll?.classification;
  let maxEnergyValue = 600;

  let thresholds = [];
  let labels = [];
  let minEnergyValue;
  const labelsMapping = {
    a: "A",
    a_plus: "A+",
    b: "B",
    c: "C",
    d: "D",
    e: "E",
    f: "F",
    g: "G",
    h: "H",
  };

  if (classification) {
    const activeClassesNonZero = Object.keys(labelsMapping).filter(
      (className) => classification[`class_${className}_min`]
    );
    const lastActiveClass = activeClassesNonZero[activeClassesNonZero?.length - 1];
    maxEnergyValue = classification[`class_${lastActiveClass}_min`] * 1.2;
    for (const className in labelsMapping) {
      const isLastActiveClass = className === lastActiveClass;
      const classMin = classification[`class_${className}_min`];
      const classMax = classification[`class_${className}_max`];

      if (!classMin && !classMax) continue;
      if (minEnergyValue === undefined) {
        minEnergyValue = classMin;
        thresholds.push(classMin);
      }
      if (classMax && !isLastActiveClass) {
        labels.push(labelsMapping[className]);
        thresholds.push(classMax);
      }
      if (classMax && isLastActiveClass) {
        labels.push(labelsMapping[className]);
      }
      if (classMin && !classMax) {
        labels.push(labelsMapping[className]);
      }
      if (isLastActiveClass) break;
    }
    thresholds.push(maxEnergyValue);
  }

  const energyEfficiencyClassConfAdjusted = energyEfficiencyClassConf
    .filter((energyClass) => labels?.includes(energyClass.rating))
    .map((energyClass) => {
      const classIndex = labels?.findIndex((label) => energyClass.rating === label);
      return {
        ...energyClass,
        max: thresholds?.[classIndex + 1],
      };
    });

  const tabName = "energyMix";
  const { data: techData, isLoading } = useGetTechData({ projectId, tabNames: [tabName] });
  const enabledTabs = getEnabledTabs(techData);
  const tabData = extractTabData({ activeTab: tabName, data: techData });

  const [isEnergyMixModalOpen, setIsEnergyMixModalOpen] = useState(false);

  return (
    <section className=" my-8 w-full  ">
      <CustomModal
        className="max-h-[90rem] overflow-scroll"
        height="95vh"
        modalOpen={showModal}
        setModalOpen={setShowModal}
        width="80vw"
      >
        {/* <SingleScenario scenario={modalContent} className="text-left" /> */}
        <div className="h-full w-full">
          {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={s} />;
          </Worker> */}
          {/* <iframe src={modalContent.pdf_url_value} width="50%" height="500px" /> */}
          {/* <iframe src={pd} width="50%" height="500px" /> */}
          {/* <embed
            type="application/pdf"
            // src="http://africau.edu/images/default/sample.pdf"
            src={modalContent.pdf_url_value}
          /> */}
          {/* <object
            data={modalContent.pdf_url_value}
            type="application/pdf"
            width="100%"
            height="500px"
          ></object> */}
          <object
            className="h-full w-full"
            // data="2024_Ventilation_Optimization.pdf"
            // data={modalContent.pdf_url_value}
            data={modalContent.pdf_url_value}
            type="application/pdf"
            // width="100%"
            // height="100%"
          />
          {/* <Document file={src}>
            <Page pageNumber={1} />
          </Document> */}
        </div>
      </CustomModal>
      <Stack className="mt-8 items-stretch  gap-8">
        <Paper className="flex-1">
          <SectionChips />
          {section === "crrem" ? (
            <StrandingChartWithEchart
              className="w-full  border-none bg-transparent pb-0 "
              isPerM2={isPerM2}
              onCrremChange={(crremData) => {
                setDataYears(crremData?.map((e) => e.year) || []);
              }}
              projectId={projectId}
              selectedMeaures={selectedMeaures}
              setIsPerM2={setIsPerM2}
            />
          ) : (
            <EnergyEfficiencyChartWithEchart
              chartHeight={400}
              className="w-full border-none bg-transparent pb-0"
              selectedMeaures={selectedMeaures}
              title={t("BuildingProfile.kpis.energyEfficiencyClass")}
            />
          )}
          <RetrofitsFinancialTimeSeriesChartWithEchart
            className="border-none bg-transparent pb-0"
            selectedMeauresRiched={selectedMeauresRiched}
            title={t("Modernization.wizard.financialDashboard")}
          />
        </Paper>
        <Paper className="relative w-[40rem]">
          <Stack className="items-start justify-between">
            <div className="text-center ">
              <h2 className="t-heading-l mb-1">{t("Modernization.goals.decarbonizationRoadmap")}</h2>
              <Stack className="t-heading-l items-center justify-center gap-1">
                <Icon iconName="Settings" onClick={() => setIsYearRangeOpen(!isYearRangeOpen)} />
                <span>
                  {yearRange[0]}-{yearRange[1]}
                </span>
              </Stack>
              <Stack
                className={cn(
                  " h-0 overflow-hidden transition-all duration-300 ease-linear",
                  isYearRangeOpen && "h-15"
                )}
              >
                <NumericRangePicker
                  changeByTyping
                  className={cn("mt-2 w-full")}
                  max={2050}
                  min={2022}
                  setValue={setYearRange}
                  step={1}
                  value={yearRange}
                />
              </Stack>
            </div>
            <Checkbox checked={isPerM2} className="mt-1" onChange={() => setIsPerM2((isPerM2) => !isPerM2)}>
              <span className="t-body-l ml-2">{t("general.perM2")}</span>
            </Checkbox>
          </Stack>
          <div className=" mt-4">
            {quantity === "consumption" && (
              <div className="w-full px-4">
                <DataCard
                  className="pb-2"
                  kpi={{
                    name: "Energy Efficiency Class ",
                    value: (
                      <EnergyEfficiencyClassTransition
                        className="flex w-full items-center justify-center"
                        conf={energyEfficiencyClassConfAdjusted}
                        statusQuo={statusQuo}
                        target={target}
                      />
                    ),
                  }}
                />
              </div>
            )}
            {kpisLabelsAndKeysMapping && (
              <KPIs
                className="w-full "
                containerProps={{
                  className: "",
                }}
                isLoading={isAllmeasuresLoading || isSelectedMesuresLoading}
                kpis={[kpisData]}
                kpisLabelsAndKeysMapping={kpisLabelsAndKeysMapping || []}
                xl2={12}
                xs={12}
              />
            )}
          </div>
        </Paper>
      </Stack>
      {/* TIMELINE */}
      <section className="mt-4 ">
        <Paper style={{ backgroundColor: "transparent", border: "none" }}>
          <h2 className="t-heading-l capitalize ">Configure your Retrofit Measures</h2>
          <div className="mx-16">
            <Slider {...settings}>
              {sortTimeline(selectedMeauresScaled)?.map((retrofit, index) => {
                const correspondingRetrofit = measuresScaled?.find((measure) => measure.name === retrofit.name);
                if (correspondingRetrofit)
                  return (
                    <div className="p-2" key={index}>
                      <TimelineItem
                        budget={correspondingRetrofit.investments}
                        desc="Upgrade insulation, lighting, etc."
                        DetailsButton={DetailsButton}
                        displayOptions={dataYears}
                        onChange={({ fieldName, value }) => {
                          withGAEventTracking(eventNames.MODERN_CARD_UPDATE_RETROFIT, {}, () =>
                            handleSelectedMeasureBlur({
                              fieldName,
                              rowId: retrofit?.id,
                              value,
                            })
                          );
                        }}
                        onDelete={() => {
                          withGAEventTracking(eventNames.MODERN_CARD_DELETE_RETROFIT, {}, () =>
                            handleDeleteSelectedMeasure({ rowId: retrofit.id })
                          );
                        }}
                        options={dataYears}
                        retrofit={retrofit}
                        retrofitDisplayOptions={measureOptions() || []}
                        retrofitOptions={measureOptions() || []}
                        title="Implement Energy Efficiency Measures"
                        unit={unit}
                      />
                    </div>
                  );
              })}
              {!isAddingNewRow && (
                <div className="p-2">
                  <AddNewTimeLineItem
                    onClick={() => {
                      setIsAddingNewRow(true);
                      trackGAEvent(eventNames.MODERN_CARD_ADD_RETROFIT);
                    }}
                  />
                </div>
              )}
              {isAddingNewRow && (
                <div className="p-2">
                  <NewTimelineItem
                    onChange={({ value }) => {
                      handleNewSelectedRowBlur({
                        fieldName: "name",
                        value,
                      });
                      setIsAddingNewRow(false);
                    }}
                    retrofitDisplayOptions={measureOptions() || []}
                    retrofitOptions={measureOptions() || []}
                  />
                </div>
              )}
            </Slider>
          </div>
        </Paper>
      </section>
      <FinancialDashbaord
        data={measuresScaled}
        isAdding={isAdding}
        isDeleting={isDeleting}
        onAddRetrofit={(retrofit) => {
          withGAEventTracking(eventNames.MODERN_DASHBOARD_ADD_RETROFIT, {}, () =>
            handleNewSelectedRowBlur({
              fieldName: columnsAPINamesEnums.NAME,
              value: retrofit?.[columnsAPINamesEnums.NAME],
            })
          );
        }}
        onDeleteRetrofit={(retrofit) => {
          const retrofitId = selectedMeaures?.find(
            (m) => m[columnsAPINamesEnums.NAME] === retrofit[columnsAPINamesEnums.NAME]
          )?.[columnsAPINamesEnums.ID];
          if (retrofitId !== -1)
            withGAEventTracking(eventNames.MODERN_DASHBOARD_DELETE_RETROFIT_, {}, () =>
              handleDeleteSelectedMeasure({ rowId: retrofitId })
            );
        }}
        projectId={projectId}
        selectedRetrofits={selectedMeaures}
      />
      <CreatRetrofitMeasureForm
        formItems={createNewMeasureFormItems}
        isOpen={isAddMeasureModalOpen}
        projectId={projectId}
        requiredFields={[columnsAPINamesEnums.NAME]}
        setIsOpen={setIsAddMeasureModalOpen}
      />
      <EditRetrofitMeasureForm
        formItems={createNewMeasureFormItems}
        initialFormData={measureForEdit}
        isOpen={isEditMeasureModalOpen}
        key={measureForEdit?.id}
        projectId={projectId}
        requiredFields={[]}
        rowId={measureForEdit?.id}
        setIsOpen={setIsEditMeasureModalOpen}
      />
      <Paper className={isTableFullscreen ? "fixed inset-0 z-10 " : "mt-8"}>
        <Stack className="mb-6 items-center justify-between">
          <Stack className="items-center gap-6">
            <h2 className="t-heading-l">{t("Modernization.wizard.retrofitMeasures")}</h2>
            <Icon iconName="Add" onClick={() => setIsAddMeasureModalOpen(true)} size="md" />
          </Stack>
          <Dropdown
            displayOptions={headers.map((header) => header.label)}
            label="select columns"
            options={headers.map((header) => header.dataKey)}
            setValues={setSelectedColumns}
            values={selectedColumns || []}
            width="40rem"
          />
          <Stack className="items-center gap-2">
            <Button
              onClick={() => {
                setIsEnergyMixModalOpen(true);
              }}
              size="sm"
              variant="secondary"
            >
              {t("TechnicalData.nav.energyMix")}
              <Icon iconName="ChevronRight" size="sm" />
            </Button>
            <Icon
              iconName={isTableFullscreen ? "FullscreenExit" : "Fullscreen"}
              onClick={() => setIsTableFullscreen((prev) => !prev)}
              size="lg"
            />
          </Stack>
        </Stack>
        <CustomModal
          className="max-h-[90rem] overflow-scroll"
          height="50rem"
          modalOpen={isEnergyMixModalOpen}
          setModalOpen={setIsEnergyMixModalOpen}
          width="80vw"
        >
          <div className="h-full w-full">
            <TechTable
              activeTab={tabName}
              data={tabData}
              enabledTabs={enabledTabs}
              parentsAreLoading={isLoading}
              projectId={projectId}
            />
          </div>
        </CustomModal>
        <Table
          addable={isPerM2}
          className="max-h-100"
          columnsMinWidth="180px"
          data={measuresScaled}
          editable={() => isPerM2}
          headers={selectedHeaders}
          idColumnName="id"
          newRowDataKey="name"
          onAddNewRow={({ fieldName, value }) =>
            withGAEventTracking(eventNames.MODERN_RETROFIT_MEASURE_ADD, {}, () =>
              handleNewRowBlur({ fieldName, value })
            )
          }
          onBlur={({ fieldName, rowId, value }) =>
            withGAEventTracking(eventNames.MODERN_RETROFIT_MEASURE_UPDATE, {}, () =>
              handleBlur({ fieldName, rowId, value })
            )
          }
          onCopy={({ rowId }) => {
            handleDuplicateRow({ rowId });
          }}
          onDelete={({ rowId }) =>
            withGAEventTracking(eventNames.MODERN_RETROFIT_MEASURE_DELETE, {}, () => handleDelete({ rowId }))
          }
          onEdit={({ rowId }) => {
            const measureForEdit = measures?.find((measure) => measure.id === rowId);
            if (measureForEdit) {
              setMeasureForEdit(measureForEdit);
              setIsEditMeasureModalOpen(true);
            }
          }}
          rowRemoveable={() => true}
          scrollDown={scrollDown}
        />
      </Paper>
    </section>
  );
};
