import { distinctFilter } from "../utils/dataManipulation";
import { sentenceCase } from "../utils/namesManipulation";

export const extractLocations = (allBuildings, nullAlternative) => {
  if (!allBuildings) return allBuildings;
  const dataLocations = allBuildings
    ?.map((building) => {
      if (!building?.location) return nullAlternative;
      return sentenceCase(building?.location);
    })
    ?.filter(distinctFilter);
  return dataLocations;
};

export const extractCertifications = (allBuildings, nullAlternative) => {
  if (!allBuildings) return allBuildings;
  const dataCertificates = allBuildings
    ?.reduce((total, current) => {
      const certificates = current?.certificate?.filter((cert) => cert.type !== "EU EPC")?.map((cert) => cert.type);
      if (!certificates || certificates.length === 0) total.push(nullAlternative);
      else {
        total.push(...(certificates || []));
      }
      return total;
    }, [])
    .filter(distinctFilter);
  return dataCertificates;
};

export const energyEfficiencyClassConf = [
  {
    bgColor: "#24FF02",
    max: 30,
    rating: "+A",
    textColor: "#000",
  },
  {
    bgColor: "#8EFF02",
    max: 50,
    rating: "A",
    textColor: "#000",
  },
  {
    bgColor: "#B9FF01",
    max: 75,
    rating: "B",
    textColor: "#000",
  },
  {
    bgColor: "#E5FE01",
    max: 100,
    rating: "C",
    textColor: "#000",
  },
  {
    bgColor: "#FBFE01",
    max: 130,
    rating: "D",
    textColor: "#000",
  },
  {
    bgColor: "#FDF001",
    max: 160,
    rating: "E",
    textColor: "#000",
  },
  {
    bgColor: "#FFD902",
    max: 200,
    rating: "F",
    textColor: "#000",
  },
  {
    bgColor: "#FD7809",
    max: 250,
    rating: "G",
    textColor: "#000",
  },
  {
    bgColor: "#FC3901",
    max: Infinity,
    rating: "H",
    textColor: "#000",
  },
];

export const getEnergyClassItem = ({ conf = energyEfficiencyClassConf, energyClass, energyConsumption }) => {
  const indexInConf = conf?.findIndex(
    (element) =>
      energyClass === element.rating ||
      (energyConsumption < element.max && energyConsumption !== null && energyConsumption !== undefined)
  );
  if (indexInConf === -1) return;
  return conf[indexInConf];
};
