import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import { isNumeric } from "../../utils/dataManipulation";
import { formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { DataCard, EmptyDataCard } from "../DataCard";
import { EnergyEfficiencyClass } from "../EnergyEfficiencyClass";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";

const EmptyState = ({ className }) => {
  return (
    <div className={`mt-4 h-22 w-1/3 ${className}`}>
      <EmptyDataCard />
    </div>
  );
};
export const KPIs = ({
  className,
  containerProps,
  energyConsumptionPerM2,
  isLoading,
  kpis,
  kpisLabelsAndKeysMapping,
  paperClassName,
  title,
  xl2 = 6,
  xs = 12,
}) => {
  const { t } = useTranslation();
  const noData = !(kpis?.length > 0) && !isNumeric(energyConsumptionPerM2);
  return (
    <article className={`relative min-h-[16rem] ${className}`}>
      <Paper className={`border-[1.6rem] border-solid border-white bg-transparent p-0 ${paperClassName}`}>
        {title && <h3 className="t-heading-l bg-white pb-2">{title}</h3>}
        <LoadingOrEmptyComponent
          emptyComponent={() => <EmptyState className="ml-3" />}
          height="11rem"
          showEmpty={noData}
          showLoading={isLoading}
        />
        <Grid className="p-3" columnSpacing={4} rowSpacing={4} {...containerProps} container>
          {kpis?.length > 0 &&
            kpisLabelsAndKeysMapping?.map((mapping, index) => {
              if (kpis?.[0]?.[mapping.dataKey] !== undefined) {
                const kpi = {
                  name: mapping.label,
                  unit: mapping.unit,
                  value: !mapping.round
                    ? kpis?.[0]?.[mapping.dataKey]
                    : formatNumberBasedOnUser(kpis?.[0]?.[mapping.dataKey]),
                };
                return (
                  <Grid item key={index} xl2={xl2} xs={xs}>
                    <DataCard kpi={kpi} />
                  </Grid>
                );
              }
            })}
          <Grid item key={kpisLabelsAndKeysMapping?.length + 1} xl2={xl2} xs={xs}>
            <EnergyEfficiencyClass
              className="min-h-[10rem]"
              energyConsumption={energyConsumptionPerM2}
              label={t("BuildingProfile.kpis.energyEfficiencyClass")}
            />
          </Grid>
        </Grid>
      </Paper>
    </article>
  );
};
