import { useTranslation } from "react-i18next";

import { Loading } from "./Loading";

const Container = ({ children, height }) => (
  <div className="relative flex items-center justify-center" style={{ height }}>
    {children}
  </div>
);

const DefaultEmptyComponent = ({ className, height }) => {
  const { t } = useTranslation();
  return (
    <Container className={className} height={height}>
      <p className=" t-body-xl -mt-2 ">{t("Modernization.goals.noGoals")}</p>
    </Container>
  );
};

export const LoadingOrEmptyComponent = ({
  className,
  emptyComponent: EmptyComponent,
  height = "10rem",
  loadingText,
  showEmpty,
  showLoading,
}) => {
  if (!EmptyComponent) EmptyComponent = () => <DefaultEmptyComponent height={height} />;
  if (showLoading)
    return (
      <Container className={className} height={height}>
        <Loading label={loadingText} />
      </Container>
    );
  else if (showEmpty) return <EmptyComponent className={className} />;
};

export const LoadingOrEmptyWrapper = ({ children, ...props }) => {
  const { showEmpty, showLoading } = props;
  if (showEmpty || showLoading) return <LoadingOrEmptyComponent {...props} />;
  else return children;
};
