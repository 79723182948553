import { createSlice } from "@reduxjs/toolkit";

import { getUserFromLocalStorage } from "../../utils/localStorage";

const initialState = {
  isInactivelyLoggedOut: false,
  isLoading: false,
  isLoggingOut: false,
  user: getUserFromLocalStorage(),
};

const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    setIsInactivelyLoggedOut: (state, { payload }) => {
      state.isInactivelyLoggedOut = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});
export const { setIsInactivelyLoggedOut, setUser } = userSlice.actions;
export default userSlice.reducer;
