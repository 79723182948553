import apiUrls from "../../api/urls";
import { customApiCall, customRequestErrorHandler } from "../../utils/axios";

export const getSelectedColumnsThunk = async (inputs, thunkAPI) => {
  try {
    const { projectId, userId } = inputs;
    const url = apiUrls.technicalData.selectedColumns.filter(projectId, userId);
    const resp = await customApiCall.get({ url });
    return resp;
  } catch (error) {
    return customRequestErrorHandler({ error, thunkAPI });
  }
};
