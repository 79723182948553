import moment from "moment";

//go-app/searchflatten?roomName=EG-f-U&startTime=2020-01-01 00:00:00&endTime=2020-03-31 00:00:00&aggregation=day&dataPoint=consumption&projectId=1
const urlProjectInfo = process.env.REACT_APP_API_URL;
const urlEnergyData = process.env.REACT_APP_ENERGY_DASHBOARD_API_URL;

const dateToApiDateString = (date) => moment(date).format("YYYY-MM-DD");
export default {
  data: {
    listFlatten: ({ dataLevel, endDate, interval, projectId, quantity, scenario, startDate }) => {
      const startTime = dateToApiDateString(startDate);
      const endTime = dateToApiDateString(endDate);
      const levelQueryParam = dataLevel === "building" ? "" : `&roomName=${dataLevel}`;
      const aggregation = interval === "monthly" ? "month" : interval === "hourly" ? "hour" : "day";
      // return `${urlEnergyData}/api/energy_data?scenarioName=${scenario}${levelQueryParam}&projectId=${projectId}&dataPoint=${quantity}&aggregation=${aggregation}&startTime=${startTime} 00:00:00&endTime=${endTime} 23:59:59`;
      return `${urlEnergyData}/api/energy_data_new_format?scenarioName=${scenario}${levelQueryParam}&projectId=${projectId}&dataPoint=${quantity}&aggregation=${aggregation}&startTime=${startTime} 00:00:00&endTime=${endTime} 23:59:59`;
      // return `${urlEnergyData}/api/energy_data_limited?scenarioName=${scenario}${levelQueryParam}&projectId=${projectId}&dataPoint=${quantity}&aggregation=${aggregation}&startTime=${startTime} 00:00:00&endTime=${endTime} 23:59:59`;
    },
  },
  projectInfo: {
    list: (projectId) => `${urlProjectInfo}/api/project_info/?project_id=${projectId}`,
  },
};
