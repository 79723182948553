import styled from "@emotion/styled";
import { Menu, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/system";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonNew } from ".";
import { sortFunction } from "../functions/general";
import { getSortType, isCellTypeDownload } from "../functions/TechnicalData";
import { updateTableCell } from "../utils/dataManipulation";
import { cn } from "../utils/styling";
import { Button } from "./ButtonNew";
import { CustomModal } from "./CustomModal";
import { DropdownSingleSelectNative } from "./DropdownSingleSelectNative";
import { Icon } from "./Icon";
import { LabelLessInput } from "./Input";

const TableHeaderCell = ({ className, columnsMinWidth, endAndromat, handleColumnClick, label, labelClassName }) => {
  return (
    <TableCell
      className={cn("h-9 p-0 pl-3 pr-1 ", className)}
      onClick={handleColumnClick}
      sx={{
        ":active": {
          background: "var(--clr-secondary-blue-50)",
          border: "1px solid var(--clr-secondary-blue-300)",
        },
        ":hover": {
          boxShadow: "var(--box-shadow-up-hover)",
        },
        background: "var(--clr-gray-50)",
        border: "1px solid var(--clr-gray-200)",
        // borderRadius: "4px 4px 0px 0px",
        minWidth: columnsMinWidth,
      }}
      tabIndex={1}
    >
      <Box className="flex items-center justify-between">
        <span className={cn("t-body-s text-gray-800 ", labelClassName)}>{label}</span>
        {endAndromat}
      </Box>
    </TableCell>
  );
};

const TableHeader = ({ columnsMinWidth, handleColumnClick, headers, isSortAscending, sortColumn }) => {
  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => {
          const sortType = getSortType(header.dataKey, sortColumn, isSortAscending);
          const isDownload = isCellTypeDownload(header.cellType);

          return (
            <TableHeaderCell
              className={isDownload ? "" : "cursor-pointer"}
              columnsMinWidth={columnsMinWidth}
              endAndromat={isDownload ? null : <Icon iconName={sortType} size="sm" svgClassName="scale-150" />}
              handleColumnClick={() => handleColumnClick(header.dataKey)}
              key={index}
              label={header.label}
              labelClassName={header.headerClassName}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export const Table = (props) => {
  const {
    addable,
    childTableDataKey = null,
    className,
    columnsMinWidth,
    data,
    editable = () => false,
    foldTableOnChangeOfVariable,
    headers,
    idColumnName,
    newRowDataKey,
    newRowHeaders,
    onAddNewRow,
    onExpand,
    onRowClick,
    rowHeight,
    rowRemoveable = () => false,
    scrollDown,
  } = props;
  const isSomeRowRemovable = data?.some((row) => rowRemoveable(row));
  const [tableData, setTableData] = useState([]);
  const [isSortAscending, setIsSortAscending] = useState(true);
  const [sortColumn, setSortColumn] = useState(null);

  const sortData = (data) => {
    if (!sortColumn) return data;
    const sortedData = [...data];
    if (isSortAscending) {
      sortedData?.sort((a, b) => sortFunction(a[sortColumn], b[sortColumn]));
    } else {
      sortedData?.sort((a, b) => sortFunction(b[sortColumn], a[sortColumn]));
    }
    return sortedData;
  };

  const hasChild = data?.some((row) => row[childTableDataKey]?.length > 0);

  const handleCellValueChange = (e, rowId, dataKey, idColumnName) => {
    const newValue = e.target.value;
    const updatedData = updateTableCell(tableData, rowId, dataKey, newValue, idColumnName);
    setTableData(updatedData);
  };

  const emptyNewRow = { id: -1, [newRowDataKey]: null };
  useEffect(() => {
    setNewRow(emptyNewRow);
    setTableData(sortData(data));
  }, [sortColumn, isSortAscending, data]);

  useEffect(() => setSelectedRow(null), [foldTableOnChangeOfVariable]);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (id) => {
    setSelectedRow(id === selectedRow ? null : id);
    if (onExpand) onExpand();
  };

  const handleColumnClick = (header) => {
    if (header === sortColumn) setIsSortAscending(!isSortAscending);
    else setIsSortAscending(true);
    setSortColumn(header);
  };

  const [newRow, setNewRow] = useState(emptyNewRow);
  const handleNewRowCellChange = (e) => {
    const newValue = e.target.value;
    setNewRow({ ...newRow, [newRowDataKey]: newValue });
  };

  const sRef = useRef();
  useEffect(() => {
    if (scrollDown) sRef.current?.scrollIntoView();
    // setNewRow(emptyNewRow);
  }, [data]);
  return (
    <>
      <Wrapper className={className} rowHeight={rowHeight} rowRemoveable={isSomeRowRemovable}>
        <MuiTable size="small" stickyHeader>
          <TableHeader
            columnsMinWidth={columnsMinWidth}
            handleColumnClick={handleColumnClick}
            headers={headers}
            isSortAscending={isSortAscending}
            sortColumn={sortColumn}
          />
          <TableBody>
            {tableData?.map((row, index) => {
              const childData = row[childTableDataKey] || [];
              const rowHasChild = childData?.length > 0;
              if (!rowHasChild)
                return (
                  <Row
                    {...props}
                    editable={editable(row)}
                    key={index}
                    onCellChange={handleCellValueChange}
                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                    parentHasChild={hasChild}
                    parentRowId={row[idColumnName]}
                    row={row}
                    rowHasChild={rowHasChild}
                    rowRemoveable={rowRemoveable(row)}
                  />
                );
              return (
                <NestedRow
                  {...props}
                  childData={[...childData]}
                  editable={editable(row)}
                  key={index}
                  onCellChange={handleCellValueChange}
                  onClick={() => {
                    handleRowClick(row[idColumnName]);
                    if (onRowClick) onRowClick(row);
                  }}
                  parentHasChild={hasChild}
                  row={row}
                  rowHasChild={rowHasChild}
                  rowRemoveable={rowRemoveable(row)}
                  selectedRow={selectedRow}
                />
              );
            })}
          </TableBody>
        </MuiTable>
        {!sortColumn && <div ref={sRef} />}
      </Wrapper>
      {addable && (
        <Wrapper className="mt-2 " rowHeight={rowHeight} rowRemoveable={isSomeRowRemovable}>
          <MuiTable
            style={{
              border: "2px solid #ddd",
              // backgroundColor: "#eee7",
            }}
          >
            <TableBody>
              <Row
                {...props}
                className=""
                // onDelete={onDelete}
                // onCellChange={onCellChange}
                editable={true}
                headers={newRowHeaders || headers.slice(0, 1)}
                // onClick={onClick}
                // selectedRow={selectedRow}
                idColumnName="id"
                onBlur={onAddNewRow}
                onCellChange={handleNewRowCellChange}
                parentRowId={-1}
                row={newRow}
                rowRemoveable={false}
              />
            </TableBody>
          </MuiTable>
        </Wrapper>
      )}
    </>
  );
};

function NestedRow(props) {
  const {
    childData,
    childEditable,
    childHeaders,
    childOnBlur,
    childOnDelete,
    idColumnName,
    row,
    rowRemoveable,
    selectedRow,
  } = props;
  return (
    <>
      <Row {...props} parentRowId={row[idColumnName]} />
      <TableRow>
        <TableCell className="m-0 p-0" colSpan={Object.keys(row)?.length}>
          <Collapse in={selectedRow === row[idColumnName]} timeout="auto" unmountOnExit>
            <Box className="my-6 mr-6">
              <Table
                {...props}
                className="pl-9"
                data={childData}
                editable={childEditable}
                headers={childHeaders}
                onBlur={childOnBlur}
                onDelete={childOnDelete}
                parentRowId={row[idColumnName]}
                // editable={() => editable}
                rowRemoveable={() => rowRemoveable}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const Row = ({
  data,
  editable,
  headers,
  idColumnName,
  onBlur,
  onCellChange,
  onClick,
  onCopy,
  onDelete,
  onEdit,
  parentHasChild,
  parentRowId,
  row,
  rowHasChild,
  rowRemoveable,
  selectedRow,
}) => {
  const [editingCell, setEditingCell] = useState(null);
  const [showActionsBtn, setShowActionsBtn] = useState(false);
  return (
    <TableRow
      className={`relative ${onClick && "cursor-pointer"}`}
      onClick={onClick}
      onMouseEnter={() => setShowActionsBtn(true)}
      onMouseLeave={() => setShowActionsBtn(false)}
      sx={{
        ":hover": {
          background: "var(--clr-secondary-blue-50)",
        },
      }}
    >
      {headers?.map((header, index) => {
        const value = header.valueTransformation?.(row);
        const subValue = header.subValueTransformation?.(row);
        const placeholder = header.placeholder;
        const href = header.href?.(row);
        const errorMessage = (input) => header.errorTransformation && header.errorTransformation(row, data, input);
        const downloadZipFile = () => header.downloadZipFile?.(row);
        const beforeTextIconName =
          index !== 0 || !rowHasChild ? null : selectedRow === row[idColumnName] ? "ChevronDown" : "ChevronRight";
        const rowId = row[idColumnName];
        const fieldName = header.dataKey;
        const cellIsReadOnly = header?.isReadOnly;
        const onCellClick = header.onCellClick;
        const valueClassName = header.valueClassName;
        return (
          <CustomCell
            beforeTextIconName={rowHasChild && beforeTextIconName}
            cellType={header.cellType}
            contentType={header.contentType}
            displayOptions={header.displayOptions && header.displayOptions(row)}
            downloadZipFile={downloadZipFile}
            editable={editable && !cellIsReadOnly}
            errorMessage={errorMessage}
            hasDeleteBtn={index === 0 && rowRemoveable}
            href={href}
            iconAction={() => {
              if (onClick) onClick();
            }}
            isEditing={editingCell && rowId === editingCell.rowId && fieldName === editingCell.fieldName}
            key={index}
            onBlur={() => {
              if (onBlur) onBlur({ fieldName, parentRowId, rowId, value });
              setEditingCell(null);
            }}
            onCellChange={(e) => {
              if (onCellChange) onCellChange(e, rowId, fieldName, idColumnName);
              if (["dropdown", "dropdownOneTime"].includes(header.cellType) && onBlur)
                onBlur({
                  fieldName,
                  parentRowId,
                  rowId,
                  value: e.target.value,
                });
            }}
            onCellClick={() => {
              if (onCellClick) onCellClick();
            }}
            onClick={() => setEditingCell({ fieldName, rowId })}
            onCopy={onCopy ? () => onCopy({ parentRowId, rowId }) : undefined}
            onDelete={onDelete ? () => onDelete({ parentRowId, rowId }) : undefined}
            onEdit={onEdit ? () => onEdit({ parentRowId, rowId }) : undefined}
            options={header.options && header.options(row)}
            placeholder={placeholder}
            setShowActionsBtn={setShowActionsBtn}
            showActionsBtn={showActionsBtn}
            subValue={subValue}
            subValueClassName="t-body-xs text-gray-500"
            value={value}
            valueClassName={`t-body-l body-cell ${
              index == 0 && parentHasChild && !rowHasChild ? "pl-11" : ""
            } ${valueClassName}`}
          />
        );
      })}
    </TableRow>
  );
};

const DownloadCell = ({ cellType, href, onCellClick, value, valueClassName }) => {
  return (
    <TableCell
      className={`${valueClassName}`}
      onClick={onCellClick}
      sx={{
        ":hover": {
          backgroundColor: "var(--clr-secondary-blue-100)",
        },
      }}
    >
      {cellType === "downloadFile" && (
        <a className=" flex cursor-pointer justify-center" href={href} rel="noreferrer" target="_blank">
          {value}
        </a>
      )}
      {cellType === "downloadZipFile" && <div className=" flex justify-center">{value}</div>}
    </TableCell>
  );
};

const RowDeleteBtn = ({ className, onDelete, setShowDeleteBtn, showDeleteBtn }) => {
  return (
    <Stack className={` cursor-pointer ${className}`} onMouseEnter={() => setShowDeleteBtn(true)}>
      {showDeleteBtn && (
        <Button
          className=" absolute -left-1"
          onClick={() => {
            if (onDelete) onDelete();
          }}
          size="sm"
          variant="tertiary"
        >
          <Icon color="red" iconName="Close" />
        </Button>
      )}
    </Stack>
  );
};

const RowActionsBtn = ({ className, onCopy, onDelete, onEdit, setShowActionsBtn, showActionsBtn }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <Stack className={` cursor-pointer ${className}`} onMouseEnter={() => setShowActionsBtn(true)}>
      <CustomModal
        className="max-h-[90rem] overflow-scroll"
        height="25rem"
        modalOpen={showModal}
        setModalOpen={setShowModal}
        width="40rem"
      >
        <Stack className="t-heading-m h-full w-full flex-col justify-between p-4">
          <h3>{t("general.deleteConfirmMessage")}</h3>
          <Stack className="gap-4">
            <ButtonNew
              className="w-20"
              onClick={() => {
                setShowModal(false);
                onDelete?.();
              }}
              size="md"
              variant="error"
            >
              {t("general.yes")}
            </ButtonNew>
            <ButtonNew className="w-20" onClick={() => setShowModal(false)} size="md" variant="secondary">
              {t("general.cancel")}
            </ButtonNew>
          </Stack>
        </Stack>
      </CustomModal>
      {showActionsBtn && (onDelete || onCopy || onEdit) && (
        <>
          <Button
            className=" absolute -left-1"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
            size="sm"
            variant="tertiary"
          >
            <Icon iconName="More" size="md" />
          </Button>
          <Menu
            anchorEl={anchorEl}
            classes={{
              paper: "p-1 px-3 w-35 rounded",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onClose={handleClose}
            open={open}
          >
            {onCopy && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  onCopy?.();
                }}
              >
                <Stack className="gap-2">
                  <Icon iconName="Duplicate" size="md" />
                  <span>{t("general.duplicate")}</span>
                </Stack>
              </MenuItem>
            )}
            {onEdit && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  onEdit?.();
                }}
              >
                <Stack className="gap-2">
                  <Icon iconName="Edit" size="md" />
                  <span>{t("general.edit")}</span>
                </Stack>
              </MenuItem>
            )}
            {onDelete && (
              <MenuItem
                className="px-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  setShowModal(true);
                }}
              >
                <Stack className="gap-2">
                  <Icon color="var(--clr-mystic-red-500)" iconName="Trash" size="md" />
                  <span>{t("general.delete")}</span>
                </Stack>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </Stack>
  );
};

const TextCellContent = ({
  contentType,
  editable,
  errorMessage,
  onBlur,
  onCellChange,
  onClick,
  placeholder,
  value,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") event.target.blur();
  };
  const handleBlur = () => {
    setIsEditing(false);
    if (onBlur) onBlur();
  };
  const handleClick = () => {
    setIsEditing(true);
    if (onClick) onClick();
  };
  const [error, setError] = useState("");
  const isValueNull = value === undefined || value === null;
  return (
    <>
      {editable && isEditing && (
        <div className="w-full">
          <LabelLessInput
            autoFocus={true}
            className="block w-full"
            height="3.2rem"
            onBlur={handleBlur}
            onChange={(e) => {
              if (onCellChange) onCellChange(e);
              if (errorMessage) setError(errorMessage(e.target.value));
            }}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            style={{
              marginLeft: "-6px",
            }}
            type={contentType ?? "text"}
            value={!isValueNull ? value : ""}
          />
          {error && <p className="t-body-s text-red-500">{error}</p>}
        </div>
      )}
      {editable && !isEditing && (
        <Button
          className=" w-full"
          onClick={handleClick}
          size="sm"
          style={{
            cursor: "text",
            justifyContent: "flex-start",
            marginLeft: "-6px",
          }}
          variant="tertiary"
        >
          {t(!isValueNull ? value : placeholder)}
        </Button>
      )}
      {!editable && <span>{value}</span>}
    </>
  );
};

const TextCell = ({
  beforeTextIconClassName,
  beforeTextIconName,
  contentType,
  editable,
  errorMessage,
  hasDeleteBtn,
  iconAction,
  onBlur,
  onCellChange,
  onCellClick,
  onClick,
  onCopy,
  onDelete,
  onEdit,
  placeholder,
  setShowActionsBtn,
  showActionsBtn,
  value,
  valueClassName,
}) => {
  return (
    <TableCell
      className={valueClassName}
      onClick={onCellClick}
      sx={{
        ":hover": {
          backgroundColor: "var(--clr-secondary-blue-100)",
        },
      }}
    >
      <Stack className="w-full" spacing={3}>
        {hasDeleteBtn && (
          <RowActionsBtn
            className="absolute -left-8 top-1/2 h-full w-8 -translate-y-1/2"
            onCopy={onCopy}
            onDelete={onDelete}
            onEdit={onEdit}
            setShowActionsBtn={setShowActionsBtn}
            showActionsBtn={showActionsBtn}
          />
        )}
        {beforeTextIconName && (
          <Icon
            className={`before-text-icon -ml-2 ${beforeTextIconClassName}`}
            iconName={beforeTextIconName}
            onClick={iconAction}
            size="md"
          />
        )}
        <TextCellContent
          contentType={contentType}
          editable={editable}
          errorMessage={errorMessage}
          onBlur={onBlur}
          onCellChange={onCellChange}
          onClick={onClick}
          placeholder={placeholder}
          value={value}
        />
      </Stack>
    </TableCell>
  );
};

const DropdownCell = ({
  beforeTextIconClassName,
  beforeTextIconName,
  cellType,
  displayOptions,
  disposeAfterSelection,
  // onClick,
  editable,
  hasDeleteBtn,
  iconAction,
  onCellChange,
  onCellClick,
  // onBlur,
  onDelete,
  options,
  placeholder,
  setShowDeleteBtn,
  showDeleteBtn,
  value,
  valueClassName,
}) => {
  return (
    <TableCell
      className={valueClassName}
      onClick={onCellClick}
      sx={{
        ":hover": {
          backgroundColor: "var(--clr-secondary-blue-100)",
        },
      }}
    >
      <Stack className="w-full" spacing={3}>
        {hasDeleteBtn && (
          <RowDeleteBtn
            className="absolute -left-8 top-1/2 h-full w-8 -translate-y-1/2"
            onDelete={onDelete}
            setShowDeleteBtn={setShowDeleteBtn}
            showDeleteBtn={showDeleteBtn}
          />
        )}
        {beforeTextIconName && (
          <Icon
            className={`before-text-icon -ml-2 ${beforeTextIconClassName}`}
            iconName={beforeTextIconName}
            onClick={iconAction}
            size="md"
          />
        )}
        <DropdownSingleSelectNative
          disabled={!editable}
          displayOptions={displayOptions}
          disposeAfterSelection={disposeAfterSelection}
          onChange={onCellChange}
          options={options}
          placeholder={placeholder}
          selectedValue={value}
          setSelectedValue={() => {}}
          width="120px"
        />
      </Stack>
    </TableCell>
  );
};

const CustomCell = (props) => {
  const { cellType } = props;
  if (cellType === "downloadFile" || cellType === "downloadZipFile") return <DownloadCell {...props} />;
  if (cellType === "dropdown" || cellType === "dropdownOneTime")
    return <DropdownCell {...props} disposeAfterSelection={cellType === "dropdownOneTime"} />;
  else {
    return <TextCell {...props} />;
  }
};
const Wrapper = styled(TableContainer)(({ rowHeight = "4.8rem", rowRemoveable }) => ({
  ".before-text-icon": {
    cursor: "pointer",
  },
  ".body-cell": {
    borderLeft: "1px solid var(--clr-gray-100)",
    borderRight: "1px solid var(--clr-gray-100)",
    // padding: "0rem 1.6rem",
    height: rowHeight,
  },
  ".opacity-zero": {
    opacity: 0,
  },
  ".text-center": {
    textAlign: "center",
  },
  paddingLeft: `${rowRemoveable ? "42px" : ""}`,
  textAlign: "left",
}));
