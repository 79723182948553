import { Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IsAllowed } from "../../acl";
import { useCheckAllFilesStatus, useGetBuilding, useGetScenarios } from "../../api/hooks/allHooks";
import apiUrls from "../../api/urls";
import { ButtonNew, CustomizedDialogs, Icon } from "../../components";
import { eventNames, trackGAEvent, withGAEventTracking } from "../../functions/googleAnalytics";
import { theme } from "../../styles/theme";
import { customApiCall } from "../../utils/axios";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const BuildingPassportHeader = ({ componentRef, pageName, projectId }) => {
  const { t } = useTranslation();

  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};

  const { user } = useSelector((store) => store.user);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const successMessage = t("general.successfulFileUpload");
  const headerTitle = t("general.uploadFiles");
  const headerText = t("general.dragToUploadFile");
  const token = user?.access_token;
  const url = apiUrls.userFiles.upload;
  const queryParams = [
    { name: "project", value: building?.id },
    { name: "user", value: user?.user_id },
  ];
  const onSuccess = (resp) => {
    setHasError(false);
    setErrorMessage(false);
  };
  const onError = (xhr) => {
    setHasError(true);
    setErrorMessage(xhr.statusText);
  };

  const downloadAllFiles = () => {
    const isTechnical = pageName === "technicalData";
    const url = apiUrls.files.folders.download(projectId, isTechnical);
    const fileName = `${isTechnical ? "All technical files.zip" : "All passport files.zip"}`;
    const errorMessage = t("general.downloadFileProblem");
    customApiCall.download({ errorMessage, fileName, url });
  };
  const { data: allFilesStatus } = useCheckAllFilesStatus(projectId, pageName);
  const { data: scenarios } = useGetScenarios(projectId);

  return (
    <LoadingOrEmptyWrapper height="100%" showLoading={isLoading}>
      <Stack className="justify-between">
        {pageName !== "modernization" ? (
          <div>
            <h2 className=" t-label-m-caps uppercase text-gray-500">
              {t("BuildingPassportSharedLayout.buildingPassport")}
            </h2>
            <h1 className="t-heading-xxl">{building?.descriptive_name}</h1>
          </div>
        ) : (
          <div>
            <h2 className=" t-label-m-caps uppercase text-gray-500">
              {t("BuildingPassportSharedLayout.energyConsultant")}
            </h2>
            <h1 className="t-heading-xxl">{building?.descriptive_name}</h1>
          </div>
        )}
        {["buildingProfile", "modernization", "technicalData"].includes(pageName) && (
          <Stack spacing={theme.spacing(4)}>
            {pageName !== "modernization" && IsAllowed("TECHNICAL_DATA.DOWNLOAD_FILES") && (
              <ButtonNew
                disabled={allFilesStatus?.status !== 200}
                onClick={() => {
                  const eventName =
                    pageName === "buildingProfile" ? eventNames.BP_DOWNLOAD_ALL : eventNames.TD_DOWNLOAD_ALL;
                  withGAEventTracking(eventName, {}, downloadAllFiles);
                }}
                size="md"
                variant="secondary"
              >
                <Stack spacing={theme.spacing(1.5)}>
                  <Icon disabled={allFilesStatus?.status !== 200} iconName="Download" />
                  <p>{t("BuildingPassportSharedLayout.downloadAllFiles")}</p>
                </Stack>
              </ButtonNew>
            )}
            {pageName === "modernization" && IsAllowed("MODERNIZATION.DOWNLOAD_SCENARIOS") && (
              <ButtonNew
                disabled={!scenarios || scenarios?.length === 0}
                onClick={() =>
                  withGAEventTracking(eventNames.MODERN_DOWNLOAD_SCRNARIOS, {}, () =>
                    exportComponentAsJPEG(componentRef)
                  )
                }
                size="md"
                variant="secondary"
              >
                <Stack spacing={theme.spacing(1.5)}>
                  <Icon disabled={!scenarios || scenarios?.length === 0} iconName="Download" />
                  <p>{t("BuildingPassportSharedLayout.downloadScenarios")}</p>
                </Stack>
              </ButtonNew>
            )}
            {pageName === "technicalData" && IsAllowed("TECHNICAL_DATA.UPLOAD_FILES") && (
              <CustomizedDialogs
                Component={
                  <ButtonNew onClick={() => trackGAEvent(eventNames.TD_UPLOAD_FILES)} size="md" variant="primary">
                    <Stack spacing={theme.spacing(1.5)}>
                      <Icon color="white" iconName="Upload" />
                      <p> {t("BuildingPassportSharedLayout.uploadFiles")}</p>
                    </Stack>
                  </ButtonNew>
                }
                errorMessage={errorMessage}
                formDataFileKeyName="file"
                hasError={hasError}
                headerText={headerText}
                headerTitle={headerTitle}
                onError={onError}
                onSuccess={onSuccess}
                queryParams={queryParams}
                successMessage={successMessage}
                token={token}
                url={url}
              />
            )}
            {pageName === "buildingProfile" && IsAllowed("BUILDING_PROFILE.THREE_D_VIEWER") && (
              <a
                href={apiUrls.threeDViewer.navigate(projectId, user?.access_token)}
                onClick={() => trackGAEvent(eventNames.BP_DOWNLOAD_3DVIEWER)}
                rel="noreferrer"
                target="_blank"
              >
                <ButtonNew size="md" variant="primary">
                  <Stack spacing={theme.spacing(1.5)}>
                    <p> {t("BuildingPassportSharedLayout.threeDViewer")}</p>
                    <Icon color="white" iconName="ChevronRight" size="sm" />
                  </Stack>
                </ButtonNew>
              </a>
            )}
          </Stack>
        )}
      </Stack>
    </LoadingOrEmptyWrapper>
  );
};
