import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { CalendarContainer } from "react-datepicker";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import { toLeaftechDateString } from "../functions/general";
import { Button } from "./ButtonNew";
import { Icon } from "./Icon";

const CustomInput = React.forwardRef(({ forwardDays, onClick, value, width }, ref) => (
  <InputWrapper
    style={{
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      position: "relative",
      width: width,
    }}
    tabIndex={0}
    value={value}
  >
    <input
      onChange={() => null}
      onClick={onClick}
      ref={ref}
      style={{
        /* grey/900 */
        // border: "1px solid #1C1D20",
        borderRadius: "7px",
        cursor: "pointer",
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        height: "4rem",
        lineHeight: "19px",
        padding: "1.05rem 1.4rem 1.05rem 1.2rem",
        width: "100%",
        /* identical to box height */
      }}
      // value={`${toDateString(value)} - ${toDateString(
      //   addDays(value, forwardDays)
      // )}`}
      value={toLeaftechDateString(value)}
      // value={value}
    />
    <Icon className="calendar-input" iconName="Calendar" onClick={onClick} />
  </InputWrapper>
));

const InputWrapper = styled("div")(({ value }) => ({
  input: {
    ":focus": {
      border: "1px solid var(--clr-secondary-blue-500)",
    },
    ":hover": {
      border: "1px solid var(--clr-secondary-blue-200)",
    },
    border: `1px solid ${value ? "var(--clr-leaftech-blue-500)" : "#1C1D20"}`,
    color: "var(--clr-grey-600)",
  },
}));

export const DateRangePicker = ({
  endDate,
  height,
  onConfirm,
  onOpen,
  setEndDate,
  setIsCalendarOpen,
  setStartDate,
  startDate,
  width,
}) => {
  const { t } = useTranslation();
  const [closeModal, setCloseModal] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const [currentMonth, setCurrentMonth] = useState(startDate.getMonth());
  let forwardDays = 7;

  const isInCurrentMonth = (date) => {
    return date.getMonth() == currentMonth;
  };

  const MyContainer = ({ children, className }) => {
    return (
      <>
        <CalendarContainer className={className}>
          <div className="main-container" style={{ fontSize: "1rem" }}>
            {children}
          </div>
        </CalendarContainer>
      </>
    );
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleClose = () => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };
  const renderDayContents = (day, date) => {
    return <span>{date.getDate().toString().padStart(2, "0")}</span>;
  };

  return (
    <Wrapper style={{ height: height, width: width }}>
      <DatePicker
        calendarClassName={`test ${closeModal ? "hidden" : "show"}`}
        calendarContainer={MyContainer}
        calendarStartDay={1}
        customInput={<CustomInput forwardDays={forwardDays} />}
        endDate={endDate}
        filterDate={isInCurrentMonth}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
          handleClose();
          if (onclose) onclose();
        }}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
          setCurrentMonth(startDate.getMonth());
          setCloseModal(false);
          if (onOpen) onOpen();
        }}
        onChange={onChange}
        onMonthChange={(date) => {
          setCurrentMonth(date.getMonth());
        }}
        renderDayContents={renderDayContents}
        selected={startDate}
        selectsRange
        shouldCloseOnSelect={false}
        startDate={startDate}
      >
        <Button
          onClick={() => {
            setCloseModal(true);
            setStartDate(selectedStartDate);
            setEndDate(selectedEndDate);
            setIsCalendarOpen(false);
            // setStartDate(selectedDate);
            // setDateInterval(selectedDateInterval);
          }}
          size="md"
          variant="secondary"
        >
          {t("EnergyPerformance.dashboard.cancel")}
        </Button>
        <Button
          onClick={() => {
            if (onConfirm) onConfirm();
            setCloseModal(true);
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            setIsCalendarOpen(false);
            // setSelectedDate(startDate);
            // setSelectedDateInterval(dateInterval);
          }}
          size="md"
          variant="primary"
        >
          {t("EnergyPerformance.dashboard.confirmSelection")}
        </Button>
      </DatePicker>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .main-container {
    position: relative;
  }
  .calendar-input {
    position: absolute;
    right: 5%;
  }
  .test {
    background-color: white;
    color: black;
    border: none;
    width: 36rem;
    padding: 2.4rem;
    border-radius: 1.5rem;
  }
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }

  div.react-datepicker__header {
    background-color: white;
    border: none;
  }
  .react-datepicker__current-month {
    color: black;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .react-datepicker__month {
    margin: 0;
    margin-top: 0.5rem;
  }
  .react-datepicker__week {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    color: gray;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  .react-datepicker__children-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    margin-top: 4rem;
    padding: 0;
  }
  .react-datepicker__month-container {
    display: block;
    margin: auto;
    float: none;
  }
  .react-datepicker__current-month {
    //styleName: Headings/Heading L;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: var(--clr-gray-900);
  }
  .react-datepicker__navigation {
    color: black;
  }
  .react-datepicker__day {
    font-family: "Exo 2";
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8rem;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    color: var(--clr-gray-900);
  }
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    /* secondary blue/50 */
    background: #e9eefc;
    /* secondary blue/500 */
    color: var(--clr-secondary-blue-500);
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    /* secondary blue/50 */
    background: #e9eefc;
    /* secondary blue/500 */
    border: 1px solid #2459ea;
    border-radius: 50%;
    color: var(--clr-secondary-blue-500);
  }
  .react-datepicker__day--disabled {
    color: var(--clr-gray-300);
  }
  /* .react-datepicker__day--in-range:hover, */
  /* .react-datepicker__day--in-selecting-range:hover */
`;
