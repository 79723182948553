import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useRef } from "react";

import { Icon } from "./Icon";

const MyTabScrollButton = (props) => {
  const { direction, ...other } = props;

  return (
    <div className="cursor-pointer" {...other}>
      <Icon
        className={`w-10 cursor-pointer ${direction === "left" && "rotate-180"}`}
        color="black"
        iconName="ChevronRight"
      />
    </div>
  );
};

export const ScrollableTabsButtonAuto = ({
  addTab,
  currentTabIndex,
  setCurrentTabIndex,
  tabs,
  tabsLabelsComponents,
  ...props
}) => {
  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const handleAdd = (event, newValue) => {
    addTab();
    tabsRef.current.scrollLeft = tabsRef.current.scrollWidth;
  };

  const tabsRef = useRef();

  return (
    <Box {...props}>
      <Tabs
        centered={false}
        onChange={handleChange}
        ScrollButtonComponent={(props) => <MyTabScrollButton {...props} />}
        scrollButtons={true}
        value={currentTabIndex}
        variant="scrollable"
      >
        {tabsLabelsComponents?.map((tab, i) => {
          return <Tab className="max-w-[40rem]" key={i} label={tab.label} />;
        })}
        <Tab className="t-heading-l " label="+" onClick={handleAdd} ref={tabsRef} />
      </Tabs>
    </Box>
  );
};
