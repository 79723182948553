import { Paper, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useGetProjectInfo } from "../../api/hooks/allHooks";
import { WizardContext } from "../../context/Wizard";
import { eventNames, withGAEventTracking } from "../../functions/googleAnalytics";
import { calculateStrandingPoint } from "../../functions/Modernization";
import { theme } from "../../styles/theme";
import LegendBullet from "../chart/LegendBullet";
import { DropdownSingleSelect } from "../DropdownSingleSelect";
import { RadioButton } from "../RadioButton";

const CustomizedDot = (props) => {
  const { cx, cy, dataPoint, mainDataKey, payload } = props;

  if (payload?.[mainDataKey] === undefined || payload[dataPoint] === undefined) return;
  return (
    <svg fill="#00a" height={6} viewBox="0 0 12 6" width={12} x={cx - 6} xmlns="http://www.w3.org/2000/svg" y={cy - 3}>
      <path d="M1 0.750002L5.5 5.25L10 0.75" stroke="#00a" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const StrandingChartLegends = ({ className, legends, width }) => {
  return (
    <Stack className={className} spacing={4} style={{ flexDirection: "row-reverse", flexWrap: "wrap", width: width }}>
      {legends?.map((legend, index) => {
        return (
          <Stack key={index} spacing={2}>
            <LegendBullet color={legend.color} height="0.8rem" type="circle" width="0.8rem" />
            <span className="t-body-xl">{legend.label}</span>
          </Stack>
        );
      })}
    </Stack>
  );
};

const CustomTooltip = ({ active, label, payload, quantity }) => {
  if (active && payload && payload.length) {
    if (payload[0].payload.crrem !== undefined)
      return (
        <Paper
          className="t-heading-l0 p-4"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <p className="mt-1 text-plunge-700 ">{`Target: ${payload[0].payload.crrem?.toFixed(2)}`}</p>
          <p className="mt-1 text-gray-900">{`${
            quantity === "consumption" ? "Asset Energy Intensity" : "Climate And Grid Corrected Asset Performance"
          }: ${payload[0].payload.current?.toFixed(2)}`}</p>
          {quantity === "emission" && (
            <p className="mt-1 text-gray-500">{`Baseline Emission: ${payload[0].payload.baseline?.toFixed(2)}`}</p>
          )}
          {payload[0].payload?.modernized !== undefined && (
            <p className="mt-1 text-blue-500">{`Retrofit: ${payload[0].payload.modernized?.toFixed(2)}`}</p>
          )}
          <p className="mt-1 text-[black]">{`Year: ${label}`}</p>
        </Paper>
      );
  }

  return null;
};

export const StrandingChart = ({
  chartHeight = 300,
  className,
  hasModernization = true,
  legendsWidth,
  onCrremChange,
  projectId,
  title,
}) => {
  const { data: projectInfo } = useGetProjectInfo(projectId);

  const { crremData, crremScenario, data, quantity, setCrremScenario, setData, setQuantity } =
    useContext(WizardContext);

  let crremScenarioList;
  try {
    crremScenarioList = JSON.parse(projectInfo?.[0]?.crrem_scenario_list);
  } catch {
    //Do nothing
  }

  crremScenarioList = crremScenarioList ?? [];

  let crremScenariosOptions = crremScenarioList?.map((option) => ({ option }));

  const unit = quantity === "emission" ? "CO2/m²" : "kWh/m²";

  useEffect(() => {
    if (!crremScenario) setCrremScenario(crremScenarioList?.[0]);
  }, [projectInfo]);

  useEffect(() => {
    setData(crremData || []);
    if (onCrremChange) onCrremChange(crremData);
  }, [crremData]);

  const [strandingPoint, setStrandingPoint] = useState([]);
  const [strandingPointsModernized, setStrandingPointsModernized] = useState([]);

  useEffect(() => {
    const enhancedData = data.map((e) => {
      if (e.crrem !== undefined) return e;
      const year = e.year;
      const yearData = data.find((e) => e.year === year && e.crrem !== undefined);
      if (yearData) return { ...e, crrem: yearData.crrem };
      return e;
    });
    setStrandingPointsModernized(calculateStrandingPoint(enhancedData, "modernized"));
    setStrandingPoint(calculateStrandingPoint(data, "current"));
  }, [data]);

  // Just for dropdown initial value to display!
  if (!projectInfo) return;

  let mainChartLegends = [
    {
      color: "var(--clr-secondary-blue-500)",
      label: "Modernized",
      quantity: "consumption",
    },
    {
      color: "var(--clr-plunge-700)",
      label: "Country Specific Energy Target",
      quantity: "consumption",
    },
    {
      color: "var(--clr-mystic-red-500)",
      label: "Stranding Event",
      quantity: "consumption",
    },
    {
      color: "var(--clr-gray-900)",
      label: "Asset Energy Intensity",
      quantity: "consumption",
    },
    {
      color: "var(--clr-secondary-blue-500)",
      label: "Modernized",
      quantity: "emission",
    },
    {
      color: "var(--clr-plunge-700)",
      label: "Decarbonization Target",
      quantity: "emission",
    },
    {
      color: "var(--clr-mystic-red-500)",
      label: "Stranding Event",
      quantity: "emission",
    },
    {
      color: "var(--clr-gray-900)",
      label: "Climate And Grid Corrected Asset Performance",
      quantity: "emission",
    },
    {
      color: "var(--clr-gray-500)",
      label: "Baseline Emission",
      quantity: "emission",
    },
  ];

  mainChartLegends = mainChartLegends.filter((legend) => {
    return (legend.label !== "Modernized" || hasModernization) && legend.quantity === quantity;
  });

  return (
    <Paper className={`relative  ${className}`}>
      <Stack alignItems="center" className="mb-9" gap={theme.spacing(6)}>
        <h2 className=" t-heading-l">{title}</h2>
        <RadioButton
          displayOptions={["CO₂ Emission", "kWh"]}
          options={["emission", "consumption"]}
          row={true}
          setValue={(newValue) =>
            withGAEventTracking(eventNames.CRREM_QUANTITY_CHANGE, {}, () => setQuantity(newValue))
          }
          value={quantity}
        />
      </Stack>
      <section className="flex items-start justify-between gap-6">
        <div className="w-full">
          <Stack alignItems="flex-start" justifyContent="space-between">
            <DropdownSingleSelect
              className="mb-6"
              label="Scenario"
              options={crremScenariosOptions.map((e) => e.option)}
              selectedValue={crremScenario ?? crremScenarioList?.[0]}
              setSelectedValue={(newValue) =>
                withGAEventTracking(eventNames.CRREM_SCENARIO_CHANGE, {}, () => setCrremScenario(newValue))
              }
              width="25rem"
            />
            <StrandingChartLegends legends={mainChartLegends} width={legendsWidth} />
          </Stack>
          <ResponsiveContainer height={chartHeight} width="100%">
            <ComposedChart
              data={data}
              margin={{
                bottom: 40,
                left: 20,
                right: 50,
                top: 30,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                angle={270}
                dataKey="year"
                domain={["dataMin", "dataMax"]}
                style={{
                  fontFamily: "Exo 2",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  letterSpacing: 0,
                  lineHeight: "1.4rem",
                }}
                tickCount={30}
                tickMargin={18}
                type="number"
              >
                <Label
                  angle={0}
                  className="t-heading-m"
                  offset={20}
                  position="bottom"
                  style={{
                    textAnchor: "start",
                  }}
                  value="Year"
                />
              </XAxis>
              <YAxis
                style={{
                  fontFamily: "Exo 2",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  letterSpacing: 0,
                  lineHeight: "1.4rem",
                }}
                tickCount={5}
                type="number"
              >
                <Label
                  angle={0}
                  className="t-subheading-m"
                  offset={15}
                  position="top"
                  style={{
                    textAnchor: "start",
                  }}
                  value={unit}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip quantity={quantity} />} />
              {quantity === "emission" && (
                <Line
                  connectNulls
                  dataKey="baseline"
                  dot={false}
                  stroke="var(--clr-gray-500)"
                  strokeDasharray={5}
                  strokeWidth={1}
                  type="linear"
                />
              )}
              {hasModernization && (
                <>
                  <Line
                    dataKey="modernized"
                    dot={false}
                    stroke="var(--clr-secondary-blue-500)"
                    strokeWidth={2}
                    type="linear"
                  />
                  <Line
                    dataKey="retrofit"
                    dot={<CustomizedDot dataPoint="modernized" mainDataKey="retrofit" />}
                    isAnimationActive={false}
                    stroke="#00a"
                    strokeDasharray="1"
                    strokeWidth={2}
                    type="linear"
                  />
                  {strandingPointsModernized?.length > 0 &&
                    strandingPointsModernized.map((sP, i) => {
                      return (
                        <ReferenceDot
                          fill="none"
                          key={i}
                          r={8}
                          stroke="var(--clr-mystic-red-500)"
                          strokeWidth={3}
                          style={{
                            filter: "drop-shadow(0px 4px 4px rgba(255, 83, 0, 0.25))",
                          }}
                          x={sP[0]}
                          y={sP[1]}
                        />
                      );
                    })}
                  {strandingPoint?.length > 0 && (
                    <ReferenceDot
                      fill="none"
                      r={8}
                      stroke="var(--clr-mystic-red-500)"
                      strokeWidth={3}
                      style={{
                        filter: "drop-shadow(0px 4px 4px rgba(255, 83, 0, 0.25))",
                      }}
                      x={strandingPoint[0][0]}
                      y={strandingPoint[0][1]}
                    />
                  )}
                </>
              )}
              <Line
                connectNulls
                dataKey="current"
                dot={false}
                stroke="var(--clr-gray-900)"
                strokeWidth={1}
                type="linear"
              />
              <Area
                connectNulls
                dataKey="crrem"
                dot={false}
                fill="var(--clr-plunge-700)"
                fillOpacity={0.05}
                stroke="var(--clr-plunge-700)"
                strokeWidth={1}
                type="linear"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </section>
    </Paper>
  );
};
