import { CircularProgress } from "@mui/material";
import { ButtonHTMLAttributes, KeyboardEventHandler, MouseEventHandler, useState } from "react";

import "./buttonNew.css";
import { cn } from "../utils/styling";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  height?: number | string;
  isActive?: boolean;
  isLoading?: boolean;
  size?: "lg" | "md" | "sm" | "xl";
  variant?: "error" | "primary" | "secondary" | "tertiary";
  width?: number | string;
}

export const Button = ({
  children,
  className,
  disabled,
  height,
  isActive,
  isLoading,
  size = "sm",
  style,
  variant = "primary",
  width,
  ...props
}: ButtonProps) => {
  const [isPressed, setIsPressed] = useState(false);
  const preventFocusAndTriggerActive: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setIsPressed(true);
  };
  const untriggerActive: MouseEventHandler<HTMLButtonElement> = () => {
    setIsPressed(false);
  };
  const keyboardTriggerActive: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.code === "Space" || event.code === "Enter") setIsPressed(true);
  };
  const keybordUntriggerActive: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.code === "Space" || event.code === "Enter") setIsPressed(false);
  };

  return (
    <button
      className={cn(
        ` btn flex items-center justify-center btn--${variant} btn-new--${size} ${isPressed ? "pressed" : ""} ${
          isActive ? "isActive" : ""
        }`,
        className
      )}
      disabled={disabled ?? isLoading}
      onKeyDown={keyboardTriggerActive}
      onKeyUp={keybordUntriggerActive}
      onMouseDown={preventFocusAndTriggerActive}
      onMouseUp={untriggerActive}
      style={{ height, width, ...style }}
      {...props}
    >
      {children}
      {isLoading && <CircularProgress className="ml-3 h-4 w-4 " size="sm" sx={{ color: "black" }} />}
    </button>
  );
};
