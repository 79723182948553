export default {
  card: {
    ADDRESS: "ADDRESS",
    "ASSET ID": "ASSET ID",
    "ASSET NAME": "ASSET NAME",
    "CO2 EMISSIONS": "CO2 EMISSIONS",
    "ENERGY CONSUMPTION": "ENERGY CONSUMPTION",
    "EPC CLASS": "EPC CLASS",
    "GROSS AREA (INCL. BASEMENT)": "GROSS AREA (INCL. BASEMENT)",
    "GROSS ASSET SIZE": "GROSS ASSET SIZE",
    "GROSS ASSET VALUE": "GROSS ASSET VALUE",
    "MODERNIZATION YEAR": "MODERNIZATION YEAR",
    "Net LETTABLE AREA (WITHOUT BASEMENT)": "Net LETTABLE AREA (WITHOUT BASEMENT)",
    "STRANDING YEAR": "STRANDING YEAR",
    title: "building information",
    TYPE: "TYPE",
    "YEAR OF CONSTRUCTION": "YEAR OF CONSTRUCTION",
  },
  certificateCard: {
    "Allotment date": "Allotment date",
    Area: "Area",
    certificateTitle: "Certificates",
    energyRatingTitle: "EU EPC rating certificates",
    epcTitle: "EPC information",
    "Valid until": "Valid until",
  },
  esg: {
    ranks: {
      aboveAverage: "Above Average",
      average: "Average",
      belowAverage: "Below Average",
      buildingType: "Bulding Type",
      quantity: "Quantity",
      top15: "Top 15%",
      top30: "Top 30%",
      unknown: "Unknown",
    },
    table: {
      columns: {
        yourBuilding: "Your Building",
      },
    },
    title: "ESG Index",
  },
  kpis: {
    anticipatedStrandingYear: "Anticipated Stranding year",
    crremM2Gross: "CRREM (m² gross)",
    endEnergyDemand: "End Energy Demand",
    endEnergyDemandComparableBuilding: "End Energy Demand Comparable Building",
    energyEfficiencyClass: "Energy Efficiency Class",
    epcConsumption: "EPC-Consumption",
    geg2024LettableArea: "GEG 2024 (m² lettable area)",
    kpisNotListed: "KPIs not listed.",
    primaryEnergyConsumption: "Primary Energy Consumption",
    primaryEnergyDemand: "Primary Energy Demand",

    primaryEnergyDemandComparableBuilding: "Primary Energy Demand Comparable Building",
    title: "KPIs",
    totalCarbonEmission: "total carbon emission",
    totalEnergyConsumption: "total energy consumption",
  },
  table: {
    columns: {
      "date modified": "date modified",
      files: "files",
      name: "name",
      type: "type",
    },
    downloadAllFiles: "Download all files",
    title: "Building passport files",
  },
};
