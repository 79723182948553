import { Paper, Stack } from "@mui/material";
import { sum } from "lodash";
import { useRef } from "react";

import { buildingUsageConfig, buildingUseTypesConfig } from "../../configs/BuildingProfile";
import { CaptureComponent } from "../CaptureComponent";
import { GeneralChartLegend } from "../chart/GeneralChartLegend";
import { PieAndDoughnutChart } from "../chart/PieAndDoughnutChart";
import { VerticalStackedBarChartColorBar } from "../chart/VerticalStackedBarChartColorBar";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const UseTypes = ({ className, data }) => {
  // const aggregated = sumByKeys({ data, keys: ['area', 'rented_area_covered'] })
  // const { area, rented_area_covered } = aggregated

  const allUseTypes = Object.keys(buildingUseTypesConfig);

  const activeUseTypes = Object.keys(data ?? {}).filter((useType) => allUseTypes.includes(useType) && data[useType]);
  const dataForUseTypeChart = activeUseTypes.map((useType) => {
    return {
      color: buildingUseTypesConfig[useType]?.color,
      label: buildingUseTypesConfig[useType]?.label,
      value: data[useType],
    };
  });

  const useTypesChartLegends = dataForUseTypeChart?.map((useType) => {
    return { color: useType?.color, label: useType.label };
  });

  const containerHeightClass = "h-[360px]";

  const totalArea = sum(activeUseTypes?.map((useType) => data[useType]));
  const vacantArea = data?.["Total Vacant Area"];
  const vacancyChartData = [
    {
      label: "Vacant",
      percentage: (100 * vacantArea) / totalArea,
    },
    {
      label: "Rented",
      percentage: 100 * (1 - vacantArea / totalArea),
    },
  ];
  const vacancyDiagramHeightClass = "h-70";

  const vacancyChartLegends = vacancyChartData?.map((row) => {
    return { ...row, color: buildingUsageConfig[row.label]?.color, value: row.percentage?.toFixed(1) };
  });

  const containerRef = useRef();
  return (
    <div className={className}>
      <div ref={containerRef}>
        <Paper>
          <Stack className="justify-between">
            <h3 className="t-heading-l">Building Usage</h3>
            <CaptureComponent targetElement={() => containerRef.current} />
          </Stack>
          <LoadingOrEmptyWrapper showEmpty={!Object.keys(data ?? {})?.length}>
            <Stack className={`mt-8 justify-between ${containerHeightClass}`}>
              <div className="w-1/2 ">
                <PieAndDoughnutChart
                  chartProps={{ height: 200 }}
                  containerProps={{ height: 250, width: "100%" }}
                  data={dataForUseTypeChart}
                  pieProps={{ dataKey: "value", innerRadius: 0, nameKey: "label", outerRadius: 100 }}
                />
                <GeneralChartLegend
                  className="mt-8 max-h-[120px] w-full overflow-auto"
                  legends={useTypesChartLegends}
                />
              </div>
              <div className={`w-2/5  ${containerHeightClass}`}>
                <div className={`relative w-full ${vacancyDiagramHeightClass}`}>
                  <Stack className={`absolute bottom-0 h-full w-full flex-col ${vacancyDiagramHeightClass}`}>
                    <VerticalStackedBarChartColorBar
                      bottom="15px"
                      className={`w-35 ${vacancyDiagramHeightClass}`}
                      config={buildingUsageConfig}
                      data={vacancyChartData}
                      // isLoading={false}
                      // isError={false}
                    />
                  </Stack>
                  <Stack className="absolute bottom-0 h-full w-full flex-col-reverse justify-between">
                    {[...Array(11).keys()].map((number) => {
                      // const flexSelfClass = number===0?'fle'
                      return (
                        <Stack className="relative w-[80%]">
                          <span className="t-numbers-xxs absolute -left-8">{number * 10}%</span>
                          <div className="h-[1px] flex-1 bg-gray-200" />
                        </Stack>
                      );
                    })}
                  </Stack>
                </div>
                <GeneralChartLegend className="mt-8" legends={vacancyChartLegends} />
              </div>
            </Stack>
          </LoadingOrEmptyWrapper>
        </Paper>
      </div>
    </div>
  );
};
