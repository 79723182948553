import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { theme } from "../../styles/theme";

const handleClick = (e, isDisabled) => {
  if (isDisabled) e.preventDefault();
};

export const SecondaryNavbar = ({ activeTabs, className, navLinks }) => {
  const { t } = useTranslation();
  return (
    <Stack
      className={`t-subheading-m ${className} border-0 border-b-2 border-solid border-gray-100`}
      spacing={theme.spacing(8)}
    >
      {navLinks.map((navLink, index) => {
        const isDisabled = activeTabs && !activeTabs[navLink.dataKey];
        return (
          <NavLink
            className={({ isActive }) =>
              `mb-[-2px] border-0 border-b-2 border-solid   px-4 py-2 uppercase   ${
                isActive
                  ? "border-blue-500 text-blue-500"
                  : isDisabled
                  ? "cursor-auto border-gray-100 text-gray-500"
                  : "border-gray-100 text-gray-900 hover:border-blue-500"
              }`
            }
            key={index}
            onClick={(e) => handleClick(e, isDisabled)}
            to={navLink.to}
          >
            {navLink.linkName}
          </NavLink>
        );
      })}
    </Stack>
  );
};
