import React, { useMemo, useState } from "react";
import "rsuite/dist/rsuite-no-reset.min.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "../../styles/dashboard.css";
import { DynamicChart, DynamicChartFullScreenHandler } from "../chart/DynamicChart";
// import { GeneralErrorBoundary } from "../ErrorBoundary";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const removeZeroFields = (data) => {
  if (!data?.length) return;
  let keys = Object.keys(data[0]);
  for (let key of keys) {
    let allZero = data.every((item) => item[key] === 0);
    if (allZero) {
      for (let obj of data) {
        delete obj[key];
      }
    }
  }
  return data;
};

export const DynamicDashboard = ({
  allChartsConfigs,
  allFilters,
  allSettings,
  chartTypes,
  dataTransformator,
  dataTransformatorMultithread,
  dynamicChartsRowHeight,
  eventHandlers,
  generalDashboardData,
  getColor,
  getColumnDisplayName,
  getColumnSortFunction,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  hoverEffect,
  isItemHovered,
  miniMapObjectsNameKey,
  multithread,
  onClick,
  onHover,
  onLegendSelect,
  onMouseOut,
  removeChart,
  scrollRef,
  setAllChartsConfigs,
  somethingIsHovered,
  specificDataGetter,
  updateChart,
}) => {
  // const dispatch = useDispatch()
  // useEffect(() => {
  //     allChartsConfigs?.forEach(config => {
  //         dispatch(setSingleConfig({ id: config.id, appName: 'Portfolio', config }))
  //     });
  // }, [allChartsConfigs])

  const layout = useMemo(
    () =>
      allChartsConfigs?.map((chart) => ({
        ...chart,
        i: `${chart.id}`,
      })),
    [allChartsConfigs]
  );
  const handleLayoutChange = (newLayout) => {
    setAllChartsConfigs(
      allChartsConfigs.map((chart) => {
        const newChart = newLayout.find((newLayoutItem) => newLayoutItem.i === `${chart.id}`);
        if (!newChart) return chart;
        else {
          const { h, w, x, y } = newChart;
          return { ...chart, h, w, x, y };
        }
      })
    );
  };
  const fullScreenChart = (id) => {
    setFullScreenChartId(id);
  };
  const [fullScreenChartId, setFullScreenChartId] = useState(null);

  return (
    <>
      <ResponsiveGridLayout
        breakpoints={{ lg: 280, md: 0, sm: 0, xs: 0, xxs: 0 }}
        className="rounded-xl bg-gray-50"
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        draggableCancel=".cancelDrag"
        layouts={{ lg: layout }}
        onLayoutChange={handleLayoutChange}
        resizeHandles={["sw", "se"]}
        rowHeight={dynamicChartsRowHeight}
      >
        {allChartsConfigs.map((item, i) => {
          const isFullScreen = fullScreenChartId === item.id;
          return (
            <div className="grid-item" key={item.id}>
              <DynamicChartFullScreenHandler isFullScreen={isFullScreen}>
                <DynamicChart
                  allFilters={allFilters}
                  allSettings={allSettings}
                  chartTypes={chartTypes}
                  config={item}
                  dataTransformator={dataTransformator}
                  dataTransformatorMultithread={dataTransformatorMultithread}
                  eventHandlers={eventHandlers}
                  fullScreenChart={fullScreenChart}
                  generalData={generalDashboardData}
                  getColor={getColor}
                  getColumnDisplayName={getColumnDisplayName}
                  getColumnSortFunction={getColumnSortFunction}
                  getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
                  getUnit={getUnit}
                  height={dynamicChartsRowHeight * item.h}
                  hoverEffect={hoverEffect}
                  id={item.id}
                  isFullScreen={isFullScreen}
                  isItemHovered={isItemHovered}
                  key={item.id}
                  miniMapObjectsNameKey={miniMapObjectsNameKey}
                  multithread={multithread}
                  onClick={onClick}
                  onHover={onHover}
                  onLegendSelect={onLegendSelect}
                  onMouseOut={onMouseOut}
                  removeChart={() => removeChart(item.id)}
                  somethingIsHovered={somethingIsHovered}
                  specificDataGetter={specificDataGetter}
                  updateChart={(key, value) => updateChart(item.id, key, value)}
                  xCoord={item.x}
                />
              </DynamicChartFullScreenHandler>
            </div>
          );
        })}
      </ResponsiveGridLayout>
      <span ref={scrollRef} />
    </>
  );
};
