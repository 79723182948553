export const getPageName = (pathname) => {
  if (!pathname) return;
  const pageName = pathname.includes("technicalData")
    ? "technicalData"
    : pathname.includes("energyPerformance")
    ? "energyPerformance"
    : pathname.includes("certificates")
    ? "certificates"
    : pathname.includes("modernization")
    ? "modernization"
    : "buildingProfile";
  return pageName;
};

export const isPageDisabled = ({ buildingActiveTabs, navLinks, pageName }) => {
  if (Object.keys(buildingActiveTabs)?.length !== 0) {
    const pageNameDataKey = navLinks.find((link) => link.to === pageName)?.dataKey;
    return !buildingActiveTabs[pageNameDataKey];
  }
};
