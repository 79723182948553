import React from "react";

import { calculateLeft } from "../../utils/position";
import Bullet from "./Bullet";

export const BulletChartColorBar = ({
  axis,
  bulletProps,
  bullets,
  className,
  gradientClassName = "barchart-bg-gradient",
  style,
}) => {
  const min = axis[0];
  const max = axis[axis.length - 1];

  return (
    <div className={`relative rounded-md ${gradientClassName} ${className}`} style={style}>
      {bullets?.map((bullet, index) => {
        const left = calculateLeft(bullet.value, min, max);
        const reverse =
          bullet.reverse !== undefined
            ? bullet.reverse
            : index === 0 && (bullets[1]?.value - bullet?.value < 60 || bullets[2]?.value - bullet?.value < 60);
        if (left)
          return (
            <Bullet
              bottom="0px"
              className="absolute"
              color={bullet.color}
              handleHeight={bullet?.handleHeight}
              height="1.6rem"
              key={`bullet ${index}`}
              label={bullet.label}
              left={`${left}%`}
              reverse={reverse}
              top="0px"
              transform="translateX(-50%)"
              type="circle"
              width="1.6rem"
              {...bulletProps}
            />
          );
      })}
    </div>
  );
};
