import { Grid, Stack } from "@mui/material";
import chroma from "chroma-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetBuildingESGInfo, useGetESGReference } from "../../api/hooks/allHooks";
import { isNumeric } from "../../utils/dataManipulation";
import { sentenceCase } from "../../utils/namesManipulation";
import { ComponentsCommonHeader } from "../ComponentsCommonHeader";
import { DataCard } from "../DataCard";
import { DropdownSingleSelect } from "../DropdownSingleSelect";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";
import { Table } from "../Table";

const ESGRankClass = ({ avg, buildingPercentageRank, top15, top30, value }) => {
  const { t } = useTranslation();

  const getRankId = () => {
    if (!isNumeric(value)) return 0;
    if (value <= top15) return 1;
    if (value <= top30) return 2;
    if (value <= avg) return 3;
    if (buildingPercentageRank > 75) return 5;
    if (value > avg) return 4;
    return 0;
  };

  const colorScales = {
    //green-700, #d7fc03 , star-400, tangerine-500, red-600
    1: chroma.scale(["#00c54d", "#d7fc03"]).domain([0, 15]),
    2: chroma.scale(["#d7fc03", "#ffea72"]).domain([15, 30]),
    3: chroma.scale(["#ffea72", "#ff9200"]).domain([30, 50]),
    4: chroma.scale(["#ff9200", "#e44a00"]).domain([50, 75]),
  };

  const unknownColor = "#bcbdc750";

  const ranksConfigs = [
    {
      bgColor: "#bcbdc750", //gray-300/20
      getColor: (value) => unknownColor,
      id: 0,
      label: t("BuildingProfile.esg.ranks.unknown"),
      textColor: "text-gray-600",
    },
    {
      bgColor: "bg-green-600/25",
      getColor: (value) => colorScales[1](value).hex(),
      getTextColor: (value) => (value < 10 ? "#fff" : "#000"),
      id: 1,
      label: t("BuildingProfile.esg.ranks.top15"),
      textColor: "text-green-900",
    },
    {
      bgColor: "bg-[#d7fc03]",
      getColor: (value) => colorScales[2](value).hex(),
      id: 2,
      label: t("BuildingProfile.esg.ranks.top30"),
      textColor: "text-gray-600",
    },
    {
      bgColor: "bg-star-400/60",
      getColor: (value) => colorScales[3](value).hex(),
      getTextColor: (value) => (value >= 45 ? "#fff" : undefined),
      id: 3,
      label: t("BuildingProfile.esg.ranks.aboveAverage"),
      textColor: "text-star-800",
    },
    {
      bgColor: "bg-tangerine-500/60",
      getColor: (value) => colorScales[4](value).hex(),
      getTextColor: (value) => "#fff",
      id: 4,
      label: t("BuildingProfile.esg.ranks.belowAverage"),
      textColor: "text-tangerine-800",
    },
    {
      bgColor: "bg-red-600",
      getColor: (value) => "#f00",
      id: 5,
      label: t("BuildingProfile.esg.ranks.belowAverage"),
      textColor: "text-white",
    },
  ];

  const rankId = getRankId();
  const rankConfig = ranksConfigs.find((config) => config.id === rankId);

  let dynamicBGColor, dynamicTextColor;
  let style = {};
  if (rankConfig?.getColor && isNumeric(buildingPercentageRank)) {
    dynamicBGColor = rankConfig.getColor(buildingPercentageRank);
    style.backgroundColor = dynamicBGColor;
  }
  if (rankConfig?.getTextColor && isNumeric(buildingPercentageRank)) {
    dynamicTextColor = rankConfig.getTextColor(buildingPercentageRank);
    style.color = dynamicTextColor;
  }

  return (
    <div
      className={`relative rounded-md p-4 ${!dynamicBGColor && rankConfig.bgColor} ${
        !dynamicTextColor && rankConfig.textColor
      }`}
      style={style}
    >
      {rankConfig?.label}
      {isNumeric(buildingPercentageRank) && (
        <span className="t-label-xs absolute bottom-[2px] left-1/2 w-full -translate-x-1/2 text-center">
          {Math.round(buildingPercentageRank)}%
        </span>
      )}
    </div>
  );
};

export const ESGIndex = ({ className, projectId }) => {
  const { t } = useTranslation();

  let { data: esgInfo, isLoading: infoIsLoading } = useGetBuildingESGInfo(projectId);
  let { data: esgReference, isLoading: referenceIsLoading } = useGetESGReference();

  if (Object.keys(esgInfo?.performance_data || {}).length)
    Object.keys(esgInfo?.performance_data).forEach((quantity) => {
      esgInfo["performance_data"][quantity] = {
        ...esgInfo["performance_data"][quantity],
      };
    });

  const esgInfoTableData = Object.entries(esgInfo?.spatial_information ?? {}).map(([usageType, percentage]) => {
    return { percentage, usageType };
  });

  const esgInfoTableColumns = [
    {
      cellType: null,
      dataKey: "usageType",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: t("BuildingProfile.esg.table.columns.yourBuilding"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["usageType"],
    },
    {
      cellType: null,
      dataKey: "percentage",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: "",
      subValueTransformation: (row) => null,
      valueTransformation: (row) => `${row["percentage"]}%`,
    },
  ];

  const infoIsEmpty = !esgInfo?.spatial_information || !Object.keys(esgInfo?.spatial_information)?.length;
  const referenceIsEmpty = !Object.keys(esgReference || {})?.length;

  const ranksConfigs = [
    {
      dataKey: "EndEnergyDemand",
      name: t("BuildingProfile.kpis.endEnergyDemand"),
      unit: "kWh/m²",
    },
    {
      dataKey: "PrimaryEnergyDemand",
      name: t("BuildingProfile.kpis.primaryEnergyDemand"),
      unit: "kWh/m²",
    },
    {
      dataKey: "CO2Emissions",
      name: t("BuildingProfile.kpis.totalCarbonEmission"),
      unit: "kgCO₂eq/m²",
    },
  ];

  const [selectedQuantity, setSelectedQunatity] = useState(ranksConfigs[0].dataKey);

  const esgReferenceTableData = Object.entries(esgReference?.[selectedQuantity] ?? {}).map(([usageType, values]) => {
    const row = { usageType };
    Object.entries(values ?? {}).forEach(([name, value]) => {
      row[name] = value;
    });
    return row;
  });

  if (esgReferenceTableData?.length) {
    const values = esgInfo?.performance_data?.[selectedQuantity];
    const top15 = values?.weighted_benchmarks?.["15"];
    const top30 = values?.weighted_benchmarks?.["30"];
    const avg = values?.weighted_benchmarks?.["50"];

    esgReferenceTableData.push({
      15: top15,
      30: top30,
      50: avg,
      usageType: t("BuildingProfile.esg.table.columns.yourBuilding"),
    });
  }

  const esgReferenceTableColumns = [
    {
      cellType: null,
      dataKey: "usageType",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: t("BuildingProfile.esg.ranks.buildingType"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["usageType"],
    },
    {
      cellType: null,
      dataKey: "50",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: t("BuildingProfile.esg.ranks.average"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["50"],
    },
    {
      cellType: null,
      dataKey: "30",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: t("BuildingProfile.esg.ranks.top30"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["30"],
    },
    {
      cellType: null,
      dataKey: "15",
      downloadZipFile: (row) => null,
      href: (row) => null,
      label: t("BuildingProfile.esg.ranks.top15"),
      subValueTransformation: (row) => null,
      valueTransformation: (row) => row["15"],
    },
  ];

  return (
    <>
      <div className={`${className}   relative min-h-[20rem]`}>
        <ComponentsCommonHeader className="!pb-4 !pt-0" title={t("BuildingProfile.esg.title")} />
        <LoadingOrEmptyComponent showEmpty={infoIsEmpty} showLoading={infoIsLoading} />
        {!infoIsLoading && (
          <Table
            className="max-h-50"
            data={esgInfoTableData}
            headers={esgInfoTableColumns}
            idColumnName="usageType"
            rowHeight="1rem"
          />
        )}
      </div>
      <Grid className="mt-1 w-full" columnSpacing={4} container rowSpacing={6}>
        {ranksConfigs.map((config) => {
          const values = esgInfo?.performance_data?.[config.dataKey];
          const top15 = values?.weighted_benchmarks?.["15"];
          const top30 = values?.weighted_benchmarks?.["30"];
          const avg = values?.weighted_benchmarks?.["50"];
          const bulidingValue = values?.building_value;
          const buildingPercentageRank = values?.current_estimated_value;
          if (isNumeric(bulidingValue))
            return (
              <Grid item xs={4}>
                <Stack className="t-label-l h-full flex-col justify-between" gap={2}>
                  <DataCard kpi={{ name: config.name, unit: config.unit, value: bulidingValue }} />
                  <ESGRankClass
                    avg={avg}
                    buildingPercentageRank={buildingPercentageRank}
                    top15={top15}
                    top30={top30}
                    value={bulidingValue}
                  />
                </Stack>
              </Grid>
            );
        })}
      </Grid>
      <div className={`${className} relative  mt-8 min-h-[20rem]`}>
        <LoadingOrEmptyComponent showEmpty={referenceIsEmpty} showLoading={referenceIsLoading} />
        {!referenceIsLoading && (
          <>
            <DropdownSingleSelect
              className="my-2 w-70 "
              displayOptions={ranksConfigs.map((config) => ` ${sentenceCase(config.name)} ${config.unit}`)}
              label={t("BuildingProfile.esg.ranks.quantity")}
              options={ranksConfigs.map((config) => config.dataKey)}
              selectedValue={selectedQuantity}
              setSelectedValue={setSelectedQunatity}
            />
            <Table
              className="max-h-50"
              data={esgReferenceTableData}
              headers={esgReferenceTableColumns}
              idColumnName="usageType"
              rowHeight="1rem"
            />
          </>
        )}
      </div>
    </>
  );
};
