import { CircularProgress, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FilterTypes } from "../../enums/components";
import {
  chartTypesEnums,
  diagramTypes,
  diagramTypesDisplayOptions,
  getSortOptions,
} from "../../enums/dynamicDashboard";
import {
  categoricalColumns,
  columnsAPINamesEnums,
  columnsDisplayNamesEnums,
  columnsToExclude,
  columnsUnits,
  defaultColors,
  FiltersNames,
  getFinancialDashboardColumnDisplayName,
  isRelevantOption,
} from "../../enums/Modernization/financialDashboard";
import {
  deleteKeysFromObjects,
  distinctFilter,
  findDataRange,
  getUniqueValuesFromJson,
} from "../../utils/dataManipulation";
import { getColorEntity, handleConfigChange, settingsOptions } from "../../utils/dynamicDashboard";
import { Button } from "../ButtonNew";
import { DynamicDashboardMultiTab } from "../chart/DynamicDashboardMultiTab";
import { CustomModal } from "../CustomModal";
import { Divider } from "../Divider";

const filtersCategories = [
  {
    categoryName: "Basic Criteria",
    filtersNames: [
      columnsAPINamesEnums.ADAPTATION,
      columnsAPINamesEnums.COMPONENT,
      columnsAPINamesEnums.SUB_COMPONENT,
      columnsAPINamesEnums.TYPE_OF_MESSURE,
    ],
  },
  {
    categoryName: "Energetic effect",
    filtersNames: [
      columnsAPINamesEnums.REDUCTION_ELECTRICITY_END,
      columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY,
      columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND,
      columnsAPINamesEnums.REDUCTION_ENERGY_END,
      columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY,
      columnsAPINamesEnums.REDUCTION_HEAT_END,
      columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY,
      columnsAPINamesEnums.ELECTRICITY_REDUCTION,
      columnsAPINamesEnums.HEAT_REDUCTION,
    ],
  },
  {
    categoryName: "Environmental Effect",
    filtersNames: [columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS, columnsAPINamesEnums.EMBODIED_CARBON],
  },
  {
    categoryName: "Economic KPIs",
    filtersNames: [columnsAPINamesEnums.ROI, columnsAPINamesEnums.INFLATION_RATE],
  },
  {
    categoryName: "Economic benefits",
    filtersNames: [
      columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS,
      columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS,
      columnsAPINamesEnums.OTHER_COST_SAVINGS,
      columnsAPINamesEnums.COST_SAVINGS,
    ],
  },
  {
    categoryName: "Operating Costs",
    filtersNames: [columnsAPINamesEnums.MAINTENANCE_COST, columnsAPINamesEnums.SERVICES_COST],
  },
  {
    categoryName: "Investment Costs",
    filtersNames: [
      columnsAPINamesEnums.LOAN_TYPE,
      columnsAPINamesEnums.FOEIGN_CAPITAL,
      columnsAPINamesEnums.OWN_CAPITAL,
      columnsAPINamesEnums.INVESTMENTS,
      columnsAPINamesEnums.INTEREST_RATE,
      columnsAPINamesEnums.REPAYMENT_TIME,
      columnsAPINamesEnums.SUBSIDIES,
    ],
  },
];

export const FinancialDashbaord = ({
  data,
  isAdding,
  isDeleting,
  onAddRetrofit,
  onDeleteRetrofit,
  projectId,
  selectedRetrofits,
}) => {
  const { t } = useTranslation();
  const dataColumns = Object.values(columnsAPINamesEnums).filter((col) => !columnsToExclude.includes(col));
  let { generalDashboardData, numericalColumns } = useMemo(() => {
    const allColumns = Object.keys(data?.[0] ?? {});
    const toBeRemovedColumns = allColumns?.filter((col) => !dataColumns.includes(col));

    const shreinkedData = deleteKeysFromObjects(data ?? [], toBeRemovedColumns);
    const generalDashboardData = shreinkedData.map((row) => {
      return {
        ...row,
        [columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION]:
          row[columnsAPINamesEnums.ELECTRICITY_REDUCTIONN] + row[columnsAPINamesEnums.HEAT_REDUCTION],
      };
    });
    const numericalColumns = dataColumns.filter((col) => !categoricalColumns.includes(col));
    return { generalDashboardData, numericalColumns };
  }, [data]);

  const [filteredData, setFilteredData] = useState(generalDashboardData);
  const useGetSpecificData = (_config) => {
    return { isError: false, isLoading: false, specificData: [] };
  };

  const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
    const { chartType, dataPoint, diagrams, xAxis, yAxis } = config;

    return JSON.stringify([dataPoint, chartType, diagrams, xAxis, yAxis]);
  }, []);

  const dataTransformator = useCallback((data, { chartType, dataPoint, diagrams, xAxis, yAxis }) => {
    let output = [];
    const { partitions, stacks } = diagrams?.[0];
    if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {
      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = { flattenData: flattenData || [] };
    } else if (
      [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.KPI, chartTypesEnums.LINE].includes(chartType)
    ) {
      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = { flattenData: flattenData || [] };
    } else if (chartType === chartTypesEnums.HEATMAP) {
      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (yAxis) filteredRow[yAxis] = row[yAxis];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      const xLabels = data?.map((row) => row[xAxis])?.filter(distinctFilter);
      xLabels?.sort();

      const yLabels = data?.map((row) => row[yAxis])?.filter(distinctFilter);
      yLabels?.sort();

      output = { flattenData: flattenData || [], xLabels, yLabels };
    } else {
      output = { flattenData: data || [] };
    }

    return output;
  }, []);

  const dynamicChartsRowHeight = 50;

  const getUnit = useCallback(({ quantity }) => columnsUnits?.[quantity], []);
  const getColumnDisplayNameCallback = useCallback((props) => getFinancialDashboardColumnDisplayName(props), []);

  const defaultPortfolioConfigsAndColorsConfigs = {
    colors: {
      Columns: {},
      Mixed: {},
      Values: {},
    },
    tabs: [
      {
        filters: {},
        selectedFiltersNames: [],
        settings: [],
        tab: "Overview",
      },
    ],
  };

  const dataColumnsDisplayOptions = dataColumns?.map((colName) => getFinancialDashboardColumnDisplayName({ colName }));

  const allSettings = (updateChart, config) => [
    {
      adjustConfig: (config) => config,
      filterLabel: "Chart title",
      filterName: FiltersNames.TITLE,
      filterType: FilterTypes.INPUT,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        height: "4rem",
        label: "Title",
        placeholder: "Title",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Metric",
      filterName: FiltersNames.DATAPOINT,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      mainDataKey: "dataPoint",
      props: {
        displayOptions: dataColumnsDisplayOptions,
        label: "Metric",
        options: dataColumns,
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "X Axis",
      filterName: FiltersNames.X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: [...dataColumnsDisplayOptions],
        label: "x-axis",
        options: [...dataColumns],
      },
      setConfig: (updateChart) => updateChart,
      visible: () =>
        [chartTypesEnums.COMPOSED].includes(config.chartType) && isRelevantOption(config.chartType, settingsOptions.X),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "X Axis",
      filterName: FiltersNames.X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: [...dataColumnsDisplayOptions],
        label: "x-axis",
        options: [...dataColumns],
      },
      setConfig: (updateChart) => updateChart,
      visible: () =>
        [chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.HEATMAP, chartTypesEnums.LINE].includes(
          config.chartType
        ) && isRelevantOption(config.chartType, settingsOptions.X),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Aggregation",
      filterName: FiltersNames.AGGREGATE_X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: ["Don't aggregate", "Aggregate"],
        label: "Aggregation",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.AGGREGATE_X_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Invert X Axis",
      filterName: FiltersNames.INVERT_X_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Invert X Axis",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Y Axis",
      filterName: FiltersNames.Y_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "w-3/4",
        displayOptions: [...dataColumnsDisplayOptions],
        label: "y-axis",
        options: [...dataColumns],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Invert Y Axis",
      filterName: FiltersNames.INVERT_Y_AXIS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["No", "Yes"],
        label: "Invert Y Axis",
        options: [0, 1],
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Lengeds Position",
      filterName: FiltersNames.LEGEND_POSITION,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        displayOptions: ["Bottom", "Right"],
        label: "Lengeds position",
        options: ["bottom", "right"],
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Show labels",
      filterName: FiltersNames.SHOW_PIE_LABELS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        displayOptions: ["No", "Yes"],
        label: "Show labels",
        options: [0, 1],
        width: "75%",
      },
      setConfig: (updateChart) => updateChart,
      visible: () => isRelevantOption(config.chartType, settingsOptions.SHOW_PIE_LABELS),
    },
    {
      adjustConfig: (config) => config,
      filterLabel: "Sort by",
      filterName: FiltersNames.SORT_VALUE,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: false,
      props: {
        className: "block w-3/4 ",
        displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
        label: "Sort by",
        options: getSortOptions({ chartType: config.chartType })?.options,
      },
      setConfig: (updateChart) => updateChart,
      visible: () => {
        return (
          isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
          !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
        );
      },
    },
    // {
    //   mainDataKey: undefined,
    //   useAsDataFilter: false,
    //   filterName: FiltersNames.PER_M2,
    //   filterLabel: 'Per m²',
    //   filterType: FilterTypes.DROPDOWN_SINGLE,
    //   adjustConfig: (config) => config,
    //   setConfig: (updateChart) => updateChart,
    //   props: {
    //     label: "Per m²",
    //     options: [0, 1],
    //     displayOptions: ["No", "Yes"],
    //     width: "75%",
    //   },
    //   visible: () => isRelevantOption(config.chartType, settingsOptions.PER_M2),
    //   isInDiagramsPart: false,
    // },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Y Axis",
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: [...dataColumnsDisplayOptions],
        label: "Y Axis",
        options: [...dataColumns],
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
    },
    // {
    //   mainDataKey: columnsAPINamesEnums.SCENARIO,
    //   useAsDataFilter: false,
    //   filterName: FiltersNames.DIAGRAMS_SCENARIO,
    //   filterLabel: 'Scenario',
    //   filterType: FilterTypes.DROPDOWN_SINGLE,
    //   adjustConfig: (config) => config,
    //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
    //   props: {
    //     label: "Scenario",
    //     options: scenarios,
    //     displayOptions: scenarios,
    //     width: "75%",
    //   },
    //   visible: () => isRelevantOption(config.chartType, FiltersNames.DIAGRAMS_SCENARIO,),
    //   isInDiagramsPart: true,
    // },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Diagram type",
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: Object.values(diagramTypesDisplayOptions).filter(
          (type) => type !== diagramTypesDisplayOptions.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0
        ),
        label: "Diagram type",
        options: Object.values(diagramTypes).filter(
          (type) => type !== diagramTypes.BAR || config[FiltersNames.AGGREGATE_X_AXIS] !== 0
        ),
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Partitions",
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: [...dataColumnsDisplayOptions],
        label: "Partitions",
        options: [...dataColumns],
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Stacks",
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: [...dataColumnsDisplayOptions],
        label: "Stacks",
        options: [...dataColumns],
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
    },
    {
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) =>
        handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      filterLabel: "Direction",
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterType: FilterTypes.DROPDOWN_SINGLE,
      isInDiagramsPart: true,
      props: {
        className: "block w-3/4 ",
        displayOptions: ["Horizontal", "Vertical"],
        label: "Direction",
        options: ["horizontal", "vertical"],
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
    },
  ];

  const nameForLocalStorage = `financialDashboardConfigs-${projectId}`;

  const loadDashboardConfigs = () => {
    let dashboardConfigs = localStorage.getItem(nameForLocalStorage);
    if (dashboardConfigs) {
      dashboardConfigs = JSON.parse(dashboardConfigs);
    } else {
      dashboardConfigs = defaultPortfolioConfigsAndColorsConfigs;
    }
    return dashboardConfigs;
  };

  let savedPortfolioAndColorsConfigsMemoized = useMemo(() => {
    let savedPortfolioAndColorsConfigs = loadDashboardConfigs(projectId);
    return savedPortfolioAndColorsConfigs;
  }, []);

  const onResetDashboardConfigs = () => {
    localStorage.removeItem(nameForLocalStorage);
  };

  const saveDashboardConfigs = (data) => {
    localStorage.setItem(nameForLocalStorage, JSON.stringify(data));
  };

  const chartTypes = useMemo(
    () => [
      chartTypesEnums.AREA,
      chartTypesEnums.BAR,
      chartTypesEnums.COMPOSED,
      chartTypesEnums.DOUGHNUT,
      chartTypesEnums.HEATMAP,
      chartTypesEnums.HISTOGRAM,
      chartTypesEnums.KPI,
      chartTypesEnums.LINE,
      chartTypesEnums.PIE,
    ],
    []
  );

  const renderChartsObjectClickModal = ({ clickedObject, relatedObjects, setClickedObject }) => {
    return (
      <CustomModal
        className="relative"
        disableScrollLock
        modalOpen={clickedObject}
        setModalOpen={setClickedObject}
        width={500}
      >
        <h3 className="t-heading-l ">{t("Modernization.wizard.retrofitMeasures")}</h3>
        {(isAdding || isDeleting) && (
          <Stack className="absolute left-4  top-4 gap-1">
            <CircularProgress className="!h-3 !w-3 " />
            <span className=" t-body-s">{isAdding ? t("general.adding") : t("general.deleting")}...</span>
          </Stack>
        )}
        <Stack className="mt-6 !h-70 flex-col gap-2 overflow-auto pr-2">
          {relatedObjects?.map((obj) => {
            const nameCol = columnsAPINamesEnums.NAME;
            const isAlreadySelected = selectedRetrofits?.map((r) => r[nameCol])?.includes(obj[nameCol]);
            return (
              <>
                <Stack className="w-full items-center justify-between">
                  <span className="t-heading-s">{obj?.[nameCol]}</span>
                  {!isAlreadySelected ? (
                    <Button
                      disabled={isAdding || isDeleting}
                      onClick={() => {
                        onAddRetrofit(obj);
                      }}
                    >
                      + {t("Modernization.wizard.addRetrofit")}
                    </Button>
                  ) : (
                    <Button
                      disabled={isAdding || isDeleting}
                      onClick={() => {
                        onDeleteRetrofit(obj);
                      }}
                      variant="error"
                    >
                      - {t("Modernization.wizard.deleteRetrofit")}
                    </Button>
                  )}
                </Stack>
                <Divider />
              </>
            );
          })}
        </Stack>
      </CustomModal>
    );
  };

  const allAvailableFilters = ({ allFiltersConfig, setConfig }) => [
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.LOAN_TYPE,
      filterName: columnsAPINamesEnums.LOAN_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.LOAN_TYPE,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.LOAN_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.LOAN_TYPE),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.COMPONENT,
      filterName: columnsAPINamesEnums.COMPONENT,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.COMPONENT,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.COMPONENT,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.COMPONENT),
        width: "100%",
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.SUB_COMPONENT,
      filterName: columnsAPINamesEnums.SUB_COMPONENT,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.SUB_COMPONENT,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.SUB_COMPONENT,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.SUB_COMPONENT),
        width: "100%",
      },
      setConfig,
    },

    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.TYPE_OF_MESSURE,
      filterName: columnsAPINamesEnums.TYPE_OF_MESSURE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsAPINamesEnums.TYPE_OF_MESSURE,
      props: {
        height: "4.8rem",
        label: columnsDisplayNamesEnums.TYPE_OF_MESSURE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsAPINamesEnums.TYPE_OF_MESSURE),
        width: "100%",
      },
      setConfig,
    },
    // {
    //   config: allFiltersConfig,
    //   filterDisplayName: columnsDisplayNamesEnums.TOTAL_ENERGY_REDUCTION,
    //   filterName: columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION,
    //   filterType: FilterTypes.NUMERIC_RANGE_PICKER,
    //   mainDataKey: columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION,
    //   props: {
    //     className: "w-full",
    //     label: columnsDisplayNamesEnums.TOTAL_ENERGY_REDUCTION,
    //     ...findDataRange({
    //       data: generalDashboardData,
    //       dataKeys: [columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION],
    //       defaultMin: 0,
    //     }),
    //   },
    //   setConfig,
    // },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.COST_SAVINGS,
      filterName: columnsAPINamesEnums.COST_SAVINGS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.COST_SAVINGS,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.COST_SAVINGS,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.COST_SAVINGS], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.CARBON_DIOXIDE_SAVINGS,
      filterName: columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.CARBON_DIOXIDE_SAVINGS,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ELECTRICITY_COST_SAVINGS,
      filterName: columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.ELECTRICITY_COST_SAVINGS,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ELECTRICITY_REDUCTION,
      filterName: columnsAPINamesEnums.ELECTRICITY_REDUCTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ELECTRICITY_REDUCTION,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.ELECTRICITY_REDUCTION,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.ELECTRICITY_REDUCTION],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.EMBODIED_CARBON,
      filterName: columnsAPINamesEnums.EMBODIED_CARBON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.EMBODIED_CARBON,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.EMBODIED_CARBON,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.EMBODIED_CARBON],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.FOEIGN_CAPITAL,
      filterName: columnsAPINamesEnums.FOEIGN_CAPITAL,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.FOEIGN_CAPITAL,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.FOEIGN_CAPITAL,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.FOEIGN_CAPITAL],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_ENERGY_COST_SAVINGS,
      filterName: columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.HEAT_ENERGY_COST_SAVINGS,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.HEAT_REDUCTION,
      filterName: columnsAPINamesEnums.HEAT_REDUCTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.HEAT_REDUCTION,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.HEAT_REDUCTION,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.HEAT_REDUCTION],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.INVESTMENTS,
      filterName: columnsAPINamesEnums.INVESTMENTS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.INVESTMENTS,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.INVESTMENTS,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.INVESTMENTS], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.MAINTENANCE_COST,
      filterName: columnsAPINamesEnums.MAINTENANCE_COST,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.MAINTENANCE_COST,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.MAINTENANCE_COST,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.MAINTENANCE_COST],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.SERVICES_COST,
      filterName: columnsAPINamesEnums.SERVICES_COST,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.SERVICES_COST,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.SERVICES_COST,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.SERVICES_COST], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.SUBSIDIES,
      filterName: columnsAPINamesEnums.SUBSIDIES,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.SUBSIDIES,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.SUBSIDIES,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.SUBSIDIES], defaultMin: 0 }),
      },
      setConfig,
    },
    // {
    //   config: allFiltersConfig,
    //   filterDisplayName: columnsDisplayNamesEnums.TOTAL_COST,
    //   filterName: columnsAPINamesEnums.TOTAL_COST,
    //   filterType: FilterTypes.NUMERIC_RANGE_PICKER,
    //   mainDataKey: columnsAPINamesEnums.TOTAL_COST,
    //   props: {
    //     className: "w-full",
    //     label: columnsDisplayNamesEnums.TOTAL_COST,
    //     ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.TOTAL_COST], defaultMin: 0 }),
    //   },
    //   setConfig,
    // },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.OTHER_COST_SAVINGS,
      filterName: columnsAPINamesEnums.OTHER_COST_SAVINGS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.OTHER_COST_SAVINGS,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.OTHER_COST_SAVINGS,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.OTHER_COST_SAVINGS],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.OWN_CAPITAL,
      filterName: columnsAPINamesEnums.OWN_CAPITAL,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.OWN_CAPITAL,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.OWN_CAPITAL,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsAPINamesEnums.OWN_CAPITAL], defaultMin: 0 }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REDUCTION_ELECTRICITY_END,
      filterName: columnsAPINamesEnums.REDUCTION_ELECTRICITY_END,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REDUCTION_ELECTRICITY_END,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REDUCTION_ELECTRICITY_END,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.REDUCTION_ELECTRICITY_END],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REDUCTION_ELECTRICITY_PRIMARY,
      filterName: columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REDUCTION_ELECTRICITY_PRIMARY,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REDUCTION_ENERGY_DEMAND,
      filterName: columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REDUCTION_ENERGY_DEMAND,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REDUCTION_ENERGY_END,
      filterName: columnsAPINamesEnums.REDUCTION_ENERGY_END,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REDUCTION_ENERGY_END,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REDUCTION_ENERGY_END,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.REDUCTION_ENERGY_END],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REDUCTION_ENERGY_PRIMARY,
      filterName: columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REDUCTION_ENERGY_PRIMARY,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REDUCTION_HEAT_END,
      filterName: columnsAPINamesEnums.REDUCTION_HEAT_END,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REDUCTION_HEAT_END,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REDUCTION_HEAT_END,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.REDUCTION_HEAT_END],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REDUCTION_HEAT_PRIMARY,
      filterName: columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REDUCTION_HEAT_PRIMARY,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.REPAYMENT_TIME,
      filterName: columnsAPINamesEnums.REPAYMENT_TIME,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.REPAYMENT_TIME,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.REPAYMENT_TIME,
        max: 100,
        min: 0,
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.INTEREST_RATE,
      filterName: columnsAPINamesEnums.INTEREST_RATE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.INTEREST_RATE,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.INTEREST_RATE,
        max: 100,
        min: 0,
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.INFLATION_RATE,
      filterName: columnsAPINamesEnums.INFLATION_RATE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.INFLATION_RATE,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.INFLATION_RATE,
        max: 100,
        min: 0,
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ROI,
      filterName: columnsAPINamesEnums.ROI,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ROI,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.ROI,
        ...findDataRange({
          data: generalDashboardData,
          dataKeys: [columnsAPINamesEnums.ROI],
          defaultMin: 0,
        }),
      },
      setConfig,
    },
    {
      config: allFiltersConfig,
      filterDisplayName: columnsDisplayNamesEnums.ADAPTATION,
      filterName: columnsAPINamesEnums.ADAPTATION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsAPINamesEnums.ADAPTATION,
      props: {
        className: "w-full",
        label: columnsDisplayNamesEnums.ADAPTATION,
        max: 100,
        min: 0,
      },
      setConfig,
    },
  ];

  return (
    <DynamicDashboardMultiTab
      allAvailableFilters={allAvailableFilters}
      allFilters={() => []}
      allSettings={allSettings}
      categoricalColumns={categoricalColumns}
      chartTypes={chartTypes}
      dataTransformator={dataTransformator}
      defaultColors={defaultColors}
      defaultPortfolioConfigsAndColorsConfigs={defaultPortfolioConfigsAndColorsConfigs}
      dynamicChartsRowHeight={dynamicChartsRowHeight}
      filteredData={filteredData}
      filtersCategories={filtersCategories}
      generalDashboardData={generalDashboardData}
      getColorEntity={getColorEntity}
      getColumnDisplayName={getColumnDisplayNameCallback}
      getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
      getUnit={getUnit}
      IdColumnName={columnsAPINamesEnums.ID}
      multithread={false}
      numericalColumns={numericalColumns}
      onReset={onResetDashboardConfigs}
      onSave={saveDashboardConfigs}
      renderChartsObjectClickModal={renderChartsObjectClickModal}
      savedPortfolioAndColorsConfigs={savedPortfolioAndColorsConfigsMemoized}
      setFilteredData={setFilteredData}
      setTriggerFlag={() => {}}
      useGetSpecificData={useGetSpecificData}
    />
  );
};
