import { getColumnDisplayName, settingsOptions } from "../../utils/dynamicDashboard";
import { chartTypesEnums, colorCategoriesEnums } from "../dynamicDashboard";

export const getPortfolioColumnDisplayName = ({ colName }) => {
  return getColumnDisplayName({ colName, dataMapping });
};

export const FiltersNames = Object.freeze({
  ADDRESS: "Addresse",
  AGGREGATE_X_AXIS: "aggregateXAxis",
  CASH_IN: "cash_in",
  CO2_EMISSION: "CO2 Emissions",
  CONSTRUCTION_YEAR: "Construction year",
  COUNT: "Count",
  DATAPOINT: "dataPoint",
  DIAGRAMS: "diagrams",
  DIAGRAMS_COLOR: "color",
  DIAGRAMS_DIAGRAM_DATA_KEY: "dataKey",
  DIAGRAMS_DIAGRAM_TYPE: "diagramType",
  DIAGRAMS_DIRECTION: "direction",
  DIAGRAMS_PARTITIONS: "partitions",
  DIAGRAMS_STACKS: "stacks",
  ELECTRICITY_CONSUMPTION: "Electrictiy consumption",
  ENERGY_CONSUMPTION: "Energy consumption",
  ENERGY_EFFICIENCY_CLASS: "Energy Efficiency class",
  FLOOR_AMOUMT: "Floor amount",
  FOOTPRINT_AREA: "Footprint area",
  GROSS_AREA: "Gross area",
  GROSS_ASSET_VALUE: "gross_asset_value",
  HEAT_ENERGY_CONSUMPTION: "Heat energy consumption",

  HEAT_ENERGY_TYPE: "Heat energy type",
  ID: "ID",
  INVERT_X_AXIS: "invertXAxis",
  INVERT_Y_AXIS: "invertYAxis",
  LATITUDE: "lat",
  LEGEND_POSITION: "legendsPosition",
  LONGITUDE: "lon",
  MAIN_USAGE: "Main usage",
  NAME: "Name",

  PORTFOLIO: "Portfolio",
  PREMISE_AREA: "Premise area",
  SHOW_PIE_LABELS: "showPieLabels",
  SORT_VALUE: "sortValue",
  STRANDING_YEAR: "Stranding year",
  TITLE: "title",
  X_AXIS: "xAxis",
  Y_AXIS: "yAxis",
});

export const columnsDisplayNamesEnums = Object.freeze({
  BALCONY: "Balkone",
  BOUGHT_ON: "Bought on",
  BUILDING_ID: "Building ID",
  BUILDING_NAME: "Building Name",
  BUILDING_TYPE: "Building Type",
  BUILDING_TYPE_ID: "Building Type ID",
  CEILING_AREA: "Ceiling Area",
  CITY: "City",
  CO2_EMISSION: "CO2 Emissions",
  COUNT: "Count",
  DISTRICT_HEATING_AVAILABLE: "District Heating available",
  ELECTRICITY_DEMAND_KWH_A: "Electricity Demand kWh/a",
  ELECTRICITY_DEMAND_KWH_M2A: "Electricity Demand kWh/m²/a",
  ENERGY_EFFICIENCY_CLASS: "EnA-Eff.kl",
  EPC_CONSUMPTION_KWH_M2A: "EPC-Consumption kWh/m²/a",
  FACADE_AREA: "Facade Area",
  FLOOR_AMOUMT: "Floor amount",
  FOOTPRINT: "Footprint",
  FULL_ADDRESS: "Full Address",
  GROSS_AREA_WITH_BASEMENT: "Gross area (incl. Basement)",
  GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT: "Gross Lettable Area (without Basement)",
  // / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
  HEAT_ENERGY_CONSUMPTION_EPC_KWH_A: "Heat Energy Consumption kWh/a",
  HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A: "Heat Energy Consumption EPC kWh/m²/a",
  HEAT_ENERGY_TYPE: "Heat Energy Type",
  HERITAGE_PROTECTED: "Heritage Protected",
  ID: "ID",
  IMAGE_URL_VALUE: "Imgae Url Value",
  INDEX: "index",
  LATITUDE: "lat",
  LOGGIA: "Loggia",
  LONGITUDE: "lon",
  MAIN_DISTRICT: "Main District",
  NATURAL_GAS_AVAILABLE: "Natural Gas available",
  NET_AREA_WITH_BASEMENT: "Net Area (incl. Basement)",
  NET_LETTABEL_AREA_WITHOUT_BASEMENT: "Net Lettable Area (without Basement)",
  // 'Accounting Area': '',
  NONE: "",
  PORTFOLIO: "Portfolio",
  PRE_FABRICATED: "Pre Fabricated",
  PREMISE: "Premise",
  RENTAL_UNITS: "Rental Units",
  SOLD_ON: "Sold on",
  STRANDING_YEAR: "Stranding year",
  STREET: "Street",
  SUB_DISTRICT: "Sub District",
  TOTAL_ENERGY_CONSUMPTION: "Total Energy consumption",
  USE_TYPE: "Use type",
  YEAR_OF_CONSTRUCTION: "Year of construction",
  ZIP: "Zip",
});

export const columnsAPINamesEnums = Object.freeze({
  BALCONY: "balcony",
  BOUGHT_ON: "bought_on",
  BUILDING_ID: "asset_id",
  BUILDING_NAME: "descriptive_name",
  BUILDING_TYPE: "building_type",
  BUILDING_TYPE_ID: "building_type_id",
  CEILING_AREA: "ceiling_area",
  CITY: "city",
  CO2_EMISSION: "carbon_dioxide_emission_value",
  COUNT: "Count",
  DISTRICT_HEATING_AVAILABLE: "district_heating_available",
  ELECTRICITY_DEMAND_KWH_A: "electricity_demand_kwh_per_annum",
  ELECTRICITY_DEMAND_KWH_M2A: "electricity_demand_kwh_per_square_meter_annum",
  ENERGY_EFFICIENCY_CLASS: "energy_label",
  EPC_CONSUMPTION_KWH_M2A: "epc_consumption_kwh_per_square_meter_annum",
  FACADE_AREA: "facade_area",
  FLOOR_AMOUMT: "floor_amount",
  FOOTPRINT: "footprint_area",
  FULL_ADDRESS: "address",
  GROSS_AREA_WITH_BASEMENT: "gross_asset_basement_included",
  GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT: "gross_lettable_area_without_basement",
  HEAT_ENERGY_CONSUMPTION_EPC_KWH_A: "heat_energy_consumption_kwh_per_annum",
  HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A: "heat_energy_consumption_epc_kwh_per_square_meter_annum",
  HEAT_ENERGY_TYPE: "heat_energy_type",
  HERITAGE_PROTECTED: "heritage_protected",
  // 'Accounting Area': '',
  ID: "id",
  IMAGE_URL_VALUE: "image_url_value",
  LATITUDE: "latitude",
  LOGGIA: "loggia",
  LONGITUDE: "longitude",
  MAIN_DISTRICT: "main_district",
  NATURAL_GAS_AVAILABLE: "natural_gas_available",
  NET_AREA_WITH_BASEMENT: "net_area_basement_included",
  NET_LETTABEL_AREA_WITHOUT_BASEMENT: "net_lettable_area_without_basement",
  PORTFOLIO: "portfolio",
  PRE_FABRICATED: "pre_fabricated",
  PREMISE: "premise_area",
  RENTAL_UNITS: "rental_units",
  SOLD_ON: "sold_on",
  STRANDING_YEAR: "stranding_year",
  STREET: "street",
  SUB_DISTRICT: "sub_district",
  TOTAL_ENERGY_CONSUMPTION: "energy_consumption",
  USE_TYPE: "type",
  YEAR_OF_CONSTRUCTION: "construction_year",
  ZIP: "post_code",

  // / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
});

export const dataMapping = {
  [columnsAPINamesEnums.BALCONY]: columnsDisplayNamesEnums.BALCONY,
  [columnsAPINamesEnums.BOUGHT_ON]: columnsDisplayNamesEnums.BOUGHT_ON,
  [columnsAPINamesEnums.BUILDING_ID]: columnsDisplayNamesEnums.BUILDING_ID,
  [columnsAPINamesEnums.BUILDING_NAME]: columnsDisplayNamesEnums.BUILDING_NAME,
  [columnsAPINamesEnums.BUILDING_TYPE]: columnsDisplayNamesEnums.BUILDING_TYPE,
  [columnsAPINamesEnums.BUILDING_TYPE_ID]: columnsDisplayNamesEnums.BUILDING_TYPE_ID,
  [columnsAPINamesEnums.CEILING_AREA]: columnsDisplayNamesEnums.CEILING_AREA,
  [columnsAPINamesEnums.CITY]: columnsDisplayNamesEnums.CITY,
  [columnsAPINamesEnums.CO2_EMISSION]: columnsDisplayNamesEnums.CO2_EMISSION,
  [columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE]: columnsDisplayNamesEnums.DISTRICT_HEATING_AVAILABLE,
  [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A]: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_A,
  [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A]: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
  [columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS]: columnsDisplayNamesEnums.ENERGY_EFFICIENCY_CLASS,
  [columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A]: columnsDisplayNamesEnums.EPC_CONSUMPTION_KWH_M2A,
  [columnsAPINamesEnums.FACADE_AREA]: columnsDisplayNamesEnums.FACADE_AREA,
  [columnsAPINamesEnums.FLOOR_AMOUMT]: columnsDisplayNamesEnums.FLOOR_AMOUMT,
  [columnsAPINamesEnums.FOOTPRINT]: columnsDisplayNamesEnums.FOOTPRINT,
  [columnsAPINamesEnums.FULL_ADDRESS]: columnsDisplayNamesEnums.FULL_ADDRESS,
  [columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT]: columnsDisplayNamesEnums.GROSS_AREA_WITH_BASEMENT,
  [columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT]:
    columnsDisplayNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
  [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A]: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
  [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A]:
    columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
  [columnsAPINamesEnums.HEAT_ENERGY_TYPE]: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
  [columnsAPINamesEnums.HERITAGE_PROTECTED]: columnsDisplayNamesEnums.HERITAGE_PROTECTED,
  // 'Accounting Area': ''
  [columnsAPINamesEnums.ID]: columnsDisplayNamesEnums.ID,
  [columnsAPINamesEnums.IMAGE_URL_VALUE]: columnsDisplayNamesEnums.IMAGE_URL_VALUE,
  [columnsAPINamesEnums.LATITUDE]: columnsDisplayNamesEnums.LATITUDE,
  [columnsAPINamesEnums.LOGGIA]: columnsDisplayNamesEnums.LOGGIA,
  [columnsAPINamesEnums.LONGITUDE]: columnsDisplayNamesEnums.LONGITUDE,
  [columnsAPINamesEnums.MAIN_DISTRICT]: columnsDisplayNamesEnums.MAIN_DISTRICT,
  [columnsAPINamesEnums.NATURAL_GAS_AVAILABLE]: columnsDisplayNamesEnums.NATURAL_GAS_AVAILABLE,
  [columnsAPINamesEnums.NET_AREA_WITH_BASEMENT]: columnsDisplayNamesEnums.NET_AREA_WITH_BASEMENT,
  [columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT]:
    columnsDisplayNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
  [columnsAPINamesEnums.PORTFOLIO]: columnsDisplayNamesEnums.PORTFOLIO,
  [columnsAPINamesEnums.PRE_FABRICATED]: columnsDisplayNamesEnums.PRE_FABRICATED,
  [columnsAPINamesEnums.PREMISE]: columnsDisplayNamesEnums.PREMISE,
  [columnsAPINamesEnums.RENTAL_UNITS]: columnsDisplayNamesEnums.RENTAL_UNITS,
  [columnsAPINamesEnums.SOLD_ON]: columnsDisplayNamesEnums.SOLD_ON,
  [columnsAPINamesEnums.STRANDING_YEAR]: columnsDisplayNamesEnums.STRANDING_YEAR,
  [columnsAPINamesEnums.STREET]: columnsDisplayNamesEnums.STREET,
  [columnsAPINamesEnums.SUB_DISTRICT]: columnsDisplayNamesEnums.SUB_DISTRICT,
  [columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION]: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
  [columnsAPINamesEnums.USE_TYPE]: columnsDisplayNamesEnums.USE_TYPE,
  [columnsAPINamesEnums.YEAR_OF_CONSTRUCTION]: columnsDisplayNamesEnums.YEAR_OF_CONSTRUCTION,
  [columnsAPINamesEnums.ZIP]: columnsDisplayNamesEnums.ZIP,
  // a': '':// 'Electricity Consumption EPC kWh/m,
};

export const categoricalColumns = [
  // 'Accounting Area': ''
  // a': '':// 'Electricity Consumption EPC kWh/m,
  columnsAPINamesEnums.PORTFOLIO,
  columnsAPINamesEnums.BUILDING_NAME,
  columnsAPINamesEnums.ZIP,
  columnsAPINamesEnums.CITY,
  columnsAPINamesEnums.MAIN_DISTRICT,
  columnsAPINamesEnums.SUB_DISTRICT,
  columnsAPINamesEnums.FULL_ADDRESS,
  columnsAPINamesEnums.STREET,
  columnsAPINamesEnums.HERITAGE_PROTECTED,
  columnsAPINamesEnums.BUILDING_TYPE,
  columnsAPINamesEnums.PRE_FABRICATED,
  columnsAPINamesEnums.LOGGIA,
  columnsAPINamesEnums.BALCONY,
  columnsAPINamesEnums.USE_TYPE,
  columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
  columnsAPINamesEnums.HEAT_ENERGY_TYPE,
  columnsAPINamesEnums.IMAGE_URL_VALUE,
];

export const dataNeededColumns = Object.values(columnsAPINamesEnums).filter(
  (col) => col !== columnsAPINamesEnums.IMAGE_URL_VALUE
);
export const infoBoxAndTableNeededColumns = [
  // Info Box Specific
  columnsAPINamesEnums.ID,
  columnsAPINamesEnums.FULL_ADDRESS,
  columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
  columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
  columnsAPINamesEnums.STRANDING_YEAR,
  columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,

  // Table Specific
  columnsAPINamesEnums.PORTFOLIO,
  columnsAPINamesEnums.HEAT_ENERGY_TYPE,

  // Common Columns
  columnsAPINamesEnums.USE_TYPE,
  columnsAPINamesEnums.IMAGE_URL_VALUE,
  columnsAPINamesEnums.BUILDING_NAME,
  columnsAPINamesEnums.CO2_EMISSION,
  columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
];

export const columnsUnits = Object.freeze({
  [columnsAPINamesEnums.BALCONY]: "",
  [columnsAPINamesEnums.BOUGHT_ON]: "Year",
  [columnsAPINamesEnums.BUILDING_ID]: "",
  [columnsAPINamesEnums.BUILDING_NAME]: "",
  [columnsAPINamesEnums.BUILDING_TYPE]: "",
  [columnsAPINamesEnums.BUILDING_TYPE_ID]: "",
  [columnsAPINamesEnums.CEILING_AREA]: "m²",
  [columnsAPINamesEnums.CITY]: "",
  [columnsAPINamesEnums.CO2_EMISSION]: "kg/m²/a",
  [columnsAPINamesEnums.COUNT]: "#",
  [columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE]: "",
  [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A]: "kWh/a",
  [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A]: "kWh/m²/a",
  [columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS]: "",
  [columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A]: "kWh/m²/a",
  [columnsAPINamesEnums.FACADE_AREA]: "m²",
  [columnsAPINamesEnums.FLOOR_AMOUMT]: "",
  [columnsAPINamesEnums.FOOTPRINT]: "m²",
  [columnsAPINamesEnums.FULL_ADDRESS]: "",
  [columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT]: "m²",
  [columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT]: "m²",
  // [columnsNamesEnums.//] / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
  [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A]: "kWh/a",
  [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A]: "kWh/m²/a",
  [columnsAPINamesEnums.HEAT_ENERGY_TYPE]: "",
  [columnsAPINamesEnums.HERITAGE_PROTECTED]: "",
  [columnsAPINamesEnums.LATITUDE]: "Deg",
  [columnsAPINamesEnums.LOGGIA]: "",
  [columnsAPINamesEnums.LONGITUDE]: "Deg",
  [columnsAPINamesEnums.MAIN_DISTRICT]: "",
  [columnsAPINamesEnums.NATURAL_GAS_AVAILABLE]: "",
  [columnsAPINamesEnums.NET_AREA_WITH_BASEMENT]: "m²",
  [columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT]: "m²",
  // 'Accounting Area': '',
  [columnsAPINamesEnums.PORTFOLIO]: "",
  [columnsAPINamesEnums.PRE_FABRICATED]: "",
  [columnsAPINamesEnums.PREMISE]: "m²",
  [columnsAPINamesEnums.RENTAL_UNITS]: "",
  [columnsAPINamesEnums.SOLD_ON]: "Year",
  [columnsAPINamesEnums.STRANDING_YEAR]: "Year",
  [columnsAPINamesEnums.STREET]: "",
  [columnsAPINamesEnums.SUB_DISTRICT]: "",
  [columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION]: "kWh/m²/a",
  [columnsAPINamesEnums.USE_TYPE]: "",
  [columnsAPINamesEnums.YEAR_OF_CONSTRUCTION]: "Year",
  [columnsAPINamesEnums.ZIP]: "",
});

export const defaultColors = Object.freeze({
  [colorCategoriesEnums.COLUMNS]: {},
  [colorCategoriesEnums.VALUES]: {},
});

const relevantConfigOptions = {
  [chartTypesEnums.AREA]: [
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.HEIGHT_100,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.SORT_BY,
    settingsOptions.DATA_POINT,
    settingsOptions.TITLE,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.BAR]: [
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.SORT_BY,
    settingsOptions.DATA_POINT,
    settingsOptions.TITLE,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.COMPOSED]: [
    settingsOptions.TITLE,
    settingsOptions.X,
    settingsOptions.QUANTITY,
    settingsOptions.SORT_BY,
    settingsOptions.DIAGRAM_TYPE,
    settingsOptions.COLOR,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.AGGREGATE_X_AXIS,
  ],
  [chartTypesEnums.DOUGHNUT]: [
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.TITLE,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.SHOW_PIE_LABELS,
  ],
  [chartTypesEnums.HEATMAP]: [
    settingsOptions.TITLE,
    settingsOptions.X,
    settingsOptions.Y,
    settingsOptions.COLOR,
    settingsOptions.DATA_POINT,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.HISTOGRAM]: [
    settingsOptions.TITLE,
    settingsOptions.QUANTITY,
    settingsOptions.SORT_BY,
    settingsOptions.COLOR,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.KPI]: [
    settingsOptions.TITLE,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DIRECTION,
    settingsOptions.DATA_POINT,
  ],
  [chartTypesEnums.LINE]: [
    settingsOptions.TITLE,
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.SORT_BY,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.MINI_MAP]: [settingsOptions.DATA_POINT, settingsOptions.COLOR_BAR_RANGE],
  [chartTypesEnums.PIE]: [
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.TITLE,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT,
    settingsOptions.SHOW_PIE_LABELS,
  ],
};

export const isRelevantOption = (chartType, option, index) => {
  if (index > 0 && chartType !== chartTypesEnums.HISTOGRAM && chartType !== chartTypesEnums.COMPOSED) return false;
  return relevantConfigOptions[chartType]?.includes(option);
};

export const PORTFOLIO_NAME_IN_API_BODY = "portfolio";
