import { Stack } from "@mui/material";
import React from "react";

import LegendBullet from "./LegendBullet";
const LegendItem = ({ bulletHeight, bulletType, bulletWidth, className, color, label }) => {
  return (
    <Stack className={className}>
      <LegendBullet color={color} height={bulletHeight} type={bulletType} width={bulletWidth} />
      <p className="text t-body-n ml-2">{label}</p>
    </Stack>
  );
};

export default LegendItem;
