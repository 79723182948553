export default {
  // general terms:
  All: "Alle",
  all: "alle",
  Brickwork: "Mauerwerk",
  "Cast concrete": "Beton",
  "Clay tiles": "Tonfliesen",
  Commercial: "Gewerbeimmobilie",

  commercial: "gewerbeimmobilie",
  Electricity: "Strom",

  "External wall": "Außenwand",
  "Flat Roof": "Flachdach",

  // Building Profile:
  floor: "Etage",
  "Ground floor": "Erdgeschoss",
  "Gypsium plaster board": "Gipskartonplatten",
  // Modernization goals:
  "improve energy efficiency": "energieeffizienz verbessern",
  " improve indoor comfort": "wohnkomfort erhöhen", //TODO: remove space at the beginning
  "Internal Wall": "Innenwand",
  "Internal Wall 1": "Innenwand 1",
  "Internal Wall 2": "Innenwand 2",
  "Internal Wall 3": "Innenwand 3",
  "Internal Wall 4": "Innenwand 4",
  Office: "Bürogebäude",
  office: "bürogebäude",
  "Plaster ceiling tiles": "Gips Deckenplatten",
  Public: "Öffentliches Gebäude",
  public: "Öffentliches Gebäude",
  // Technical Data:
  " PV System": "PV Anlage",
  "PV System": "PV Anlage",
  " reduce carbon footprint": "CO₂-Fußabdruck reduzieren", //TODO: remove space at the beginning
  "Roofing felt": "Teerpappe",

  "Roof screed": "Dachestrich",
  "Single pane glass": "Einscheibenglas",
  Vattenfall: "Vattenfall",
};
