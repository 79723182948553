const loginTexts = {
  form: {
    consent: "Ich stimme den ",
    fieldsMustBeFilled: "Alle Felder müssen ausgefüllt sein",
    forgotText: "Passwort vergessen?",
    help: "Kontaktieren Sie uns hier.",
    noAccount: "Sie haben noch kein Konto?",
    password: {
      placeholder: "Passwort",
    },
    signInText: "Anmelden",
    toTermsAndConditions: "allgemeinen Geschäftsbedingungen zu",
    username: {
      placeholder: "Nutzername",
    },
  },
  login: "login",
  logoText: "powered by",
  resetPassword: {
    cancel: "back to login",
    errorButtonText: "okay",
    errorText: "We could not reset your password, please write us an email.",
    errorTitle: "sorry!",
    label: "email",
    placeholder: "insert your email",
    submit: "reset password",
    successfulButtonText: "okay",
    successText: "check your email to reset your password",
    successTitle: "we've sent you an email",
    text: "don't worry, we'll email you the instructions to reset your password shortly.",
    title: "reset your password",
  },
  subtitle: "Für die Dekarbonisierung Ihres Gebäudebestands.",
  title: "Digital Twin-Plattform",
  welcome: "willkommen",
};
export default loginTexts;
