import { sum } from "lodash";

import { distinctFilter, getSegmentsCrossPointCoords } from "../utils/dataManipulation";

export const getCrossYear = (data, dataKey) => {
  if (!data?.length > 0) return [];
  const allYears = data?.map((e) => e?.year)?.filter(distinctFilter);
  allYears.sort((a, b) => a - b);
  let crossYears = [];
  for (let index in allYears) {
    index = Number(index);
    const year = allYears[index];
    const nextYear = allYears[index + 1];
    const yearDataItems = data?.filter((e) => e?.year === year && e?.crrem !== undefined && e[dataKey] !== undefined);
    const nextYearDataItems = data?.filter(
      (e) => e?.year === nextYear && e?.crrem !== undefined && e[dataKey] !== undefined
    );

    if (!nextYearDataItems.length > 0 || !yearDataItems.length > 0) continue;
    const yearData = yearDataItems[yearDataItems.length - 1];
    const nextYearData = nextYearDataItems[0];
    const diff = yearData.crrem - yearData[dataKey];
    const nextDiff = nextYearData.crrem - nextYearData[dataKey];
    if (diff >= 0 && nextDiff < 0) {
      crossYears.push([year, nextYear]);
    }
  }

  return crossYears;
};

export const attachValuesToRetrofits = ({ keys, measures, selectedRetrofits }) => {
  return selectedRetrofits?.map((retrofit) => {
    const correspondingRetrofit = measures?.find((measure) => measure.name === retrofit.name);
    if (correspondingRetrofit) {
      const values = {};
      keys?.forEach((key) => {
        values[key] = correspondingRetrofit[key];
      });
      return { ...retrofit, ...values };
    }
    return retrofit;
  });
};

export const calculateStrandingPoint = (data, dataKey) => {
  const strandingPoints = [];
  if (!data?.length > 0) return [];
  const crossYears = getCrossYear(data, dataKey);
  for (const crossYear of crossYears) {
    const [year, nextYear] = crossYear;
    if (!year || !nextYear) continue;
    const yearDataItems = data.filter((e) => e.year === year && e.crrem !== undefined && e[dataKey] !== undefined);
    const nextYearDataItems = data.filter(
      (e) => e.year === nextYear && e.crrem !== undefined && e[dataKey] !== undefined
    );

    if (!nextYearDataItems.length > 0 || !yearDataItems.length > 0) continue;
    const yearData = yearDataItems[yearDataItems.length - 1];
    const nextYearData = nextYearDataItems[0];
    const lineA = [
      [year, yearData[dataKey]],
      [nextYear, nextYearData[dataKey]],
    ];
    const lineB = [
      [year, yearData?.crrem],
      [nextYear, nextYearData?.crrem],
    ];
    strandingPoints.push(getSegmentsCrossPointCoords(lineA, lineB));
  }
  return strandingPoints;
};

export const getRetrofitYCoords = (retrofits, data, dataKey = "amount") => {
  let retrofitYCoords = [];
  retrofits.forEach((retrofit, index) => {
    const sumOfPreviousRetros = sum(retrofits.slice(0, index).map((e) => e[dataKey]));
    const retrofitYearData = data?.find((e) => e.year === retrofit.year);
    if (!retrofitYearData) return;
    retrofitYCoords.push([
      retrofitYearData?.current - (sumOfPreviousRetros || 0),
      retrofitYearData?.current - (sumOfPreviousRetros || 0) - retrofit[dataKey],
    ]);
  });
  return retrofitYCoords;
};

export const addRetrofitsToData = (retrofits, data) => {
  if (!retrofits?.length > 0) return data;
  retrofits.sort((a, b) => a.year - b.year);
  const retrofitYCoords = getRetrofitYCoords(retrofits, data);
  const primaryRetrofitYCoords = getRetrofitYCoords(retrofits, data, "primaryModernized");
  if (!retrofitYCoords?.length > 0) return data;
  const newData = data.map((e) => {
    const modernized = e.current - sum(retrofits?.filter((retro) => e.year > retro.year)?.map((retro) => retro.amount));
    const primaryModernized =
      e.current - sum(retrofits?.filter((retro) => e.year > retro.year)?.map((retro) => retro.primaryModernized));
    return { ...e, modernized: Math.max(modernized || e.current, 0), primaryModernized };
  });
  retrofitYCoords.forEach((_, index) => {
    newData.push({
      primaryRetrofit: Math.max(primaryRetrofitYCoords[index][0], 0),
      retrofit: Math.max(retrofitYCoords[index][0], 0),
      year: retrofits[index].year,
    });
    newData.push({
      modernized: Math.max(retrofitYCoords[index][1], 0),
      primaryModernized: Math.max(primaryRetrofitYCoords[index][1], 0),
      primaryRetrofit: Math.max(primaryRetrofitYCoords[index][1] + 0.5, 0),
      retrofit: Math.max(retrofitYCoords[index][1] + 0.5, 0),
      year: retrofits[index].year,
    });
  });

  newData.sort((a, b) => a.year - b.year);
  return newData;
};

export const getNGF = (consumption, grossArea, netArea) => {
  return (consumption * grossArea) / netArea;
};

const getIthInflatedValue = (current, iteration, inflationRatePercentage) => {
  return Math.pow(1 + inflationRatePercentage / 100, iteration) * current;
};

const getIthLoanRepaymentValue = (loan, repaymentTime, iteration) => {
  if (iteration < 0 || iteration > repaymentTime - 1) return 0;
  return loan / repaymentTime;
};

const getIthInterestedValue = (current, totalIterations, iteration, interestRatePercentage) => {
  if (iteration < 0 || iteration > totalIterations - 1) return 0;
  return current * (interestRatePercentage / 100);
};

export const getSingleMeasureFinancialValuesTimeSeries = ({ endYear, initialValues, startYear }) => {
  const {
    foreignCapital,
    inflationRatePercentage,
    initialCostsSavings,
    initialEnergySaving,
    initialMaintenanceCosts,
    initialOtherSavings,
    initialServicesCosts,
    interestRatePercentage,
    loanRepaymentTime,
    ownCapital,
    subsidies,
  } = initialValues;

  const output = [];
  const startYearInputsValues = { foreignCapital, ownCapital, subsidies };
  const OtherYearsInputValues = { foreignCapital: 0, ownCapital: 0, subsidies: 0 };

  for (let year = startYear; year <= endYear; year++) {
    let timeSeriesItem;
    const iteration = year - startYear;

    timeSeriesItem = {
      costSavings: getIthInflatedValue(initialCostsSavings, iteration, inflationRatePercentage),
      energySavings: getIthInflatedValue(initialEnergySaving, iteration, inflationRatePercentage),
      foreignCapitalCost: -getIthInterestedValue(foreignCapital, loanRepaymentTime, iteration, interestRatePercentage),
      loanRepayment: -getIthLoanRepaymentValue(foreignCapital, loanRepaymentTime, iteration),
      maintenanceCosts: -getIthInflatedValue(initialMaintenanceCosts, iteration, inflationRatePercentage),
      otherSavings: getIthInflatedValue(initialOtherSavings, iteration, inflationRatePercentage),
      servicesCosts: -getIthInflatedValue(initialServicesCosts, iteration, inflationRatePercentage),
      year,
      ...(iteration === 0 ? startYearInputsValues : OtherYearsInputValues),
    };

    output.push(timeSeriesItem);
  }
  return output;
};
