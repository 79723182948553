export const buildingUseTypesConfig = {
  Healthcare: {
    color: "#94DCF8",
    label: "Healthcare",
  },
  Hotel: {
    color: "#0070C0",
    label: "Hotel",
  },
  "Industrial, Distribution Warehouse": {
    color: "#7030A0",
    label: "Industrial, Distribution Warehouse",
  },
  "Industrial, Distribution Warehouse Cooled": {
    color: "#E49EDD",
    label: "Industrial, Distribution Warehouse Cooled",
  },
  "Lodging, Leisure & Recreation": {
    color: "#00FFFF",
    label: "Lodging, Leisure & Recreation",
  },
  Office: {
    color: "#4EA72E",
    label: "Office",
  },
  Residential: {
    color: "#104861",
    label: "Residential",
  },
  "Retail, High Street": {
    color: "#FFC000",
    label: "Retail, High Street",
  },
  "Retail, Shopping Center": {
    color: "#eeee00",
    label: "Retail, Shopping Center",
  },
  "Retail, Warehouse": {
    color: "#FF6600",
    label: "Retail, Warehouse",
  },
};

export const buildingUsageConfig = {
  Rented: {
    // color: '#00B050',
    color: "var(--clr-plunge-500)",
    label: "Average annual used area [m²]",
  },
  Vacant: {
    color: "var(--clr-mystic-red-500)",
    label: "Average annual vacant area [m²]",
    // color: '#00B050',
    textColor: "#000",
  },
};
