import { Stack } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

import { useGetTechData } from "../api/hooks/allHooks";
import { NavMenu } from "../components/TechnicalData/NavMenu";
import { TechTable } from "../components/TechnicalData/TechTable";
import { tabNames } from "../configs/TechnicalData";
import { eventNames, trackGAEvent, withGAEventTracking } from "../functions/googleAnalytics";
import { extractTabData, getDefaultActiveTab, getEnabledTabs } from "../functions/TechnicalData";
import useQueryParamsState from "../hooks/states";

export const TechnicalData = () => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const paramKey = "tab";
  const paramValue = searchParams.get(paramKey);

  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useQueryParamsState(paramKey, "windows");

  const { data, isError, isLoading } = useGetTechData({ projectId, tabNames });
  const enabledTabs = getEnabledTabs(data);
  const tabData = extractTabData({ activeTab, data });

  useEffect(() => {
    if (!isError) setActiveTab(paramValue || getDefaultActiveTab(enabledTabs));
  }, [isLoading]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["techDataAllParents", activeTab, projectId],
    });
  }, [activeTab]);

  useEffect(() => {
    trackGAEvent(eventNames.TD_VIEW);
  }, []);

  return (
    <>
      <Helmet>
        <title>Technical data | Leaftech</title>
      </Helmet>
      <Stack className="mt-4 items-start" spacing={4}>
        <NavMenu
          activeTab={activeTab}
          className="w-60 p-0 pt-2"
          enabledTabs={enabledTabs}
          setActiveTab={(tabName) =>
            withGAEventTracking(eventNames.TD_TAB_VIEW, { tabName }, () => setActiveTab(tabName))
          }
        />
        <TechTable
          activeTab={activeTab || "windows"}
          data={tabData}
          enabledTabs={enabledTabs}
          parentsAreLoading={isLoading}
          projectId={projectId}
        />
      </Stack>
    </>
  );
};
