export default {
  bulletChart: {
    carbonTitle: "Carbon performance indicator",
    energyTitle: "Energy performance indicator",
    "High tax zone": "High tax zone",
    "Low tax zone": "Low tax zone",
    "Net-Zero emission": "Net-Zero emission",
    "net zero": "net zero",
    "Status quo": "Status quo",
    "Target value": "Target value",
  },
  dashboard: {
    addNewChart: "Add new chart",
    areaIsMissing: "Building area is missing so the numbers are not valid.",
    cancel: "Cancel",
    "CO₂ emission": "CO₂ emission",
    confirmSelection: "Confirm selection",
    Consumption: "Consumption",
    "Cooling system": "Cooling system",
    "Cooling system-Measured": "Cooling system-Measured",
    "Cooling system-Simulated": "Cooling system-Simulated",
    dataDropDownLabel: "select data points",
    datesMustBeSelected: "Start date and end date must be selected.",
    deleteChart: "Delete chart",
    downloadText: "Download information",
    Electricity: "Electricity",
    "Electricity-Measured": "Electricity-Measured",
    "Electricity-Simulated": "Electricity-Simulated",
    getDataProblem: "There was a problem getting data!",
    "Heating system": "Heating system",
    "Heating system-Measured": "Heating system-Measured",
    "Heating system-Simulated": "Heating system-Simulated",
    "Hot water system": "Hot water system",
    "Hot water system-Measured": "Hot water system-Measured",
    "Hot water system-Simulated": "Hot water system-Simulated",
    liveCalculationMode: "Live Calculation Mode",
    measured: "measured",
    noData: "There is nothing to show!",
    pauseInstantCalculation: "pause instant calculation",
    Price: "Price",
    simulated: "simulated",
    title: "Performance dashboard",
    total: "total",
    Total: "Total",
    totalConsumption: "total cosumption",

    totalEmission: "total emission",
    totalPrice: "total price",
  },
  table: {
    columns: {
      co2Emissions: "CO₂ emissions kgCO₂/kWh",
      costPerkWh: "Cost €/kWh",
      costPerYear: "Cost increase per year in %",
      "Utility mix": "Utility mix",
    },
    downloadText: "Download information",
    title: "Energy mix of building",
  },
};
