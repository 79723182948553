import { Grid, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { IsAllowed } from "../acl";
import { downloadCertificateFiles } from "../api/functions/all";
import {
  useCheckCertificatesDownloadFilesStatus,
  useGetBuilding,
  useGetBuildingEPCInfo,
  useGetBuildingFiles,
  useGetBuildingKpis,
  useGetKPIs,
  useGetProjectInfo,
} from "../api/hooks/allHooks";
import { DataCard } from "../components";
import { AssetDetailedCard } from "../components/BuildingProfile/AssetDetailedCard";
import { BuildingProfileTable } from "../components/BuildingProfile/BuildingProfileTable";
import { ESGIndex } from "../components/BuildingProfile/ESGIndex";
import { KPIs } from "../components/BuildingProfile/KPIs";
import { UseTypes } from "../components/BuildingProfile/UseTypes";
import { CaptureComponent } from "../components/CaptureComponent";
import { BulletChartDoubleWithIntervals } from "../components/chart/BulletChart";
import { ComponentsCommonHeader } from "../components/ComponentsCommonHeader";
import { EnergyEfficiencyClass } from "../components/EnergyEfficiencyClass";
import { TextErrorBoundary } from "../components/ErrorBoundary";
import { LabelValueInline } from "../components/LabelValueInline";
import { StrandingChart } from "../components/Modernization/StrandingChart";
import ScrollToTop from "../components/ScrollToTop";
import { WizardContextProvider } from "../context/Wizard";
import { eventNames, trackGAEvent, withGAEventTracking } from "../functions/googleAnalytics";
import { theme } from "../styles/theme";
import { isNumeric } from "../utils/dataManipulation";
import { dateDifference, dateDifferenceString } from "../utils/date";
import { generateAxisValues } from "../utils/dynamicDashboard";
import { valueOrAlternative } from "../utils/namesManipulation";

export const BuildingProfile = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};

  const { data: projectInfo } = useGetProjectInfo(projectId, {});

  let crremScenario;
  if (projectInfo?.[0]?.crrem_scenario_list) {
    crremScenario = JSON.parse(projectInfo?.[0]?.crrem_scenario_list)?.[0];
  }

  const crremKPIs = useGetKPIs(projectId, crremScenario);
  const { data: oldKpis, isLoading: kpiIsLoading } = useGetBuildingKpis(projectId);

  const kpis =
    crremKPIs.total_carbon_emission !== undefined && crremKPIs.total_energy_consumption !== undefined
      ? [crremKPIs]
      : oldKpis;

  const kpisLabelsAndKeysMapping = [
    {
      dataKey: "total_energy_consumption",
      label: t("BuildingProfile.kpis.totalEnergyConsumption"),
      round: true,
      unit: "kWh/m²/a",
    },
    {
      dataKey: "total_carbon_emission",
      label: t("BuildingProfile.kpis.totalCarbonEmission"),
      round: true,
      unit: "kg/m²/a",
    },
    {
      dataKey: "stranding_year",
      label: t("BuildingProfile.kpis.anticipatedStrandingYear"),
      round: false,
      unit: "",
    },
  ];

  const { data: buildingFiles, isLoading: bFIsLoading } = useGetBuildingFiles(projectId);

  useEffect(() => {
    trackGAEvent(eventNames.BP_VIEW);
  }, []);

  const assetCardConfig = [
    [
      { dataKey: "descriptive_name", label: "ASSET NAME" },
      { dataKey: "type", label: "TYPE" },
      { dataKey: "asset_id", label: "ASSET ID" },
      { dataKey: "address", label: "ADDRESS" },
    ],
    [
      { dataKey: "construction_year", label: "YEAR OF CONSTRUCTION" },
      { dataKey: "last_modernized", label: "MODERNIZATION YEAR" },
      {
        dataKey: "gross_asset_basement_included",
        isNumeric: true,
        label: "GROSS AREA (INCL. BASEMENT)",
        renderUnit: (value) => value && " m²",
      },
      {
        dataKey: "net_lettable_area_without_basement",
        isNumeric: true,
        label: "Net LETTABLE AREA (WITHOUT BASEMENT)",
        renderUnit: (value) => value && " m²",
      },
    ],
  ];
  const containerRef = useRef();

  const [epcCrremSelectedSection, setEpcCrremSelectedSection] = useState("epc");

  let { data: epcInfoAll } = useGetBuildingEPCInfo(projectId);
  epcInfoAll = epcInfoAll?.length ? epcInfoAll[0] : {};
  if (Object.keys(epcInfoAll)?.length) {
    try {
      epcInfoAll.epc_valid_days = dateDifferenceString(dateDifference(epcInfoAll?.valid_until, epcInfoAll?.issued_on));
    } catch {
      // Do Nothing
    }
  }
  const bullets = [
    {
      color: "var(--clr-gray-800)",
      handleHeight: 0,
      label: `${t("BuildingProfile.kpis.primaryEnergyDemand")}: ${epcInfoAll?.primary_energy_kwh_per_m2}`,
      reverse: true,
      value: epcInfoAll?.primary_energy_kwh_per_m2,
    },
    {
      color: "var(--clr-gray-400)",
      handleHeight: 30,
      label: `${t("BuildingProfile.kpis.primaryEnergyDemandComparableBuilding")}: ${
        epcInfoAll?.primary_energy_reference_kwh_per_m2
      }`,
      reverse: true,
      value: epcInfoAll?.primary_energy_reference_kwh_per_m2,
    },
    {
      color: "var(--clr-gray-800)",
      handleHeight: 0,
      label: `${t("BuildingProfile.kpis.endEnergyDemand")}: ${epcInfoAll?.end_energy_kwh_per_m2}`,
      reverse: false,
      value: epcInfoAll?.end_energy_kwh_per_m2,
    },
    {
      color: "var(--clr-gray-400)",
      handleHeight: 30,
      label: `${t("BuildingProfile.kpis.endEnergyDemandComparableBuilding")}: ${
        epcInfoAll?.end_energy_reference_kwh_per_m2
      }`,
      reverse: false,
      value: epcInfoAll?.end_energy_reference_kwh_per_m2,
    },
  ];

  let bulletsMaxValue =
    Math.max(
      epcInfoAll?.primary_energy_kwh_per_m2,
      epcInfoAll?.primary_energy_reference_kwh_per_m2,
      epcInfoAll?.end_energy_kwh_per_m2,
      epcInfoAll?.end_energy_reference_kwh_per_m2
    ) ?? 0;

  if (!isNumeric(bulletsMaxValue)) bulletsMaxValue = 0;

  const defaultEnergyAxis = [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550];

  const classification = epcInfoAll?.classification;
  let energyAxis = defaultEnergyAxis;
  let maxEnergyValue = 600;

  let thresholds = [];
  let labels = [];
  let minEnergyValue;
  let fMax;
  const labelsMapping = {
    a: "A",
    a_plus: "A+",
    b: "B",
    c: "C",
    d: "D",
    e: "E",
    f: "F",
    g: "G",
    h: "H",
  };

  if (classification) {
    const activeClassesNonZero = Object.keys(labelsMapping).filter(
      (className) => classification[`class_${className}_min`]
    );
    const lastActiveClass = activeClassesNonZero[activeClassesNonZero?.length - 1];
    maxEnergyValue = classification[`class_${lastActiveClass}_min`] * 1.2;
    const maxAxisValue = Math.max(maxEnergyValue, bulletsMaxValue);
    energyAxis = !isNumeric(maxAxisValue) ? defaultEnergyAxis : generateAxisValues(0, maxAxisValue, 50);
    for (const className in labelsMapping) {
      const isLastActiveClass = className === lastActiveClass;
      const classMin = classification[`class_${className}_min`];
      const classMax = classification[`class_${className}_max`];

      if (!classMin && !classMax) continue;

      if (className === "f") {
        fMax = classMax;
      }
      if (minEnergyValue === undefined) {
        minEnergyValue = classMin;
        thresholds.push(classMin);
      }
      if (classMax && !isLastActiveClass) {
        labels.push(labelsMapping[className]);
        thresholds.push(classMax);
      }
      if (classMax && isLastActiveClass) {
        labels.push(labelsMapping[className]);
      }
      if (classMin && !classMax) {
        labels.push(labelsMapping[className]);
      }
      if (isLastActiveClass) break;
    }
    thresholds.push(maxEnergyValue);
  }

  const epcInfoTextConfig = [
    { dataKey: "epc_number", label: "EPC Number" },
    { dataKey: "issued_on", label: "EPC Issued On" },
    { dataKey: "type", label: "EPC Type" },
    { dataKey: "valid_until", label: "EPC Valid Until" },
    { dataKey: "according_to", label: "EPC According To" },
    { dataKey: "epc_valid_days", label: "EPC Valid Days" },
    { dataKey: "class_defined_via", label: "Class Defined With" },
    { dataKey: "issued_by", label: "EPC Issued By" },
  ];
  const epcInfoTextAndValues = epcInfoTextConfig.map((item) => ({ ...item, value: epcInfoAll?.[item.dataKey] }));

  const containerRef2 = useRef();
  let usages = {};
  if (building?.building_used_type) {
    try {
      usages = JSON.parse(building?.building_used_type);
    } catch {
      // Do Nothing
    }
  }
  const { data: downloadFileExistenceStatus } = useCheckCertificatesDownloadFilesStatus(projectId, "epc");

  const maxAxisValue = Math.max(...(energyAxis ?? []));
  let fMaxToMax = 1;
  try {
    fMaxToMax = fMax / maxAxisValue;
  } catch {
    // Do Nothing
  }

  const colorbarScaleColors = [
    { color: "#1E955B", threshold: 0 },
    { color: "#51AB4A", threshold: 14 },
    { color: "#CADC09", threshold: 28 },
    { color: "#FFFD0A", threshold: 42 },
    { color: "#FDF400", threshold: 56 },
    { color: "#FAA112", threshold: 70 },
    { color: "#FE553D", threshold: 90 },
    // { color: '#F94D48', threshold: 90 },
    { color: "#F00", threshold: 100 },
  ];

  let colorbarScaleRelativeColors = colorbarScaleColors.map((item) => ({
    ...item,
    threshold: item.threshold * fMaxToMax,
  }));
  if (labels?.includes(labelsMapping["g"])) {
    try {
      const gTreshold = classification[`class_g_max`] / maxAxisValue;
      colorbarScaleRelativeColors.push({ color: "#F00", threshold: Math.min(gTreshold, 1) * 100 });
    } catch {
      // Do Nothing
    }
  }

  if (labels?.includes(labelsMapping["h"])) {
    try {
      const hMinTreshold = classification[`class_h_min`] / maxAxisValue;
      const hMaxTreshold = classification[`class_h_max`] / maxAxisValue;
      colorbarScaleRelativeColors.push({ color: "#e30202", threshold: hMinTreshold * 100 });
      colorbarScaleRelativeColors.push({ color: "#e30202", threshold: Math.min(hMaxTreshold, 1) * 100 });
    } catch {
      // Do Nothing
    }
  }

  const colorbarScale =
    "linear-gradient(to right " +
    colorbarScaleRelativeColors.reduce((total, curr) => total + `, ${curr.color} ${curr.threshold}% `, " ") +
    ")";
  return (
    <>
      <Helmet>
        <title>Building profile | Leaftech</title>
      </Helmet>
      <ScrollToTop />
      <Stack className="items-start justify-between" ref={containerRef2} spacing={6}>
        {/* Left column */}
        <section className="min-w-[47%] max-w-[50%] overflow-hidden">
          <AssetDetailedCard
            building={building}
            buildingType={building?.type}
            config={assetCardConfig}
            imgPath={building?.image_url_value}
            isLoading={isLoading}
            name={t("BuildingProfile.card.title")}
          />
          <UseTypes className="mt-8" data={usages} />
        </section>
        {/* Right column */}
        <section className="w-[49%]">
          <div ref={containerRef}>
            <Paper className="relative">
              <CaptureComponent className="absolute right-6 top-7" targetElement={() => containerRef.current} />
              <Stack
                className={`t-subheading-m ${""} mb-2 border-0 border-b-2 border-solid  border-gray-100`}
                spacing={theme.spacing(8)}
              >
                {["epc", "crrem", "esg"].map((navLink, index) => {
                  const isActive = navLink === epcCrremSelectedSection;
                  return (
                    <span
                      className={`mb-[-2px] cursor-pointer border-0 border-b-2 border-solid  px-4 py-2 uppercase   
                    ${
                      isActive ? "border-blue-500 text-blue-500" : "border-gray-100 text-gray-900 hover:border-blue-500"
                    }`}
                      key={index}
                      onClick={() => setEpcCrremSelectedSection(navLink)}
                    >
                      {navLink}
                    </span>
                  );
                })}
              </Stack>
              {epcCrremSelectedSection === "crrem" && (
                <>
                  <TextErrorBoundary>
                    <WizardContextProvider hasModernization={false} projectId={projectId}>
                      <StrandingChart
                        chartHeight={280}
                        className="h-[45.5rem] w-full border-none bg-transparent"
                        hasModernization={false}
                        legendsWidth="450px"
                        projectId={projectId}
                        title={t("BuildingProfile.kpis.crremM2Gross")}
                      />
                    </WizardContextProvider>
                    <KPIs
                      className="-mx-3 mt-4"
                      isLoading={kpiIsLoading}
                      kpis={kpis || []}
                      // title={t("BuildingProfile.kpis.crremM2Gross")}
                      kpisLabelsAndKeysMapping={kpisLabelsAndKeysMapping}
                      paperClassName="!border-0 -p-6"
                      xl2={4}
                      xs={4}
                    />
                  </TextErrorBoundary>
                </>
              )}
              {epcCrremSelectedSection === "epc" && (
                <TextErrorBoundary>
                  <div>
                    <ComponentsCommonHeader
                      action={() =>
                        withGAEventTracking(eventNames.BP_DOWNLOAD_CERTIFICATE, { certificateType: "epc" }, () =>
                          downloadCertificateFiles({ certificateType: "epc", projectId })
                        )
                      }
                      actionIsDisabled={downloadFileExistenceStatus?.status !== 200}
                      actionText={t("Certificates.cards.buttonShort")}
                      className="!pb-4 !pt-0"
                      hasActionAccess={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
                      title={t("BuildingProfile.certificateCard.epcTitle")}
                    />
                    <Stack className="flex-col items-start pb-15">
                      <Grid className="items-start justify-between" columnSpacing={4} container rowSpacing={6}>
                        {epcInfoTextAndValues?.map((item, i) => {
                          return (
                            <Grid item key={i} xs={6}>
                              <LabelValueInline
                                label={item.label}
                                labelProps={{ className: "t-heading-s text-gray-400" }}
                                value={valueOrAlternative(item.value, t("general.noData"))}
                                valueProps={{ className: "t-body-l" }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid className="mt-8 w-full" columnSpacing={4} container rowSpacing={6}>
                        {isNumeric(epcInfoAll?.epc_carbon_dioxide_emission) && (
                          <Grid item xs={4}>
                            <DataCard
                              kpi={{
                                name: t("BuildingProfile.card.CO2 EMISSIONS"),
                                unit: "KgCO₂",
                                value: epcInfoAll?.epc_carbon_dioxide_emission,
                              }}
                            />
                          </Grid>
                        )}
                        {isNumeric(epcInfoAll?.primary_energy_kwh_per_m2) && (
                          <Grid item xs={4}>
                            <DataCard
                              kpi={{
                                name: t("BuildingProfile.kpis.primaryEnergyConsumption"),
                                unit: "kWh",
                                value: epcInfoAll?.primary_energy_kwh_per_m2,
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={4}>
                          <EnergyEfficiencyClass
                            className="h-full min-h-[10rem] w-50"
                            energyClass={epcInfoAll?.class}
                            label={t("BuildingProfile.kpis.energyEfficiencyClass")}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <BulletChartDoubleWithIntervals
                      axis={energyAxis}
                      bulletColorBarClassName="barchart-new-bg-gradient-energy-performance"
                      bulletColorBarStyle={{ background: colorbarScale }}
                      // header={'Title'}
                      // pill="kWh/m²/a"
                      bullets={bullets}
                      className="mx-8 my-16"
                      isLoading={false}
                      labels={labels}
                      thresholds={thresholds}
                    />
                  </div>
                </TextErrorBoundary>
              )}
              {epcCrremSelectedSection === "esg" && (
                <TextErrorBoundary>
                  <ESGIndex projectId={projectId} />
                </TextErrorBoundary>
              )}
            </Paper>
          </div>
          <BuildingProfileTable
            buildingFiles={buildingFiles || []}
            className="mt-8 p-6"
            isLoading={bFIsLoading}
            projectId={projectId}
            tableClassName="max-h-[30rem]"
          />
          {/* <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={nonEnergyCertificates}
            projectId={projectId}
            isLoading={ceIsLoading}
            title={t("BuildingProfile.certificateCard.certificateTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.carbonButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="certificate"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          />
          <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={energyCertificates}
            isLoading={ceIsLoading}
            projectId={projectId}
            title={t("BuildingProfile.certificateCard.energyRatingTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.energyButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="epc"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          /> */}
        </section>
      </Stack>
      {/* <DynamicDashboardHourlyDataGetter projectId={projectId} enabled={true} /> */}
    </>
  );
};
