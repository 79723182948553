import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useAddMeasure } from "../../api/hooks/allHooks";
import { FilterTypes } from "../../enums/components";
import { CustomModal } from "../CustomModal";
import DynamicForm, { FormField } from "../DynamicForm";

type CreatRetrofitMeasureFormProps = {
  formItems: FormItem[];
  isOpen: boolean;
  projectId: number | string;
  requiredFields?: string[];
  setIsOpen: (isOpen: boolean) => void;
};

type FormItem = FormField & { optionsFn?: (formData: Record<string, unknown>) => string[] };

const CreatRetrofitMeasureForm: React.FC<CreatRetrofitMeasureFormProps> = ({
  formItems,
  isOpen,
  projectId,
  requiredFields,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store.user);
  const [formData, setFormData] = useState<Record<string, unknown>>({});
  const fieldsWithActions: FormItem[] = formItems.map((field) => ({
    config: formData,
    filterLabel: field?.props?.label,
    filterLabelClassName: "text-gray-500 t-heading-s w-2/3",
    filterName: field.filterName,
    filterType: field.filterType,
    props: {
      ...field.props,
      height: "4rem",
      options: field.filterType === FilterTypes.DROPDOWN_SINGLE ? field.optionsFn?.(formData) : [],
      width: "50%",
    },
    required: requiredFields?.includes(field.filterName),
    setConfig: (filterName, newValue) => {
      setFormData({ ...formData, [filterName]: newValue });
    },
    visible: () => true,
  }));

  const { isLoading, mutate: addMeasure } = useAddMeasure();

  const handleSubmit = () => {
    const emptyRequiredFields = requiredFields?.filter(
      (f) => formData[f] === undefined || (typeof formData[f] === "string" && formData[f].trim() === "")
    );
    if (emptyRequiredFields && emptyRequiredFields.length > 0) {
      const emptyRequiredFieldsLabel = fieldsWithActions
        .filter((f) => emptyRequiredFields?.includes(f.filterName))
        .map((f) => f.filterLabel);
      toast.error(t("general.form.filledFieldsConstraint", { requiredFields: emptyRequiredFieldsLabel?.join(", ") }));
      return;
    }
    addMeasure({
      data: { ...formData, project: projectId, user: user?.user_id },
      onSettled: () => {
        setIsOpen(false);
        setFormData({});
      },
      onSuccess: () => {
        toast.success("Added successfully!");
      },
      projectId,
    });
  };

  return (
    <CustomModal
      className="max-h-[90rem] !pb-4"
      height="95vh"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      width="70rem"
    >
      <div className="h-full w-full ">
        <DynamicForm
          fields={fieldsWithActions}
          isSubmitting={isLoading}
          onSubmit={handleSubmit}
          title={t("Modernization.wizard.newMeasureForm.title") as string}
        />
      </div>
    </CustomModal>
  );
};

export default CreatRetrofitMeasureForm;
