export default {
  card: {
    ADDRESS: "Adresse",
    "ASSET ID": "Gebäude ID",
    "ASSET NAME": "Name des Gebäudes",
    "GROSS ASSET SIZE": "Bruttogrundfläche",
    "GROSS ASSET VALUE": "Geschätzter Immobilienwert",
    "MODERNIZATION YEAR": "Modernisierung",
    title: "Gebäude Informationen",
    TYPE: "Kategorie",
    "YEAR OF CONSTRUCTION": "Baujahr",
  },
  certificateCard: {
    "Allotment date": "Zuteilungsdatum:",
    Area: "Fläche:",
    certificateTitle: "Zertifikate",
    energyRatingTitle: "EPC Bewertungszertifikate",
    "Valid until": "Gültig bis",
  },
  kpis: {
    kpisNotListed: "KPIs sind nicht aufgeführt.",
    title: "KPIs",
    totalCarbonEmission: "Gesamter CO₂-Ausstoß",
    totalEnergyConsumption: "Energieverbrauch",
  },
  table: {
    columns: {
      "date modified": "Datum geändert",
      files: "Datei",
      name: "Name",
      type: "Typ",
    },
    downloadAllFiles: "Alle Dateien herunterladen",
    title: "Gebäudedokumente",
  },
};
