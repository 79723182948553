import { useMemo } from "react";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { PORTFOLIO_NAME_IN_API_BODY } from "../../enums/AssetOverview/portfolio";
import { ENERGY_DASHBOARD_NAME_API_BODY } from "../../enums/energyDashboard";
import { getCrossYear } from "../../functions/Modernization";
import { customApiCall } from "../../utils/axios";
import { distinctFilter } from "../../utils/dataManipulation";
import { getMonthString, getWeekdayString } from "../../utils/date";
import {
  deleteChildFilesRow,
  deleteParentFilesRow,
  getChildFiles,
  getDashboardNew,
  getParentFiles,
  postChildFiles,
  postParentFiles,
  postTranslation,
} from "../functions/all";
import apiUrls from "../urls";

// ==========================
// Hooks Skleton
// ==========================
const useGeneralGetHook = (cacheName, url, rest) => {
  return useQuery(cacheName, () => customApiCall.get({ url }), {
    enabled: true,
    ...rest,
  });
};

const useGeneralHeadHook = (cacheName, url) => {
  return useQuery(cacheName, () => customApiCall.head({ url }));
};

// ==========================
// Common Hooks
// ==========================
export const useGetBuilding = (projectId) => {
  const url = apiUrls.assets.get(projectId);
  return useGeneralGetHook(["building2", projectId], url);
};

export const useGetBuildingEPCInfo = (projectId) => {
  const url = apiUrls.assets.getEPC(projectId);
  return useGeneralGetHook(["buildingEPCInfo", projectId], url);
};

export const useGetBuildingESGInfo = (projectId) => {
  const url = apiUrls.assets.getESGInfo(projectId);
  return useGeneralGetHook(["buildingESGInfo", projectId], url);
};

export const useGetESGReference = () => {
  const url = apiUrls.assets.getESGReference();
  return useGeneralGetHook(["ESGReference"], url);
};

export const useGetBulidingActiveTabs = (projectId) => {
  const url = apiUrls.assets.getPages(projectId);
  const { data, isError, isLoading } = useGeneralGetHook(["ActiveTabs", projectId], url);
  const filteredData = data?.find((building) => {
    return building?.project_id === Number(projectId);
  });
  return { data: filteredData || {}, isError, isLoading };
};

export const useGetBuildingCertificates = (projectId) => {
  const url = apiUrls.certificates.list(projectId);
  const { data, isLoading } = useGeneralGetHook(["certs2", projectId], url);
  const energyCertificates = (data || []).filter((e) => e.type === "EU EPC");
  const nonEnergyCertificates = (data || []).filter((e) => e.type !== "EU EPC");
  return { data: { energyCertificates, nonEnergyCertificates }, isLoading };
};
export const useCheckAllFilesStatus = (projectId, pageName) => {
  const isTechnical = pageName === "technicalData";
  const url = apiUrls.files.folders.download(projectId, isTechnical);
  return useGeneralHeadHook(["allFilesExistence", projectId, pageName], url);
};
// ==========================
// Asset Overview
// ==========================
export const useGetAllBuildings = (UserId) => {
  const url = apiUrls.assets.list(UserId);
  return useGeneralGetHook(["allBuildings", UserId], url);
};

// ==========================
// Portfolio
// ==========================
export const useGetPortfolioDashboardConfigs = (userId) => {
  const url = apiUrls.dynamicDashboard.configs.get(userId, PORTFOLIO_NAME_IN_API_BODY);
  return useGeneralGetHook(["portfolioDashboardConfigs", userId, PORTFOLIO_NAME_IN_API_BODY], url);
};

// ==========================
// Building Profile
// ==========================
export const useGetBuildingFiles = (projectId) => {
  const url = apiUrls.layerFiles.buildingPassport.list(projectId);
  return useGeneralGetHook(["buildingFiles", projectId], url);
};
export const useGetBuildingKpis = (projectId) => {
  const url = apiUrls.kpis.list(projectId);
  return useGeneralGetHook(["kpis", projectId], url);
};
export const useCheckFloorPlanAllFilesStatus = (projectId) => {
  const url = apiUrls.layerFiles.buildingPassport.download(projectId);
  return useGeneralHeadHook(["floorPlanAllFiles", projectId], url);
};
export const useGetBulidingUsage = (projectId) => {
  const url = apiUrls.buildingUsage.list(projectId);
  return useGeneralGetHook(["bulidingUsage", projectId], url);
};

export const useGetKPIs = (projectId, scenario) => {
  let consumptionKPI, emissionKPI;
  const { data: consumption } = useGetCrrem(projectId, scenario, "consumption");
  const { data: emission } = useGetCrrem(projectId, scenario, "emission");
  if (emission?.length) emissionKPI = emission[0]?.climate;
  if (consumption?.length) consumptionKPI = consumption[0]?.climate;

  const strandingYearInterval = getCrossYear(emission, "climate");
  const strandingYear = strandingYearInterval?.[0]?.length && strandingYearInterval[0][0];

  return {
    stranding_year: strandingYear ?? "Stranded",
    total_carbon_emission: emissionKPI,
    total_energy_consumption: consumptionKPI,
  };
};

// ==========================
// Tech Data
// ==========================
export const useGetTechData = ({ projectId, tabNames }) => {
  const allParentsFiles = useQueries(
    tabNames.map((tab) => {
      return {
        queryFn: () => getParentFiles({ activeTab: tab, projectId }),
        queryKey: ["techDataAllParents", tab, projectId],
        select: (data) => {
          const newData = data?.map((row) => {
            const hasFiles = row?.layers?.some((layer) => layer.file_url);
            return { ...row, hasFiles };
          });
          return { data: newData, tab };
        },
      };
    })
  );
  const isLoading = allParentsFiles?.some((result) => result.isLoading);
  const isError = allParentsFiles?.some((result) => result.isError);
  return { data: allParentsFiles, isError, isLoading };
};

export const useGetChildrenFiles = ({ activeTab, childIds }) => {
  const childrenFiles = useQueries(
    (childIds || [])?.map((id) => {
      return {
        queryFn: () => getChildFiles({ activeTab, layerId: id }),
        queryKey: ["techDataChild", activeTab, id],
        select: (data) => {
          return { ...data, id };
        },
      };
    })
  );
  const isLoading = childrenFiles?.some((result) => result.isLoading);

  return { data: childrenFiles, isLoading };
};

export const useModifyParentTableCell = () => {
  const queryClient = useQueryClient();
  return useMutation(postParentFiles, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["techDataAllParents", context.activeTab, context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { activeTab, data, projectId, rowId } = inputs;
      await queryClient.cancelQueries(["techDataAllParents", activeTab, projectId]);
      const previousData = queryClient.getQueryData(["techDataAllParents", activeTab, projectId]);
      queryClient.setQueryData(["techDataAllParents", activeTab, projectId], (oldData) => {
        const newData = oldData?.payload?.map((row) => {
          if (row.id === rowId) return { ...row, ...data };
          else {
            return row;
          }
        });
        return { payload: newData };
      });
      return { activeTab, previousData, projectId };
    },
    // onSettled: (_data, _error, _variables, context) => {
    //   const { projectId } = context;
    //   queryClient.invalidateQueries(["allMeasures", projectId]);
    //   queryClient.invalidateQueries(["selectedMeasures", projectId]);
    // },
    onSettled: (inputs, _error, _variables, context) => {
      const { activeTab, projectId } = context;
      queryClient.invalidateQueries(["techDataAllParents", activeTab, projectId]);
    },
  });
};

export const useDeleteParentTableRow = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteParentFilesRow, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["techDataAllParents", context.activeTab, context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { activeTab, projectId, rowId } = inputs;
      await queryClient.cancelQueries(["techDataAllParents", activeTab, projectId]);
      const previousData = queryClient.getQueryData(["techDataAllParents", activeTab, projectId]);
      queryClient.setQueryData(["techDataAllParents", activeTab, projectId], (oldData) => {
        const newData = oldData?.payload?.filter((row) => row.id !== rowId);
        return { payload: newData };
      });
      return { activeTab, previousData, projectId };
    },
    onSettled: (inputs) => {
      const { activeTab, projectId } = inputs;
      queryClient.invalidateQueries(["techDataAllParents", activeTab, projectId]);
    },
  });
};

export const useModifyChildTableCell = () => {
  const queryClient = useQueryClient();
  return useMutation(postChildFiles, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["techDataAllParents", context.activeTab, context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { activeTab, data, layerId, projectId, rowId } = inputs;
      await queryClient.cancelQueries(["techDataAllParents", activeTab, projectId]);
      const previousData = queryClient.getQueryData(["techDataAllParents", activeTab, projectId]);
      queryClient.setQueryData(["techDataAllParents", activeTab, projectId], (oldData) => {
        const newData = oldData?.payload?.map((parentRow) => {
          if (parentRow.id === rowId) {
            const newLayers = parentRow?.layers?.map((childRow) => {
              if (childRow.id === layerId) return { ...childRow, ...data };
              return childRow;
            });
            return { ...parentRow, layers: newLayers };
          }
          return parentRow;
        });
        return { payload: newData };
      });
      return { activeTab, previousData, projectId };
    },
    onSettled: (inputs) => {
      const { activeTab, data, projectId } = inputs;

      queryClient.invalidateQueries(["techDataAllParents", activeTab, projectId]);
    },
  });
};

export const useDeleteChildTableRow = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteChildFilesRow, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["techDataAllParents", context.activeTab, context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { activeTab, layerId, projectId, rowId } = inputs;
      await queryClient.cancelQueries(["techDataAllParents", activeTab, projectId]);
      const previousData = queryClient.getQueryData(["techDataAllParents", activeTab, projectId]);
      queryClient.setQueryData(["techDataAllParents", activeTab, projectId], (oldData) => {
        const newData = oldData?.payload?.map((parentRow) => {
          if (parentRow.id === rowId) {
            const newLayers = parentRow?.layers?.filter((childRow) => childRow.id !== layerId);
            return { ...parentRow, layers: newLayers };
          }
          return parentRow;
        });
        return { payload: newData };
      });
      return { activeTab, previousData, projectId };
    },
    onSettled: (inputs) => {
      const { activeTab, projectId } = inputs;
      queryClient.invalidateQueries(["techDataAllParents", activeTab, projectId]);
    },
  });
};

export const useCheckTechDataDownloadFilesStatus = (projectId, activeTab) => {
  const url = apiUrls.layerFiles.techData?.[activeTab]?.downloadAll?.(projectId);
  return useGeneralHeadHook(["TechDataFiles", projectId, activeTab], url);
};
// ==========================
// Energy Performance
// ==========================
export const useGetPerformanceIndicator = (projectId) => {
  const url = apiUrls.performanceIndicator.list({
    projectId,
  });
  return useGeneralGetHook(["performanceIndicator", projectId], url);
};

export const useGetEnergyMix = (projectId) => {
  const url = apiUrls.energyMix.list({ projectId });
  return useGeneralGetHook(["energyMix", projectId], url);
};

export const useGetDashboard = (inputs) => {
  const { enabled, endDate, interval, projectId, quantity, startDate } = inputs;
  const url = apiUrls.energyPerformance[interval].list({
    endDate,
    projectId,
    quantity,
    startDate,
  });
  return useGeneralGetHook(["dashboard", projectId, quantity, startDate, endDate, interval], url, { enabled });
};

const addExtraFieldsToDashboardData = (data, interval) => {
  if (!data?.length || !interval) return;
  const output = data.map((row, ind) => {
    let dateTimeString = row.datetime;
    if (interval === "monthly") dateTimeString += "-01 00:00:00";
    if (interval === "daily") dateTimeString += " 00:00:00";
    const parts = dateTimeString.split(/[- :]/);
    // const dateTime = new Date(
    //   Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5])
    // );
    const dateTime = new Date(dateTimeString + " UTC");

    const year = dateTime.getUTCFullYear();
    const monthNumber = dateTime.getUTCMonth() + 1;
    const month = getMonthString(monthNumber - 1);
    const yearMonth = month + " " + year;
    const dayOfMonth = dateTime.getUTCDate();
    const hourOfDay = dateTime.getUTCHours();

    const dayOfWeekInteger = dateTime.getUTCDay();
    const dayOfWeek = getWeekdayString(dayOfWeekInteger);

    const startOfYear = new Date(Date.UTC(dateTime.getUTCFullYear(), 0, 1, 0, 0, 0));
    const timeDifference = dateTime - startOfYear;
    const dayOfYear = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
    const hourOfYear = Math.floor(timeDifference / (1000 * 60 * 60)) + 1;
    const cetHour = (hourOfDay + 1) % 24;
    const isWorkingDay = (dayOfWeekInteger >= 1 && dayOfWeekInteger <= 5) + 0;
    const isWorkingHour = (isWorkingDay && hourOfDay <= 18 && hourOfDay >= 7) + 0;

    return {
      ...row,
      cetHour,
      dayOfMonth,
      dayOfWeek,
      dayOfYear,
      hourOfDay,
      hourOfYear,
      isWorkingDay,
      isWorkingHour,
      month,
      monthNumber,
      year,
      yearMonth,
    };
  });
  return output;
};

export const useGetDashboardNew = (inputs) => {
  const { dataLevel, enabled, endDate, interval, projectId, quantities, scenarios, startDate } = inputs;
  const allSegments = [];
  scenarios?.forEach((scenario) => {
    quantities?.forEach((quantity) => {
      allSegments.push({ quantity, scenario });
    });
  });
  const dashboardNew = useQueries(
    allSegments.map(({ quantity, scenario }) => {
      const apiCallInputs = {
        dataLevel,
        endDate,
        interval,
        projectId,
        quantity,
        scenario,
        startDate,
      };
      return {
        enabled,
        queryFn: () => getDashboardNew(apiCallInputs),
        queryKey: ["dashboardNew", scenario, dataLevel, quantity, projectId, startDate, endDate, interval],
        select: (data) => {
          const { columns, reformattedValues } = data;
          const newData = reformattedValues?.map((row) => {
            return { ...row, dataPoint: quantity, scenario };
          });
          return { columns, data: newData };
        },
      };
    })
  );
  const isLoading = dashboardNew?.some((result) => result.isLoading);
  const isError = dashboardNew?.every((result) => result.isError);
  let mergedData = dashboardNew.reduce((total, quantityScenario) => {
    return quantityScenario?.status === "success" && quantityScenario?.data?.data?.length
      ? [...total, ...quantityScenario?.data?.data]
      : total;
  }, []);

  const apiColumns = dashboardNew.reduce((total, quantityScenario) => {
    return quantityScenario?.status === "success" && quantityScenario?.data?.columns?.length
      ? [...total, ...quantityScenario?.data?.columns]
      : total;
  }, []);

  const enrichedData = useMemo(
    () => addExtraFieldsToDashboardData(mergedData, interval),
    [JSON.stringify(inputs) + `${mergedData?.length}`]
  );

  const enrichedDataColumns = Object.keys(enrichedData?.[0] ?? {});

  return {
    allColumns: [...(apiColumns ?? []), ...(enrichedDataColumns ?? [])]?.filter(distinctFilter),
    data: enrichedData,
    isError,
    isLoading,
  };
};

export const useGetEnergyDashbordConfigs = (projectId) => {
  const url = apiUrls.dynamicDashboard.configs.get(projectId, ENERGY_DASHBOARD_NAME_API_BODY);
  return useGeneralGetHook(["energyDashbordConfigs", projectId, ENERGY_DASHBOARD_NAME_API_BODY], url);
};

export const useGetProjectInfo = (projectId, initialData) => {
  const url = apiUrls.energyPerformanceNew.projectInfo.list(projectId);
  return useGeneralGetHook(["projectInfo", projectId], url, [{ initialData }]);
};

// ==========================
// Certificates
// ==========================
export const useGetCertificateStatus = (projectId) => {
  const url = apiUrls.certificates.certificationStatus.list({
    projectId,
  });
  return useGeneralGetHook(["certificateStatus", projectId], url);
};

export const useGetEnergyRatingStatus = (projectId) => {
  const url = apiUrls.certificates.energyRatingStatus.list({
    projectId,
  });
  return useGeneralGetHook(["energyRatingStatus", projectId], url);
};
export const useCheckCertificatesDownloadFilesStatus = (projectId, certificateType) => {
  const url = apiUrls.files.certificates.download(projectId, certificateType);
  return useGeneralHeadHook(["certificatesFiles", projectId, certificateType], url);
};
// ==========================
// Modernization
// ==========================
export const useGetGoals = (projectId) => {
  const url = apiUrls.modernization.goals.list(projectId);
  return useGeneralGetHook(["getGoals", projectId], url);
};

export const useGetScenarios = (projectId) => {
  const url = apiUrls.modernization.scenarios.list(projectId);
  return useGeneralGetHook(["getScenarios", projectId], url);
};
export const useCheckScenariosFilesStatus = (projectId) => {
  const url = apiUrls.modernization.scenarioFiles.download(projectId);
  return useGeneralHeadHook(["scenariosFiles", projectId], url);
};
// ==========================
// Translations
// ==========================
export const useGetTranslations = () => {
  const url = apiUrls.translation.get("de");
  return useGeneralGetHook("getTranslations", url);
};

export const useModifyTranslation = () => {
  const queryClient = useQueryClient();
  return useMutation(postTranslation, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData("getTranslations", context.previousData);
    },
    onMutate: async (inputs) => {
      const { data } = inputs;
      await queryClient.cancelQueries("getTranslations");
      const previousData = queryClient.getQueryData("getTranslations");
      queryClient.setQueryData("getTranslations", (oldData) => {
        return { ...oldData, ...data };
      });
      return { previousData };
    },
    onSettled: (inputs) => {
      queryClient.invalidateQueries("getTranslations");
    },
    onSuccess: () => {
      toast.success("Successfully modified.");
    },
  });
};

// ============== CRREM TOOL
export const useGetCrrem = (projectId, degree, parameter) => {
  const url = apiUrls.modernization.crrem.list({
    degree,
    parameter,
    projectId,
  });
  const { data, ...rest } = useGeneralGetHook(["crrem", projectId, degree, parameter], url);
  const filteredAndMemoizedData = useMemo(() => {
    return { data: data?.filter((row) => row.year >= 2022), ...rest };
  }, [data]);
  return filteredAndMemoizedData;
};

//=============================
// Wizard
//============================
export const useGetAllMeasures = (projectId, enabled) => {
  const url = apiUrls.modernization.retrofits.list(projectId);
  return useGeneralGetHook(["allMeasures", projectId], url, { enabled });
};

export const postMeasure = async ({ data }) => {
  const url = apiUrls.modernization.retrofits.post;
  const resp = await customApiCall.post({ data, url });
  return resp;
};

export const deleteMeasure = async ({ rowId }) => {
  const url = apiUrls.modernization.retrofits.get(rowId);
  const resp = await customApiCall.delete({ url });
  return resp;
};

export const modifyMeasure = async ({ data, rowId }) => {
  const url = apiUrls.modernization.retrofits.get(rowId);
  const resp = await customApiCall.patch({ data, url });
  return resp;
};

export const useAddMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(postMeasure, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["allMeasures", context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { data, onSettled: onReqSettled, onSuccess: onReqSuccess, projectId } = inputs;
      await queryClient.cancelQueries(["allMeasures", projectId]);
      const previousData = queryClient.getQueryData(["allMeasures", projectId]);
      queryClient.setQueryData(["allMeasures", projectId], (oldData) => {
        const newData = [...oldData];
        newData.push({ ...data, id: -2 });
        return newData;
      });
      return { onReqSettled, onReqSuccess, previousData, projectId };
    },
    onSettled: (_data, _error, _variables, context) => {
      const { onReqSettled, projectId } = context;
      queryClient.invalidateQueries(["allMeasures", projectId]);
      onReqSettled?.();
    },
    onSuccess: (_data, _error, context) => {
      const { onReqSuccess } = context;
      onReqSuccess?.();
    },
  });
};

export const useDeleteMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMeasure, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["allMeasures", context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { projectId, rowId } = inputs;
      await queryClient.cancelQueries(["allMeasures", projectId]);
      const previousData = queryClient.getQueryData(["allMeasures", projectId]);
      queryClient.setQueryData(["allMeasures", projectId], (oldData) => {
        const newData = oldData?.filter((row) => row.id !== rowId);
        return newData;
      });
      return { previousData, projectId };
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["allMeasures", projectId]);
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};

export const useModifyMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(modifyMeasure, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["allMeasures", context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { data, onSettled: onReqSettled, onSuccess: onReqSuccess, projectId, rowId } = inputs;
      await queryClient.cancelQueries(["allMeasures", projectId]);
      const previousData = queryClient.getQueryData(["allMeasures", projectId]);
      queryClient.setQueryData(["allMeasures", projectId], (oldData) => {
        const newData = oldData?.map((row) => {
          if (row.id === rowId) return { ...row, ...data };
          else {
            return row;
          }
        });
        return newData;
      });
      return { onReqSettled, onReqSuccess, previousData, projectId };
    },
    onSettled: (_data, _error, _variables, context) => {
      const { onReqSettled, projectId } = context;
      queryClient.invalidateQueries(["allMeasures", projectId]);
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
      onReqSettled?.();
    },
    onSuccess: (_data, _error, context) => {
      const { onReqSuccess } = context;
      onReqSuccess?.();
    },
  });
};

// ========= User Selected Measures
export const useGetAllSelectedMeasures = (projectId, enabled = true) => {
  const url = apiUrls.modernization.selectedRetrofit.list(projectId);
  return useGeneralGetHook(["selectedMeasures", projectId], url, { enabled });
};

export const postSelectedMeasure = async ({ data }) => {
  const url = apiUrls.modernization.selectedRetrofit.post;
  const resp = await customApiCall.post({ data, url });
  return resp;
};

export const deleteSelectedMeasure = async ({ rowId }) => {
  const url = apiUrls.modernization.selectedRetrofit.get(rowId);
  const resp = await customApiCall.delete({ url });
  return resp;
};

export const modifySelectedMeasure = async ({ data, rowId }) => {
  const url = apiUrls.modernization.selectedRetrofit.get(rowId);
  const resp = await customApiCall.patch({ data, url });
  return resp;
};

export const useAddSelectedMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(postSelectedMeasure, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["selectedMeasures", context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { data, projectId } = inputs;
      await queryClient.cancelQueries(["selectedMeasures", projectId]);
      const previousData = queryClient.getQueryData(["selectedMeasures", projectId]);
      queryClient.setQueryData(["selectedMeasures", projectId], (oldData) => {
        const newData = [...oldData];
        newData.push({ ...data, id: -2 });
        return newData;
      });
      return { previousData, projectId };
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};

export const useDeleteSelectedMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSelectedMeasure, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["selectedMeasures", context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { projectId, rowId } = inputs;
      await queryClient.cancelQueries(["selectedMeasures", projectId]);
      const previousData = queryClient.getQueryData(["selectedMeasures", projectId]);
      queryClient.setQueryData(["selectedMeasures", projectId], (oldData) => {
        const newData = oldData?.filter((row) => row.id !== rowId);
        return newData;
      });
      return { previousData, projectId };
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};

export const useModifySelectedMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(modifySelectedMeasure, {
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(["selectedMeasures", context.projectId], context.previousData);
    },
    onMutate: async (inputs) => {
      const { data, projectId, rowId } = inputs;
      await queryClient.cancelQueries(["selectedMeasures", projectId]);
      const previousData = queryClient.getQueryData(["selectedMeasures", projectId]);
      queryClient.setQueryData(["selectedMeasures", projectId], (oldData) => {
        const newData = oldData?.map((row) => {
          if (row.id === rowId) return { ...row, ...data };
          else {
            return row;
          }
        });
        return newData;
      });
      return { previousData, projectId };
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};
