import axios from "axios";
import i18next from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

import apiUrls from "../api/urls";
import { de } from "./de";
import { en } from "./en";

const resources = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
};

const languageInLocalstorage = localStorage.getItem("language");

const loadResources = async (locale) => {
  return await axios
    .get(apiUrls.translation.get(locale))
    .then((response) => response.data)
    .catch((error) => {});
};

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split("|");
      loadResources(lng).then((response) => {
        callback(null, { data: response, status: 200 });
      });
    } catch (e) {
      callback(null, { status: 500 });
    }
  },
};

i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    backend: {
      backendOptions: [backendOptions],
      backends: [HttpBackend, resourcesToBackend(resources)],
    },
    fallbackLng: "en",
    lng: languageInLocalstorage || "en",
  });

export default i18next;
