import { Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Icon } from "./Icon";

export const CustomModal = ({
  children,
  className,
  closeIcon = true,
  height = "400px",
  left = "50%",
  modalOpen,
  padding = "5rem 2.4rem 4rem 2.4rem",
  right,
  setModalOpen,
  style,
  top = "50%",
  width = "400px",
  ...props
}) => {
  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      onClose={() => setModalOpen(false)}
      open={modalOpen}
      {...props}
    >
      <Wrapper
        className={className}
        height={height}
        left={left}
        padding={padding}
        right={right}
        style={style}
        top={top}
        width={width}
      >
        {children}
        {closeIcon && <Icon className="close" iconName="Close" onClick={() => setModalOpen(false)} />}
      </Wrapper>
    </Modal>
  );
};
const Wrapper = styled("div")(({ height, left, padding, right, style, top, width }) => ({
  ".close": {
    cursor: "pointer",
    display: "block",
    position: "absolute",
    right: "2rem",
    top: "2rem",
  },
  backgroundColor: "var(--clr-white)",
  border: "1px solid var(--clr-gray-100)",
  borderRadius: "7px",
  boxShadow: "var(--box-shadow-elevation-400)",
  height: height,
  left,
  padding,
  position: "absolute",
  right,
  textAlign: "center",
  top,
  transform: "translate(-50%, -50%)",
  width: width,
  ...style,
}));
