import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import { useGetAllMeasures, useGetAllSelectedMeasures, useGetCrrem } from "../api/hooks/allHooks";
import { columnsAPINamesEnums } from "../enums/Modernization/financialDashboard";
import { addRetrofitsToData } from "../functions/Modernization";

type ContextValue = {
  crremData: CrremRow[];
  crremScenario: string | undefined;
  data: DataRow[];
  dataEmission: DataRow[];
  isAllmeasuresLoading: boolean;
  isSelectedMesuresLoading: boolean;
  measures: Measure[];
  quantity: "consumption" | "emission";
  section: "crrem" | "energy";
  selectedMeaures: SelectedMeasure[];
  setCrremScenario: (newData: ContextValue["crremScenario"]) => void;
  setData: (newData: ContextValue["data"]) => void;
  setQuantity: (newQuantity: ContextValue["quantity"]) => void;
  setSection: (newQuantity: ContextValue["section"]) => void;
  setYearRange: (yearRange: ContextValue["yearRange"]) => void;
  yearRange: [number, number];
};

type CrremRow = Exclude<DataRow, "modernized">;

type DataRow = {
  baseline?: number | undefined;
  climate: number | undefined;
  crrem: number | undefined;
  current: number | undefined;
  id: number;
  modernized?: number | undefined;
  project: number;
  year: number | undefined;
};

type Measure = Record<(typeof columnsAPINamesEnums)[keyof typeof columnsAPINamesEnums], number | string | undefined>;

type SelectedMeasure = {
  id: number;
  name: string;
  project: number;
  retrofit_model: number;
  user: number;
  year: number;
};

const defaultValue: ContextValue = {
  crremData: [],
  crremScenario: undefined,
  data: [],
  dataEmission: [],
  isAllmeasuresLoading: false,
  isSelectedMesuresLoading: false,
  measures: [],
  quantity: "emission",
  section: "crrem",
  selectedMeaures: [],
  setCrremScenario: () => {},
  setData: () => {},
  setQuantity: () => "emission",
  setSection: () => {},
  setYearRange: () => {},
  yearRange: [2022, 2050],
};

export const WizardContext = createContext<ContextValue>(defaultValue);

export const WizardContextProvider = ({
  children,
  hasModernization = true,
  projectId,
}: {
  children: ReactNode;
  hasModernization?: boolean;
  projectId: number | string;
}) => {
  const [data, setData] = useState<ContextValue["data"]>(defaultValue.data);
  const [dataEmission, setDataEmission] = useState<ContextValue["dataEmission"]>(defaultValue.data);
  const [quantity, setQuantity] = useState<ContextValue["quantity"]>(defaultValue.quantity);
  const [section, setSection] = useState<ContextValue["section"]>(defaultValue.section);
  const [yearRange, setYearRange] = useState<ContextValue["yearRange"]>(defaultValue.yearRange);

  const { data: selectedMeaures, isLoading: isSelectedMesuresLoading } = useGetAllSelectedMeasures(
    projectId,
    hasModernization
  );

  const { data: measures, isLoading: isAllmeasuresLoading } = useGetAllMeasures(projectId, hasModernization);

  const [retrofits, setRetrofits] = useState(selectedMeaures);

  const [crremScenario, setCrremScenario] = useState<ContextValue["crremScenario"]>();

  const { data: crremDataRaw } = useGetCrrem(projectId, crremScenario, quantity);
  const crremData = useMemo(
    () =>
      crremDataRaw?.map((row: CrremRow) => {
        return { ...row, baseline: row.current, current: row.climate };
      }),
    [crremDataRaw]
  );

  var dataInit = crremData || [];

  useEffect(() => {
    if (!hasModernization) return;
    const retrofitsWithAmount = selectedMeaures?.map((retrofit: SelectedMeasure) => {
      const correspondingRetrofit = measures?.find((measure: Measure) => measure.name === retrofit.name);
      if (correspondingRetrofit) {
        const amount =
          quantity !== "consumption"
            ? correspondingRetrofit?.carbon_dioxide_savings
            : correspondingRetrofit?.[columnsAPINamesEnums.REDUCTION_ENERGY_END];
        return {
          ...retrofit,
          amount: Number(amount),
          primaryModernized: correspondingRetrofit?.[columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY],
        };
      }
      return retrofit;
    });
    setRetrofits(retrofitsWithAmount);
  }, [JSON.stringify(selectedMeaures), quantity, crremScenario, measures, section]);

  useEffect(() => {
    if (!hasModernization) return;
    const selectedRetrofits = (retrofits || [])
      .map((retrofit: SelectedMeasure) => ({ ...retrofit, year: Number(retrofit.year) }))
      .filter((retrofit: SelectedMeasure) => retrofit.year >= 2020 && retrofit.year <= 2130);
    const dataWithRetrofits = addRetrofitsToData(selectedRetrofits, dataInit);
    setData(dataWithRetrofits);
    if (quantity === "emission") setDataEmission(dataWithRetrofits);
  }, [retrofits, crremDataRaw, quantity, crremScenario]);

  return (
    <WizardContext.Provider
      value={{
        crremData,
        crremScenario,
        data,
        dataEmission,
        isAllmeasuresLoading,
        isSelectedMesuresLoading,
        measures,
        quantity,
        section,
        selectedMeaures: !hasModernization ? [] : selectedMeaures,
        setCrremScenario,
        setData,
        setQuantity,
        setSection,
        setYearRange,
        yearRange,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};
