import { Paper, Stack } from "@mui/material";
import { isInteger } from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { useGetProjectInfo } from "../../api/hooks/allHooks";
import { WizardContext } from "../../context/Wizard";
import { eventNames, withGAEventTracking } from "../../functions/googleAnalytics";
import { calculateStrandingPoint } from "../../functions/Modernization";
import { theme } from "../../styles/theme";
import { formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { getCSSVar } from "../../utils/styling";
import { DynamicEchartsChart } from "../chart/DynamicChartMemoized";
import { DropdownSingleSelect } from "../DropdownSingleSelect";
import { RadioButton } from "../RadioButton";

export const StrandingChartWithEchart = ({
  chartHeight = 350,
  className,
  hasModernization = true,
  onCrremChange,
  projectId,
  title,
}) => {
  const { data: projectInfo } = useGetProjectInfo(projectId);

  const { crremData, crremScenario, data, quantity, setCrremScenario, setData, setQuantity, yearRange } =
    useContext(WizardContext);

  let crremScenarioList;
  try {
    crremScenarioList = JSON.parse(projectInfo?.[0]?.crrem_scenario_list);
  } catch {
    //Do nothing
  }

  crremScenarioList = crremScenarioList ?? [];

  let crremScenariosOptions = crremScenarioList?.map((option) => ({ option }));

  const unit = quantity === "emission" ? "CO2/m²" : "kWh/m²";

  useEffect(() => {
    if (!crremScenario) setCrremScenario(crremScenarioList?.[0]);
  }, [projectInfo]);

  useEffect(() => {
    setData(crremData || []);
    if (onCrremChange) onCrremChange(crremData);
  }, [crremData]);

  const [strandingPoint, setStrandingPoint] = useState([]);
  const [strandingPointsModernized, setStrandingPointsModernized] = useState([]);

  useEffect(() => {
    const enhancedData = data.map((e) => {
      if (e.crrem !== undefined) return e;
      const year = e.year;
      const yearData = data.find((e) => e.year === year && e.crrem !== undefined);
      if (yearData) return { ...e, crrem: yearData.crrem };
      return e;
    });
    setStrandingPointsModernized(calculateStrandingPoint(enhancedData, "modernized"));
    setStrandingPoint(calculateStrandingPoint(data, "current"));
  }, [data]);

  // Just for dropdown initial value to display!
  if (!projectInfo) return;

  const dataForChart = data?.filter((yearData) => yearData.year <= yearRange[1] && yearData.year >= yearRange[0]);
  const option = {
    animation: true,
    dataZoom: [
      {
        right: 0,
        type: "slider",
        width: 16,
        yAxisIndex: 0,
      },
    ],
    legend: {
      bottom: 0,
      left: 10,
      orient: "horizontal",
      right: 40,
      textStyle: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3rem",
        fontWeight: 600,
      },
    },

    series: [
      ...(quantity === "emission"
        ? [
            {
              color: getCSSVar("--clr-gray-500"),
              connectNulls: true,
              data: dataForChart.map((d) => [d.year, d.baseline]),
              lineStyle: { color: getCSSVar("--clr-gray-500"), type: "dashed", width: 1 },
              name: "Baseline Emission",
              symbol: "none",
              type: "line",
            },
          ]
        : []),

      ...(hasModernization
        ? [
            {
              color: getCSSVar("--clr-secondary-blue-500"),
              data: dataForChart.map((d) => [d.year, d.modernized]),
              lineStyle: { color: getCSSVar("--clr-secondary-blue-500"), width: 2 },
              name: "Modernized",
              symbol: "none",
              type: "line",
            },
            {
              color: "#00a",
              data: dataForChart.map((d) => [d.year, d.retrofit]),
              itemStyle: { color: "#00a" },
              lineStyle: { color: "#00a", type: "dashed", width: 2 },
              name: "Retrofit",
              symbol: "circle",
              symbolSize: 6,
              type: "line",
            },
            ...(strandingPointsModernized?.map((sP, i) => ({
              data: [[sP[0], sP[1]]],
              itemStyle: {
                borderColor: getCSSVar("--clr-mystic-red-500"),
                borderWidth: 3,
                color: "none",
                shadowBlur: 4,
                shadowColor: "rgba(255, 83, 0, 0.25)",
              },
              name: `Stranding Event ${i}`,
              symbolSize: 16,
              type: "scatter",
            })) || []),
            ...(strandingPoint?.length > 0
              ? [
                  {
                    data: [[strandingPoint[0][0], strandingPoint[0][1]]],
                    itemStyle: {
                      borderColor: getCSSVar("--clr-mystic-red-500"),
                      borderWidth: 3,
                      color: "none",
                      shadowBlur: 4,
                      shadowColor: "rgba(255, 83, 0, 0.25)",
                    },
                    name: "Stranding Event",
                    symbolSize: 16,
                    type: "scatter",
                  },
                ]
              : []),
          ]
        : []),

      {
        color: getCSSVar("--clr-gray-700"),
        connectNulls: true,
        data: dataForChart.map((d) => [d.year, d.current]),
        lineStyle: { color: getCSSVar("--clr-gray-700"), width: 1 },
        name: quantity === "consumption" ? "Asset Energy Intensity" : "Climate And Grid Corrected Asset Performance",
        symbol: "none",
        type: "line",
      },
      {
        areaStyle: { color: getCSSVar("--clr-plunge-700"), opacity: 0.05 },
        color: getCSSVar("--clr-plunge-700"),
        connectNulls: true,
        data: dataForChart.map((d) => [d.year, d.crrem]),
        lineStyle: { color: getCSSVar("--clr-plunge-700"), width: 1 },
        name: quantity === "consumption" ? "Country Specific Energy Target" : "Decarbonization Target",
        symbol: "none",
        type: "line",
      },
    ],
    tooltip: {
      formatter: (params) => {
        const year = params?.[0]?.data?.[0];
        const filtered = params
          .filter((p) => {
            return p.data?.[1] !== null && p.data?.[1] !== undefined && p.seriesName !== "Retrofit";
          })
          .map((p) => `${p.marker} ${p.seriesName}: ${formatNumberBasedOnUser(p?.data?.[1])}`)
          .join("<br/>");
        return `<h1>${isInteger(year) ? year : year?.toFixed(2)}</h1>
        ${filtered} `;
      },
      trigger: "axis",
    },
    xAxis: [
      {
        axisPointer: {
          type: "shadow",
        },
        max: yearRange[1],
        min: yearRange[0],
        name: "Year",
        nameGap: 25,
        nameLocation: "center",
        type: "value",
      },
    ],

    yAxis: [
      {
        name: unit,
        nameGap: 10,
        nameLocation: "end",
        nameRotate: 0,
        nameTextStyle: {},
        type: "value",
      },
    ],
  };

  return (
    <Paper className={`relative  ${className}`}>
      <section className="flex items-start justify-between gap-6">
        <div className="w-full">
          <Stack alignItems="flex-start" justifyContent="space-between">
            <DropdownSingleSelect
              className="mb-6"
              label="Scenario"
              options={crremScenariosOptions.map((e) => e.option)}
              selectedValue={crremScenario ?? crremScenarioList?.[0]}
              setSelectedValue={(newValue) =>
                withGAEventTracking(eventNames.CRREM_SCENARIO_CHANGE, {}, () => setCrremScenario(newValue))
              }
              width="25rem"
            />
            <Stack alignItems="center" className="mb-9" gap={theme.spacing(6)}>
              <h2 className=" t-heading-l">{title}</h2>
              <RadioButton
                displayOptions={["CO₂ Emission", "kWh"]}
                options={["emission", "consumption"]}
                row={true}
                setValue={(newValue) =>
                  withGAEventTracking(eventNames.CRREM_QUANTITY_CHANGE, {}, () => setQuantity(newValue))
                }
                value={quantity}
              />
            </Stack>
          </Stack>
          <div className="relative " style={{ height: chartHeight }}>
            <DynamicEchartsChart option={option} />
          </div>
        </div>
      </section>
    </Paper>
  );
};
