import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

export const renderCustomizedLabel = ({ cx, cy, index, innerRadius, midAngle, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      className="t-body-xl"
      dominantBaseline="central"
      fill="white"
      style={{
        fontSize: "",
      }}
      textAnchor={x > cx ? "start" : "end"}
      x={x}
      y={y}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const PieAndDoughnutChart = ({ chartProps, containerProps, data, pieProps }) => {
  return (
    <ResponsiveContainer height={225} width="100%" {...containerProps}>
      <PieChart height={200} margin={0} {...chartProps}>
        <Pie
          cx="50%"
          cy="50%"
          data={data}
          dataKey="value"
          fill="#82ca9d"
          label={renderCustomizedLabel}
          labelLine={false}
          nameKey="name"
          outerRadius={110}
          {...pieProps}
        >
          {data?.map((entry, index) => (
            <Cell fill={entry.color} key={`cell-${index}`} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export const SunburstChart = ({ chartProps, containerProps, doughnutProps, pieProps }) => {
  return (
    <ResponsiveContainer height={225} width="100%" {...containerProps}>
      <PieChart height={200} margin={0} {...chartProps}>
        <Pie
          cx="50%"
          cy="50%"
          dataKey="value"
          fill="#82ca9d"
          label={renderCustomizedLabel}
          labelLine={false}
          nameKey="name"
          outerRadius={110}
          {...pieProps}
        >
          {pieProps.data?.map((entry, index) => (
            <Cell fill={entry.color} key={`cell-${index}`} />
          ))}
        </Pie>
        <Pie
          cx="50%"
          cy="50%"
          dataKey="value"
          fill="#82ca9d"
          label={renderCustomizedLabel}
          labelLine={false}
          nameKey="name"
          outerRadius={110}
          {...doughnutProps}
        >
          {doughnutProps.data?.map((entry, index) => (
            <Cell fill={entry.color} key={`cell-${index}`} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
