import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import { exportComponentAsJPEG } from "react-component-export-image";
import { GiPhotoCamera } from "react-icons/gi";

const removeNoscriptTags = () => {
  document.querySelectorAll("noscript")?.forEach((noscript) => {
    noscript.style.display = "none";
  });
};

const restoreNoscriptTags = () => {
  document.querySelectorAll("noscript")?.forEach((noscript) => {
    noscript.style.display = "";
  });
};

export const captureElement = (targetElement, filename = "screenshot") => {
  removeNoscriptTags();

  html2canvas(targetElement, {
    useCORS: true,
  }).then((canvasSnapshot) => {
    const screenshot = canvasSnapshot.toDataURL("image/png");
    saveAs(screenshot, `${filename}.png`);
  });
  restoreNoscriptTags();
};

export const CaptureComponent1 = ({ filename, style, targetElement, ...props }) => {
  return (
    <GiPhotoCamera
      onClick={(e) => {
        exportComponentAsJPEG(targetElement());
      }}
      style={{
        cursor: "pointer",
        height: "24px",
        width: "24px",
        ...style,
      }}
      {...props}
    />
  );
};

export const CaptureComponent = ({ filename, style, targetElement, ...props }) => {
  const captureScreenshot = () => {
    const options = {
      backgroundColor: "#ffffff",
      cacheBust: false,
      quality: 1,
      style: { height: "auto", width: "auto" },
    };
    // htmlToImage.toPng(targetElement())
    //     .then(function (dataUrl) {
    //         var img = new Image();
    //         img.src = dataUrl;
    //         document.body.appendChild(img);
    //     }).catch(e => {
    //         console.log(e)
    //     })

    // const blob = await htmlToImage.toBlob(targetElement(), { quality: 1, backgroundColor: '#ffffff' });
    // const url = URL.createObjectURL(blob);

    // const link = document.createElement('a');
    // link.href = url;
    // link.download = 'component-screenshot.png';
    // link.click();

    // // Release the object URL after download
    // URL.revokeObjectURL(url);
    htmlToImage
      .toPng(targetElement(), options)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "component-screenshot.jpeg";
        link.click();
      })
      .catch((err) => console.log("Error capturing screenshot:", err));
  };
  return (
    <GiPhotoCamera
      onClick={(e) => {
        captureScreenshot();
      }}
      style={{
        cursor: "pointer",
        height: "24px",
        width: "24px",
        ...style,
      }}
      {...props}
    />
  );
};
