import styled from "styled-components";

import { LabelColumn } from "../components/chart/labelColumn";
const barsInforamtions = [
  {
    color: "#00725A",
    rating: "a",
    score: [92, null],
    width: 30,
  },
  {
    color: "#00C59C",
    rating: "b",
    score: [81, 91],
    width: 40,
  },
  {
    color: "#00C54D",
    rating: "c",
    score: [69, 80],
    width: 50,
  },
  {
    color: "#E4CC00",
    rating: "d",
    score: [55, 68],
    width: 60,
  },
  {
    color: "#FFAD72",
    rating: "e",
    score: [39, 54],
    width: 70,
  },
  {
    color: "#E48200",
    rating: "f",
    score: [21, 38],
    width: 80,
  },
  {
    color: "#E44A00",
    rating: "g",
    score: [1, 20],
    width: 90,
  },
];
const status = {
  current: {
    rating: "e",
    score: 39,
  },
  potential: {
    rating: "c",
    score: 79,
  },
};
export const EnergyRating = () => {
  return (
    <Wrapper>
      <h2>energy rating</h2>
      <div className="content">
        <article className="ratings">
          <div className="energy-titles article-title">
            <h3>score</h3>
            <h3>energy rating</h3>
          </div>
          <div className="energy-bars">
            {barsInforamtions?.map((bar, index) => {
              return (
                <div className="energy-bar" key={index} style={{ backgroundColor: bar.color, width: `${bar.width}%` }}>
                  {bar.score[1] && (
                    <span>
                      {bar.score[0]}-{bar.score[1]}
                    </span>
                  )}{" "}
                  {!bar.score[1] && <span>{bar.score[0]}+</span>}
                  <span>{bar.rating}</span>
                </div>
              );
            })}
          </div>
        </article>
        <LabelColumn
          barsInforamtions={barsInforamtions}
          height="2.5rem"
          marginBottom="0.2rem"
          status={status.current}
          title="current"
         />
        <LabelColumn
          barsInforamtions={barsInforamtions}
          height="2.5rem"
          marginBottom="0.2rem"
          status={status.potential}
          title="potential"
         />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  width: 50%;
  background-color: white;
  padding: 1.5rem;
  border-radius: 1.5rem;
  .content {
    display: flex;
    text-transform: capitalize;
  }
  article {
    padding-top: 1rem;
  }
  .ratings {
    width: 60%;
  }
  .energy-titles {
    display: flex;
    gap: 10%;
  }
  .article-title {
    margin-bottom: 1rem;
  }
  .energy-bar {
    border-radius: 0.3rem;
    color: white;
    padding: 0.5rem;
    margin-bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
  }
`;
