import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function useQueryParamsState(paramKey, defaultValue) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(searchParams.get(paramKey) || defaultValue);

  useEffect(() => {
    const paramValue = searchParams.get(paramKey);
    if (paramValue !== state) {
      setState(paramValue || defaultValue);
    }
  }, [searchParams, paramKey, state, defaultValue]);

  const updateState = (newValue) => {
    setState(newValue);

    const updatedParams = new URLSearchParams(searchParams);
    if (newValue) {
      updatedParams.set(paramKey, newValue);
    } else {
      updatedParams.delete(paramKey);
    }

    setSearchParams(updatedParams, { replace: true });
  };

  return [state, updateState];
}

export default useQueryParamsState;
