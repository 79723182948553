import React from "react";
import { Helmet } from "react-helmet-async";

import { EnergyDashboardNewMultiTab } from "../components/EnergyPerformance/EnergyDashboardNewMultiTab";

export const EnergyPerformanceNewMultiTab = () => {
  return (
    <>
      <Helmet>
        <title>Energy & CO₂ | Leaftech</title>
      </Helmet>
      <EnergyDashboardNewMultiTab />
    </>
  );
};
