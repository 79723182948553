const baseUrl = process.env.REACT_APP_API_URL + "/api";
const layersUrl = baseUrl + "/layers";

export default {
  buildingPassport: {
    download: (projectId) => `${layersUrl}/get_files_of_floors_plan/?project_id=${projectId}`,
    list: (projectId) => `${layersUrl}/get_all_floors_plan_of_floors_layer/?project_id=${projectId}`,
  },
  techData: {
    energyMix: {
      detail: (rowId) => `${baseUrl}/energy_mix/${rowId}/`,
      list: (projectId) => `${baseUrl}/energy_mix/?project_id=${projectId}`,
    },
    notExistedYet: {
      list: (projectId) => `${layersUrl}/notExistedYet/?project_id=${projectId}`,
    },
    rooftop: {
      detail: (rowId) => `${baseUrl}/rooftop_interaction/${rowId}/`,
      downloadAll: (projectId) =>
        `${layersUrl}/get_whole_files_of_envelop_type/?project_id=${projectId}&envelop_type=rooftop/`,
      downloadLayer: (projectId, layerId) =>
        `${layersUrl}/get_files_layer_zip_files_of_specific_envelop/?project_id=${projectId}&layer_id=${layerId}&layer_type=rooftop/`,
      layerDetail: (rowId) => `${baseUrl}/rooftop_layer_interaction/${rowId}/`,
      layerList: (rooftopId) => `${layersUrl}/get_rooftop_layer_list_of_rooftop/?rooftop_id=${rooftopId}`,
      list: (projectId) => `${layersUrl}/get_rooftop_list_with_layers_of_project/?project_id=${projectId}`,
    },
    wall: {
      detail: (rowId) => `${baseUrl}/facade_interaction/${rowId}/`,
      downloadAll: (projectId) =>
        `${layersUrl}/get_whole_files_of_envelop_type/?project_id=${projectId}&envelop_type=facade/`,
      downloadLayer: (projectId, layerId) =>
        `${layersUrl}/get_files_layer_zip_files_of_specific_envelop/?project_id=${projectId}&layer_id=${layerId}&layer_type=facade/`,
      layerDetail: (rowId) => `${baseUrl}/facade_layer_interaction/${rowId}/`,
      layerList: (facadeId) => `${layersUrl}/get_facade_layer_list_of_facade/?facade_id=${facadeId}`,
      list: (projectId) => `${layersUrl}/get_facade_list_with_layers_of_project/?project_id=${projectId}`,
    },
    windows: {
      detail: (rowId) => `${baseUrl}/windows_interaction/${rowId}/`,
      downloadAll: (projectId) =>
        `${layersUrl}/get_whole_files_of_envelop_type/?project_id=${projectId}&envelop_type=window/`,
      downloadLayer: (projectId, layerId) =>
        `${layersUrl}/get_files_layer_zip_files_of_specific_envelop/?project_id=${projectId}&layer_id=${layerId}&layer_type=window/`,
      layerDetail: (rowId) => `${baseUrl}/windows_layer_interaction/${rowId}/`,
      layerList: (windowId) => `${layersUrl}/get_window_layer_list_of_window/?window_id=${windowId}`,
      list: (projectId) => `${layersUrl}/get_windows_list_with_layers_of_project/?project_id=${projectId}`,
    },
  },
};
