export default {
  footer: {
    copyright: "© Copyright 2023 Leaftech GmbH - Alle Rechte vorbehalten.",
    imprint: "Impressum",
    privacyPolicy: "Datenschutz",
  },
  forceLogoutText:
    "You've been logged out for data protection purposes. Please log in again to continue accessing your account.",
  forceLogoutTitle: "You've been logged out",
  header: {
    assets: "Liegenschaften",
    logout: "Abmelden",
    logoutToast: "Erfolgreiche Abmeldung",
  },
};
