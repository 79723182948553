import { CircularProgress as CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { BsTrash3 as Trash } from "react-icons/bs";
import { BsFullscreen as Fullscreen } from "react-icons/bs";
import { BsFullscreenExit as FullscreenExit } from "react-icons/bs";
import { CiEraser as Erase } from "react-icons/ci";
import { CiEdit as Edit } from "react-icons/ci";
import { FaRegCirclePause as Pause } from "react-icons/fa6";
import { HiWrenchScrewdriver as WrenchScrewDriver } from "react-icons/hi2";
import { IoIosColorPalette as ColorPalette } from "react-icons/io";
import { IoMdMore as More } from "react-icons/io";
import { IoDuplicateOutline as Duplicate } from "react-icons/io5";
import { LiaPlayCircleSolid as Play } from "react-icons/lia";
import { LuFilter as Filter } from "react-icons/lu";
import { PiPaintBrushBroad as PaintBrush } from "react-icons/pi";
import { SlEnergy as Energy } from "react-icons/sl";
import styled from "styled-components";

import { ReactComponent as Add } from "../Asset/Icons/addSm.svg";
import { ReactComponent as ArrowDown } from "../Asset/Icons/arrowDown.svg";
import { ReactComponent as ArrowThinLeft } from "../Asset/Icons/arrowThinLeft.svg";
import { ReactComponent as ArrowThinRight } from "../Asset/Icons/arrowThinRight.svg";
import { ReactComponent as ArrowUpRight } from "../Asset/Icons/arrowUpRight.svg";
import { ReactComponent as Barchart } from "../Asset/Icons/barchart.svg";
import { ReactComponent as BatteryIcon } from "../Asset/Icons/battery.svg";
import { ReactComponent as BuildingIcon } from "../Asset/Icons/building.svg";
import { ReactComponent as BuildingTypeIcon } from "../Asset/Icons/buildingType.svg";
import { ReactComponent as Calendar } from "../Asset/Icons/calendar.svg";
import { ReactComponent as Camera } from "../Asset/Icons/camera.svg";
import { ReactComponent as CertificateIcon } from "../Asset/Icons/certificate.svg";
import { ReactComponent as ChevronDown } from "../Asset/Icons/chevronDown.svg";
import { ReactComponent as ChevronRight } from "../Asset/Icons/chevronSmRight.svg";
import { ReactComponent as Close } from "../Asset/Icons/close-md.svg";
import { ReactComponent as Coins } from "../Asset/Icons/coinsSm.svg";
import { ReactComponent as ConstructionIcon } from "../Asset/Icons/construction.svg";
import { ReactComponent as Contact } from "../Asset/Icons/contact.svg";
import { ReactComponent as Details } from "../Asset/Icons/details.svg";
import { ReactComponent as Download } from "../Asset/Icons/downloadSm.svg";
import { ReactComponent as Exit } from "../Asset/Icons/exit.svg";
import { ReactComponent as EyeClose } from "../Asset/Icons/eyeClose.svg";
import { ReactComponent as Eyeglasses } from "../Asset/Icons/eyeglasses.svg";
import { ReactComponent as EyeOpen } from "../Asset/Icons/eyeOpen.svg";
import { ReactComponent as FlatCube } from "../Asset/Icons/flatCube.svg";
import { ReactComponent as GridIcon } from "../Asset/Icons/grid.svg";
import { ReactComponent as Handshake } from "../Asset/Icons/handshake.svg";
import { ReactComponent as Info } from "../Asset/Icons/infoSm.svg";
import { ReactComponent as ListIcon } from "../Asset/Icons/list.svg";
import { ReactComponent as LocationIcon } from "../Asset/Icons/location.svg";
import { ReactComponent as Piechart } from "../Asset/Icons/piechart.svg";
import { ReactComponent as Remove } from "../Asset/Icons/removeSm.svg";
import { ReactComponent as Settings } from "../Asset/Icons/settings.svg";
import { ReactComponent as Sorting } from "../Asset/Icons/sorting.svg";
import { ReactComponent as SortingAscending } from "../Asset/Icons/sortingAscending.svg";
import { ReactComponent as SortingDescending } from "../Asset/Icons/sortingDescending.svg";
import { ReactComponent as Tree } from "../Asset/Icons/tree.svg";
import { ReactComponent as Upload } from "../Asset/Icons/uploadSm.svg";
import { ReactComponent as Wrench } from "../Asset/Icons/wrench.svg";

const icons = {
  Add,
  ArrowDown,
  ArrowThinLeft,
  ArrowThinRight,
  ArrowUpRight,
  Barchart,
  BatteryIcon,
  BuildingIcon,
  BuildingTypeIcon,
  Calendar,
  Camera,
  CertificateIcon,
  ChevronDown,
  ChevronRight,
  CircularProgress,
  Close,
  Coins,
  ColorPalette,
  ConstructionIcon,
  Contact,
  Details,
  Download,
  Duplicate,
  Edit,
  Energy,
  Erase,
  Exit,
  EyeClose,
  Eyeglasses,
  EyeOpen,
  Filter,
  FlatCube,
  Fullscreen,
  FullscreenExit,
  GridIcon,
  Handshake,
  Info,
  ListIcon,
  LocationIcon,
  More,
  PaintBrush,
  Pause,
  Piechart,
  Play,
  Remove,
  Settings,
  Sorting,
  SortingAscending,
  SortingDescending,
  Trash,
  Tree,
  Upload,
  Wrench,
  WrenchScrewDriver,
};

type IconProps = {
  className?: string;
  color?: string;
  disabled?: boolean;
  fill?: string;
  iconName: keyof typeof icons;
  onClick?: string;
  size: "lg" | "md" | "sm";
  stroke?: string;
  style?: string;
  svgClassName?: string;
};

export const Icon = ({
  className,
  color,
  disabled,
  fill,
  iconName,
  onClick,
  size,
  stroke,
  style,
  svgClassName,
}: IconProps) => {
  fill = (fill && disabled && "var(--clr-gray-500)") || fill;
  stroke = (stroke && disabled && "var(--clr-gray-500)") || stroke;
  color = (color && disabled && "var(--clr-gray-500)") || color;

  const SelectedIcon = icons[iconName];

  const svgRef = useRef<SVGElement | undefined>(undefined);

  const [fillColor, setFillColor] = useState<string | undefined>();
  const [strokeColor, setStrokeColor] = useState<string | undefined>();

  useEffect(() => {
    if (color && svgRef.current) {
      const strokeAttribute = svgRef?.current?.children[0].getAttribute("stroke");
      const fillAttribute = svgRef?.current?.children[0].getAttribute("fill");
      if (strokeAttribute !== null || fillAttribute === null) setStrokeColor(color);
      if (fillAttribute !== null) setFillColor(color);
    }
    if (fill) setFillColor(fill);
    if (stroke) setStrokeColor(stroke);
  }, [svgRef, color, fill, stroke]);

  const widthAndHeight = size === "lg" ? 8 : size === "md" ? 6 : 4;
  const containerClassName = `w-${widthAndHeight} h-${widthAndHeight}`;

  const scaleClassName = size === "lg" ? "scale-[1.95]" : size === "md" ? "scale-[1.48]" : "";

  if (iconName)
    return (
      <Wrapper
        className={`${onClick && "cursor-pointer"} ${containerClassName} ${className}`}
        fill={fillColor}
        onClick={onClick}
        stroke={strokeColor}
        style={style}
      >
        <SelectedIcon className={`${scaleClassName} ${svgClassName}`} color={color} ref={svgRef} />
      </Wrapper>
    );
  else return <span />;
};
const Wrapper = styled("span")<{ fill?: string; stroke?: string }>(({ fill, stroke }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",

  path: {
    fill: fill,
    stroke: stroke,
  },
}));
