import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "./ButtonNew";
import { Icon } from "./Icon";
import { LinearProgressWithLabel } from "./ProgressBar";
export function Dropzone({
  cancelUpload,
  className,
  getInputProps,
  getRootProps,
  open,
  progress,
  selectedFileName,
  upload,
  uploadState,
}) {
  const { t } = useTranslation();
  return (
    <Wrapper className={className} {...getRootProps({ className: "dropzone" })}>
      <div className="icon-and-button">
        <input {...getInputProps()} id="input" />
        {uploadState === "FileNotSelected" && (
          <>
            <Icon iconName="Upload" size="md" />
            <Button className="browse-btn capitalize" onClick={open} size="md" variant="primary">
              {t("general.browse")}
            </Button>
          </>
        )}
        {uploadState === "FileSelected" && (
          <>
            <p className="t-body-l text-gray-800">{selectedFileName}</p>
            <Button className="upload-btn capitalize" onClick={upload} size="md" variant="primary">
              {t("general.upload")}
            </Button>
          </>
        )}
        {uploadState === "UploadStarted" && (
          <>
            <Box sx={{ width: "80%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
            <p className="upload-status t-body-xs sentence-case text-gray-800">
              {t("general.uploadingFile")} ... {`${Math.round(progress)}%`}
            </p>
            <Button className="cancel-btn capitalize" onClick={cancelUpload} size="md" variant="secondary">
              {t("general.cancel")}
            </Button>
          </>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled("div")({
  ".cancel-btn, .browse-btn, .upload-btn": {
    marginTop: "1.6rem",
  },
  ".icon-and-button": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "8.4rem",
  },

  ".upload-status": {
    marginTop: "0.8rem",
  },
  border: "1px dashed var(--clr-gray-200)",
  borderRadius: "4px",
});
