export default {
  nav: {
    "building automation": "building automation",
    "building automationDetails": "building automation details",
    "building automationFilesDownload": "Download building automation files",
    "building envelope": "building envelope",
    "cooling system": "cooling system",
    "cooling systemDetails": "cooling system details",
    "cooling systemFilesDownload": "Download cooling system files",
    energyMix: "Energy mix",
    energyMixDetails: "Energy mix details",
    energyMixFilesDownload: "Download energy mix files",
    "energy supply system": "energy supply system",
    "heating system": "heating system",

    "heating systemDetails": "heating system details",
    "heating systemFilesDownload": "Download heating system files",
    "hot water": "hot water",
    "hot waterDetails": "hot water details",
    "hot waterFilesDownload": "Download hot water files",
    other: "other",
    "PV system": "PV system",
    "PV systemDetails": "PV system details",
    "PV systemFilesDownload": "Download PV system files",

    rooftop: "rooftop",
    rooftopDetails: "rooftop details",
    rooftopFilesDownload: "Download rooftop files",
    "shading system": "shading system",
    "shading systemDetails": "shading system details",
    "shading systemFilesDownload": "Download shading system files",
    wall: "wall",
    wallDetails: "wall details",
    wallFilesDownload: "Download wall files",

    windows: "windows",
    windowsDetails: "windows details",
    windowsFilesDownload: "Download windows files",
  },
  noInfoAvailable: "There is no information available!",
  table: {
    columns: {
      Area: "Area",
      Colour: "Colour",
      "Created at": "Created at",
      Density: "Density",
      Description: "Description",
      files: "files",
      Files: "Files",
      gValue: "G-Value",
      "Heat capacity": "Heat capacity",
      Id: "Id",
      Installed: "Installed",
      lastChecked: "Last checked",
      Layer: "Layer",
      Modernization: "Modernization",
      numberOfWindows: "Number of windows",

      "Thermal conductivity": "Thermal conductivity",
      Thickness: "Thickness",
      "Updated at": "Updated at",
      uValue: "U-Value",
      windowType: "Window type",
    },
    title: {},
  },
};
