import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import { formatNumberBasedOnUser } from "../utils/namesManipulation";
import { cn } from "../utils/styling";

export const NumericRangePicker = ({
  changeByTyping,
  className,
  label,
  max,
  min,
  setValue,
  step,
  style,
  value,
  ...props
}) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sliderValue = [value?.[0], value?.[1]];
  const valueIsNotSelected = sliderValue[0] === min && sliderValue[1] === max;

  return (
    <div
      className={cn("h-12", className)}
      style={{
        backgroundColor: "var(--clr-white)",
        border: `1px solid ${valueIsNotSelected ? "var(--clr-gray-900)" : "var(--clr-leaftech-blue-500)"}`,
        borderRadius: "7px",
        fontFamily: "Nunito Sans",
        fontSize: "1.4rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "1.9rem",
        padding: "4px 12px",
        ...style,
      }}
      {...props}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <span
          style={{
            fontSize: "1.2rem",
          }}
        >
          {label}
        </span>
        <Stack className="ml-2" gap={1}>
          {changeByTyping && (
            <>
              <input
                className="t-numbers-xxs w-20 rounded border-gray-100 px-1"
                onChange={(e) => setValue([e.target.value, sliderValue[1]])}
                value={formatNumberBasedOnUser(sliderValue[0])}
              />
              <span>-</span>
              <input
                className="t-numbers-xxs w-20 rounded border-gray-100 px-1"
                onChange={(e) => setValue([sliderValue[0], e.target.value])}
                value={formatNumberBasedOnUser(sliderValue[1])}
              />
            </>
          )}
          {!changeByTyping && (
            <>
              <span className="t-numbers-xxs">{formatNumberBasedOnUser(sliderValue[0])}</span>
              <span>-</span>
              <span className="t-numbers-xxs">{formatNumberBasedOnUser(sliderValue[1])}</span>
            </>
          )}
        </Stack>
      </div>
      <div className="w-full">
        <Box
          sx={{
            alignItems: "center",
            color: "#000",
            display: "flex",
            fontSize: "1.2rem",
            gap: "16px",
          }}
        >
          <span>{formatNumberBasedOnUser(min)}</span>
          <Slider
            max={max}
            min={min}
            onChange={handleChange}
            size="small"
            step={step ?? (min < 1 ? 0.01 : max < 10 ? 0.1 : 1)}
            sx={{
              padding: "2px",
            }}
            value={sliderValue}
            valueLabelDisplay="off"
          />
          <span>{formatNumberBasedOnUser(max)}</span>
        </Box>
      </div>
    </div>
  );
};
