export const chartTypesEnums = Object.freeze({
  AREA: "area",
  BAR: "bar",
  COMPOSED: "composed",
  DOUGHNUT: "doughnut",
  HEATMAP: "heatmap",
  HISTOGRAM: "histogram",
  KPI: "kpi",
  LINE: "line",
  MINI_MAP: "miniMap",
  PIE: "pie",
  SCATTER: "scatter",
});

export const colorCategoriesEnums = Object.freeze({
  COLUMNS: "Columns",
  MIXED: "Mixed",
  VALUES: "Values",
});

export const defaultColors = Object.freeze({
  [colorCategoriesEnums.COLUMNS]: {},
  [colorCategoriesEnums.VALUES]: {},
});

export const valuesEnums = Object.freeze({
  COUNT: "Count",
  DIAGRAM_FILTER_PREFIX: "diagramFilter_",
  INDEX: "Index",
  NO_DATA: "No Data",
});

export const diagramTypes = Object.freeze({
  AREA: "area",
  BAR: "bar",
  LINE: "line",
  SCATTER: "scatter",
});

export const diagramTypesDisplayOptions = Object.freeze({
  AREA: "Area",
  BAR: "Bar",
  LINE: "Line",
  SCATTER: "Scatter",
});

export const getSortOptions = ({ chartType }) => {
  let displayOptions = [],
    options = [];
  options = ["x", "y"];
  displayOptions = ["X Axis", "Y Axis"];
  if (chartType === chartTypesEnums.LINE) {
    options = ["x", "y", "All values"];
    displayOptions = ["X Axis", "Y Axis", "All values"];
  }
  return { displayOptions, options };
};
