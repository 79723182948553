import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { theme } from "../src/styles/theme";

import "react-toastify/dist/ReactToastify.css";

import {
  Administration,
  AssetOverview,
  BuildingPassportSharedLayout,
  BuildingProfile,
  Login,
  Modernization,
  ProtectedRoute,
  SharedLayout,
  TechnicalData,
} from "./pages";
import { EnergyPerformanceNewMultiTab } from "./pages/EnergyPerformanceNewMultiTab";
import { LandingPageSharedLayout } from "./pages/LandingPageSharedLayout";
import "./App.css";
import "./styles/rsuitjsCustom.css";
import { Portfolio } from "./pages/Portfolio";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { store } from "./store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 10000000, // about 3 hours
      refetchOnWindowFocus: false,
      retry: false,
      // cacheTime: 0,
      staleTime: 10000000,
    },
  },
});
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Routes>
                <Route element={<Login />} path="login" />
                <Route element={<PrivacyPolicy />} path="privacyPolicy" />
                <Route
                  element={
                    <ProtectedRoute>
                      <SharedLayout />
                    </ProtectedRoute>
                  }
                  path="/"
                >
                  <Route element={<LandingPageSharedLayout />} path="">
                    <Route element={<AssetOverview />} path="" />
                    <Route element={<Portfolio />} path="portfolio" />
                  </Route>
                  <Route element={<Administration />} path="administration" />
                  <Route element={<BuildingPassportSharedLayout />} path="/details/:projectId/">
                    <Route element={<BuildingProfile />} index path="buildingProfile" />
                    <Route element={<EnergyPerformanceNewMultiTab />} path="energyPerformance" />
                    <Route element={<TechnicalData />} path="technicalData" />
                    <Route element={<Modernization />} path="modernization" />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
            <ToastContainer />
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
