import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const Footer = ({ className }) => {
  const { t } = useTranslation();
  return (
    <footer className={`t-body-s mt-3 h-8 border-t-gray-100 bg-gray-50 pt-4 text-gray-600 ${className}`}>
      <Stack className="justify-between">
        <span className="text-gray-600">{t(`SharedLayout.footer.copyright`)}</span>
        <Stack className="text-gray-600" spacing={8}>
          <span className="text-blue-500">{t(`SharedLayout.footer.imprint`)}</span>
          <span className="text-blue-500">{t(`SharedLayout.footer.privacyPolicy`)}</span>
        </Stack>
      </Stack>
    </footer>
  );
};
