import { getColumnDisplayName, settingsOptions } from "../../utils/dynamicDashboard";
import { chartTypesEnums, colorCategoriesEnums } from "../dynamicDashboard";

export const getFinancialDashboardColumnDisplayName = ({ colName }) => {
  return getColumnDisplayName({ colName, dataMapping });
};

export const FiltersNames = Object.freeze({
  AGGREGATE_X_AXIS: "aggregateXAxis",
  DATAPOINT: "dataPoint",
  DIAGRAMS: "diagrams",
  DIAGRAMS_COLOR: "color",
  DIAGRAMS_DIAGRAM_DATA_KEY: "dataKey",
  DIAGRAMS_DIAGRAM_TYPE: "diagramType",
  DIAGRAMS_DIRECTION: "direction",
  DIAGRAMS_PARTITIONS: "partitions",
  DIAGRAMS_STACKS: "stacks",
  INVERT_X_AXIS: "invertXAxis",
  INVERT_Y_AXIS: "invertYAxis",
  LEGEND_POSITION: "legendsPosition",
  SHOW_PIE_LABELS: "showPieLabels",
  SORT_VALUE: "sortValue",
  TITLE: "title",
  X_AXIS: "xAxis",
  Y_AXIS: "yAxis",
});

export const columnsDisplayNamesEnums = Object.freeze({
  ADAPTATION: "Adaptation",
  CARBON_DIOXIDE_SAVINGS: "CO₂ Emission Savings",
  COMPONENT: "Component",
  COST_SAVINGS: "Cost Savings",
  ELECTRICITY_COST_SAVINGS: "Electricity Cost Savings",
  ELECTRICITY_REDUCTION: "Reduction in Electricity Demand",
  EMBODIED_CARBON: "Embodied Carbon",
  FOEIGN_CAPITAL: "Foreign Capital",
  HEAT_ENERGY_COST_SAVINGS: "Heat Energy Cost Savings",
  HEAT_REDUCTION: "Reduction in Heat Energy Demand",
  ID: "ID",
  INFLATION_RATE: "Inflation Rate",

  INTEREST_RATE: "Interest Rate",
  // UTILTIY_COST_REDUCTION: "utility_cost_reduction",
  // CARBON_DIOXIDE_REDUCTION: "carbon_dioxide_reduction",
  INVESTMENTS: "Investment Costs",
  LOAN_TYPE: "Loan Type",
  MAINTENANCE_COST: "Maintanance Costs",
  MEASURE_DESCRIPTION: "Measure Description",
  NAME: "Name",
  OTHER_COST_SAVINGS: "Other Cost Saving",
  OWN_CAPITAL: "Own Capital",
  // New
  PDF_URL: "PDF URL",
  REDUCTION_ELECTRICITY_END: "Reduction in Electricity End Energy",
  REDUCTION_ELECTRICITY_PRIMARY: "Reduction in Electricity Primary Energy",
  REDUCTION_ENERGY_DEMAND: "Reduction in Energy Demand",
  REDUCTION_ENERGY_END: "Reduction in End Energy",
  REDUCTION_ENERGY_PRIMARY: "Reduction in Primary Energy",
  REDUCTION_HEAT_END: "Reduction in Heat End Energy",
  REDUCTION_HEAT_PRIMARY: "Reduction in Heat Primary Energy",
  REPAYMENT_TIME: "Repayment Time",
  ROI: "Roi",
  SERVICES_COST: "Services Costs",
  SUB_COMPONENT: "Sub Component",
  SUBSIDIES: "Subsidies",
  // Not in api result
  TOTAL_COST: "Total costs",

  TOTAL_ENERGY_REDUCTION: "Reduction in energy",
  TYPE_OF_MESSURE: "Type of Measure",
});

export const columnsAPINamesEnums = Object.freeze({
  ADAPTATION: "adaptation",
  CARBON_DIOXIDE_SAVINGS: "carbon_dioxide_savings",
  COMPONENT: "component",
  COST_SAVINGS: "cost_savings",
  ELECTRICITY_COST_SAVINGS: "electricity_cost_saving",
  ELECTRICITY_REDUCTION: "electricity_reduction",
  EMBODIED_CARBON: "embodied_carbon",
  FOEIGN_CAPITAL: "foreign_capital",
  HEAT_ENERGY_COST_SAVINGS: "heat_energy_cost_saving",
  HEAT_REDUCTION: "energy_reduction",
  ID: "id",
  INFLATION_RATE: "inflation_rate",

  INTEREST_RATE: "interest_rate",
  // UTILTIY_COST_REDUCTION: "utility_cost_reduction",
  // CARBON_DIOXIDE_REDUCTION: "carbon_dioxide_reduction",
  INVESTMENTS: "investments",
  LOAN_TYPE: "Loan_type",
  MAINTENANCE_COST: "maintanance_cost",
  MEASURE_DESCRIPTION: "measure_description",
  NAME: "name",
  OTHER_COST_SAVINGS: "other_cost_saving",
  OWN_CAPITAL: "own_capital",
  // New
  PDF_URL: "pdf_url",
  REDUCTION_ELECTRICITY_END: "reduction_in_electricity_end_energy",
  REDUCTION_ELECTRICITY_PRIMARY: "reduction_in_electricity_primary_energy",
  REDUCTION_ENERGY_DEMAND: "reduction_in_energy_demand",
  REDUCTION_ENERGY_END: "reduction_in_end_energy",
  REDUCTION_ENERGY_PRIMARY: "reduction_in_primary_energy",
  REDUCTION_HEAT_END: "reduction_in_heat_end_energy",
  REDUCTION_HEAT_PRIMARY: "reduction_in_heat_primary_energy",
  REPAYMENT_TIME: "repayment_time",
  ROI: "roi",
  SERVICES_COST: "services_cost",
  SUB_COMPONENT: "sub_component",
  SUBSIDIES: "subsidies",
  // Not in api result
  TOTAL_COST: "total_cost",

  TOTAL_ENERGY_REDUCTION: "total_energy_reduction",
  TYPE_OF_MESSURE: "type_of_measure",
});

export const dataMapping = {
  [columnsAPINamesEnums.ADAPTATION]: [columnsDisplayNamesEnums.ADAPTATION],
  [columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS]: [columnsDisplayNamesEnums.CARBON_DIOXIDE_SAVINGS],
  [columnsAPINamesEnums.COMPONENT]: [columnsDisplayNamesEnums.COMPONENT],
  [columnsAPINamesEnums.COST_SAVINGS]: [columnsDisplayNamesEnums.COST_SAVINGS],
  [columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS]: [columnsDisplayNamesEnums.ELECTRICITY_COST_SAVINGS],
  [columnsAPINamesEnums.ELECTRICITY_REDUCTION]: [columnsDisplayNamesEnums.ELECTRICITY_REDUCTION],
  [columnsAPINamesEnums.EMBODIED_CARBON]: [columnsDisplayNamesEnums.EMBODIED_CARBON],
  [columnsAPINamesEnums.EMBODIED_CARBON]: [columnsDisplayNamesEnums.EMBODIED_CARBON],
  [columnsAPINamesEnums.FOEIGN_CAPITAL]: [columnsDisplayNamesEnums.FOEIGN_CAPITAL],
  [columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS]: [columnsDisplayNamesEnums.HEAT_ENERGY_COST_SAVINGS],
  // [columnsAPINamesEnums.UTILTIY_COST_REDUCTION]: [columnsDisplayNamesEnums.UTILTIY_COST_REDUCTION],
  // [columnsAPINamesEnums.CARBON_DIOXIDE_REDUCTION]: [columnsDisplayNamesEnums.CARBON_DIOXIDE_REDUCTION],
  [columnsAPINamesEnums.HEAT_REDUCTION]: [columnsDisplayNamesEnums.HEAT_REDUCTION],
  [columnsAPINamesEnums.ID]: [columnsDisplayNamesEnums.ID],
  [columnsAPINamesEnums.INFLATION_RATE]: [columnsDisplayNamesEnums.INFLATION_RATE],
  [columnsAPINamesEnums.INTEREST_RATE]: [columnsDisplayNamesEnums.INTEREST_RATE],
  [columnsAPINamesEnums.INVESTMENTS]: [columnsDisplayNamesEnums.INVESTMENTS],

  [columnsAPINamesEnums.LOAN_TYPE]: [columnsDisplayNamesEnums.LOAN_TYPE],
  [columnsAPINamesEnums.MAINTENANCE_COST]: [columnsDisplayNamesEnums.MAINTENANCE_COST],
  [columnsAPINamesEnums.MEASURE_DESCRIPTION]: [columnsDisplayNamesEnums.MEASURE_DESCRIPTION],
  [columnsAPINamesEnums.NAME]: [columnsDisplayNamesEnums.NAME],
  [columnsAPINamesEnums.OTHER_COST_SAVINGS]: [columnsDisplayNamesEnums.OTHER_COST_SAVINGS],
  [columnsAPINamesEnums.OWN_CAPITAL]: [columnsDisplayNamesEnums.OWN_CAPITAL],
  [columnsAPINamesEnums.PDF_URL]: [columnsDisplayNamesEnums.PDF_URL],
  [columnsAPINamesEnums.REDUCTION_ELECTRICITY_END]: [columnsDisplayNamesEnums.REDUCTION_ELECTRICITY_END],
  [columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY]: [columnsDisplayNamesEnums.REDUCTION_ELECTRICITY_PRIMARY],
  [columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND]: [columnsDisplayNamesEnums.REDUCTION_ENERGY_DEMAND],
  [columnsAPINamesEnums.REDUCTION_ENERGY_END]: [columnsDisplayNamesEnums.REDUCTION_ENERGY_END],
  [columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY]: [columnsDisplayNamesEnums.REDUCTION_ENERGY_PRIMARY],
  [columnsAPINamesEnums.REDUCTION_HEAT_END]: [columnsDisplayNamesEnums.REDUCTION_HEAT_END],
  [columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY]: [columnsDisplayNamesEnums.REDUCTION_HEAT_PRIMARY],
  [columnsAPINamesEnums.REPAYMENT_TIME]: [columnsDisplayNamesEnums.REPAYMENT_TIME],
  [columnsAPINamesEnums.ROI]: [columnsDisplayNamesEnums.ROI],
  [columnsAPINamesEnums.SERVICES_COST]: [columnsDisplayNamesEnums.SERVICES_COST],
  [columnsAPINamesEnums.SUB_COMPONENT]: [columnsDisplayNamesEnums.SUB_COMPONENT],
  [columnsAPINamesEnums.SUBSIDIES]: [columnsDisplayNamesEnums.SUBSIDIES],
  [columnsAPINamesEnums.TOTAL_COST]: [columnsDisplayNamesEnums.TOTAL_COST],
  [columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION]: [columnsDisplayNamesEnums.TOTAL_ENERGY_REDUCTION],
  [columnsAPINamesEnums.TYPE_OF_MESSURE]: [columnsDisplayNamesEnums.TYPE_OF_MESSURE],
};

export const columnsToExclude = [columnsAPINamesEnums.PDF_URL];

export const categoricalColumns = [
  columnsAPINamesEnums.NAME,
  columnsAPINamesEnums.COMPONENT,
  columnsAPINamesEnums.SUB_COMPONENT,
  columnsAPINamesEnums.TYPE_OF_MESSURE,

  columnsAPINamesEnums.MEASURE_DESCRIPTION,
  columnsAPINamesEnums.LOAN_TYPE,
];

export const columnsUnits = Object.freeze({
  [columnsAPINamesEnums.ADAPTATION]: "%",
  [columnsAPINamesEnums.CARBON_DIOXIDE_SAVINGS]: "kg/m²/a",
  [columnsAPINamesEnums.COMPONENT]: "",
  [columnsAPINamesEnums.COST_SAVINGS]: "€/a/m²",
  [columnsAPINamesEnums.ELECTRICITY_COST_SAVINGS]: "€/a/m²",
  [columnsAPINamesEnums.ELECTRICITY_REDUCTION]: "kWh/m²",
  [columnsAPINamesEnums.EMBODIED_CARBON]: "kg CO₂/m²",
  [columnsAPINamesEnums.FOEIGN_CAPITAL]: "€/m²",
  [columnsAPINamesEnums.HEAT_ENERGY_COST_SAVINGS]: "€/a/m²",
  [columnsAPINamesEnums.HEAT_REDUCTION]: "kWh/m²",
  [columnsAPINamesEnums.ID]: "",
  [columnsAPINamesEnums.INFLATION_RATE]: "%",
  [columnsAPINamesEnums.INTEREST_RATE]: "%",
  [columnsAPINamesEnums.INVESTMENTS]: "€/m²",

  [columnsAPINamesEnums.LOAN_TYPE]: "",
  [columnsAPINamesEnums.MAINTENANCE_COST]: "€/a/m²",
  [columnsAPINamesEnums.MEASURE_DESCRIPTION]: "",
  [columnsAPINamesEnums.NAME]: "",
  [columnsAPINamesEnums.OTHER_COST_SAVINGS]: "€/a/m²",
  [columnsAPINamesEnums.OWN_CAPITAL]: "€/m²",
  [columnsAPINamesEnums.PDF_URL]: "",
  [columnsAPINamesEnums.REDUCTION_ELECTRICITY_END]: "kWh/m²",
  [columnsAPINamesEnums.REDUCTION_ELECTRICITY_PRIMARY]: "kWh/m²",
  [columnsAPINamesEnums.REDUCTION_ENERGY_DEMAND]: "kWh/m²",
  [columnsAPINamesEnums.REDUCTION_ENERGY_END]: "kWh/m²",
  [columnsAPINamesEnums.REDUCTION_ENERGY_PRIMARY]: "kWh/m²",
  [columnsAPINamesEnums.REDUCTION_HEAT_END]: "kWh/m²",
  [columnsAPINamesEnums.REDUCTION_HEAT_PRIMARY]: "kWh/m²",
  [columnsAPINamesEnums.REPAYMENT_TIME]: "years",
  [columnsAPINamesEnums.ROI]: "a",
  [columnsAPINamesEnums.SERVICES_COST]: "€/a/m²",
  [columnsAPINamesEnums.SUB_COMPONENT]: "",
  [columnsAPINamesEnums.SUBSIDIES]: "€/m²",
  [columnsAPINamesEnums.TOTAL_COST]: "",
  [columnsAPINamesEnums.TOTAL_ENERGY_REDUCTION]: "kWh/m²",
  [columnsAPINamesEnums.TYPE_OF_MESSURE]: "",
});

export const defaultColors = Object.freeze({
  [colorCategoriesEnums.COLUMNS]: {},
  [colorCategoriesEnums.VALUES]: {},
});

const relevantConfigOptions = {
  [chartTypesEnums.AREA]: [
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.HEIGHT_100,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.SORT_BY,
    settingsOptions.DATA_POINT,
    settingsOptions.TITLE,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.BAR]: [
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.SORT_BY,
    settingsOptions.DATA_POINT,
    settingsOptions.TITLE,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.COMPOSED]: [
    settingsOptions.TITLE,
    settingsOptions.X,
    settingsOptions.QUANTITY,
    settingsOptions.SORT_BY,
    settingsOptions.DIAGRAM_TYPE,
    settingsOptions.COLOR,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
    settingsOptions.AGGREGATE_X_AXIS,
  ],
  [chartTypesEnums.DOUGHNUT]: [
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.TITLE,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.SHOW_PIE_LABELS,
  ],
  [chartTypesEnums.HEATMAP]: [
    settingsOptions.TITLE,
    settingsOptions.X,
    settingsOptions.Y,
    settingsOptions.COLOR,
    settingsOptions.DATA_POINT,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.HISTOGRAM]: [
    settingsOptions.TITLE,
    settingsOptions.QUANTITY,
    settingsOptions.SORT_BY,
    settingsOptions.COLOR,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.KPI]: [
    settingsOptions.TITLE,
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.DIRECTION,
    settingsOptions.DATA_POINT,
  ],
  [chartTypesEnums.LINE]: [
    settingsOptions.TITLE,
    settingsOptions.X,
    settingsOptions.PARTITIONS,
    settingsOptions.SORT_BY,
    settingsOptions.DIRECTION,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT,
    settingsOptions.INVERT_X_AXIS,
    settingsOptions.INVERT_Y_AXIS,
  ],
  [chartTypesEnums.MINI_MAP]: [settingsOptions.DATA_POINT, settingsOptions.COLOR_BAR_RANGE],
  [chartTypesEnums.PIE]: [
    settingsOptions.PARTITIONS,
    settingsOptions.STACKS,
    settingsOptions.TITLE,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT,
    settingsOptions.SHOW_PIE_LABELS,
  ],
};

export const isRelevantOption = (chartType, option, index) => {
  if (index > 0 && chartType !== chartTypesEnums.HISTOGRAM && chartType !== chartTypesEnums.COMPOSED) return false;
  return relevantConfigOptions[chartType]?.includes(option);
};

// export const FINANCIAL_DASHBOARD_NAME_IN_API_BODY = 'portfolio'
