import { Stack } from "@mui/material";

import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";
import { BulletChartAxis } from "./BulletChartAxis";
import { BulletChartColorBar } from "./BulletChartColorBar";
import BulletChartLegend from "./BulletChartLegend";
import { BulletChartZones } from "./BulletChartZones";
import { Pill } from "./Pill";

export const BulletChart = ({
  axis,
  bulletColorBarClassName,
  bullets,
  className,
  hasZones = false,
  header,
  isLoading,
  legends,
  pill,
  zones,
}) => {
  return (
    <LoadingOrEmptyWrapper height="16rem" showLoading={isLoading}>
      {/* <Paper className={`${className} `}> */}
      <div className={className}>
        {/* Header */}
        <Stack spacing={4}>
          <h2 className="t-heading-l">{header}</h2>
          <Pill>{pill}</Pill>
        </Stack>
        <BulletChartLegend className="mt-4" hasZones={hasZones} legends={legends} />
        {/* Chart */}
        <div className="relative mt-6 h-23 pt-8">
          <BulletChartColorBar
            axis={axis}
            bullets={bullets}
            className="h-4"
            gradientClassName={bulletColorBarClassName}
          />
          <BulletChartAxis axis={axis} className="mt-6" />
          {hasZones && <BulletChartZones axis={axis} className="mt-6" zones={zones} />}
        </div>
        {/* </Paper> */}
      </div>
    </LoadingOrEmptyWrapper>
  );
};

export const BulletChartDoubleWithIntervals = ({
  axis,
  bulletColorBarClassName,
  bulletColorBarStyle,
  bullets,
  className,
  isLoading,
  labels,
  thresholds,
}) => {
  const axisLength = axis?.[axis?.length - 1] - axis?.[0];
  return (
    <LoadingOrEmptyWrapper height="16rem" showLoading={isLoading}>
      <div className={className}>
        {/* Chart */}
        <div className="relative h-23">
          <div className="h-6">
            <div className="relative h-full">
              <BulletChartColorBar
                axis={axis}
                bulletProps={{
                  bottom: "16px",
                }}
                bullets={bullets?.filter((bullet) => !bullet.reverse)}
                className="h-6 rounded-none rounded-tl-full rounded-tr-full"
                gradientClassName={bulletColorBarClassName}
                style={bulletColorBarStyle}
              />
              <Stack className="absolute top-0 h-full w-full">
                {thresholds.map((threshold, index) => {
                  const maxIndex = thresholds?.length - 1;
                  if (index === maxIndex) return;
                  const label = labels[index];
                  // const labelIntervalLength = thresholds[index + 1] - threshold
                  const isLastLabel = index === maxIndex - 1;
                  const labelIntervalLength = !isLastLabel
                    ? thresholds[index + 1] - threshold
                    : axis[axis?.length - 1] - threshold;
                  const labelWidth = (100 * labelIntervalLength) / axisLength;
                  return (
                    <Stack
                      className="h-full justify-center"
                      key={index}
                      style={{ borderRight: !isLastLabel && "1px solid black", width: `${labelWidth}%` }}
                    >
                      <span className="t-numbers-m">{label}</span>
                    </Stack>
                  );
                })}
              </Stack>
            </div>
          </div>
          <BulletChartAxis axis={axis} className="relative flex h-6 items-center" />
          <BulletChartColorBar
            axis={axis}
            bulletProps={{ top: "16px" }}
            bullets={bullets?.filter((bullet) => bullet.reverse)}
            className="h-6 rounded-none rounded-bl-full rounded-br-full"
            gradientClassName={bulletColorBarClassName}
            style={bulletColorBarStyle}
          />
        </div>
      </div>
    </LoadingOrEmptyWrapper>
  );
};
