export default {
  bulletChart: {
    carbonTitle: "CO₂ Emissionen",
    energyTitle: "Energiebedarf",
    "High tax zone": "Hochsteuerzone",
    "Low tax zone": "Niedrigsteuerzone",
    "Net-Zero emission": "Net-Zero Emissionen",
    "net zero": "Net-Zero",
    "Status quo": "Status quo",
    "Target value": "Zielwert",
  },
  dashboard: {
    addNewChart: "Diagramm hinzufügen",
    areaIsMissing: "Die Gebäudefläche fehlt, deswegen sind die Nummern ungültig.",
    cancel: "abbrechen",
    "CO₂ emission": "CO₂ emissionen",
    confirmSelection: "bestätigen",
    Consumption: "Verbrauch",
    "Cooling system": "Kühlsystem",
    "Cooling system-Measured": "Kühlsystem-Gemessen",
    "Cooling system-Simulated": "Kühlsystem-Simuliert",
    dataDropDownLabel: "Datenpunkte auswählen",
    datesMustBeSelected: "Startdatum und Enddatum müssen ausgewählt werden.",
    deleteChart: "Diagramm entfernen",
    downloadText: "Informationen herunterladen",
    Electricity: "Strom",
    "Electricity-Measured": "Strom-Gemessen",
    "Electricity-Simulated": "Strom-Simuliert",
    getDataProblem: "Beim Abrufen der Daten ist ein Problem aufgetreten!",
    "Heating system": "Heizsystem",
    "Heating system-Measured": "Heizsystem-Gemessen",
    "Heating system-Simulated": "Heizsystem-Simuliert",
    "Hot water system": "Warmwassersystem",
    "Hot water system-Measured": "Warmwassersystem-Gemessen",
    "Hot water system-Simulated": "Warmwassersystem-Simuliert",
    measured: "Gemessen",
    noData: "Nichts vorhanden!",
    Price: "Preis",
    simulated: "Simuliert",
    title: "Leistungs - Dashboard",
    total: "gesamtsumme",
    totalConsumption: "total cosumption",
    totalEmission: "total emission",
    totalPrice: "total price",
  },
  table: {
    columns: {
      "CO₂ emission": "CO₂ emissionen",
      Price: "Preis",
      Source: "Herkunft",
      Type: "Typ",
      "Utility mix": "Versorgung",
    },
    downloadText: "Informationen herunterladen",
    title: "Energiemix der Liegenschaft",
  },
};
