import { useMediaQuery } from "@mui/material";
// TODO: send a credential error toast when 401.
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import { loginUser } from "../api/functions/all";
import apiUrls from "../api/urls/";
import logo from "../Asset/LEAFTECH.svg";
import backgroundImgCropped from "../Asset/LooperBGCropped.png";
import backgroundImgLg from "../Asset/LooperBGLg.png";
import { ButtonNew, Checkbox, CustomModal, Input } from "../components";
import { PasswordInput } from "../components/Input";
import { setIsInactivelyLoggedOut, setUser } from "../features/user/userSlice";
import { addUserToLocalStorage } from "../utils/localStorage";

const Login = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("loginChangeBackground"));

  const { i18n, t } = useTranslation();
  const alternativeLanguage = i18n.language === "en" ? "de" : "en";

  const dispatch = useDispatch();
  const { isInactivelyLoggedOut, user } = useSelector((store) => store.user);
  const [isLoading, setIsLoading] = useState(false);
  const [consent, setConsent] = useState(false);
  const name = useRef("");
  const password = useRef("");
  const hanleSubmit = async (e) => {
    e.preventDefault();
    if (!name.current?.value | !password.current?.value | !consent) {
      toast.error(t("Login.form.fieldsMustBeFilled"));
      return;
    }
    const values = {
      grant_type: "password",
      password: password.current?.value,
      username: name.current?.value,
    };
    setIsLoading(true);
    loginUser(values)
      .then((resp) => {
        setIsLoading(false);
        dispatch(setUser(resp));
        addUserToLocalStorage(resp);
        toast.success(t("Login.welcome"));
      })
      .catch((error) => {
        setIsLoading(false);
        const defaultErrorMessage = "sorry! there was a problem on our end!";
        const msg = error.response.status === 401 ? "username or password is wrong!" : defaultErrorMessage;
        toast.error(msg);
      });
  };

  const setModalOpen = (modalState) => dispatch(setIsInactivelyLoggedOut(modalState));

  if (user) return <Navigate to="/" />;
  return (
    <>
      <Helmet>
        <title>Login | Leaftech</title>
      </Helmet>
      <section>
        {/* Background image */}
        <img
          alt="Login Background"
          className="absolute right-0 h-screen w-7/10"
          src={isSmallScreen ? backgroundImgCropped : backgroundImgLg}
        />
        {/* Logo */}
        <div className="absolute right-0 p-6 text-center">
          <p className="t-subheading-m uppercase">{t("Login.logoText")}</p>
          <a href="https://leaftech.eu/" rel="noreferrer" target="_blank">
            <img alt="logo" className="mt-1" src={logo} />
          </a>
        </div>
        <ButtonNew
          className="absolute right-50 top-5 uppercase"
          onClick={() => {
            localStorage.setItem("language", alternativeLanguage);
            i18n.changeLanguage(alternativeLanguage);
          }}
          size="lg"
          variant="tertiary"
        >
          {alternativeLanguage}
        </ButtonNew>
        {/* Form */}

        <form className="absolute left-30 top-1/2 w-102 -translate-y-1/2">
          <h1 className="t-heading-xl">{t("Login.title")}</h1>
          <span className="t-heading-m mt-2 inline-block">{t("Login.subtitle")}</span>
          {/* inputs-container */}
          <div className="mt-10 w-full text-right">
            {/* username */}
            <Input
              className="mb-4 block w-full "
              height="4.8rem"
              inputRef={name}
              label={t("Login.form.username.placeholder")}
              placeholder={t("Login.form.username.placeholder")}
            />
            {/* password */}
            <PasswordInput
              className="mb-4 block w-full"
              height="4.8rem"
              inputRef={password}
              label={t("Login.form.password.placeholder")}
              placeholder={t("Login.form.password.placeholder")}
            />
            <a className="t-body-n text-blue-500" href={`${apiUrls.user.resetPassword}`}>
              {t("Login.form.forgotText")}
            </a>
          </div>
          {/* Agree to terms and conditions */}
          <div className="mb-4 mt-10 ">
            <Checkbox
              checked={consent}
              onChange={(e) => {
                setConsent(e.target.checked);
              }}
            >
              <span className="ml-3 inline-block">
                <span className="t-heading-s mr-1 inline-block cursor-pointer">{t("Login.form.consent")}</span>
                <Link to="/privacyPolicy">
                  <span className="t-body-n text-blue-500">{t("Login.form.toTermsAndConditions")}</span>
                </Link>
              </span>
            </Checkbox>
          </div>
          <ButtonNew disabled={isLoading} onClick={hanleSubmit} size="lg" type="submit" variant="primary">
            <p className="t-mobile-label">{t("Login.form.signInText")}</p>
          </ButtonNew>
          <div className="mt-4">
            <span className="t-heading-s">{t("Login.form.noAccount")}</span>
            <a className="t-body-n ml-1 inline-block text-blue-500" href="mailto:service@leaftech.eu">
              {t("Login.form.help")}
            </a>
          </div>
        </form>
        <CustomModal
          className="relative"
          height="21.6rem"
          modalOpen={isInactivelyLoggedOut}
          setModalOpen={setModalOpen}
        >
          <p className="t-heading-m text-gray-800">{t("SharedLayout.forceLogoutTitle")}</p>
          <p className="t-body-l mt-1 text-gray-500">{t("SharedLayout.forceLogoutText")}</p>
          <ButtonNew
            className="absolute left-1/2 mt-8 capitalize"
            onClick={() => setModalOpen(false)}
            size="md"
            style={{ transform: "translateX(-50%)" }}
            type="button"
            variant="primary"
          >
            {t("Login.login")}
          </ButtonNew>
        </CustomModal>
      </section>
    </>
  );
};

export default Login;
